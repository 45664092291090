import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, 
         s05_calcariospesquisa, s05_calcariosbuscaid, s05_calcariosnovo, s05_calcarioseditar, s05_calcariosdeletar, calcariospesquisa,
         s05_adubospesquisa, s05_adubosbuscaid, s05_adubosnovo, s05_aduboseditar, s05_adubosdeletar, adubospesquisa,
         s05_pragaspesquisa, s05_pragasbuscaid, s05_pragasnovo, s05_pragaseditar, s05_pragasdeletar, pragaspesquisa,
         s05_analisesdesolopesquisa, s05_analisesdesolobuscaid, s05_analisesdesolonovo, s05_analisesdesoloeditar, s05_analisesdesolodeletar, analisesdesolopesquisa } from '../../../Services/apifunctions';
import { decriptar, limpamask, decimalMask, ajustamaskdecimal, anoMask } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#535353",
      color: theme.palette.common.white,
    },
    body: {
        backgroundColor: "#f0f0f0",
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class S05_CaracteristicasEstabelecimento extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s05_caracteristicasestabelecimento : {
                cliente : '',
                id : '',
                associadoentidadeclasse : '',
                associadocooperativa : '',
                associadosindicado : '',
                associadomovimento : '',
                associadomoradores : '',
                energiaeletrica : '',
                recebeorientacao : '',
                orientacao_governo : '',
                orientacao_propria : '',
                orientacao_cooperativas : '',
                orientacao_empresasintegradoras : '',
                orientacao_empresasplanejamento : '',
                orientacao_ongs : '',
                orientacao_sistemas : '',
                orientacao_outra : '',
                informacoes_televisao : '',
                informacoes_radio : '',
                informacoes_internet : '',
                informacoes_revistas : '',
                informacoes_jornais : '',
                informacoes_reunioes : '',
                informacoes_outraforma : '',
                informacoes_naoobtem : '',
                praticas_plantionivel : '',
                praticas_rotacaoculturas : '',
                praticas_pousiodescansosolo : '',
                praticas_protecaoencostas : '',
                praticas_recuperacaomataciliar : '',
                praticas_reflorestamento : '',
                praticas_estabilizacaovocorocas : '',
                praticas_manejoflorestal : '',
                praticas_outraspraticas : '',
                praticas_nenhuma : '',
                fezaplicacorretivosph : '',
                anoaplicacaocorretivosph : '',
                fezadubacao : '',
                costumafazeradubacao : '',
                fezanalisesolo : '',
                anoanalisesolo : '',
                utilizouagrotoxicos : '',
                agriculturapecuariaorganica : '',
                sistemapreparosolo : '',
                areaplantiodireto : '',
                hanascentes : '',
                hariosriachos : '',
                hapocoscisternas : '',
                fezirrigacao : '',
                area_inundacao : '',
                area_sulcos : '',
                area_outrossuperficie : '',
                area_autopropelido : '',
                area_pivocentral : '',
                area_aspersao : '',
                area_gotejamento : '',
                area_microaspersao : '',
                area_outroslocalizada : '',
                area_subsuperficial : '',
                area_molhacao : '',
                aguapermanente : ''
            },
            calcarios: [],
            s05_calcarios : [],
            s05_calcario : {
                cliente : '',
                idcenso : '',
                id : '',
                idcalcario : '',
                quantidade : '',
                observacao : '',
                descricaocalcario : ''
            },
            adubos: [],
            s05_adubos : [],
            s05_adubo : {
                cliente : '',
                idcenso : '',
                id : '',
                idadubo : '',
                quantidade : '',
                observacao : '',
                descricaoadubo : '',
                composicaoadubo : ''
            },
            analisesdesolo: [],
            s05_analisesdesolo : [],
            s05_analisedesolo : {
                cliente : '',
                idcenso : '',
                id : '',
                idanalisedesolo : '',
                observacao : '',
                descricaoanalisedesolo : ''
            },
            pragas: [],
            s05_pragas : [],
            s05_praga : {
                cliente : '',
                idcenso : '',
                id : '',
                idpraga : '',
                produtoutilizado : '',
                observacao : '',
                descricaopraga : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s05_caracteristicasestabelecimento: {
                        ...this.state.s05_caracteristicasestabelecimento, 
                        cliente : dados.cliente,
                        id : dados.id,
                        associadoentidadeclasse : dados.associadoentidadeclasse,
                        associadocooperativa : dados.associadocooperativa,
                        associadosindicado : dados.associadosindicado,
                        associadomovimento : dados.associadomovimento,
                        associadomoradores : dados.associadomoradores,
                        energiaeletrica : dados.energiaeletrica,
                        recebeorientacao : dados.recebeorientacao,
                        orientacao_governo : dados.orientacao_governo,
                        orientacao_propria : dados.orientacao_propria,
                        orientacao_cooperativas : dados.orientacao_cooperativas,
                        orientacao_empresasintegradoras : dados.orientacao_empresasintegradoras,
                        orientacao_empresasplanejamento : dados.orientacao_empresasplanejamento,
                        orientacao_ongs : dados.orientacao_ongs,
                        orientacao_sistemas : dados.orientacao_sistemas,
                        orientacao_outra : dados.orientacao_outra,
                        informacoes_televisao : dados.informacoes_televisao,
                        informacoes_radio : dados.informacoes_radio,
                        informacoes_internet : dados.informacoes_internet,
                        informacoes_revistas : dados.informacoes_revistas,
                        informacoes_jornais : dados.informacoes_jornais,
                        informacoes_reunioes : dados.informacoes_reunioes,
                        informacoes_outraforma : dados.informacoes_outraforma,
                        informacoes_naoobtem : dados.informacoes_naoobtem,
                        praticas_plantionivel : dados.praticas_plantionivel,
                        praticas_rotacaoculturas : dados.praticas_rotacaoculturas,
                        praticas_pousiodescansosolo : dados.praticas_pousiodescansosolo,
                        praticas_protecaoencostas : dados.praticas_protecaoencostas,
                        praticas_recuperacaomataciliar : dados.praticas_recuperacaomataciliar,
                        praticas_reflorestamento : dados.praticas_reflorestamento,
                        praticas_estabilizacaovocorocas : dados.praticas_estabilizacaovocorocas,
                        praticas_manejoflorestal : dados.praticas_manejoflorestal,
                        praticas_outraspraticas : dados.praticas_outraspraticas,
                        praticas_nenhuma : dados.praticas_nenhuma,
                        fezaplicacorretivosph : dados.fezaplicacorretivosph,
                        anoaplicacaocorretivosph : dados.anoaplicacaocorretivosph,
                        fezadubacao : dados.fezadubacao,
                        costumafazeradubacao : dados.costumafazeradubacao,
                        fezanalisesolo : dados.fezanalisesolo,
                        anoanalisesolo : dados.anoanalisesolo,
                        utilizouagrotoxicos : dados.utilizouagrotoxicos,
                        agriculturapecuariaorganica : dados.agriculturapecuariaorganica,
                        sistemapreparosolo : dados.sistemapreparosolo,
                        areaplantiodireto : dados.areaplantiodireto,
                        hanascentes : dados.hanascentes,
                        hariosriachos : dados.hariosriachos,
                        hapocoscisternas : dados.hapocoscisternas,
                        fezirrigacao : dados.fezirrigacao,
                        area_inundacao : dados.area_inundacao,
                        area_sulcos : dados.area_sulcos,
                        area_outrossuperficie : dados.area_outrossuperficie,
                        area_autopropelido : dados.area_autopropelido,
                        area_pivocentral : dados.area_pivocentral,
                        area_aspersao : dados.area_aspersao,
                        area_gotejamento : dados.area_gotejamento,
                        area_microaspersao : dados.area_microaspersao,
                        area_outroslocalizada : dados.area_outroslocalizada,
                        area_subsuperficial : dados.area_subsuperficial,
                        area_molhacao : dados.area_molhacao,
                        aguapermanente : dados.aguapermanente
                    }}, () => {
                        this.setState({
                            s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, 
                                areaplantiodireto: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.areaplantiodireto),
                                area_inundacao: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_inundacao),
                                area_sulcos: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_sulcos),
                                area_outrossuperficie: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_outrossuperficie),
                                area_autopropelido: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_autopropelido),
                                area_pivocentral: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_pivocentral),
                                area_aspersao: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_aspersao),
                                area_gotejamento: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_gotejamento),
                                area_microaspersao: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_microaspersao),
                                area_outroslocalizada: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_outroslocalizada),
                                area_subsuperficial: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_subsuperficial),
                                area_molhacao: ajustamaskdecimal(this.state.s05_caracteristicasestabelecimento.area_molhacao)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                }
                else {
                    this.setState({s05_caracteristicasestabelecimento: {
                        ...this.state.s05_caracteristicasestabelecimento, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        associadoentidadeclasse : '1',
                        associadocooperativa : false,
                        associadosindicado : false,
                        associadomovimento : false,
                        associadomoradores : false,
                        energiaeletrica : '1',
                        recebeorientacao : '1',
                        orientacao_governo : false,
                        orientacao_propria : false,
                        orientacao_cooperativas : false,
                        orientacao_empresasintegradoras : false,
                        orientacao_empresasplanejamento : false,
                        orientacao_ongs : false,
                        orientacao_sistemas : false,
                        orientacao_outra : false,
                        informacoes_televisao : false,
                        informacoes_radio : false,
                        informacoes_internet : false,
                        informacoes_revistas : false,
                        informacoes_jornais : false,
                        informacoes_reunioes : false,
                        informacoes_outraforma : false,
                        informacoes_naoobtem : false,
                        praticas_plantionivel : false,
                        praticas_rotacaoculturas : false,
                        praticas_pousiodescansosolo : false,
                        praticas_protecaoencostas : false,
                        praticas_recuperacaomataciliar : false,
                        praticas_reflorestamento : false,
                        praticas_estabilizacaovocorocas : false,
                        praticas_manejoflorestal : false,
                        praticas_outraspraticas : false,
                        praticas_nenhuma : false,
                        fezaplicacorretivosph : '1',
                        anoaplicacaocorretivosph : '',
                        fezadubacao : '1',
                        costumafazeradubacao : '1',
                        fezanalisesolo : '1',
                        anoanalisesolo : '',
                        utilizouagrotoxicos : '1',
                        agriculturapecuariaorganica : '1',
                        sistemapreparosolo : '1',
                        areaplantiodireto : '',
                        hanascentes : '1',
                        hariosriachos : '1',
                        hapocoscisternas : '1',
                        fezirrigacao : '1',
                        area_inundacao : '',
                        area_sulcos : '',
                        area_outrossuperficie : '',
                        area_autopropelido : '',
                        area_pivocentral : '',
                        area_aspersao : '',
                        area_gotejamento : '',
                        area_microaspersao : '',
                        area_outroslocalizada : '',
                        area_subsuperficial : '',
                        area_molhacao : '',
                        aguapermanente : '1'
                    }});

                    this.setState({ novocadastro : true });
                }
            });
            
            this.PesquisaS05_Calcario();
            this.PesquisaS05_Adubo();
            this.PesquisaS05_AnaliseDeSolo();
            this.PesquisaS05_Praga();
        }
    };

    // Relacionamento Censo->Calcário

    PesquisaS05_Calcario() {
        s05_calcariospesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s05_calcarios: dados})
        });
    };

    MostrarDetalhesCalcario(id) {
        if (id == null)
        {
            this.setState({s05_calcario: {
                ...this.state.s05_calcario, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idcalcario : '',
                quantidade : '',
                observacao : '',
                descricaocalcario : ''
            }});
        }
        else
        {
            s05_calcariosbuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s05_calcario: {
                        ...this.state.s05_calcario, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idcalcario : dados.idcalcario,
                        quantidade : dados.quantidade,
                        observacao : dados.observacao,
                        descricaocalcario : dados.descricaocalcario
                    }}, () => {
                        this.setState({
                            s05_calcario: {...this.state.s05_calcario, 
                                quantidade: ajustamaskdecimal(this.state.s05_calcario.quantidade)
                            }
                        });
                    });        
                }
            });
        }

        window.$('#detalhescalcario').modal("show");
    };

    onChangeCalcario(e) {
        this.setState({s05_calcario: {...this.state.s05_calcario, [e.target.name]: e.target.value}});
    };
    
    PesquisaCalcario() {
        calcariospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({calcarios: dados});
        });

        window.$('#calcarios').modal("show");
    };

    SelecionarCalcario(calcario, descricao) {
        this.setState({s05_calcario: {
            ...this.state.s05_calcario, 
            idcalcario: calcario,
            descricaocalcario: descricao
        }});
        window.$('#calcarios').modal("hide");
    };

    onConfirmarCalcarioClick() {
        this.setState({
            s05_calcario: {...this.state.s05_calcario,
                quantidade: limpamask(true, this.state.s05_calcario.quantidade)
            }
        }, async () => {
            if (this.state.s05_calcario.id === '')
            {
                s05_calcariosnovo(this.state.s05_calcario).then(r => {
                    this.PesquisaS05_Calcario();
                });
            }
            else
            {
                s05_calcarioseditar(this.state.s05_calcario).then(r => {
                    this.PesquisaS05_Calcario();
                });
            }
        });
    };

    onDeletarCalcarioClick(cliente, idcenso, id) {
        s05_calcariosdeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS05_Calcario();
        });
    };
    
    // Relacionamento Censo->Adubo
    
    PesquisaS05_Adubo() {
        s05_adubospesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s05_adubos: dados})
        });
    };

    MostrarDetalhesAdubo(id) {
        if (id == null)
        {
            this.setState({s05_adubo: {
                ...this.state.s05_adubo, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idadubo : '',
                quantidade : '',
                observacao : '',
                descricaoadubo : '',
                composicaoadubo : ''
            }});
        }
        else
        {
            s05_adubosbuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s05_adubo: {
                        ...this.state.s05_adubo, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idadubo : dados.idadubo,
                        quantidade : dados.quantidade,
                        observacao : dados.observacao,
                        descricaoadubo : dados.descricaoadubo,
                        composicaoadubo : dados.composicaoadubo
                    }}, () => {
                        this.setState({
                            s05_adubo: {...this.state.s05_adubo, 
                                quantidade: ajustamaskdecimal(this.state.s05_adubo.quantidade)
                            }
                        });
                    });        
                }
            });
        }

        window.$('#detalhesadubo').modal("show");
    };

    onChangeAdubo(e) {
        this.setState({s05_adubo: {...this.state.s05_adubo, [e.target.name]: e.target.value}});
    };

    PesquisaAdubo() {
        adubospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({adubos: dados});
        });

        window.$('#adubos').modal("show");
    };

    SelecionarAdubo(adubo, descricao, composicao) {
        this.setState({s05_adubo: {
            ...this.state.s05_adubo, 
            idadubo: adubo,
            descricaoadubo: descricao,
            composicaoadubo: composicao
        }});
        window.$('#adubos').modal("hide");
    };

    onConfirmarAduboClick() {
        this.setState({
            s05_adubo: {...this.state.s05_adubo,
                quantidade: limpamask(true, this.state.s05_adubo.quantidade)
            }
        }, async () => {
            if (this.state.s05_adubo.id === '')
            {
                s05_adubosnovo(this.state.s05_adubo).then(r => {
                    this.PesquisaS05_Adubo();
                });
            }
            else
            {
                s05_aduboseditar(this.state.s05_adubo).then(r => {
                    this.PesquisaS05_Adubo();
                });
            }
        });
    };

    onDeletarAduboClick(cliente, idcenso, id) {
        s05_adubosdeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS05_Adubo();
        });
    };

    // Relacionamento Censo->AnaliseDeSolo
    
    PesquisaS05_AnaliseDeSolo() {
        s05_analisesdesolopesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s05_analisesdesolo: dados})
        });
    };

    MostrarDetalhesAnaliseDeSolo(id) {
        if (id == null)
        {
            this.setState({s05_analisedesolo: {
                ...this.state.s05_analisedesolo, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idanalisedesolo : '',
                observacao : '',
                descricaoanalisedesolo : ''
            }});
        }
        else
        {
            s05_analisesdesolobuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s05_analisedesolo: {
                        ...this.state.s05_analisedesolo, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idanalisedesolo : dados.idanalisedesolo,
                        observacao : dados.observacao,
                        descricaoanalisedesolo : dados.descricaoanalisedesolo
                    }});        
                }
            });
        }

        window.$('#detalhesanalisedesolo').modal("show");
    };

    onChangeAnaliseDeSolo(e) {
        this.setState({s05_analisedesolo: {...this.state.s05_analisedesolo, [e.target.name]: e.target.value}});
    };

    PesquisaAnaliseDeSolo() {
        analisesdesolopesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({analisesdesolo: dados});
        });

        window.$('#analisesdesolo').modal("show");
    };

    SelecionarAnaliseDeSolo(analisedesolo, descricao) {
        this.setState({s05_analisedesolo: {
            ...this.state.s05_analisedesolo, 
            idanalisedesolo: analisedesolo,
            descricaoanalisedesolo: descricao
        }});
        window.$('#analisesdesolo').modal("hide");
    };

    onConfirmarAnaliseDeSoloClick() {
        if (this.state.s05_analisedesolo.id === '')
        {
            s05_analisesdesolonovo(this.state.s05_analisedesolo).then(r => {
                this.PesquisaS05_AnaliseDeSolo();
            });
        }
        else
        {
            s05_analisesdesoloeditar(this.state.s05_analisedesolo).then(r => {
                this.PesquisaS05_AnaliseDeSolo();
            });
        }
    };

    onDeletarAnaliseDeSoloClick(cliente, idcenso, id) {
        s05_analisesdesolodeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS05_AnaliseDeSolo();
        });
    };

    // Relacionamento Censo->Pragas
    
    PesquisaS05_Praga() {
        s05_pragaspesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s05_pragas: dados})
        });
    };

    MostrarDetalhesPraga(id) {
        if (id == null)
        {
            this.setState({s05_praga: {
                ...this.state.s05_praga, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idpraga : '',
                produtoutilizado : '',
                observacao : '',
                descricaopraga : ''
            }});
        }
        else
        {
            s05_pragasbuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s05_praga: {
                        ...this.state.s05_praga, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idpraga : dados.idpraga,
                        produtoutilizado : dados.produtoutilizado,
                        observacao : dados.observacao,
                        descricaopraga : dados.descricaopraga
                    }});        
                }
            });
        }

        window.$('#detalhespraga').modal("show");
    };

    onChangePraga(e) {
        this.setState({s05_praga: {...this.state.s05_praga, [e.target.name]: e.target.value}});
    };

    PesquisaPraga() {
        pragaspesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({pragas: dados});
        });

        window.$('#pragas').modal("show");
    };

    SelecionarPraga(praga, descricao) {
        this.setState({s05_praga: {
            ...this.state.s05_praga, 
            idpraga: praga,
            descricaopraga: descricao
        }});
        window.$('#pragas').modal("hide");
    };

    onConfirmarPragaClick() {
        if (this.state.s05_praga.id === '')
        {
            s05_pragasnovo(this.state.s05_praga).then(r => {
                this.PesquisaS05_Praga();
            });
        }
        else
        {
            s05_pragaseditar(this.state.s05_praga).then(r => {
                this.PesquisaS05_Praga();
            });
        }
    };

    onDeletarPragaClick(cliente, idcenso, id) {
        s05_pragasdeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS05_Praga();
        });
    };

    // Fim Relacionamentos

    onChange(e) {
        this.setState({
            s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, [e.target.name]: e.target.value }
        }, () => {
            if (this.state.s05_caracteristicasestabelecimento.associadoentidadeclasse === '1') {
                this.setState({
                    s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, 
                        associadocooperativa : false,
                        associadosindicado : false,
                        associadomovimento : false,
                        associadomoradores : false
                    }
                });
            }
    
            if (this.state.s05_caracteristicasestabelecimento.fezadubacao !== '1') {
                this.setState({
                    s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, 
                        costumafazeradubacao : "1"
                    }
                });
            }

            if (this.state.s05_caracteristicasestabelecimento.sistemapreparosolo !== '3') {
                this.setState({
                    s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, 
                        areaplantiodireto : ""
                    }
                });
            }

            if (this.state.s05_caracteristicasestabelecimento.fezaplicacorretivosph !== '2') {
                this.setState({
                    s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, 
                        anoaplicacaocorretivosph : ""
                    }
                });
            }

            if (this.state.s05_caracteristicasestabelecimento.fezanalisesolo !== '2') {
                this.setState({
                    s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, 
                        anoanalisesolo : ""
                    }
                });
            }
        });
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento, [name]: checked }});
    }

    async salvar(botao) {
        this.setState({
            s05_caracteristicasestabelecimento: {...this.state.s05_caracteristicasestabelecimento,
                areaplantiodireto: limpamask(true, this.state.s05_caracteristicasestabelecimento.areaplantiodireto),
                area_inundacao: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_inundacao),
                area_sulcos: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_sulcos),
                area_outrossuperficie: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_outrossuperficie),
                area_autopropelido: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_autopropelido),
                area_pivocentral: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_pivocentral),
                area_aspersao: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_aspersao),
                area_gotejamento: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_gotejamento),
                area_microaspersao: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_microaspersao),
                area_outroslocalizada: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_outroslocalizada),
                area_subsuperficial: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_subsuperficial),
                area_molhacao: limpamask(true, this.state.s05_caracteristicasestabelecimento.area_molhacao)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s05_caracteristicasestabelecimento);
            }
            else {
                censoeditar(this.props.match.url, this.state.s05_caracteristicasestabelecimento);
            }
            if (botao === 'Back') {
                this.props.history.push('/S04');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S06');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>05 - CARACTERÍSTICAS DO ESTABELECIMENTO AGROPECUÁRIO</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-O(a) Produtor(a) é associado(a) a cooperativa ou entidade de classe?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="associadoentidadeclasse" name="associadoentidadeclasse" value={this.state.s05_caracteristicasestabelecimento.associadoentidadeclasse} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <span>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    <svg className="bi bi-arrow-right acertaposicaolabel" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z" clip-rule="evenodd"/>
                                                        <path fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z" clip-rule="evenodd"/>
                                    </svg>
                                    &nbsp;&nbsp;
                                    <FormControl disabled={this.state.s05_caracteristicasestabelecimento.associadoentidadeclasse === "1"} component="fieldset">
                                        <span>
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.associadocooperativa} onClick={this.onCheckBoxClick.bind(this, "associadocooperativa", !this.state.s05_caracteristicasestabelecimento.associadocooperativa)} value="associadocooperativa"/>
                                                }
                                                label="Cooperativa"
                                            />  
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.associadosindicado} onClick={this.onCheckBoxClick.bind(this, "associadosindicado", !this.state.s05_caracteristicasestabelecimento.associadosindicado)} value="associadosindicado"/>
                                                }
                                                label="Entidade de classe - sindicato"
                                            />  
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.associadomovimento} onClick={this.onCheckBoxClick.bind(this, "associadomovimento", !this.state.s05_caracteristicasestabelecimento.associadomovimento)} value="associadomovimento"/>
                                                }
                                                label="Associações / movimentos produtores(as)"
                                            />  
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.associadomoradores} onClick={this.onCheckBoxClick.bind(this, "associadomoradores", !this.state.s05_caracteristicasestabelecimento.associadomoradores)} value="associadomoradores"/>
                                                }
                                                label="Associações de moradores(as)"
                                            />  
                                        </span>
                                    </FormControl>
                                </span>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-No estabelecimento se utiliza energia elétrica?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="energiaeletrica" name="energiaeletrica" value={this.state.s05_caracteristicasestabelecimento.energiaeletrica} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-O estabelecimento recebe orientação e assistência de técnico especializado em agropecuária?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="recebeorientacao" name="recebeorientacao" value={this.state.s05_caracteristicasestabelecimento.recebeorientacao} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Qual é a origem da orientação e assistência técnica recebida?</small>
                        </span>
                    </div>
                    <div className="col-sm-3 posicaorb">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_governo} onClick={this.onCheckBoxClick.bind(this, "orientacao_governo", !this.state.s05_caracteristicasestabelecimento.orientacao_governo)} value="orientacao_governo"/>
                            }
                            label="Governo (Federal, Estadual ou Municipal)"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_propria} onClick={this.onCheckBoxClick.bind(this, "orientacao_propria", !this.state.s05_caracteristicasestabelecimento.orientacao_propria)} value="orientacao_propria"/>
                            }
                            label="Própria ou do próprio Produtor"
                        />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_cooperativas} onClick={this.onCheckBoxClick.bind(this, "orientacao_cooperativas", !this.state.s05_caracteristicasestabelecimento.orientacao_cooperativas)} value="orientacao_cooperativas"/>
                            }
                            label="Cooperativas"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_empresasintegradoras} onClick={this.onCheckBoxClick.bind(this, "orientacao_empresasintegradoras", !this.state.s05_caracteristicasestabelecimento.orientacao_empresasintegradoras)} value="orientacao_empresasintegradoras"/>
                            }
                            label="Empresas integradoras"
                        />  
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_empresasplanejamento} onClick={this.onCheckBoxClick.bind(this, "orientacao_empresasplanejamento", !this.state.s05_caracteristicasestabelecimento.orientacao_empresasplanejamento)} value="orientacao_empresasplanejamento"/>
                            }
                            label="Empresas privadas de planejamento"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_ongs} onClick={this.onCheckBoxClick.bind(this, "orientacao_ongs", !this.state.s05_caracteristicasestabelecimento.orientacao_ongs)} value="orientacao_ongs"/>
                            }
                            label="Organização não-governamental - ONG"
                        />  
                    </div>
                    <div className="col-sm">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_sistemas} onClick={this.onCheckBoxClick.bind(this, "orientacao_sistemas", !this.state.s05_caracteristicasestabelecimento.orientacao_sistemas)} value="orientacao_sistemas"/>
                            }
                            label="Sistema S"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.orientacao_outra} onClick={this.onCheckBoxClick.bind(this, "orientacao_outra", !this.state.s05_caracteristicasestabelecimento.orientacao_outra)} value="orientacao_outra"/>
                            }
                            label="Outra"
                        />  
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">05</small>
                            <small>-De que forma obtém informações técnicas?</small>
                        </span>
                    </div>
                    <div className="col-sm-3 posicaorb">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_televisao} onClick={this.onCheckBoxClick.bind(this, "informacoes_televisao", !this.state.s05_caracteristicasestabelecimento.informacoes_televisao)} value="informacoes_televisao"/>
                            }
                            label="Televisão"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_radio} onClick={this.onCheckBoxClick.bind(this, "informacoes_radio", !this.state.s05_caracteristicasestabelecimento.informacoes_radio)} value="informacoes_radio"/>
                            }
                            label="Rádio"
                        />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_internet} onClick={this.onCheckBoxClick.bind(this, "informacoes_internet", !this.state.s05_caracteristicasestabelecimento.informacoes_internet)} value="informacoes_internet"/>
                            }
                            label="Internet"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_revistas} onClick={this.onCheckBoxClick.bind(this, "informacoes_revistas", !this.state.s05_caracteristicasestabelecimento.informacoes_revistas)} value="informacoes_revistas"/>
                            }
                            label="Revistas"
                        />  
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_jornais} onClick={this.onCheckBoxClick.bind(this, "informacoes_jornais", !this.state.s05_caracteristicasestabelecimento.informacoes_jornais)} value="informacoes_jornais"/>
                            }
                            label="Jornais"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_reunioes} onClick={this.onCheckBoxClick.bind(this, "informacoes_reunioes", !this.state.s05_caracteristicasestabelecimento.informacoes_reunioes)} value="informacoes_reunioes"/>
                            }
                            label="Reuniões técnicas / Seminários"
                        />  
                    </div>
                    <div className="col-sm">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_outraforma} onClick={this.onCheckBoxClick.bind(this, "informacoes_outraforma", !this.state.s05_caracteristicasestabelecimento.informacoes_outraforma)} value="informacoes_outraforma"/>
                            }
                            label="Outra forma"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.informacoes_naoobtem} onClick={this.onCheckBoxClick.bind(this, "informacoes_naoobtem", !this.state.s05_caracteristicasestabelecimento.informacoes_naoobtem)} value="informacoes_naoobtem"/>
                            }
                            label="Não obtém informações técnicas"
                        />  
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">06</small>
                            <small>-Quais destas práticas agrícolas são utilizadas no estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm-4 posicaorb">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_plantionivel} onClick={this.onCheckBoxClick.bind(this, "praticas_plantionivel", !this.state.s05_caracteristicasestabelecimento.praticas_plantionivel)} value="praticas_plantionivel"/>
                            }
                            label="Plantio em nível"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_rotacaoculturas} onClick={this.onCheckBoxClick.bind(this, "praticas_rotacaoculturas", !this.state.s05_caracteristicasestabelecimento.praticas_rotacaoculturas)} value="praticas_rotacaoculturas"/>
                            }
                            label="Rotação de culturas"
                        /><br />   
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_pousiodescansosolo} onClick={this.onCheckBoxClick.bind(this, "praticas_pousiodescansosolo", !this.state.s05_caracteristicasestabelecimento.praticas_pousiodescansosolo)} value="praticas_pousiodescansosolo"/>
                            }
                            label="Pousio ou descanso de solos"
                        /><br />   
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_protecaoencostas} onClick={this.onCheckBoxClick.bind(this, "praticas_protecaoencostas", !this.state.s05_caracteristicasestabelecimento.praticas_protecaoencostas)} value="praticas_protecaoencostas"/>
                            }
                            label="Proteção e conservação de encostas"
                        />  
                    </div>
                    <div className="col-sm-4">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_recuperacaomataciliar} onClick={this.onCheckBoxClick.bind(this, "praticas_recuperacaomataciliar", !this.state.s05_caracteristicasestabelecimento.praticas_recuperacaomataciliar)} value="praticas_recuperacaomataciliar"/>
                            }
                            label="Recuperação de mata ciliar"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_reflorestamento} onClick={this.onCheckBoxClick.bind(this, "praticas_reflorestamento", !this.state.s05_caracteristicasestabelecimento.praticas_reflorestamento)} value="praticas_reflorestamento"/>
                            }
                            label="Reflorestamento para proteção de nascentes"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_estabilizacaovocorocas} onClick={this.onCheckBoxClick.bind(this, "praticas_estabilizacaovocorocas", !this.state.s05_caracteristicasestabelecimento.praticas_estabilizacaovocorocas)} value="praticas_estabilizacaovocorocas"/>
                            }
                            label="Estabilização de voçorocas"
                        />  
                    </div>
                    <div className="col-sm">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_manejoflorestal} onClick={this.onCheckBoxClick.bind(this, "praticas_manejoflorestal", !this.state.s05_caracteristicasestabelecimento.praticas_manejoflorestal)} value="praticas_manejoflorestal"/>
                            }
                            label="Manejo florestal"
                        /><br />  
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_outraspraticas} onClick={this.onCheckBoxClick.bind(this, "praticas_outraspraticas", !this.state.s05_caracteristicasestabelecimento.praticas_outraspraticas)} value="praticas_outraspraticas"/>
                            }
                            label="Outras práticas agrícolas"
                        /><br />   
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s05_caracteristicasestabelecimento.praticas_nenhuma} onClick={this.onCheckBoxClick.bind(this, "praticas_nenhuma", !this.state.s05_caracteristicasestabelecimento.praticas_nenhuma)} value="praticas_nenhuma"/>
                            }
                            label="Nenhuma"
                        />  
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Fez aplicação de calcário ou outros corretivos do pH do solo no estabelecimento ou aplicação de gesso?</small>
                        </span>
                    </div>
                    <div className="col-sm-12 posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="fezaplicacorretivosph" name="fezaplicacorretivosph" value={this.state.s05_caracteristicasestabelecimento.fezaplicacorretivosph} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <span>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim - Qual o ano da aplicação?" />
                                    <TextField disabled={this.state.s05_caracteristicasestabelecimento.fezaplicacorretivosph === '2' ? false : true} InputProps={{ inputComponent: anoMask }} variant="outlined" size="small" id="anoaplicacaocorretivosph" name="anoaplicacaocorretivosph" value={this.state.s05_caracteristicasestabelecimento.anoaplicacaocorretivosph} onChange={e => this.onChange(e)}/>
                            </span>
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s05_caracteristicasestabelecimento.fezaplicacorretivosph === '1' ? true : false}>
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesCalcario.bind(this, null)}>Incluir Corretivo</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Corretivos Utilizados</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-center">Quantidade</StyledTableCell>
                                            <StyledTableCell className="text-left">Observação</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s05_calcarios.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesCalcario.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesCalcario.bind(this, item.id)}>{item.descricaocalcario}</button></StyledTableCell >
                                                        <StyledTableCell className="text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesCalcario.bind(this, item.id)}>{item.quantidade.toLocaleString('pt-BR')} Ton/Ha</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesCalcario.bind(this, item.id)}>{item.observacao}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarCalcarioClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">08</small>
                            <small>-Fez adubação?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="fezadubacao" name="fezadubacao" value={this.state.s05_caracteristicasestabelecimento.fezadubacao} onChange={e => this.onChange(e)}>
                                <span>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                    <FormControl disabled={this.state.s05_caracteristicasestabelecimento.fezadubacao === '1' ? false : true} component="fieldset">
                                        <RadioGroup aria-label="costumafazeradubacao" name="costumafazeradubacao" value={this.state.s05_caracteristicasestabelecimento.costumafazeradubacao} onChange={e => this.onChange(e)}>
                                            <span>
                                                <svg className="bi bi-arrow-right" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z" clip-rule="evenodd"/>
                                                    <path fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z" clip-rule="evenodd"/>
                                                </svg>
                                                <small className="espacamentorb"> Costuma fazer adubação?</small>
                                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                            </span>
                                        </RadioGroup>
                                    </FormControl>
                                </span>
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, adubação química" />
                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, adubação orgânica" />
                                <FormControlLabel value="4" control={<Radio color="primary"/>} label="Sim, adubação química e orgânica" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s05_caracteristicasestabelecimento.fezadubacao === '1' ? true : false}>
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesAdubo.bind(this, null)}>Incluir Adubo</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Adubos Utilizados</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-left">Composição</StyledTableCell>
                                            <StyledTableCell className="text-center">Quantidade</StyledTableCell>
                                            <StyledTableCell className="text-left">Observação</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s05_adubos.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesAdubo.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesAdubo.bind(this, item.id)}>{item.descricaoadubo}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesAdubo.bind(this, item.id)}>{item.composicaoadubo}</button></StyledTableCell >
                                                        <StyledTableCell className="text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesAdubo.bind(this, item.id)}>{item.quantidade.toLocaleString('pt-BR')} Ton/Ha</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesAdubo.bind(this, item.id)}>{item.observacao}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarAduboClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">09</small>
                            <small>-Fez análise de solo?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="fezanalisesolo" name="fezanalisesolo" value={this.state.s05_caracteristicasestabelecimento.fezanalisesolo} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <span>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim - Qual o ano da análise?" />
                                    <TextField disabled={this.state.s05_caracteristicasestabelecimento.fezanalisesolo === '2' ? false : true} InputProps={{ inputComponent: anoMask }} variant="outlined" size="small" id="anoanalisesolo" name="anoanalisesolo" value={this.state.s05_caracteristicasestabelecimento.anoanalisesolo} onChange={e => this.onChange(e)}/>
                            </span>
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s05_caracteristicasestabelecimento.fezanalisesolo === '1' ? true : false}>
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesAnaliseDeSolo.bind(this, null)}>Incluir Análise de Solo</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Análises Feitas</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-left">Observação</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s05_analisesdesolo.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesAnaliseDeSolo.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesAnaliseDeSolo.bind(this, item.id)}>{item.descricaoanalisedesolo}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesAnaliseDeSolo.bind(this, item.id)}>{item.observacao}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarAnaliseDeSoloClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">10</small>
                            <small>-Utilizou agrotóxicos para o controle de pragas ou doenças em vegetais?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="utilizouagrotoxicos" name="utilizouagrotoxicos" value={this.state.s05_caracteristicasestabelecimento.utilizouagrotoxicos} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, não precisou utilizar" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s05_caracteristicasestabelecimento.utilizouagrotoxicos === '1' ? true : false}>
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesPraga.bind(this, null)}>Incluir Praga</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Pragas Controladas</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-left">Produto Utilizado</StyledTableCell>
                                            <StyledTableCell className="text-left">Observação</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s05_pragas.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesPraga.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesPraga.bind(this, item.id)}>{item.descricaopraga}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesPraga.bind(this, item.id)}>{item.produtoutilizado}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesPraga.bind(this, item.id)}>{item.observacao}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarPragaClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">11</small>
                            <small>-No estabelecimento se faz agricultura orgânica ou pecuária orgânica ? <em>(Conforme definido na Lei nº 10.831 de 23 de dezembro de 2003)</em>?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="agriculturapecuariaorganica" name="agriculturapecuariaorganica" value={this.state.s05_caracteristicasestabelecimento.agriculturapecuariaorganica} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não faz" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, faz para produção vegetal" />
                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, faz para produção animal" />
                                <FormControlLabel value="4" control={<Radio color="primary"/>} label="Sim, faz para produção vegetal e animal" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">12</small>
                            <small>-Qual sistema de preparo de solo foi utilizado no estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="sistemapreparosolo" name="sistemapreparosolo" value={this.state.s05_caracteristicasestabelecimento.sistemapreparosolo} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Cultivo convencional (aração mais gradagem) ou gradagem profunda" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Cultivo mínimo (só gradagem)" />
                                <span>
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Plantio direto na palha - Qual a área de plantio direto?" />
                                    <TextField disabled={this.state.s05_caracteristicasestabelecimento.sistemapreparosolo === '3' ? false : true} InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaplantiodireto" name="areaplantiodireto" value={this.state.s05_caracteristicasestabelecimento.areaplantiodireto} onChange={e => this.onChange(e)}/>
                                    <small> (informar na mesma unidade de medida de superfície utilizada no Quadro 01, quesito 18)</small>
                                </span>
                                <FormControlLabel value="4" control={<Radio color="primary"/>} label="Nenhum" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-4">
                        <div className="col-sm-12 subtitulo">
                            <span>
                                <small className="circulopreto">13</small>
                                <small>-Há nascentes no estabelecimento?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="sistemapreparohanascentessolo" name="hanascentes" value={this.state.s05_caracteristicasestabelecimento.hanascentes} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, protegida por mata" />
                                        <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, não protegida por mata" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">14</small>
                                <small>-Há rios ou riachos no estabelecimento?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="hariosriachos" name="hariosriachos" value={this.state.s05_caracteristicasestabelecimento.hariosriachos} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, protegido por mata" />
                                        <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, não protegido por mata" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="col-sm">
                            <div className="col-sm-12 subtitulo acertasubtitulo">
                                <span>
                                    <small className="circulopreto">15</small>
                                    <small>-Há poços ou cisternas no estabelecimento?</small>
                                </span>
                            </div>
                            <div className="row">
                                <div className="col-sm posicaorb">
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="hapocoscisternas" name="hapocoscisternas" value={this.state.s05_caracteristicasestabelecimento.hapocoscisternas} onChange={e => this.onChange(e)}>
                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, poço convencional (comum, caipira, cacimba, etc.)" />
                                            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, poço tubular profundo jorrante" />
                                            <FormControlLabel value="4" control={<Radio color="primary"/>} label="Sim, poço tubular profundo não jorrante" />
                                            <FormControlLabel value="5" control={<Radio color="primary"/>} label="Sim, cisterna para captura de água da chuva" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">16</small>
                                <small>-Fez irrigação no estabelecimento?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="fezirrigacao" name="fezirrigacao" value={this.state.s05_caracteristicasestabelecimento.fezirrigacao} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">17</small>
                            <small>-Informe a área irrigada no(s) respectivo(s) método(s) utilizado(s) <em>(na mesma unidade de medida de superfície declarada no Quesito 18 do Quadro 01)</em></small>
                        </span>
                    </div>
                    <div className="col-sm-6 posicaorb">
                        <div className="subtitulo">
                            <em><strong>Sistema de irrigação por superfície:</strong></em>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Inundação</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_inundacao" name="area_inundacao" value={this.state.s05_caracteristicasestabelecimento.area_inundacao} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Sulcos</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_sulcos" name="area_sulcos" value={this.state.s05_caracteristicasestabelecimento.area_sulcos} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Outros por superfície (corrugação, faixa)</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_outrossuperficie" name="area_outrossuperficie" value={this.state.s05_caracteristicasestabelecimento.area_outrossuperficie} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="subtitulo">
                            <em><strong>Sistema de irrigação por aspersão:</strong></em>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Autopropelido / carretel enrolador</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_autopropelido" name="area_autopropelido" value={this.state.s05_caracteristicasestabelecimento.area_autopropelido} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Pivô central</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_pivocentral" name="area_pivocentral" value={this.state.s05_caracteristicasestabelecimento.area_pivocentral} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Aspersão convencional (portátil, semiportátil, fixo, canhão hidráulico, malha)</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_aspersao" name="area_aspersao" value={this.state.s05_caracteristicasestabelecimento.area_aspersao} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="subtitulo">
                            <em><strong>Sistema de irrigação localizada:</strong></em>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Gotejamento</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_gotejamento" name="area_gotejamento" value={this.state.s05_caracteristicasestabelecimento.area_gotejamento} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Microaspersão</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_microaspersao" name="area_microaspersao" value={this.state.s05_caracteristicasestabelecimento.area_microaspersao} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Outros métodos de irrigação localizada (xique-xique, potejamento, etc.)</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_outroslocalizada" name="area_outroslocalizada" value={this.state.s05_caracteristicasestabelecimento.area_outroslocalizada} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="subtitulo">
                            <em><strong>Outros Sistemas:</strong></em>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Subsuperficial</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_subsuperficial" name="area_subsuperficial" value={this.state.s05_caracteristicasestabelecimento.area_subsuperficial} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-6 posicaorb">Molhação (com mangueiras, baldes, regadores, etc.)</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="area_molhacao" name="area_molhacao" value={this.state.s05_caracteristicasestabelecimento.area_molhacao} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">18</small>
                                <small>-Tem disponibilidade permanente de água (o ano todo) no estabelecimento?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="aguapermanente" name="aguapermanente" value={this.state.s05_caracteristicasestabelecimento.aguapermanente} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />

                {/* Popups para s05_calcarios */}

                <div class="modal fade" id="detalhescalcario" tabindex="-1" role="dialog" aria-labelledby="detalhesLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhesLabel">Corretivo</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s05_calcario.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Calcário" id="calcario" name="calcario" value={this.state.s05_calcario.idcalcario} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaocalcario" name="descricaocalcario" value={this.state.s05_calcario.descricaocalcario} style={{width: 300 + 'px'}}/>
                                        <Link underlineAlways onClick={this.PesquisaCalcario.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Ton/Ha</InputAdornment> }} label="Quantidade" id="quantidade" name="quantidade" value={this.state.s05_calcario.quantidade} style={{width: 150 + 'px'}} onChange={e => this.onChangeCalcario(e)} autoFocus/><p />
                                        <TextField multiline rows="3" label="Observações" id="observacao" name="observacao" value={this.state.s05_calcario.observacao} style={{width: 450 + 'px'}} onChange={e => this.onChangeCalcario(e)}/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarCalcarioClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="calcarios" tabindex="-1" role="dialog" aria-labelledby="calcariosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="calcariosLabel">Selecione o Distrito</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.calcarios.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarCalcario.bind(this, item.id, item.descricao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarCalcario.bind(this, item.id, item.descricao)}>{item.descricao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>  

                {/* Popups para s05_adubos */}
                
                <div class="modal fade" id="detalhesadubo" tabindex="-1" role="dialog" aria-labelledby="detalhesaduboLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhesaduboLabel">Adubo</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s05_adubo.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Adubo" id="adubo" name="adubo" value={this.state.s05_adubo.idadubo} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaoadubo" name="descricaoadubo" value={this.state.s05_adubo.descricaoadubo} style={{width: 300 + 'px'}}/>
                                        <TextField label="Composição" id="composicaoadubo" name="composicaoadubo" value={this.state.s05_adubo.composicaoadubo} style={{width: 300 + 'px'}}/>
                                        <Link onClick={this.PesquisaAdubo.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Ton/Ha</InputAdornment> }} label="Quantidade" id="quantidade" name="quantidade" value={this.state.s05_adubo.quantidade} style={{width: 150 + 'px'}} onChange={e => this.onChangeAdubo(e)} autoFocus/><p />
                                        <TextField multiline rows="3" label="Observações" id="observacao" name="observacao" value={this.state.s05_adubo.observacao} style={{width: 450 + 'px'}} onChange={e => this.onChangeAdubo(e)}/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarAduboClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="adubos" tabindex="-1" role="dialog" aria-labelledby="adubosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="adubosLabel">Selecione o Adubo</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell>Composição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.adubos.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarAdubo.bind(this, item.id, item.descricao, item.composicao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarAdubo.bind(this, item.id, item.descricao, item.composicao)}>{item.descricao}</button></StyledTableCell >
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarAdubo.bind(this, item.id, item.descricao, item.composicao)}>{item.composicao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Popups para s05_analisesdesolo */}
                
                <div class="modal fade" id="detalhesanalisedesolo" tabindex="-1" role="dialog" aria-labelledby="detalhesanalisedesoloLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhesanalisedesoloLabel">Análise de Solo</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s05_analisedesolo.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Análise" id="analisedesolo" name="analisedesolo" value={this.state.s05_analisedesolo.idanalisedesolo} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaoanalisedesolo" name="descricaoanalisedesolo" value={this.state.s05_analisedesolo.descricaoanalisedesolo} style={{width: 300 + 'px'}}/>
                                        <Link onClick={this.PesquisaAnaliseDeSolo.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField multiline rows="3" label="Observações" id="observacao" name="observacao" value={this.state.s05_analisedesolo.observacao} style={{width: 450 + 'px'}} onChange={e => this.onChangeAnaliseDeSolo(e)} autoFocus/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarAnaliseDeSoloClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="analisesdesolo" tabindex="-1" role="dialog" aria-labelledby="analisesdesoloLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="analisesdesoloLabel">Selecione a Análise de Solo</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.analisesdesolo.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarAnaliseDeSolo.bind(this, item.id, item.descricao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarAnaliseDeSolo.bind(this, item.id, item.descricao)}>{item.descricao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Popups para s05_pragas */}
                
                <div class="modal fade" id="detalhespraga" tabindex="-1" role="dialog" aria-labelledby="detalhespragaLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhespragaLabel">Praga</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s05_praga.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Praga" id="praga" name="praga" value={this.state.s05_praga.idpraga} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaopraga" name="descricaopraga" value={this.state.s05_praga.descricaopraga} style={{width: 300 + 'px'}}/>
                                        <Link onClick={this.PesquisaPraga.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField label="Produto Utilizado" id="produtoutilizado" name="produtoutilizado" value={this.state.s05_praga.produtoutilizado} style={{width: 450 + 'px'}} onChange={e => this.onChangePraga(e)} autoFocus/><p />
                                        <TextField multiline rows="3" label="Observações" id="observacao" name="observacao" value={this.state.s05_praga.observacao} style={{width: 450 + 'px'}} onChange={e => this.onChangePraga(e)}/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarPragaClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="pragas" tabindex="-1" role="dialog" aria-labelledby="pragasLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="pragasLabel">Selecione a Praga</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.pragas.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarPraga.bind(this, item.id, item.descricao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarPraga.bind(this, item.id, item.descricao)}>{item.descricao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        );
    }
}

export default S05_CaracteristicasEstabelecimento;
