import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

class S39_Despesas extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s39_despesas : {
                cliente : '',
                id : '',
                arrendamento : '',
                salarios : '',
                contratacaoservicos : '',
                aduboscorretivos : '',
                agrotoxicos : '',
                transporteproducao : '',
                medicamentos : '',
                suplementos : '',
                sementesmudas : '',
                energiaeletrica : '',
                compraanimais : '',
                compramaquinas : '',
                combustiveis : '',
                novasculturas : '',
                formacaopastagens : '',
                outrasdespesas : '',
                especificaroutrasdespesas : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s39_despesas: {
                        ...this.state.s39_despesas, 
                        cliente : dados.cliente,
                        id : dados.id,
                        arrendamento : dados.arrendamento,
                        salarios : dados.salarios,
                        contratacaoservicos : dados.contratacaoservicos,
                        aduboscorretivos : dados.aduboscorretivos,
                        agrotoxicos : dados.agrotoxicos,
                        transporteproducao : dados.transporteproducao,
                        medicamentos : dados.medicamentos,
                        suplementos : dados.suplementos,
                        sementesmudas : dados.sementesmudas,
                        energiaeletrica : dados.energiaeletrica,
                        compraanimais : dados.compraanimais,
                        compramaquinas : dados.compramaquinas,
                        combustiveis : dados.combustiveis,
                        novasculturas : dados.novasculturas,
                        formacaopastagens : dados.formacaopastagens,
                        outrasdespesas : dados.outrasdespesas,
                        especificaroutrasdespesas : dados.especificaroutrasdespesas
                    }}, () => {
                        this.setState({
                            s39_despesas: {...this.state.s39_despesas, 
                                arrendamento: ajustamaskdecimal(this.state.s39_despesas.arrendamento),
                                salarios: ajustamaskdecimal(this.state.s39_despesas.salarios),
                                contratacaoservicos: ajustamaskdecimal(this.state.s39_despesas.contratacaoservicos),
                                aduboscorretivos: ajustamaskdecimal(this.state.s39_despesas.aduboscorretivos),
                                agrotoxicos: ajustamaskdecimal(this.state.s39_despesas.agrotoxicos),
                                transporteproducao: ajustamaskdecimal(this.state.s39_despesas.transporteproducao),
                                medicamentos: ajustamaskdecimal(this.state.s39_despesas.medicamentos),
                                suplementos: ajustamaskdecimal(this.state.s39_despesas.suplementos),
                                sementesmudas: ajustamaskdecimal(this.state.s39_despesas.sementesmudas),
                                energiaeletrica: ajustamaskdecimal(this.state.s39_despesas.energiaeletrica),
                                compraanimais: ajustamaskdecimal(this.state.s39_despesas.compraanimais),
                                compramaquinas: ajustamaskdecimal(this.state.s39_despesas.compramaquinas),
                                combustiveis: ajustamaskdecimal(this.state.s39_despesas.combustiveis),
                                novasculturas: ajustamaskdecimal(this.state.s39_despesas.novasculturas),
                                formacaopastagens: ajustamaskdecimal(this.state.s39_despesas.formacaopastagens),
                                outrasdespesas: ajustamaskdecimal(this.state.s39_despesas.outrasdespesas)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s39_despesas: {...this.state.s39_despesas, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        arrendamento : '',
                        salarios : '',
                        contratacaoservicos : '',
                        aduboscorretivos : '',
                        agrotoxicos : '',
                        transporteproducao : '',
                        medicamentos : '',
                        suplementos : '',
                        sementesmudas : '',
                        energiaeletrica : '',
                        compraanimais : '',
                        compramaquinas : '',
                        combustiveis : '',
                        novasculturas : '',
                        formacaopastagens : '',
                        outrasdespesas : '',
                        especificaroutrasdespesas : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s39_despesas: {...this.state.s39_despesas, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s39_despesas: {...this.state.s39_despesas,
                arrendamento: limpamask(true, this.state.s39_despesas.arrendamento),
                salarios: limpamask(true, this.state.s39_despesas.salarios),
                contratacaoservicos: limpamask(true, this.state.s39_despesas.contratacaoservicos),
                aduboscorretivos: limpamask(true, this.state.s39_despesas.aduboscorretivos),
                agrotoxicos: limpamask(true, this.state.s39_despesas.agrotoxicos),
                transporteproducao: limpamask(true, this.state.s39_despesas.transporteproducao),
                medicamentos: limpamask(true, this.state.s39_despesas.medicamentos),
                suplementos: limpamask(true, this.state.s39_despesas.suplementos),
                sementesmudas: limpamask(true, this.state.s39_despesas.sementesmudas),
                energiaeletrica: limpamask(true, this.state.s39_despesas.energiaeletrica),
                compraanimais: limpamask(true, this.state.s39_despesas.compraanimais),
                compramaquinas: limpamask(true, this.state.s39_despesas.compramaquinas),
                combustiveis: limpamask(true, this.state.s39_despesas.combustiveis),
                novasculturas: limpamask(true, this.state.s39_despesas.novasculturas),
                formacaopastagens: limpamask(true, this.state.s39_despesas.formacaopastagens),
                outrasdespesas: limpamask(true, this.state.s39_despesas.outrasdespesas)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s39_despesas);
            }
            else {
                censoeditar(this.props.match.url, this.state.s39_despesas);
            }
            if (botao === 'Back') {
                this.props.history.push('/S38');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S40');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>39 - DESPESAS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Qual foi o valor total das despesas realizadas no estabelecimento?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Arrendamento</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="arrendamento" name="arrendamento" value={this.state.s39_despesas.arrendamento} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Salários</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="salarios" name="salarios" value={this.state.s39_despesas.salarios} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Contratação de seviços</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="contratacaoservicos" name="contratacaoservicos" value={this.state.s39_despesas.contratacaoservicos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Adubos e corretivos</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="aduboscorretivos" name="aduboscorretivos" value={this.state.s39_despesas.aduboscorretivos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Agrotóxicos</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="agrotoxicos" name="agrotoxicos" value={this.state.s39_despesas.agrotoxicos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Transporte da produção</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="transporteproducao" name="transporteproducao" value={this.state.s39_despesas.transporteproducao} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Medicamentos</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="medicamentos" name="medicamentos" value={this.state.s39_despesas.medicamentos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Sal, ração e outros suplementos</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="suplementos" name="suplementos" value={this.state.s39_despesas.suplementos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Sementes e mudas (exclusive permanentes e silvicultura)</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="sementesmudas" name="sementesmudas" value={this.state.s39_despesas.sementesmudas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Energia elétrica</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="energiaeletrica" name="energiaeletrica" value={this.state.s39_despesas.energiaeletrica} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Compra de animais</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="compraanimais" name="compraanimais" value={this.state.s39_despesas.compraanimais} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Compra de máquinas e veículos</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="compramaquinas" name="compramaquinas" value={this.state.s39_despesas.compramaquinas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Combustíveis e lubrificantes</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="combustiveis" name="combustiveis" value={this.state.s39_despesas.combustiveis} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Novas culturas permanentes e silvicultura</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="novasculturas" name="novasculturas" value={this.state.s39_despesas.novasculturas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Formação de pastagens</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="formacaopastagens" name="formacaopastagens" value={this.state.s39_despesas.formacaopastagens} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Outras despesas</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="outrasdespesas" name="outrasdespesas" value={this.state.s39_despesas.outrasdespesas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 posicaorb">Especificar quais são as Outras despesas</div>
                            <div className="col-sm posicaorb"><TextField fullWidth multiline rows="2" variant="outlined" size="small" id="especificaroutrasdespesas" name="especificaroutrasdespesas" value={this.state.s39_despesas.especificaroutrasdespesas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <p />
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S39_Despesas;