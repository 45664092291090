import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
 
class S15_Muares extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s15_muares : {
                cliente : '',
                id : '',
                totalcabecas : '',
                vendidoscabecas : '',
                vendidosvalor : '',
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s15_muares: {
                        ...this.state.s15_muares, 
                        cliente : dados.cliente,
                        id : dados.id,
                        totalcabecas : dados.totalcabecas,
                        vendidoscabecas : dados.vendidoscabecas,
                        vendidosvalor : dados.vendidosvalor
                    }}, () => {
                        this.setState({
                            s15_muares: {...this.state.s15_muares, 
                                vendidosvalor: ajustamaskdecimal(this.state.s15_muares.vendidosvalor)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s15_muares: {...this.state.s15_muares, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        totalcabecas : '',
                        vendidoscabecas : '',
                        vendidosvalor : '',
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s15_muares: {...this.state.s15_muares, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s15_muares: {...this.state.s15_muares,
                totalcabecas: limpamask(true, this.state.s15_muares.totalcabecas),
                vendidoscabecas: limpamask(true, this.state.s15_muares.vendidoscabecas),
                vendidosvalor: limpamask(true, this.state.s15_muares.vendidosvalor)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s15_muares);
            }
            else {
                censoeditar(this.props.match.url, this.state.s15_muares);
            }
            if (botao === 'Back') {
                this.props.history.push('/S14');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S16');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>15 - MUARES: BURROS E MULAS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Muares no estabelecimento</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Total</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="totalcabecas" name="totalcabecas" value={this.state.s15_muares.totalcabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Vendidos (número/valor)</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="vendidoscabecas" name="vendidoscabecas" value={this.state.s15_muares.vendidoscabecas} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="vendidosvalor" name="vendidosvalor" value={this.state.s15_muares.vendidosvalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S15_Muares;