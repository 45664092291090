import React, { Component } from 'react';
import Chart from "react-google-charts";
import { estatisticas } from '../../Services/apifunctions';

class CondicaoLegal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dadoschart : []
        };
    }

    componentDidMount() {
        estatisticas(localStorage.getItem('cliente'), "CondicaoLegal").then(dados => {
            const Names = Object.keys(dados);
            const Values = Object.values(dados);
            const chartData = [["nome", "valor"]];
            var name = '';
            for (let i = 0; i < Names.length; i += 1) {
                if (Names[i] === "1") { 
                    name = 'Produtor(a) individual';
                } else {
                    if (Names[i] === "2") { 
                        name = 'Casal, união de pessoas, condomínio ou consórcio';
                    } else {
                        if (Names[i] === "3") { 
                            name = 'Cooperativa';
                        } else {
                            if (Names[i] === "4") { 
                                name = 'S/A ou Ltda';
                            } else {
                                if (Names[i] === "5") { 
                                    name = 'Instituição de utilidade pública';
                                } else {
                                    if (Names[i] === "6") { 
                                        name = 'Governo';
                                    } else {
                                        if (Names[i] === "7") { 
                                            name = 'Outra condição';
                                        } else {
                                            name = 'Não informado'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                chartData.push([name, Values[i]])
            };

            this.setState({dadoschart: chartData});
        });
    }

    render() {
        return (
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={this.state.dadoschart}
                options={{
                    legend: 'none',
                    title: 'Condição Legal',
                    animation: {
                        duration: 1000,
                        easing: 'out',
                        startup: true,
                    },
                    hAxis: {
                    title: 'Quantidade',
                    },
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        );
    }
}

export default CondicaoLegal;