import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import './CadastrosCenso.css';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class Navegacao extends Component {

    handleChange(e) {
        this.props.history.push(`/S${e.target.value}`)
    }

    render() {
        return (
            <div className="geral container-fluid text-center">
                <FormControl className="seletor" variant="outlined" size="small">
                    <Select 
                        id="seletor"
                        value="00"
                        onChange={e => this.handleChange(e)}
                    >
                        <MenuItem value="00">Ir para...</MenuItem>
                        <MenuItem value="01">01 - Identificação E Localização Do Estabelecimento Agropecuário</MenuItem>
                        <MenuItem value="02">02 - Características Do Estabelecimento Agropecuário E Do(A) Produtor(A)</MenuItem>
                        <MenuItem value="03">03 - Distribuição Da Área Do Estabelecimento Na Data De Referência</MenuItem>
                        <MenuItem value="04">04 - Distribuição Da Área Do Estabelecimento, Segundo A Utilização Das Terras Na Data De Referência</MenuItem>
                        <MenuItem value="05">05 - Características Do Estabelecimento Agropecuário</MenuItem>
                        <MenuItem value="06">06 - Unidades Armazenadoras Para Grãos Existentes No Estabelecimento Na Data De Referência</MenuItem>
                        <MenuItem value="07">07 - Tratores, Implementos, Máquinas E Veículos Existentes No Estabelecimento Na Data De Referência</MenuItem>
                        <MenuItem value="08">08 - Número De Pessoas Ocupadas No Estabelecimento No Período De Referência</MenuItem>
                        <MenuItem value="09">09 - Produtor E Pessoas Com Laços De Parentesco Com O Mesmo, Trabalhadores Permanentes, Temporários E Parceiros Que Trabalhavam No Estabelecimento Na Data De Referência)</MenuItem>
                        <MenuItem value="10">10 - Pecuária No Estabelecimento No Período De Referência</MenuItem>
                        <MenuItem value="11">11 - Bovinos</MenuItem>
                        <MenuItem value="12">12 - Bubalinos: Búfalos E Búfalas</MenuItem>
                        <MenuItem value="13">13 - Equinos: Cavalos E Éguas</MenuItem>
                        <MenuItem value="14">14 - Asininos: Jumentos E Jumentas</MenuItem>
                        <MenuItem value="15">15 - Muares: Burros E Mulas</MenuItem>
                        <MenuItem value="16">16 - Suínos: Porcos E Porcas</MenuItem>
                        <MenuItem value="17">17 - Caprinos: Bodes E Cabras</MenuItem>
                        <MenuItem value="18">18 - Ovinos: Carneiros E Ovelhas</MenuItem>
                        <MenuItem value="19">19 - Galinhas, Galos, Frangas, Frangos E Pintos</MenuItem>
                        <MenuItem value="20">20 - Codornas</MenuItem>
                        <MenuItem value="21">21 - Outras Aves</MenuItem>
                        <MenuItem value="22">22 - Coelhos</MenuItem>
                        <MenuItem value="23">23 - Criação De Abelhas</MenuItem>
                        <MenuItem value="24">24 - Aquicultura: Peixes, Camarões E Moluscos</MenuItem>
                        <MenuItem value="25">25 - Ranicultura</MenuItem>
                        <MenuItem value="26">26 - Sericicultura: Bicho-Da-Seda</MenuItem>
                        <MenuItem value="27">27 - Pesca, Apanha Ou Captura De Moluscos Ou Crustáceos</MenuItem>
                        <MenuItem value="28">28 - Atividades Da Produção Vegetal</MenuItem>
                        <MenuItem value="29">29 - Lavoura Temporária</MenuItem>
                        <MenuItem value="30">30 - Lavoura Permanente</MenuItem>
                        <MenuItem value="31">31 - Horticultura</MenuItem>
                        <MenuItem value="32">32 - Extração Vegetal</MenuItem>
                        <MenuItem value="33">33 - Floricultura</MenuItem>
                        <MenuItem value="34">34 - Efetivos Da Silvicultura</MenuItem>
                        <MenuItem value="35">35 - Produtos Da Silvicultura</MenuItem>
                        <MenuItem value="36">36 - Agroindústria Rural</MenuItem>
                        <MenuItem value="37">37 - Outras Receitas Do Estabelecimento e Outras Rendas Do Produtor</MenuItem>
                        <MenuItem value="38">38 - Financiamentos, Empréstimos E Garantia De Preços</MenuItem>
                        <MenuItem value="39">39 - Despesas</MenuItem>
                        <MenuItem value="40">40 - Coleta Em Outro Endereço</MenuItem>
                        <MenuItem value="41">41 - Autenticação</MenuItem>
                    </Select>
                </FormControl>
            </div>
        )}
}

export default withRouter(Navegacao);