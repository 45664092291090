import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

class S21_OutrasAves extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s21_outrasaves : {
                cliente : '',
                id : '',
                patosscabecas : '',
                peruscabecas : '',
                avestruzescabecas : '',
                vendidoscabecas : '',
                vendidosvalor : '',
                ovosvendidosduzias : '',
                ovosvendidosvalor : '',
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s21_outrasaves: {
                        ...this.state.s21_outrasaves, 
                        cliente : dados.cliente,
                        id : dados.id,
                        patosscabecas : dados.patosscabecas,
                        peruscabecas : dados.peruscabecas,
                        avestruzescabecas : dados.avestruzescabecas,
                        vendidoscabecas : dados.vendidoscabecas,
                        vendidosvalor : dados.vendidosvalor,
                        ovosvendidosduzias : dados.ovosvendidosduzias,
                        ovosvendidosvalor : dados.ovosvendidosvalor
                    }}, () => {
                        this.setState({
                            s21_outrasaves: {...this.state.s21_outrasaves, 
                                vendidosvalor: ajustamaskdecimal(this.state.s21_outrasaves.vendidosvalor),
                                ovosvendidosduzias: ajustamaskdecimal(this.state.s21_outrasaves.ovosvendidosduzias),
                                ovosvendidosvalor: ajustamaskdecimal(this.state.s21_outrasaves.ovosvendidosvalor)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s21_outrasaves: {...this.state.s21_outrasaves, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        patosscabecas : '',
                        peruscabecas : '',
                        avestruzescabecas : '',
                        vendidoscabecas : '',
                        vendidosvalor : '',
                        ovosvendidosduzias : '',
                        ovosvendidosvalor : '',
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s21_outrasaves: {...this.state.s21_outrasaves, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s21_outrasaves: {...this.state.s21_outrasaves,
                patosscabecas: limpamask(true, this.state.s21_outrasaves.patosscabecas),
                peruscabecas: limpamask(true, this.state.s21_outrasaves.peruscabecas),
                avestruzescabecas: limpamask(true, this.state.s21_outrasaves.avestruzescabecas),
                vendidoscabecas: limpamask(true, this.state.s21_outrasaves.vendidoscabecas),
                vendidosvalor: limpamask(true, this.state.s21_outrasaves.vendidosvalor),
                ovosvendidosduzias: limpamask(true, this.state.s21_outrasaves.ovosvendidosduzias),
                ovosvendidosvalor: limpamask(true, this.state.s21_outrasaves.ovosvendidosvalor)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s21_outrasaves);
            }
            else {
                censoeditar(this.props.match.url, this.state.s21_outrasaves);
            }
            if (botao === 'Back') {
                this.props.history.push('/S20');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S22');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>21 - OUTRAS AVES</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Qual era o efetivo de outras aves?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Patos, gansos, marrecos, perdizes e faisões</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="patosscabecas" name="patosscabecas" value={this.state.s21_outrasaves.patosscabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Perus</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="peruscabecas" name="peruscabecas" value={this.state.s21_outrasaves.peruscabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Avestruzes</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="avestruzescabecas" name="avestruzescabecas" value={this.state.s21_outrasaves.avestruzescabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual foi a quantidade vendida de outras aves?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="vendidoscabecas" name="vendidoscabecas" value={this.state.s21_outrasaves.vendidoscabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Valor total da venda</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="vendidosvalor" name="vendidosvalor" value={this.state.s21_outrasaves.vendidosvalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Qual foi a quantidade de ovos vendidos de outras aves?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida de ovos</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Dúzias</InputAdornment> }} variant="outlined" size="small" id="ovosvendidosduzias" name="ovosvendidosduzias" value={this.state.s21_outrasaves.ovosvendidosduzias} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Valor total da venda de ovos</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="ovosvendidosvalor" name="ovosvendidosvalor" value={this.state.s21_outrasaves.ovosvendidosvalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S21_OutrasAves;