import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, produtosvegetaispesquisa } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Link } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class S32_ExtracaoVegetal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s32_extracaovegetal : {
                cliente : '',
                id : '',
                produtovegetal : '',
                codigo : '',
                quantidadeobtida : '',
                quantidadevendida : '',
                unidademedida : '',
                equivalenciamedida : '',
                precomediovendido : '',
                extracaoartesanal : '',
                extracaotrabalhomecanizado : '',
                descricaoprodutovegetal : ''
            },
            produtosvegetais : []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s32_extracaovegetal: {
                        ...this.state.s32_extracaovegetal, 
                        cliente : dados.cliente,
                        id : dados.id,
                        produtovegetal : dados.produtovegetal,
                        codigo : dados.codigo,
                        quantidadeobtida : dados.quantidadeobtida,
                        quantidadevendida : dados.quantidadevendida,
                        unidademedida : dados.unidademedida,
                        equivalenciamedida : dados.equivalenciamedida,
                        precomediovendido : dados.precomediovendido,
                        extracaoartesanal : dados.extracaoartesanal,
                        extracaotrabalhomecanizado : dados.extracaotrabalhomecanizado,
                        descricaoprodutovegetal : dados.descricaoprodutovegetal
                    }}, () => {
                        this.setState({
                            s32_extracaovegetal: {...this.state.s32_extracaovegetal, 
                                quantidadeobtida: ajustamaskdecimal(this.state.s32_extracaovegetal.quantidadeobtida),
                                quantidadevendida: ajustamaskdecimal(this.state.s32_extracaovegetal.quantidadevendida),
                                precomediovendido: ajustamaskdecimal(this.state.s32_extracaovegetal.precomediovendido)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s32_extracaovegetal: {...this.state.s32_extracaovegetal, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        produtovegetal : '',
                        codigo : '',
                        quantidadeobtida : '',
                        quantidadevendida : '',
                        unidademedida : '',
                        equivalenciamedida : '',
                        precomediovendido : '',
                        extracaoartesanal : '1',
                        extracaotrabalhomecanizado : '1',
                        descricaoprodutovegetal : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s32_extracaovegetal: {...this.state.s32_extracaovegetal, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s32_extracaovegetal: {...this.state.s32_extracaovegetal,
                quantidadeobtida: limpamask(true, this.state.s32_extracaovegetal.quantidadeobtida),
                quantidadevendida: limpamask(true, this.state.s32_extracaovegetal.quantidadevendida),
                precomediovendido: limpamask(true, this.state.s32_extracaovegetal.precomediovendido)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s32_extracaovegetal);
            }
            else {
                censoeditar(this.props.match.url, this.state.s32_extracaovegetal);
            }
            if (botao === 'Back') {
                this.props.history.push('/S31');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S33');
            }
        });
    };

    PesquisaProdutosVegetais() {
        produtosvegetaispesquisa(localStorage.getItem('cliente'), "@", "2").then(dados => { 
            this.setState({produtosvegetais: dados});
        });
        window.$('#produtosvegetais').modal("show");
    };

    SelecionarProdutoVegetal(produtovegetal, descricao) {
        this.setState({s32_extracaovegetal: {
            ...this.state.s32_extracaovegetal, 
            produtovegetal: produtovegetal,
            descricaoprodutovegetal : descricao
        }});
        window.$('#produtosvegetais').modal("hide");
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>32 - EXTRAÇÃO VEGETAL</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Produtos da Extração Vegetal</small>&nbsp;
                        </span>
                    </div>
                </div>
                <div className="col-sm posicaorb">
                    <div className="row">
                        <div className="col-sm-3 posicaorb">Nome</div>
                        <div className="col-sm-4">
                            <TextField variant="outlined" disabled style={{width: 90 + '%'}} size="small" id="produtovegetal" name="produtovegetal" value={this.state.s32_extracaovegetal.descricaoprodutovegetal} />
                            <Link to="#" onClick={this.PesquisaProdutosVegetais.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                        </div>
{/*                            
                        <div className="col-sm-1 text-right">Código</div>
                        <div className="col-sm"><TextField variant="outlined" size="small" id="codigo" name="codigo" value={this.state.s32_extracaovegetal.codigo} onChange={e => this.onChange(e)}/><p /></div>
*/}                            
                    </div>
                    <div className="row">
                        <div className="col-sm-3 posicaorb">Quantidade obtida</div>
                        <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="quantidadeobtida" name="quantidadeobtida" value={this.state.s32_extracaovegetal.quantidadeobtida} onChange={e => this.onChange(e)}/><p /></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                        <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="quantidadevendida" name="quantidadevendida" value={this.state.s32_extracaovegetal.quantidadevendida} onChange={e => this.onChange(e)}/><p /></div>
                    </div>
{/*                        
                    <div className="row">
                        <div className="col-sm-3 posicaorb">Unidade de medida (produtovegetal/equivalência)</div>
                        <div className="col-sm">
                            <TextField variant="outlined" size="small" id="unidademedida" name="unidademedida" value={this.state.s32_extracaovegetal.unidademedida} onChange={e => this.onChange(e)}/>&nbsp;
                            <TextField variant="outlined" size="small" id="equivalenciamedida" name="equivalenciamedida" value={this.state.s32_extracaovegetal.equivalenciamedida} onChange={e => this.onChange(e)}/>
                            <p />
                        </div>
                    </div>
*/}                        
                    <div className="row">
                        <div className="col-sm-3 posicaorb">Preço médio do produto vendido</div>
                        <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediovendido" name="precomediovendido" value={this.state.s32_extracaovegetal.precomediovendido} onChange={e => this.onChange(e)}/><p /></div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-A extração vegetal é feita de forma artesanal e autônoma (individualmente ou com a família)?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="extracaoartesanal" name="extracaoartesanal" value={this.state.s32_extracaovegetal.extracaoartesanal} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <span>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    <svg className="bi bi-arrow-right acertaposicaolabel" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z" clip-rule="evenodd"/>
                                                    <path fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z" clip-rule="evenodd"/>
                                    </svg>
                                    &nbsp;&nbsp;
                                    A extração é feita com trabalho mecanizado?<br />
                                    <div className="row">
                                    <div className="col-sm-3"></div>
                                        <div className="col-sm">
                                            <FormControl disabled={this.state.s32_extracaovegetal.extracaoartesanal === "1"} component="fieldset">
                                                <RadioGroup aria-label="extracaotrabalhomecanizado" name="extracaotrabalhomecanizado" value={this.state.s32_extracaovegetal.extracaotrabalhomecanizado} onChange={e => this.onChange(e)}>
                                                    <span>
                                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                                    </span>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </span>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />

                {/* Modal para selecionar PRODUTOS VEGETAIS */}
                <div class="modal fade" id="produtosvegetais" tabindex="-1" role="dialog" aria-labelledby="produtosvegetaisLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione o Produto da Extração Vegetal</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.produtosvegetais.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarProdutoVegetal.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarProdutoVegetal.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>
        );
    }
}

export default S32_ExtracaoVegetal;