import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, cpfMask, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import * as yup from 'yup';

const checkoutSchema = yup.object().shape({
    cpfprodutor : yup
        .string()
        .matches(/\d{11}/, { message: 'O CPF deve ter 11 dígitos', excludeEmptyString: true }),
    cpfinformante : yup
        .string()
        .matches(/\d{11}/, { message: 'O CPF deve ter 11 dígitos', excludeEmptyString: true }),
});

class S41_Autenticacao extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s41_autenticacao : {
                cliente : '',
                id : '',
                quemprestouinformacoes : '',
                cpfprodutor : '',
                cpfinformante : '',
                possuicar : ''
            },
            cpfprodutor_error : '',
            cpfinformante_error : ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s41_autenticacao: {
                        ...this.state.s41_autenticacao, 
                        cliente : dados.cliente,
                        id : dados.id,
                        quemprestouinformacoes : dados.quemprestouinformacoes,
                        cpfprodutor : dados.cpfprodutor,
                        cpfinformante : dados.cpfinformante,
                        possuicar : dados.possuicar
                    }}, () => {
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s41_autenticacao: {...this.state.s41_autenticacao, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        quemprestouinformacoes : '1',
                        cpfprodutor : '',
                        cpfinformante : '',
                        possuicar : '1'
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s41_autenticacao: {...this.state.s41_autenticacao, [e.target.name]: e.target.value }
        });
    }

    async salvar(botao) {
        this.setState({
            s41_autenticacao: {...this.state.s41_autenticacao,
                    cpfprodutor: limpamask(false, this.state.s41_autenticacao.cpfprodutor),
                    cpfinformante: limpamask(false, this.state.s41_autenticacao.cpfinformante)
                }
            }, async () => {
                try {
                    this.setState({ 
                        cpfprodutor_error : '',
                        cpfinformante_error : ''
                    })  
        
                    await checkoutSchema.validate(this.state.s41_autenticacao, { abortEarly: false });
                    
                    if (this.state.novocadastro) {
                        censonovo(this.props.match.url, this.state.s41_autenticacao);
                    }
                    else {
                        censoeditar(this.props.match.url, this.state.s41_autenticacao);
                    }
                    if (botao === 'Back') {
                        this.props.history.push('/S40');
                    }
                    else
                    if (botao === 'Next') {
                        this.props.history.push('/ConsultaCenso');
                    }
                }
                catch(err) {
                    if (err instanceof yup.ValidationError) {
                        for (var i = 0; i < err.inner.length; i++) {
                            this.setState({ [err.inner[i].path +  '_error'] : err.inner[i].message });
                        }
                    }
                }
            }
        );
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>41 - AUTENTICAÇÃO</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Quem prestou as informações?</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="quemprestouinformacoes" name="quemprestouinformacoes" value={this.state.s41_autenticacao.quemprestouinformacoes} onChange={e => this.onChange(e)}>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div><FormControlLabel value="1" control={<Radio color="primary"/>} label="Produtor(a)" /></div>
                                            <div><FormControlLabel value="2" control={<Radio color="primary"/>} label="Parente do(a) produtor(a)" /></div>
                                            <div><FormControlLabel value="3" control={<Radio color="primary"/>} label="Administrador(a)" /></div>
                                        </div>
                                        <div className="col-sm">
                                            <div><FormControlLabel value="4" control={<Radio color="primary"/>} label="Capataz" /></div>
                                            <div><FormControlLabel value="5" control={<Radio color="primary"/>} label="Empregado(a)" /></div>
                                            <div><FormControlLabel value="6" control={<Radio color="primary"/>} label="Outra pessoa" /></div>
                                        </div>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-sm subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-CPF do Produtor (Cadastro de Pessoas Físicas)?</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <TextField InputProps={{ inputComponent: cpfMask }} variant="outlined" size="small" id="cpfprodutor" name="cpfprodutor" value={this.state.s41_autenticacao.cpfprodutor} onChange={e => this.onChange(e)}/>
                            <h6>{this.state.cpfprodutor_error}</h6><p />
                        </div>
                    </div>
                </div>
                <p />
                <div className="row">
                    <div className="col-sm subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-estabelecimento possui CAR (Cadastro Ambiental Rural)?</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="possuicar" name="possuicar" value={this.state.s41_autenticacao.possuicar} onChange={e => this.onChange(e)}>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Não sabe" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-sm subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-CPF do Informante (Cadastro de Pessoas Físicas)?</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <TextField InputProps={{ inputComponent: cpfMask }} variant="outlined" size="small" id="cpfinformante" name="cpfinformante" value={this.state.s41_autenticacao.cpfinformante} onChange={e => this.onChange(e)}/>
                            <h6>{this.state.cpfinformante_error}</h6><p />
                        </div>
                    </div>
                </div>
                <p />
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S41_Autenticacao;