import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

class S27_MoluscosCrustaceos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s27_moluscoscrustaceos : {
                cliente : '',
                id : '',
                objetivoprincipal : '',
                formacaptura : '',
                totalvendidovalor : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s27_moluscoscrustaceos: {
                        ...this.state.s27_moluscoscrustaceos, 
                        cliente : dados.cliente,
                        id : dados.id,
                        objetivoprincipal : dados.objetivoprincipal,
                        formacaptura : dados.formacaptura,
                        totalvendidovalor : dados.totalvendidovalor
                    }}, () => {
                        this.setState({
                            s27_moluscoscrustaceos: {...this.state.s27_moluscoscrustaceos, 
                                objetivoprincipal: ajustamaskdecimal(this.state.s27_moluscoscrustaceos.objetivoprincipal),
                                formacaptura: ajustamaskdecimal(this.state.s27_moluscoscrustaceos.formacaptura),
                                totalvendidovalor: ajustamaskdecimal(this.state.s27_moluscoscrustaceos.totalvendidovalor)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s27_moluscoscrustaceos: {...this.state.s27_moluscoscrustaceos, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        objetivoprincipal : '1',
                        formacaptura : '1',
                        totalvendidovalor : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s27_moluscoscrustaceos: {...this.state.s27_moluscoscrustaceos, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s27_moluscoscrustaceos: {...this.state.s27_moluscoscrustaceos,
                objetivoprincipal: limpamask(true, this.state.s27_moluscoscrustaceos.objetivoprincipal),
                formacaptura: limpamask(true, this.state.s27_moluscoscrustaceos.formacaptura),
                totalvendidovalor: limpamask(true, this.state.s27_moluscoscrustaceos.totalvendidovalor)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s27_moluscoscrustaceos);
            }
            else {
                censoeditar(this.props.match.url, this.state.s27_moluscoscrustaceos);
            }
            if (botao === 'Back') {
                this.props.history.push('/S26');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S28');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>27 - PESCA, APANHA OU CAPTURA DE MOLUSCOS OU CRUSTÁCEOS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <span>
                                    <small className="circulopreto">01</small>
                                    <small>-Objetivo principal</small>
                                </span>
                            </div>
                            <div className="col-sm posicaorb">
                                <FormControl disabled={this.state.s27_moluscoscrustaceos.naoexistembovinos} component="fieldset">
                                    <RadioGroup aria-label="objetivoprincipal" name="objetivoprincipal" value={this.state.s27_moluscoscrustaceos.objetivoprincipal} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Para consumo doméstico" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Para venda" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>   
                    <div className="col-sm-4">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <span>
                                    <small className="circulopreto">02</small>
                                    <small>-Forma</small>
                                </span>
                            </div>
                            <div className="col-sm posicaorb">
                                <FormControl disabled={this.state.s27_moluscoscrustaceos.naoexistembovinos} component="fieldset">
                                    <RadioGroup aria-label="formacaptura" name="formacaptura" value={this.state.s27_moluscoscrustaceos.formacaptura} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Artesanal e autônoma (individual ou com a família)" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Por outros meios, exceto o artesanal e autônoma" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>   
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <span>
                                    <small className="circulopreto">03</small>
                                    <small>-Valor total da venda do pescado, moluscos ou crustáceos</small>
                                </span>
                            </div>
                            <br /><br />
                            <div className="row">
                                <div className="col-sm posicaorb"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="totalvendidovalor" name="totalvendidovalor" value={this.state.s27_moluscoscrustaceos.totalvendidovalor} onChange={e => this.onChange(e)}/><p /></div>
                            </div>
                        </div> 
                    </div>   
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S27_MoluscosCrustaceos;