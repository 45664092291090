import React, { Component } from 'react';

class Login extends Component {

     render() {
        return (
            <div>
                Erro 404 - Página não encontrada.
            </div>
        );
    }
}

export default Login;