import React, { Component } from 'react';
import Chart from "react-google-charts";
import { estatisticas } from '../../Services/apifunctions';

class Pecuaria extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dadoschart : []
        };
    }

    componentDidMount() {
        estatisticas(localStorage.getItem('cliente'), "Pecuaria").then(dados => {
            const Names = Object.keys(dados);
            const Values = Object.values(dados);
            const chartData = [["nome", "valor"]];
            for (let i = 0; i < Names.length; i += 1) {
                chartData.push([Names[i], Values[i]])
            };

            this.setState({dadoschart: chartData});
        });
    }

    render() {
        return (
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={this.state.dadoschart}
                options={{
                    colors: ['purple'],
                    legend: 'none',
                    title: 'Pecuária no Estabelecimento',
                    chartArea: {
                        width: '90%',
                    },
                    animation: {
                        duration: 1000,
                        easing: 'out',
                        startup: true,
                    },
                    vAxis: {
                    title: 'Quantidade',
                    },
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        );
    }
}

export default Pecuaria;