import React, { Component } from 'react';
import { Link } from "react-router-dom";

class MenuRelatorios extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="geral container-fluid">
                <h1 className="cabeccadastro">Relatórios</h1>
                <ul>
                    <li><Link to="/relestabelecimentosxdistrito" style={{textDecoration: "none"}}>Estabelecimentos Por Distrito</Link></li>
                    <li><Link to="/relestabelecimentosxpecuaria" style={{textDecoration: "none"}}>Estabelecimentos Por Atividade Pecuária</Link></li>
                    <li><Link to="/relestabelecimentosxlavoura" style={{textDecoration: "none"}}>Estabelecimentos Por Lavoura</Link></li>
                </ul>
                
            </div>

        )
    }
}

export default MenuRelatorios;