import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class S19_Galinaceos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s19_galinaceos : {
                cliente : '',
                id : '',
                efetivocabecas : '',
                naoexistiamgalinhas : '',
                frangoscabecas : '',
                galoscabecas : '',
                galinhascabecas : '',
                matrizeirascabecas : '',
                vendidoscabecas : '',
                vendidosvalor : '',
                ovosproduzidosduzias : '',
                ovosvendidosduzias : '',
                precomedioduzia : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s19_galinaceos: {
                        ...this.state.s19_galinaceos, 
                        cliente : dados.cliente,
                        id : dados.id,
                        efetivocabecas : dados.efetivocabecas,
                        naoexistiamgalinhas : dados.naoexistiamgalinhas,
                        frangoscabecas : dados.frangoscabecas,
                        galoscabecas : dados.galoscabecas,
                        galinhascabecas : dados.galinhascabecas,
                        matrizeirascabecas : dados.matrizeirascabecas,
                        vendidoscabecas : dados.vendidoscabecas,
                        vendidosvalor : dados.vendidosvalor,
                        ovosproduzidosduzias : dados.ovosproduzidosduzias,
                        ovosvendidosduzias : dados.ovosvendidosduzias,
                        precomedioduzia : dados.precomedioduzia
                    }}, () => {
                        this.setState({
                            s19_galinaceos: {...this.state.s19_galinaceos, 
                                vendidosvalor: ajustamaskdecimal(this.state.s19_galinaceos.vendidosvalor),
                                ovosproduzidosduzias: ajustamaskdecimal(this.state.s19_galinaceos.ovosproduzidosduzias),
                                ovosvendidosduzias: ajustamaskdecimal(this.state.s19_galinaceos.ovosvendidosduzias),
                                precomedioduzia: ajustamaskdecimal(this.state.s19_galinaceos.precomedioduzia)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s19_galinaceos: {...this.state.s19_galinaceos, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        efetivocabecas : '',
                        naoexistiamgalinhas : true,
                        frangoscabecas : '',
                        galoscabecas : '',
                        galinhascabecas : '',
                        matrizeirascabecas : '',
                        vendidoscabecas : '',
                        vendidosvalor : '',
                        ovosproduzidosduzias : '',
                        ovosvendidosduzias : '',
                        precomedioduzia : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s19_galinaceos: {...this.state.s19_galinaceos, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s19_galinaceos: {...this.state.s19_galinaceos, [name]: checked }}, () => {
            if (this.state.s19_galinaceos.naoexistiamgalinhas) {
                this.setState({
                    s19_galinaceos: {...this.state.s19_galinaceos, 
                        efetivocabecas : '',
                        frangoscabecas : '',
                        galoscabecas : '',
                        galinhascabecas : '',
                        matrizeirascabecas : '',
                        vendidoscabecas : '',
                        vendidosvalor : '',
                        ovosproduzidosduzias : '',
                        ovosvendidosduzias : '',
                        precomedioduzia : ''
                    }
                });
            }
            else
            {
                this.setState({
                    s19_galinaceos: {...this.state.s19_galinaceos, 
                        efetivocabecas : '',
                        frangoscabecas : '',
                        galoscabecas : '',
                        galinhascabecas : '',
                        matrizeirascabecas : '',
                        vendidoscabecas : '',
                        vendidosvalor : '',
                        ovosproduzidosduzias : '',
                        ovosvendidosduzias : '',
                        precomedioduzia : ''
                    }
                });
            }            
        });
    }

    async salvar(botao) {
        this.setState({
            s19_galinaceos: {...this.state.s19_galinaceos,
                efetivocabecas: limpamask(true, this.state.s19_galinaceos.efetivocabecas),
                frangoscabecas: limpamask(true, this.state.s19_galinaceos.frangoscabecas),
                galoscabecas: limpamask(true, this.state.s19_galinaceos.galoscabecas),
                galinhascabecas: limpamask(true, this.state.s19_galinaceos.galinhascabecas),
                matrizeirascabecas: limpamask(true, this.state.s19_galinaceos.matrizeirascabecas),
                vendidoscabecas: limpamask(true, this.state.s19_galinaceos.vendidoscabecas),
                vendidosvalor: limpamask(true, this.state.s19_galinaceos.vendidosvalor),
                ovosproduzidosduzias: limpamask(true, this.state.s19_galinaceos.ovosproduzidosduzias),
                ovosvendidosduzias: limpamask(true, this.state.s19_galinaceos.ovosvendidosduzias),
                precomedioduzia: limpamask(true, this.state.s19_galinaceos.precomedioduzia)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s19_galinaceos);
            }
            else {
                censoeditar(this.props.match.url, this.state.s19_galinaceos);
            }
            if (botao === 'Back') {
                this.props.history.push('/S18');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S20');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>19 - GALINHAS, GALOS, FRANGAS, FRANGOS E PINTOS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Qual era o efetivo de galinhas, galos, frangas, frangos e pintos no estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Efetivo</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="efetivocabecas" name="efetivocabecas" value={this.state.s19_galinaceos.efetivocabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={this.state.s19_galinaceos.naoexistiamgalinhas} onClick={this.onCheckBoxClick.bind(this, "naoexistiamgalinhas", !this.state.s19_galinaceos.naoexistiamgalinhas)} value="naoexistiamgalinhas"/>}
                                    label="Não existiam galinhas, galos, frangas, frangos e pintos"
                                /><br />  
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual era a composição do plantel?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Pintos, frangos e frangas para engorda</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="frangoscabecas" name="frangoscabecas" value={this.state.s19_galinaceos.frangoscabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Galos</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="galoscabecas" name="galoscabecas" value={this.state.s19_galinaceos.galoscabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Galinhas poedeiras</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="galinhascabecas" name="galinhascabecas" value={this.state.s19_galinaceos.galinhascabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Matrizeiras/Avozeiras</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="matrizeirascabecas" name="matrizeirascabecas" value={this.state.s19_galinaceos.matrizeirascabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Quantas galinhas, galos, frangas, frangos e pintos foram vendidos</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Vendidos (número)</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="vendidoscabecas" name="vendidoscabecas" value={this.state.s19_galinaceos.vendidoscabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Valor total da venda do animal vivo</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="vendidosvalor" name="vendidosvalor" value={this.state.s19_galinaceos.vendidosvalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Qual foi quantidade total produzida e vendida de ovos de galinha</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade produzida</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Dúzias</InputAdornment> }} variant="outlined" size="small" id="ovosproduzidosduzias" name="ovosproduzidosduzias" value={this.state.s19_galinaceos.ovosproduzidosduzias} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Dúzias</InputAdornment> }} variant="outlined" size="small" id="ovosvendidosduzias" name="ovosvendidosduzias" value={this.state.s19_galinaceos.ovosvendidosduzias} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio da dúzia de ovos vendidos</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s19_galinaceos.naoexistiamgalinhas} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomedioduzia" name="precomedioduzia" value={this.state.s19_galinaceos.precomedioduzia} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S19_Galinaceos;