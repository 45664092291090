import React, { Component } from 'react';
import '../Consultas.css';
import {distritospesquisa, subdistritospesquisa, subdistritosbuscaid, subdistritosnovo, subdistritoseditar, subdistritosdeletar } from '../../Services/apifunctions';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class ConsultaSubDistrito extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            subdistritos : [],
            subdistrito : {
                    cliente: '',
                    distrito : '',
                    descricaodistrito : '',
                    subdistrito : '',
                    codigo: '',
                    descricao : '', 
            }, 
            distritos : [],
            termo : '',
        };
    }

    Pesquisa() {
        subdistritospesquisa(localStorage.getItem('cliente'), this.state.termo).then(dados => { 
            this.setState({subdistritos: dados});
        });
    };

    MostrarDetalhes(distrito, subdistrito) {
        if (distrito == null)
        {
            this.setState({subdistrito: {
                ...this.state.subdistrito, 
                cliente: localStorage.getItem('cliente'), 
                distrito: '', 
                descricaodistrito: '',
                subdistrito: '', 
                codigo: '',
                descricao: ''
            }});
        }
        else
        {
            subdistritosbuscaid(localStorage.getItem('cliente'), distrito, subdistrito).then(dados => { 
                if (dados.subdistrito !== '') {
                    this.setState({subdistrito: {
                        ...this.state.subdistrito, 
                        cliente: dados.cliente, 
                        distrito: dados.distrito, 
                        descricaodistrito: dados.descricaodistrito,
                        subdistrito: dados.subdistrito, 
                        codigo: dados.codigo, 
                        descricao: dados.descricao
                    }});
                }
            });
        }

        window.$('#detalhes').modal("show");
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    onChangeSubDistrito(e) {
        this.setState({subdistrito: {...this.state.subdistrito, [e.target.name]: e.target.value}});
    };
    
    onDeletarClick(cliente, distrito, subdistrito) {
        subdistritosdeletar(cliente, distrito, subdistrito).then(r => {
            this.Pesquisa();
        });
    };
    
    onConfirmarClick() {
        if (this.state.subdistrito.subdistrito === '')
        {
            subdistritosnovo(this.state.subdistrito).then(r => {
                this.Pesquisa();
            });
        }
        else
        {
            subdistritoseditar(this.state.subdistrito).then(r => {
                this.Pesquisa();
            });
        }
    };

    PesquisaDistritos() {
        distritospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({distritos: dados});
        });
        window.$('#distritos').modal("show");
    };

    SelecionarDistrito(distrito, descricao) {
        this.setState({subdistrito: {
            ...this.state.subdistrito, 
            distrito: distrito,
            descricaodistrito: descricao
        }});
        window.$('#distritos').modal("hide");
    };

    render() {
        return (
            <div className="geral container-fluid">
                <h1 className="cabeccadastro">Subdistritos</h1>
                <div className="row quadrobusca">
                    <div className="col-md-11">
                        <TextField variant="outlined" margin="dense" label="Informe o texto a ser pesquisado" className="camposbusca" id="Pesquisa" name="termo" value={this.state.termo} onChange={e => this.onChange(e)} autoFocus/><p />
                    </div>
                    <div className="col botaopesquisa">
                        <Link href="#" onClick={this.Pesquisa.bind(this)}><SearchIcon fontSize="large" /></Link>
                    </div>
                </div>
                <button className="btn btn-link botaonovo" type="button" onClick={this.MostrarDetalhes.bind(this, null)}>Novo Cadastro</button>
                <div className="tabela">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                    <StyledTableCell className="colkey">Distrito</StyledTableCell>
                                    <StyledTableCell>Descrição Distrito</StyledTableCell>
                                    <StyledTableCell className="colkey">Subdistrito</StyledTableCell>
                                    <StyledTableCell>Código</StyledTableCell>
                                    <StyledTableCell>Descrição</StyledTableCell>
                                    <StyledTableCell className="colbuttons"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.subdistritos.map((item, indice) => {
                                        return (
                                            <StyledTableRow  key={indice}>
                                                <StyledTableCell  className="colkey"><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.distrito, item.subdistrito)}>{item.distrito}</Link></StyledTableCell > 
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.distrito, item.subdistrito)}>{item.descricaodistrito}</Link></StyledTableCell > 
                                                <StyledTableCell  className="colkey"><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.distrito, item.subdistrito)}>{item.subdistrito}</Link></StyledTableCell > 
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.distrito, item.subdistrito)}>{item.codigo}</Link></StyledTableCell >
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.distrito, item.subdistrito)}>{item.descricao}</Link></StyledTableCell >
                                                <StyledTableCell  className="colbuttons"><Link href="#" onClick={() => { if (window.confirm('Confirma a exclusão do item selecionado?')) this.onDeletarClick(item.cliente, item.distrito, item.subdistrito) } }><DeleteForeverIcon /></Link></StyledTableCell >
                                            </StyledTableRow >
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div class="modal fade" id="detalhes" tabindex="-1" role="dialog" aria-labelledby="detalhesLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhesLabel">Detalhes</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField label="Distrito" id="distrito" name="distrito" value={this.state.subdistrito.distrito} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaodistrito" name="descricaodistrito" value={this.state.subdistrito.descricaodistrito} style={{width: 300 + 'px'}}/>
                                        <Link href="#" onClick={this.PesquisaDistritos.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField label="Subdistrito" disabled id="subdistrito" name="subdistrito" value={this.state.subdistrito.subdistrito} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Código" id="codigo" name="codigo" value={this.state.subdistrito.codigo} style={{width: 450 + 'px'}} onChange={e => this.onChangeSubDistrito(e)} autoFocus/><p />
                                        <TextField label="Descrição" id="descricao" name="descricao" value={this.state.subdistrito.descricao} style={{width: 450 + 'px'}} onChange={e => this.onChangeSubDistrito(e)}/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="distritos" tabindex="-1" role="dialog" aria-labelledby="distritosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione o Distrito</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Código</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.distritos.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link href="#" onClick={this.SelecionarDistrito.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link href="#" onClick={this.SelecionarDistrito.bind(this, item.id, item.descricao)}>{item.codigo}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link href="#" onClick={this.SelecionarDistrito.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default ConsultaSubDistrito;