import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import { PrivateRoute, LoginLayout, MainLayout } from './components/Autenticacao/Autenticacao';
import Main from './components/Main/Main';
import E404 from './components/Errors/E404';
import ConsultaDistrito from './components/ConsultaDistrito/ConsultaDistrito';
import ConsultaSubDistrito from './components/ConsultaSubDistrito/ConsultaSubDistrito';
import ConsultaSetor from './components/ConsultaSetor/ConsultaSetor';
import ConsultaLogradouro from './components/ConsultaLogradouro/ConsultaLogradouro';
import ConsultaGleba from './components/ConsultaGleba/ConsultaGleba';
import ConsultaUsuario from './components/ConsultaUsuario/ConsultaUsuario';
import ConsultaRecenseador from './components/ConsultaRecenseador/ConsultaRecenseador';
import ConsultaUnidadeSuperficie from './components/ConsultaUnidadeSuperficie/ConsultaUnidadeSuperficie';
import ConsultaLavoura from './components/ConsultaLavoura/ConsultaLavoura';
import ConsultaProdutoVegetal from './components/ConsultaProdutoVegetal/ConsultaProdutoVegetal';
import ConsultaAdubo from './components/ConsultaAdubo/ConsultaAdubo';
import ConsultaCalcario from './components/ConsultaCalcario/ConsultaCalcario';
import ConsultaAnalisesDeSolo from './components/ConsultaAnalisesDeSolo/ConsultaAnalisesDeSolo';
import ConsultaImplementos from './components/ConsultaImplementos/ConsultaImplementos';
import ConsultaParasitas from './components/ConsultaParasitas/ConsultaParasitas';
import ConsultaPragas from './components/ConsultaPragas/ConsultaPragas';
import ConsultaProgramasGovernamentais from './components/ConsultaProgramasGovernamentais/ConsultaProgramasGovernamentais';
import ConsultaCenso from './components/ConsultaCenso/ConsultaCenso';
import S01_Identificacao from './components/ConsultaCenso/CadastrosCenso/S01_Identificacao';
import S02_CaracteristicasProdutor from './components/ConsultaCenso/CadastrosCenso/S02_CaracteristicasProdutor';
import S03_DistribuicaoEstabelecimento from './components/ConsultaCenso/CadastrosCenso/S03_DistribuicaoEstabelecimento';
import S04_DistribuicaoUtilizacao from './components/ConsultaCenso/CadastrosCenso/S04_DistribuicaoUtilizacao';
import S05_CaracteristicasEstabelecimento from './components/ConsultaCenso/CadastrosCenso/S05_CaracteristicasEstabelecimento';
import S06_UnidadesArmazenadoras from './components/ConsultaCenso/CadastrosCenso/S06_UnidadesArmazenadoras';
import S07_MaquinasVeiculos from './components/ConsultaCenso/CadastrosCenso/S07_MaquinasVeiculos';
import S08_PessoasEstabelecimento from './components/ConsultaCenso/CadastrosCenso/S08_PessoasEstabelecimento';
import S09_Trabalhadores from './components/ConsultaCenso/CadastrosCenso/S09_Trabalhadores';
import S10_Pecuaria from './components/ConsultaCenso/CadastrosCenso/S10_Pecuaria';
import S11_Bovinos from './components/ConsultaCenso/CadastrosCenso/S11_Bovinos';
import S12_Bubalinos from './components/ConsultaCenso/CadastrosCenso/S12_Bubalinos';
import S13_Equinos from './components/ConsultaCenso/CadastrosCenso/S13_Equinos';
import S14_Asininos from './components/ConsultaCenso/CadastrosCenso/S14_Asininos';
import S15_Muares from './components/ConsultaCenso/CadastrosCenso/S15_Muares';
import S16_Suinos from './components/ConsultaCenso/CadastrosCenso/S16_Suinos';
import S17_Caprinos from './components/ConsultaCenso/CadastrosCenso/S17_Caprinos';
import S18_Ovinos from './components/ConsultaCenso/CadastrosCenso/S18_Ovinos';
import S19_Galinaceos from './components/ConsultaCenso/CadastrosCenso/S19_Galinaceos';
import S20_Codornas from './components/ConsultaCenso/CadastrosCenso/S20_Codornas';
import S21_OutrasAves from './components/ConsultaCenso/CadastrosCenso/S21_OutrasAves';
import S22_Coelhos from './components/ConsultaCenso/CadastrosCenso/S22_Coelhos';
import S23_Abelhas from './components/ConsultaCenso/CadastrosCenso/S23_Abelhas';
import S24_Aquicultura from './components/ConsultaCenso/CadastrosCenso/S24_Aquicultura';
import S25_Ranicultura from './components/ConsultaCenso/CadastrosCenso/S25_Ranicultura';
import S26_Sericicultura from './components/ConsultaCenso/CadastrosCenso/S26_Sericicultura';
import S27_MoluscosCrustaceos from './components/ConsultaCenso/CadastrosCenso/S27_MoluscosCrustaceos';
import S28_ProducaoVegetal from './components/ConsultaCenso/CadastrosCenso/S28_ProducaoVegetal';
import S29_LavouraTemporaria from './components/ConsultaCenso/CadastrosCenso/S29_LavouraTemporaria';
import S30_LavouraPermanente from './components/ConsultaCenso/CadastrosCenso/S30_LavouraPermanente';
import S31_Horticultura from './components/ConsultaCenso/CadastrosCenso/S31_Horticultura';
import S32_ExtracaoVegetal from './components/ConsultaCenso/CadastrosCenso/S32_ExtracaoVegetal';
import S33_Floricultura from './components/ConsultaCenso/CadastrosCenso/S33_Floricultura';
import S34_EfetivosSilvicultura from './components/ConsultaCenso/CadastrosCenso/S34_EfetivosSilvicultura';
import S35_ProdutosSilvicultura from './components/ConsultaCenso/CadastrosCenso/S35_ProdutosSilvicultura';
import S36_AgroIndustriaRural from './components/ConsultaCenso/CadastrosCenso/S36_AgroIndustriaRural';
import S37_OutrasReceitas from './components/ConsultaCenso/CadastrosCenso/S37_OutrasReceitas';
import S38_Financiamentos from './components/ConsultaCenso/CadastrosCenso/S38_Financiamentos';
import S39_Despesas from './components/ConsultaCenso/CadastrosCenso/S39_Despesas';
import S40_OutroEndereco from './components/ConsultaCenso/CadastrosCenso/S40_OutroEndereco';
import S41_Autenticacao from './components/ConsultaCenso/CadastrosCenso/S41_Autenticacao';
import MenuRelatorios from './components/Relatorios/MenuRelatorios';
import RelEstabelecimentosXDistritos from './components/Relatorios/RelEstabelecimentosXDistrito';
import RelEstabelecimentosXPecuaria from './components/Relatorios/RelEstabelecimentosXPecuaria';
import RelEstabelecimentosXLavoura from './components/Relatorios/RelEstabelecimentosXLavoura';
import 'typeface-roboto';

function App() {
  return (
      <div>
        <Switch>
          <Route path="/login" exact layout={LoginLayout} component={Login} />
          <PrivateRoute path="/" exact layout={MainLayout} component={Main} />
          <PrivateRoute path="/consultadistrito" exact layout={MainLayout} component={ConsultaDistrito} />
          <PrivateRoute path="/consultasubdistrito" exact layout={MainLayout} component={ConsultaSubDistrito} />
          <PrivateRoute path="/consultasetor" exact layout={MainLayout} component={ConsultaSetor} />
          <PrivateRoute path="/consultagleba" exact layout={MainLayout} component={ConsultaGleba} />
          <PrivateRoute path="/consultalogradouro" exact layout={MainLayout} component={ConsultaLogradouro} />
          <PrivateRoute path="/consultausuario" exact layout={MainLayout} component={ConsultaUsuario} />
          <PrivateRoute path="/consultarecenseador" exact layout={MainLayout} component={ConsultaRecenseador} />
          <PrivateRoute path="/consultaunidadesuperficie" exact layout={MainLayout} component={ConsultaUnidadeSuperficie} />
          <PrivateRoute path="/consultalavoura" exact layout={MainLayout} component={ConsultaLavoura} />
          <PrivateRoute path="/consultaprodutovegetal" exact layout={MainLayout} component={ConsultaProdutoVegetal} />
          <PrivateRoute path="/consultaadubo" exact layout={MainLayout} component={ConsultaAdubo} />
          <PrivateRoute path="/consultacalcario" exact layout={MainLayout} component={ConsultaCalcario} />
          <PrivateRoute path="/consultaanalisesdesolo" exact layout={MainLayout} component={ConsultaAnalisesDeSolo} />
          <PrivateRoute path="/consultaimplementos" exact layout={MainLayout} component={ConsultaImplementos} />
          <PrivateRoute path="/consultaparasitas" exact layout={MainLayout} component={ConsultaParasitas} />
          <PrivateRoute path="/consultapragas" exact layout={MainLayout} component={ConsultaPragas} />
          <PrivateRoute path="/consultaprogramasgovernamentais" exact layout={MainLayout} component={ConsultaProgramasGovernamentais} />
          <PrivateRoute path="/consultacenso" exact layout={MainLayout} component={ConsultaCenso} />
          <PrivateRoute path="/s01" exact layout={MainLayout} component={S01_Identificacao} />
          <PrivateRoute path="/s02" exact layout={MainLayout} component={S02_CaracteristicasProdutor} />
          <PrivateRoute path="/s03" exact layout={MainLayout} component={S03_DistribuicaoEstabelecimento} />
          <PrivateRoute path="/s04" exact layout={MainLayout} component={S04_DistribuicaoUtilizacao} />
          <PrivateRoute path="/s05" exact layout={MainLayout} component={S05_CaracteristicasEstabelecimento} />
          <PrivateRoute path="/s06" exact layout={MainLayout} component={S06_UnidadesArmazenadoras} />
          <PrivateRoute path="/s07" exact layout={MainLayout} component={S07_MaquinasVeiculos} />
          <PrivateRoute path="/s08" exact layout={MainLayout} component={S08_PessoasEstabelecimento} />
          <PrivateRoute path="/s09" exact layout={MainLayout} component={S09_Trabalhadores} />
          <PrivateRoute path="/s10" exact layout={MainLayout} component={S10_Pecuaria} />
          <PrivateRoute path="/s11" exact layout={MainLayout} component={S11_Bovinos} />
          <PrivateRoute path="/s12" exact layout={MainLayout} component={S12_Bubalinos} />
          <PrivateRoute path="/s13" exact layout={MainLayout} component={S13_Equinos} />
          <PrivateRoute path="/s14" exact layout={MainLayout} component={S14_Asininos} />
          <PrivateRoute path="/s15" exact layout={MainLayout} component={S15_Muares} />
          <PrivateRoute path="/s16" exact layout={MainLayout} component={S16_Suinos} />
          <PrivateRoute path="/s17" exact layout={MainLayout} component={S17_Caprinos} />
          <PrivateRoute path="/s18" exact layout={MainLayout} component={S18_Ovinos} />
          <PrivateRoute path="/s19" exact layout={MainLayout} component={S19_Galinaceos} />
          <PrivateRoute path="/s20" exact layout={MainLayout} component={S20_Codornas} />
          <PrivateRoute path="/s21" exact layout={MainLayout} component={S21_OutrasAves} />
          <PrivateRoute path="/s22" exact layout={MainLayout} component={S22_Coelhos} />
          <PrivateRoute path="/s23" exact layout={MainLayout} component={S23_Abelhas} />
          <PrivateRoute path="/s24" exact layout={MainLayout} component={S24_Aquicultura} />
          <PrivateRoute path="/s25" exact layout={MainLayout} component={S25_Ranicultura} />
          <PrivateRoute path="/s26" exact layout={MainLayout} component={S26_Sericicultura} />
          <PrivateRoute path="/s27" exact layout={MainLayout} component={S27_MoluscosCrustaceos} />
          <PrivateRoute path="/s28" exact layout={MainLayout} component={S28_ProducaoVegetal} />
          <PrivateRoute path="/s29" exact layout={MainLayout} component={S29_LavouraTemporaria} />
          <PrivateRoute path="/s30" exact layout={MainLayout} component={S30_LavouraPermanente} />
          <PrivateRoute path="/s31" exact layout={MainLayout} component={S31_Horticultura} />
          <PrivateRoute path="/s32" exact layout={MainLayout} component={S32_ExtracaoVegetal} />
          <PrivateRoute path="/s33" exact layout={MainLayout} component={S33_Floricultura} />
          <PrivateRoute path="/s34" exact layout={MainLayout} component={S34_EfetivosSilvicultura} />
          <PrivateRoute path="/s35" exact layout={MainLayout} component={S35_ProdutosSilvicultura} />
          <PrivateRoute path="/s36" exact layout={MainLayout} component={S36_AgroIndustriaRural} />
          <PrivateRoute path="/s37" exact layout={MainLayout} component={S37_OutrasReceitas} />
          <PrivateRoute path="/s38" exact layout={MainLayout} component={S38_Financiamentos} />
          <PrivateRoute path="/s39" exact layout={MainLayout} component={S39_Despesas} />
          <PrivateRoute path="/s40" exact layout={MainLayout} component={S40_OutroEndereco} />
          <PrivateRoute path="/s41" exact layout={MainLayout} component={S41_Autenticacao} />
          <PrivateRoute path="/relatorios" exact layout={MainLayout} component={MenuRelatorios} />
          <PrivateRoute path="/relestabelecimentosxdistrito" exact layout={MainLayout} component={RelEstabelecimentosXDistritos} />
          <PrivateRoute path="/relestabelecimentosxpecuaria" exact layout={MainLayout} component={RelEstabelecimentosXPecuaria} />
          <PrivateRoute path="/relestabelecimentosxlavoura" exact layout={MainLayout} component={RelEstabelecimentosXLavoura} />
          <Route path="/" layout={LoginLayout} component={E404} />
        </Switch>
    </div>
  )
}

export default App;
