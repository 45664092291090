import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, telefonefixoMask, telefonecelularMask, cnpjMask, integerMask, emailMask, limpamask } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as yup from 'yup';

const checkoutSchema = yup.object().shape({
    cnpj : yup
        .string()
        .matches(/\d{14}/, { message: 'O CNPJ deve ter 14 dígitos', excludeEmptyString: true }),
    administrador_telefonefixo : yup
        .string()
        .matches(/\d{10}/, { message: 'O telefone fixo deve ter 10 dígitos incluindo o DDD', excludeEmptyString: true }),
    administrador_telefonecelular : yup
        .string()
        .matches(/\d{11}/, { message: 'O telefone celular deve ter 11 dígitos incluindo o DDD', excludeEmptyString: true }),
    administrador_email : yup
        .string()
        .email('Digite um e-mail válido'),
    administrador_whatsapp : yup
        .string()
        .matches(/\d{11}/, { message: 'O número do Whatsapp deve ter 11 dígitos incluindo o DDD', excludeEmptyString: true }),
    administrador_telegram : yup
        .string()
        .matches(/\d{11}/, { message: 'O número do Telegram deve ter 11 dígitos incluindo o DDD', excludeEmptyString: true }),
});

class S02_CaracteristicasProdutor extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s02_caracteristicasprodutor : {
                cliente : '',
                id : '',
                condicaolegal : '',
                inscritocnpj : '',
                cnpj : '',
                direcaotrabalhos : '',
                administrador_sexo : '',
                administrador_idade : '',
                produtor_sexo : '',
                produtor_idade : '',
                produtor_cor_raca : '',
                produtor_sabelerescrever : '',
                produtor_escolaridade : '',
                produtor_concluiu : '',
                administrador_nome : '',
                administrador_telefonefixo : '',
                administrador_telefonecelular : '',
                administrador_email : '',
                administrador_whatsapp : '',
                administrador_telegram : '',
                residenoestabelecimento : '',
                finalidadeproducao : '',
                rendamaior : '',
                possuidap : '',
                dap_mulher : '',
                dap_jovem : ''
            },
            cnpj_error : '',
            administrador_telefonefixo_error : '',
            administrador_telefonecelular_error : '',
            administrador_email_error : '',
            administrador_whatsapp_error : '',
            administrador_telegram_error : '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s02_caracteristicasprodutor: {
                        ...this.state.s02_caracteristicasprodutor, 
                        cliente : dados.cliente,
                        id : dados.id,
                        condicaolegal : dados.condicaolegal,
                        inscritocnpj : dados.inscritocnpj,
                        cnpj : dados.cnpj,
                        direcaotrabalhos : dados.direcaotrabalhos,
                        administrador_sexo : dados.administrador_sexo,
                        administrador_idade : dados.administrador_idade,
                        produtor_sexo : dados.produtor_sexo,
                        produtor_idade : dados.produtor_idade,
                        produtor_cor_raca : dados.produtor_cor_raca,
                        produtor_sabelerescrever : dados.produtor_sabelerescrever,
                        produtor_escolaridade : dados.produtor_escolaridade,
                        produtor_concluiu : dados.produtor_concluiu,
                        administrador_nome : dados.administrador_nome,
                        administrador_telefonefixo : dados.administrador_telefonefixo,
                        administrador_telefonecelular : dados.administrador_telefonecelular,
                        administrador_email : dados.administrador_email,
                        administrador_whatsapp : dados.administrador_whatsapp,
                        administrador_telegram : dados.administrador_telegram,
                        residenoestabelecimento : dados.residenoestabelecimento,
                        finalidadeproducao : dados.finalidadeproducao,
                        rendamaior : dados.rendamaior,
                        possuidap : dados.possuidap,
                        dap_mulher : dados.dap_mulher,
                        dap_jovem : dados.dap_jovem
                    }});

                    this.setState({ novocadastro : false });
                }
                else {
                    this.setState({s02_caracteristicasprodutor: {
                        ...this.state.s02_caracteristicasprodutor, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        condicaolegal : '',
                        inscritocnpj : '1',
                        cnpj : '',
                        direcaotrabalhos : '',
                        administrador_sexo : '',
                        administrador_idade : '',
                        produtor_sexo : '',
                        produtor_idade : '',
                        produtor_cor_raca : '',
                        produtor_sabelerescrever : '',
                        produtor_escolaridade : '',
                        produtor_concluiu : '',
                        administrador_nome : '',
                        administrador_telefonefixo : '',
                        administrador_telefonecelular : '',
                        administrador_email : '',
                        administrador_whatsapp : '',
                        administrador_telegram : '',
                        residenoestabelecimento : '',
                        finalidadeproducao : '',
                        rendamaior : '',
                        possuidap : '',
                        dap_mulher : '',
                        dap_jovem : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s02_caracteristicasprodutor: {...this.state.s02_caracteristicasprodutor, [e.target.name]: e.target.value }
        }, () => {
            if (this.state.s02_caracteristicasprodutor.inscritocnpj === '1') {
                this.setState({
                    s02_caracteristicasprodutor: {...this.state.s02_caracteristicasprodutor, cnpj: '' }
                });
            }
        });
    }

    onDirecaoTrabalhosClick(e) {
        this.setState({
            s02_caracteristicasprodutor: {...this.state.s02_caracteristicasprodutor, 
                                administrador_sexo: '',
                                administrador_idade: ''
                                }
        });
    }
    
    onPossuiDAPClick(e) {
        this.setState({
            s02_caracteristicasprodutor: {...this.state.s02_caracteristicasprodutor, 
                                dap_mulher: '',
                                dap_jovem: ''
                                }
        });
    }
    
    async salvar(botao) {
        this.setState({
            s02_caracteristicasprodutor: {...this.state.s02_caracteristicasprodutor,
                                cnpj: limpamask(false, this.state.s02_caracteristicasprodutor.cnpj),
                                administrador_idade: limpamask(false, this.state.s02_caracteristicasprodutor.administrador_idade),
                                produtor_idade: limpamask(false, this.state.s02_caracteristicasprodutor.produtor_idade),
                                administrador_telefonefixo: limpamask(false, this.state.s02_caracteristicasprodutor.administrador_telefonefixo),
                                administrador_telefonecelular: limpamask(false, this.state.s02_caracteristicasprodutor.administrador_telefonecelular),
                                administrador_whatsapp: limpamask(false, this.state.s02_caracteristicasprodutor.administrador_whatsapp),
                                administrador_telegram: limpamask(false, this.state.s02_caracteristicasprodutor.administrador_telegram),
                            }
            }, async () => {
                try {
                    this.setState({ cnpj_error : '' });
                    this.setState({ administrador_telefonefixo_error : '' });
                    this.setState({ administrador_telefonecelular_error : '' });
                    this.setState({ administrador_email_error : '' });
                    this.setState({ administrador_whatsapp_error : '' });
                    this.setState({ administrador_telegram_error : '' });

                    await checkoutSchema.validate(this.state.s02_caracteristicasprodutor, { abortEarly: false });
        
                    if (this.state.novocadastro) {
                        censonovo(this.props.match.url, this.state.s02_caracteristicasprodutor);
                    }
                    else {
                        censoeditar(this.props.match.url, this.state.s02_caracteristicasprodutor);
                    }
                    if (botao === 'Back') {
                        this.props.history.push('/S01');
                    }
                    else
                    if (botao === 'Next') {
                        this.props.history.push('/S03');
                    }
                }
                catch(err) {
                    if (err instanceof yup.ValidationError) {
                        for (var i = 0; i < err.inner.length; i++) {
                            this.setState({ [err.inner[i].path +  '_error'] : err.inner[i].message });
                        }
                    }
                }
            }
        );
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>02 - CARACTERÍSTICAS DO ESTABELECIMENTO AGROPECUÁRIO E DO(A) PRODUTOR(A)</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Condição legal do(a) Produtor(a) (assinalar aquela em que o(a) produtor(a) se enquadre)</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                <RadioGroup className="posicaorb" aria-label="condicaolegal" name="condicaolegal" value={this.state.s02_caracteristicasprodutor.condicaolegal} onChange={e => this.onChange(e)}>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Produtor(a) individual" />
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Casal (quando os dois forem responsáveis pela direção), união de pessoas, condomínio ou consórcio" />
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Cooperativa" />
                                    <FormControlLabel value="4" control={<Radio color="primary"/>} label="Sociedade anônima (S/A) ou por cotas de responsabilidade limitada (Ltda)" />
                                    <FormControlLabel value="5" control={<Radio color="primary"/>} label="Instituição de utilidade pública" />
                                    <FormControlLabel value="6" control={<Radio color="primary"/>} label="Governo ( Federal, Estadual ou Municipal )" />
                                    <FormControlLabel value="7" control={<Radio color="primary"/>} label="Outra condição" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-O estabelecimento ou o(a) Produtor(a) é inscrito(a) no Cadastro Nacional de Pessoas Jurídicas - CNPJ?</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <span>
                                <FormControl component="fieldset">
                                        <RadioGroup className="posicaorb" aria-label="inscritocnpj" name="inscritocnpj" value={this.state.s02_caracteristicasprodutor.inscritocnpj} onChange={e => this.onChange(e)}>
                                            <span>
                                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                            </span>
                                        </RadioGroup>
                                </FormControl>
                                <TextField disabled={this.state.s02_caracteristicasprodutor.inscritocnpj === "1"} InputProps={{ inputComponent: cnpjMask }} variant="outlined" size="small" label="Qual é o número do CNPJ?" id="cnpj" name="cnpj" value={this.state.s02_caracteristicasprodutor.cnpj} onChange={e => this.onChange(e)}/>
                                <h6>{this.state.cnpj_error}</h6><p />
                            </span>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Direção dos trabalhos do estabelecimento</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                <RadioGroup className="posicaorb" aria-label="direcaotrabalhos" name="direcaotrabalhos" value={this.state.s02_caracteristicasprodutor.direcaotrabalhos} onChange={e => this.onChange(e)}>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Produtor(a) titular diretamente" onClick={e => this.onDirecaoTrabalhosClick(e)}/>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Casal (codireção)" onClick={e => this.onDirecaoTrabalhosClick(e)}/>
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Produtor(a) titular através de um(a) encarregado(a) ou pessoa com a qual tenha laços de parentesco" onClick={e => this.onDirecaoTrabalhosClick(e)}/>
                                    <span>
                                        <FormControlLabel value="4" control={<Radio color="primary"/>} label="Administrador(a) ---> Qual o sexo ?" />
                                        <FormControl disabled={this.state.s02_caracteristicasprodutor.direcaotrabalhos !== "4"} component="fieldset">
                                            <RadioGroup aria-label="administrador_sexo" name="administrador_sexo" value={this.state.s02_caracteristicasprodutor.administrador_sexo} onChange={e => this.onChange(e)}>
                                                <span>
                                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Homem" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Mulher" />
                                                </span>
                                            </RadioGroup>
                                        </FormControl>
                                        <TextField disabled={this.state.s02_caracteristicasprodutor.direcaotrabalhos !== "4"} InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" label="Qual a idade?" id="administrador_idade" name="administrador_idade" value={this.state.s02_caracteristicasprodutor.administrador_idade} onChange={e => this.onChange(e)}/><p />
                                    </span>
                                    <FormControlLabel className="acertaposicaorb" value="5" control={<Radio color="primary"/>} label="Produtores(as) (explorações comunitárias)" onClick={e => this.onDirecaoTrabalhosClick(e)}/>
                                    <FormControlLabel value="6" control={<Radio color="primary"/>} label="Outra pessoa" onClick={e => this.onDirecaoTrabalhosClick(e)}/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Sexo</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="produtor_sexo" name="produtor_sexo" value={this.state.s02_caracteristicasprodutor.produtor_sexo} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Masculino" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Feminino" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12">
                        <span>
                            <span>
                                <small className="align-middle circulopreto">05</small>
                                <small className="align-middle espacamentorb">-Idade</small>
                            </span>
                            <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" label="" id="produtor_idade" name="produtor_idade" value={this.state.s02_caracteristicasprodutor.produtor_idade} onChange={e => this.onChange(e)}/><p />
                        </span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">06</small>
                            <small>-Cor ou Raça</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="produtor_cor_raca" name="produtor_cor_raca" value={this.state.s02_caracteristicasprodutor.produtor_cor_raca} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1" control={<Radio color="primary"/>} label="Branca" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Preta" />
                                            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Amarela" />
                                            <FormControlLabel value="4" control={<Radio color="primary"/>} label="Parda" />
                                            <FormControlLabel value="5" control={<Radio color="primary"/>} label="Indígena" />
                                            <FormControlLabel value="6" control={<Radio color="primary"/>} label="Desejo não informar" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Sabe ler e escrever</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="produtor_sabelerescrever" name="produtor_sabelerescrever" value={this.state.s02_caracteristicasprodutor.produtor_sabelerescrever} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">08</small>
                            <small>-Qual o curso mais elevado que frequenta ou frequentou?</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                <RadioGroup className="posicaorb" aria-label="produtor_escolaridade" name="produtor_escolaridade" value={this.state.s02_caracteristicasprodutor.produtor_escolaridade} onChange={e => this.onChange(e)}>
                                    <div className='row'>
                                        <div className='col-sm'>
                                            <div>
                                                <FormControlLabel value="01"  control={<Radio color="primary"/>} label="Nunca frequentou escola" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="02" control={<Radio color="primary"/>} label="Classe de alfabetização - CA" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="03"  control={<Radio color="primary"/>} label="Alfabetização de jovens e adultos - AJA" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="04" control={<Radio color="primary"/>} label="Antigo primário (elementar)" />
                                            </div>
                                        </div>
                                        <div className='col-sm'>
                                            <div>
                                                <FormControlLabel value="05"  control={<Radio color="primary"/>} label="Antigo ginasial (médio 1º ciclo)" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="06" control={<Radio color="primary"/>} label="Regular do ensino fundamental ou 1º grau" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="07"  control={<Radio color="primary"/>} label="Educação de jovens e adultos (EJA) do ensino fundamental ou supletivo do 1º grau" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="08" control={<Radio color="primary"/>} label="Antigo científico, clássico, etc. (médio 2º ciclo)" />
                                            </div>
                                        </div>
                                        <div className='col-sm'>
                                            <div>
                                                <FormControlLabel value="09"  control={<Radio color="primary"/>} label="Regular do ensino médio ou 2º grau" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="10" control={<Radio color="primary"/>} label="Técnico de ensino médio ou do 2º grau" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="11"  control={<Radio color="primary"/>} label="Educação de jovens e adultos (EJA) do ensino médio ou supletivo do 2º grau" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="12" control={<Radio color="primary"/>} label="Superior de graduação" />
                                            </div>
                                            <div>
                                                <FormControlLabel value="13"  control={<Radio color="primary"/>} label="Mestrado ou Doutorado" />
                                            </div>
                                        </div>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">09</small>
                            <small>-Concluiu este curso?</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="produtor_concluiu" name="produtor_concluiu" value={this.state.s02_caracteristicasprodutor.produtor_concluiu} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">10</small>
                            <small>-Qual é o nome do administrador?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <TextField variant="outlined" size="small" fullWidth id="administrador_nome" name="administrador_nome" value={this.state.s02_caracteristicasprodutor.administrador_nome} onChange={e => this.onChange(e)}/><p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">11</small>
                                <small>-Qual é o telefone de contato do administrador?</small>
                            </span>
                        </div>
                       <p className="posicaorb"><TextField InputProps={{ inputComponent: telefonefixoMask }} variant="outlined" size="small" label="Fixo" id="administrador_telefonefixo" name="administrador_telefonefixo" value={this.state.s02_caracteristicasprodutor.administrador_telefonefixo} onChange={e => this.onChange(e)}/>
                       <h6>{this.state.administrador_telefonefixo_error}</h6></p>
                        <p className="posicaorb"><TextField InputProps={{ inputComponent: telefonecelularMask }} variant="outlined" size="small" label="Celular" id="administrador_telefonecelular" name="administrador_telefonecelular" value={this.state.s02_caracteristicasprodutor.administrador_telefonecelular} onChange={e => this.onChange(e)}/>
                        <h6>{this.state.administrador_telefonecelular_error}</h6></p>
                    </div>
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">12</small>
                                <small>-Quais são os contatos eletrônicos do administrador?</small>
                            </span>
                        </div>
                        <p className="posicaorb"><TextField InputProps={{ inputComponent: emailMask }} variant="outlined" size="small" fullWidth label="Email" id="administrador_email" name="administrador_email" value={this.state.s02_caracteristicasprodutor.administrador_email} onChange={e => this.onChange(e)}/>
                        <h6>{this.state.administrador_email_error}</h6></p>
                        <div className="row">
                            <div className="col-sm">
                                <p className="posicaorb"><TextField InputProps={{ inputComponent: telefonecelularMask }} variant="outlined" size="small" label="Whatsapp" id="administrador_whatsapp" name="administrador_whatsapp" value={this.state.s02_caracteristicasprodutor.administrador_whatsapp} onChange={e => this.onChange(e)}/>
                                <h6>{this.state.administrador_whatsapp_error}</h6></p>
                            </div>
                            <div className="col-sm">
                                <p className="posicaorb"><TextField InputProps={{ inputComponent: telefonecelularMask }} variant="outlined" size="small" label="Telegram" id="administrador_telegram" name="administrador_telegram" value={this.state.s02_caracteristicasprodutor.administrador_telegram} onChange={e => this.onChange(e)}/>
                                <h6>{this.state.administrador_telegram_error}</h6></p>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">13</small>
                            <small>-A pessoa que administra reside no estabelecimento?</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="residenoestabelecimento" name="residenoestabelecimento" value={this.state.s02_caracteristicasprodutor.residenoestabelecimento} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">14</small>
                            <small>-Qual é a finalidade principal da produção agropecuária do estabelecimento?</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="finalidadeproducao" name="finalidadeproducao" value={this.state.s02_caracteristicasprodutor.finalidadeproducao} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Consumo próprio do Produtor e de pessoas com laços de parentesco com o(a) mesmo(a)" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Comercialização da produção, escambo ou troca" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">15</small>
                            <small>-A renda obtida com atividades desenvolvidas no estabelecimento é maior que outras rendas obtidas pelo produtor?</small>
                        </span>
                    </div>
                        <div className="col-sm">
                            <FormControl component="fieldset">
                                    <RadioGroup className="posicaorb" aria-label="rendamaior" name="rendamaior" value={this.state.s02_caracteristicasprodutor.rendamaior} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                            <FormControlLabel value="3" control={<Radio color="primary"/>} label="Renda Única do Estabelecimento" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">16</small>
                            <small>-O produtor possui DAP (Declaração de Aptidão ao PRONAF)?</small>
                        </span>
                    </div>
                        <div className="col-sm-2">
                            <FormControl component="fieldset">
                                <RadioGroup className="posicaorb" aria-label="possuidap" name="possuidap" value={this.state.s02_caracteristicasprodutor.possuidap} onChange={e => this.onChange(e)}>
                                    <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" onClick={e => this.onPossuiDAPClick(e)}/>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Não sabe" onClick={e => this.onPossuiDAPClick(e)}/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-sm-4 text-right">
                            <br />
                            <span>
                                <small>Tem DAP acessória (mulher)</small>
                                <FormControl disabled={this.state.s02_caracteristicasprodutor.possuidap !== "2"} component="fieldset">
                                    <RadioGroup className="posicaorb acertaposicaolabel" aria-label="dap_mulher" name="dap_mulher" value={this.state.s02_caracteristicasprodutor.dap_mulher} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                            </span>
                            <br />
                            <span>
                                <small>Tem DAP acessória (jovem)</small>
                                <FormControl disabled={this.state.s02_caracteristicasprodutor.possuidap !== "2"} component="fieldset">
                                    <RadioGroup className="posicaorb acertaposicaolabel" aria-label="dap_jovem" name="dap_jovem" value={this.state.s02_caracteristicasprodutor.dap_jovem} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="1"  control={<Radio color="primary"/>} label="Não" />
                                            <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                        </span>
                                    </RadioGroup>
                            </FormControl>
                            </span>
                        </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S02_CaracteristicasProdutor;