import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import './Menu.css';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import RoomIcon from '@material-ui/icons/Room';
import HomeIcon from '@material-ui/icons/Home';
import MapIcon from '@material-ui/icons/Map';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Divider from '@material-ui/core/Divider';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import SpaIcon from '@material-ui/icons/Spa';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import GrainIcon from '@material-ui/icons/Grain';
import EcoIcon from '@material-ui/icons/Eco';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import TimelineIcon from '@material-ui/icons/Timeline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import EmojiNatureIcon from '@material-ui/icons/EmojiNature';
import BugReportIcon from '@material-ui/icons/BugReport';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PrintIcon from '@material-ui/icons/Print';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { logoff : false }
        };
 
    render() {
        if (this.state.logoff) {
            return <Redirect to='/' />
        }
        return (
          <Paper className="menu">
            <MenuList className="linksemsublinhado">
              
              <Link to="/" style={{textDecoration: "none"}}>
                <MenuItem>
                  <ListItemIcon><HomeIcon fontSize="large" /></ListItemIcon>
                  <Typography variant="inherit">Home</Typography>
                </MenuItem>
              </Link>

              {localStorage.getItem("administrador") === "true" ? (
                <div>
                  <Divider />
                  
                  <Link to="/consultausuario" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><PeopleAltIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Usuários</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultarecenseador" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><PermContactCalendarIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Recenseadores</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultadistrito" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><MapIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit">Distritos</Typography>
                    </MenuItem>
                  </Link>
              
                  {localStorage.getItem("temsubdistrito") === "true" ? (
                    <Link to="/consultasubdistrito" style={{textDecoration: "none"}}>
                      <MenuItem>
                        <ListItemIcon><RoomIcon fontSize="large" /></ListItemIcon>
                        <Typography variant="inherit" noWrap>Subdistritos</Typography>
                      </MenuItem>
                    </Link> 
                  ): (
                    null
                  )}

                  <Link to="/consultagleba" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><GrainIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Glebas</Typography>
                    </MenuItem>
                  </Link>

                  {localStorage.getItem("temsetor") === "true" ? (
                    <Link to="/consultasetor" style={{textDecoration: "none"}}>
                      <MenuItem>
                        <ListItemIcon><AccountTreeIcon fontSize="large" /></ListItemIcon>
                        <Typography variant="inherit" noWrap>Setores</Typography>
                      </MenuItem>
                    </Link>
                  ): (
                    null
                  )}

                  <Link to="/consultalogradouro" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><ZoomOutMapIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Logradouros</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaunidadesuperficie" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><SquareFootIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Unidades de Superfície</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultalavoura" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><SpaIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Lavouras</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaprodutovegetal" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><LocalFloristIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Produtos Vegetais</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaadubo" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><EcoIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Adubos</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultacalcario" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><RotateRightIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Corretivos do Solo</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaanalisesdesolo" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><TimelineIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Análises de Solo</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaimplementos" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><LocalShippingIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Implementos Agrícolas</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaparasitas" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><EmojiNatureIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Parasitas</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultapragas" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><BugReportIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Pragas</Typography>
                    </MenuItem>
                  </Link>

                  <Link to="/consultaprogramasgovernamentais" style={{textDecoration: "none"}}>
                    <MenuItem>
                      <ListItemIcon><MenuBookIcon fontSize="large" /></ListItemIcon>
                      <Typography variant="inherit" noWrap>Programas Governamentais</Typography>
                    </MenuItem>
                  </Link>
                </div>
              ) : (
                  null
              )}

              <Divider />

              <Link to="/consultacenso" style={{textDecoration: "none"}}> 
                <MenuItem>
                  <ListItemIcon><EqualizerIcon fontSize="large" /></ListItemIcon>
                  <Typography variant="inherit" noWrap>Censo</Typography>
                </MenuItem>
              </Link>

              <Divider />

              <Link to="/relatorios" style={{textDecoration: "none"}}> 
                <MenuItem>
                  <ListItemIcon><PrintIcon fontSize="large" /></ListItemIcon>
                  <Typography variant="inherit" noWrap>Relatórios</Typography>
                </MenuItem>
              </Link>

            </MenuList>
          </Paper>
        );
    }
}

export default Menu;