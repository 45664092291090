import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, produtosvegetaispesquisa } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class S35_ProdutosSilvicultura extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s35_produtossilvicultura : {
                cliente : '',
                id : '',
                produtovegetal : '',
                codigo : '',
                quantidadeproduzida : '',
                quantidadevendida : '',
                unidademedida : '',
                equivalenciaunidademedida : '',
                precomediovendido : '',
                descricaoprodutovegetal : ''
            },
            produtosvegetais : []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s35_produtossilvicultura: {
                        ...this.state.s35_produtossilvicultura, 
                        cliente : dados.cliente,
                        id : dados.id,
                        produtovegetal : dados.produtovegetal,
                        codigo : dados.codigo,
                        quantidadeproduzida : dados.quantidadeproduzida,
                        quantidadevendida : dados.quantidadevendida,
                        unidademedida : dados.unidademedida,
                        equivalenciaunidademedida : dados.equivalenciaunidademedida,
                        precomediovendido : dados.precomediovendido,
                        descricaoprodutovegetal : dados.descricaoprodutovegetal
                    }}, () => {
                        this.setState({
                            s35_produtossilvicultura: {...this.state.s35_produtossilvicultura, 
                                quantidadeproduzida: ajustamaskdecimal(this.state.s35_produtossilvicultura.quantidadeproduzida),
                                quantidadevendida: ajustamaskdecimal(this.state.s35_produtossilvicultura.quantidadevendida),
                                precomediovendido: ajustamaskdecimal(this.state.s35_produtossilvicultura.precomediovendido)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s35_produtossilvicultura: {...this.state.s35_produtossilvicultura, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        produtovegetal : '',
                        codigo : '',
                        quantidadeproduzida : '',
                        quantidadevendida : '',
                        unidademedida : '',
                        equivalenciaunidademedida : '',
                        precomediovendido : '',
                        descricaoprodutovegetal : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s35_produtossilvicultura: {...this.state.s35_produtossilvicultura, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s35_produtossilvicultura: {...this.state.s35_produtossilvicultura,
                quantidadeproduzida: limpamask(true, this.state.s35_produtossilvicultura.quantidadeproduzida),
                quantidadevendida: limpamask(true, this.state.s35_produtossilvicultura.quantidadevendida),
                precomediovendido: limpamask(true, this.state.s35_produtossilvicultura.precomediovendido)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s35_produtossilvicultura);
            }
            else {
                censoeditar(this.props.match.url, this.state.s35_produtossilvicultura);
            }
            if (botao === 'Back') {
                this.props.history.push('/S34');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S36');
            }
        });
    };

    PesquisaProdutosVegetais() {
        produtosvegetaispesquisa(localStorage.getItem('cliente'), "@", "5").then(dados => { 
            this.setState({produtosvegetais: dados});
        });
        window.$('#produtosvegetais').modal("show");
    };

    SelecionarProdutoVegetal(produtovegetal, descricao) {
        this.setState({s35_produtossilvicultura: {
            ...this.state.s35_produtossilvicultura, 
            produtovegetal: produtovegetal,
            descricaoprodutovegetal : descricao
        }});
        window.$('#produtosvegetais').modal("hide");
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>35 - PRODUTOS DA SILVICULTURA</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Produtos da Silvicultura</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Nome</div>
                            <div className="col-sm-4">
                                <TextField variant="outlined" disabled style={{width: 90 + '%'}} size="small" id="produtovegetal" name="produtovegetal" value={this.state.s35_produtossilvicultura.descricaoprodutovegetal} />
                                <Link to="#" onClick={this.PesquisaProdutosVegetais.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                            </div>
{/*                            
                            <div className="col-sm-1 text-right">Código</div>
                            <div className="col-sm"><TextField variant="outlined" size="small" id="codigo" name="codigo" value={this.state.s35_produtossilvicultura.codigo} onChange={e => this.onChange(e)}/><p /></div>
*/}                            
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade produzida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="quantidadeproduzida" name="quantidadeproduzida" value={this.state.s35_produtossilvicultura.quantidadeproduzida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="quantidadevendida" name="quantidadevendida" value={this.state.s35_produtossilvicultura.quantidadevendida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
{/*                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Unidade de medida (produtovegetal/equivalência)</div>
                            <div className="col-sm">
                                <span>
                                    <TextField variant="outlined" size="small" id="unidademedida" name="unidademedida" value={this.state.s35_produtossilvicultura.unidademedida} onChange={e => this.onChange(e)}/>&nbsp;
                                    <TextField variant="outlined" size="small" id="equivalenciaunidademedida" name="equivalenciaunidademedida" value={this.state.s35_produtossilvicultura.equivalenciaunidademedida} onChange={e => this.onChange(e)}/>
                                </span><p />
                            </div>
                        </div>
*/}                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do produto vendido</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediovendido" name="precomediovendido" value={this.state.s35_produtossilvicultura.precomediovendido} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <p />
                <Divider />
                <p />

                {/* Modal para selecionar PRODUTOS VEGETAIS */}
                <div class="modal fade" id="produtosvegetais" tabindex="-1" role="dialog" aria-labelledby="produtosvegetaisLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione o Produto da Silvicultura</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.produtosvegetais.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarProdutoVegetal.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarProdutoVegetal.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S35_ProdutosSilvicultura;