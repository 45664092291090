import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, limpamask, decimalMask, ajustamaskdecimal } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class S03_DistribuicaoEstabelecimento extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s03_distribuicaoestabelecimento : {
                cliente : '',
                id : '',
                areatotal : '',
                areaproprias : '',
                areaconcedidas : '',
                areaarrendadas : '',
                areaparceria : '',
                areacomodato : '',
                areaocupadas : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s03_distribuicaoestabelecimento: {
                        ...this.state.s03_distribuicaoestabelecimento, 
                        cliente : dados.cliente,
                        id : dados.id,
                        areatotal : localStorage.getItem('areatotal'),
                        areaproprias : dados.areaproprias,
                        areaconcedidas : dados.areaconcedidas,
                        areaarrendadas : dados.areaarrendadas,
                        areaparceria : dados.areaparceria,
                        areacomodato : dados.areacomodato,
                        areaocupadas : dados.areaocupadas
                    }}, () => {
                        this.setState({
                            s03_distribuicaoestabelecimento: {...this.state.s03_distribuicaoestabelecimento, 
                                                areatotal: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areatotal),
                                                areaproprias: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areaproprias),
                                                areaconcedidas: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areaconcedidas),
                                                areaarrendadas: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areaarrendadas),
                                                areaparceria: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areaparceria),
                                                areacomodato: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areacomodato),
                                                areaocupadas: ajustamaskdecimal(this.state.s03_distribuicaoestabelecimento.areaocupadas)
                                            }
                            });
                            this.setState({ novocadastro : false });
                        });
                        this.calculadiferenca();
                    }
                else {
                    this.setState({s03_distribuicaoestabelecimento: {
                        ...this.state.s03_distribuicaoestabelecimento, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        areatotal : localStorage.getItem('areatotal'),
                        areaproprias : '',
                        areaconcedidas : '',
                        areaarrendadas : '',
                        areaparceria : '',
                        areacomodato : '',
                        areaocupadas : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s03_distribuicaoestabelecimento: {...this.state.s03_distribuicaoestabelecimento, [e.target.name]: e.target.value }
        }, () => {
            this.calculadiferenca();
        });
    }

    async salvar(botao) {
        if (this.state.diferenca === 0) {
            this.setState({
                s03_distribuicaoestabelecimento: {...this.state.s03_distribuicaoestabelecimento,
                        areatotal: limpamask(true, this.state.s03_distribuicaoestabelecimento.areatotal),
                        areaproprias: limpamask(true, this.state.s03_distribuicaoestabelecimento.areaproprias),
                        areaconcedidas: limpamask(true, this.state.s03_distribuicaoestabelecimento.areaconcedidas),
                        areaarrendadas: limpamask(true, this.state.s03_distribuicaoestabelecimento.areaarrendadas),
                        areaparceria: limpamask(true, this.state.s03_distribuicaoestabelecimento.areaparceria),
                        areacomodato: limpamask(true, this.state.s03_distribuicaoestabelecimento.areacomodato),
                        areaocupadas: limpamask(true, this.state.s03_distribuicaoestabelecimento.areaocupadas)
                    }
                }, async () => {
                    if (this.state.novocadastro) {
                        censonovo(this.props.match.url, this.state.s03_distribuicaoestabelecimento);
                    }
                    else {
                        censoeditar(this.props.match.url, this.state.s03_distribuicaoestabelecimento);
                    }
                    if (botao === 'Back') {
                        this.props.history.push('/S02');
                    }
                    else
                    if (botao === 'Next') {
                        this.props.history.push('/S04');
                    }
                }
            );
        } else {
            alert('Valores informados não somam o total da área informada no quesito 01. Campo DIFERENÇA deve ser zerado.')
        }
    };

    calculadiferenca() {
        this.setState({
            diferenca : parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areatotal === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areatotal)) -
                        parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areaproprias === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areaproprias)) -
                        parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areaconcedidas === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areaconcedidas)) -
                        parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areaarrendadas === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areaarrendadas)) -
                        parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areaparceria === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areaparceria)) -
                        parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areacomodato === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areacomodato)) -
                        parseInt(limpamask(true, this.state.s03_distribuicaoestabelecimento.areaocupadas === "" ? 0 : this.state.s03_distribuicaoestabelecimento.areaocupadas))
        });
    }

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>03 - DISTRIBUIÇÃO DA ÁREA DO ESTABELECIMENTO NA DATA DE REFERÊNCIA</strong>
                    </div>
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <small>Composição da área total do estabelecimento, segundo a condição legal das terras, na mesma unidade de medida de superfície declarada no quesito 18 do QUADRO 01</small></div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-T O T A L ( importar a área informada no QUADRO 01 QUESITO 18 com respectiva unidade de medida de superfície)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <TextField InputProps={{ inputComponent: decimalMask }} disabled className="teste" variant="outlined" size="small" id="areatotal" name="areatotal" value={this.state.s03_distribuicaoestabelecimento.areatotal}/>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Área de terras próprias</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaproprias" name="areaproprias" value={this.state.s03_distribuicaoestabelecimento.areaproprias} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Área de terras concedidas por orgão fundiário sem título definitivo (inclusive assentamento e com concessão de direito real de uso)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaconcedidas" name="areaconcedidas" value={this.state.s03_distribuicaoestabelecimento.areaconcedidas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Área de terras arrendadas de terceiros e que estavam sendo utilizadas pelo(a) produtor(a)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaarrendadas" name="areaarrendadas" value={this.state.s03_distribuicaoestabelecimento.areaarrendadas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">05</small>
                            <small>-Área de terras em parceria</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaparceria" name="areaparceria" value={this.state.s03_distribuicaoestabelecimento.areaparceria} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">06</small>
                            <small>-Área terras em regime de comodato (contrato ou acerto entre as partes)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areacomodato" name="areacomodato" value={this.state.s03_distribuicaoestabelecimento.areacomodato} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Área de terras ocupadas</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaocupadas" name="areaocupadas" value={this.state.s03_distribuicaoestabelecimento.areaocupadas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo text-right">
                        <strong className="textodiferenca">DIFERENÇA</strong>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} disabled="true" variant="outlined" size="small" id="diferenca" name="diferenca" value={this.state.diferenca}/></span>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S03_DistribuicaoEstabelecimento;