import React, { Component } from 'react';
import './Relatorios.css';
import { relestabelecimentosxpecuaria } from '../../Services/apifunctions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { geraPDF } from "../funcoesgerais";
import { ModCabecalho, ModRodape, ModEstabelecimentosXPecuaria} from "./Modelos";
import ejs from "ejs";

class RelEstabelecimentosXPecuaria extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            atividade: '1',
            descricaoatividade: 'Criação de bovinos',
            formato: 'P'
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        switch (e.target.value) {
            case "1":
                this.setState({ descricaoatividade: 'Criação de bovinos' });
                break;
            case "2":
                this.setState({ descricaoatividade: "Criação de búfalos" });
                break;
            case "3":
                this.setState({ descricaoatividade: "Criação de equinos" });
                break;
            case "4":
                this.setState({ descricaoatividade: "Criação de asininos (jumentos)" });
                break;
            case "5":
                this.setState({ descricaoatividade: "Criação de muares (burros e mulas)" });
                break;
            case "6":
                this.setState({ descricaoatividade: "Criação de suínos" });
                break;
            case "7":
                this.setState({ descricaoatividade: "Criação de caprinos" });
                break;
            case "8":
                this.setState({ descricaoatividade: "Criação de ovinos" });
                break;
            case "9":
                this.setState({ descricaoatividade: "Criação de galinhas, galos, frangas, frangos ou pintos" });
                break;
            case "10":
                this.setState({ descricaoatividade: "Criação de codornas" });
                break;
            case "11":
                this.setState({ descricaoatividade: "Criação de outras aves" });
                break;
            case "12":
                this.setState({ descricaoatividade: "Criação de coelhos" });
                break;
            case "13":
                this.setState({ descricaoatividade: "Criação de abelhas" });
                break;
            case "14":
                this.setState({ descricaoatividade: "Criação de peixes, camarões e moluscos (Aquicultura)" });
                break;
            case "15":
                this.setState({ descricaoatividade: "Criação de rã" });
                break;
            case "16":
                this.setState({ descricaoatividade: "Criação de bicho-da-seda" });
                break;
            case "17":
                this.setState({ descricaoatividade: "Pesca" });
                break;
            case "18":
                this.setState({ descricaoatividade: "Não houve criação" });
                break;
            default:
                this.setState({ descricaoatividade: '' });
        }

    };

    onImprimirClick() {
        relestabelecimentosxpecuaria(localStorage.getItem('cliente'), this.state.atividade).then(dados => { 
            var html = ModCabecalho;
            html += ModEstabelecimentosXPecuaria;
            html += ModRodape;
    
            geraPDF(ejs.render(html, { logomarca: localStorage.getItem("logomarca"), cliente: localStorage.getItem('nomecliente'), relatorio: "Relação de Estabelecimentos com " + this.state.descricaoatividade, atividades: dados, formato: this.state.formato }), this.state.formato);
        });

    };

    render() {
        return (
            <div className="geral container-fluid">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="border border-5 border-bottom-0 border-dark tituloselecao text-center">
                            <h3><strong>Relação de Estabelecimentos por Atividade Pecuária</strong></h3>
                        </div>
                        <div className="border border-3 border-dark selecao text-center">
                            <div>
                                <div><strong>Selecione a Atividade:</strong></div>
                                <FormControl className="seletor" style={{width: "80%"}} variant="outlined" size="small">
                                    <Select 
                                        id="atividade"
                                        name="atividade"
                                        value={this.state.atividade}
                                        onChange={e => this.onChange(e)}
                                    >
                                        <MenuItem value="1">Criação de bovinos</MenuItem>
                                        <MenuItem value="2">Criação de búfalos</MenuItem>
                                        <MenuItem value="3">Criação de equinos</MenuItem>
                                        <MenuItem value="4">Criação de asininos (jumentos)</MenuItem>
                                        <MenuItem value="5">Criação de muares (burros e mulas)</MenuItem>
                                        <MenuItem value="6">Criação de suínos</MenuItem>
                                        <MenuItem value="7">Criação de caprinos</MenuItem>
                                        <MenuItem value="8">Criação de ovinos</MenuItem>
                                        <MenuItem value="9">Criação de galinhas, galos, frangas, frangos ou pintos</MenuItem>
                                        <MenuItem value="10">Criação de codornas</MenuItem>
                                        <MenuItem value="11">Criação de outras aves</MenuItem>
                                        <MenuItem value="12">Criação de coelhos</MenuItem>
                                        <MenuItem value="13">Criação de abelhas</MenuItem>
                                        <MenuItem value="14">Criação de peixes, camarões e moluscos (Aquicultura)</MenuItem>
                                        <MenuItem value="15">Criação de rã</MenuItem>
                                        <MenuItem value="16">Criação de bicho-da-seda</MenuItem>
                                        <MenuItem value="17">Pesca</MenuItem>
                                        <MenuItem value="18">Não houve criação</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="formato" name="formato" value={this.state.formato} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="P" control={<Radio color="primary"/>} label="Retrato" />
                                            <FormControlLabel value="L" control={<Radio color="primary"/>} label="Paisagem" />
                                        </span>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onImprimirClick.bind(this)}>Imprimir</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        );
    }
}

export default RelEstabelecimentosXPecuaria;