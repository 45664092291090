import React, { Component } from 'react';
import '../Consultas.css';
import {logradourospesquisa, logradourosbuscaid, logradourosnovo, logradouroseditar, logradourosdeletar, glebaspesquisa } from '../../Services/apifunctions';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class ConsultaLogradouros extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            logradouros : [],
            logradouro : {
                    cliente: '',
                    id : '',
                    tipo: '',
                    nome : '',
                    gleba : '', 
                    nomegleba: ''
            }, 
            termo : '',
            glebas : []
        };
    }

    Pesquisa() {
        logradourospesquisa(localStorage.getItem('cliente'), this.state.termo).then(dados => { 
            this.setState({logradouros: dados});
        });
    };

    MostrarDetalhes(id) {
        if (id == null)
        {
            this.setState({logradouro: {
                ...this.state.logradouro, 
                cliente: localStorage.getItem('cliente'), 
                id: '', 
                tipo: '',
                nome: '',
                gleba: '',
                nomegleba: ''
            }});
        }
        else
        {
            logradourosbuscaid(localStorage.getItem('cliente'), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({logradouro: {
                        ...this.state.logradouro, 
                        cliente: dados.cliente, 
                        id: dados.id, 
                        tipo: dados.tipo, 
                        nome: dados.nome,
                        gleba: dados.gleba,
                        nomegleba: dados.nomegleba
                    }});
                }
            });
        }

        window.$('#detalhes').modal("show");
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    onChangeSetor(e) {
        this.setState({logradouro: {...this.state.logradouro, [e.target.name]: e.target.value}});
    };
    
    onDeletarClick(cliente, id) {
        logradourosdeletar(cliente, id).then(r => {
            this.Pesquisa();
        });
    };
    
    onConfirmarClick() {
        if (this.state.logradouro.id === '')
        {
            logradourosnovo(this.state.logradouro).then(r => {
                this.Pesquisa();
            });
        }
        else
        {
            logradouroseditar(this.state.logradouro).then(r => {
                this.Pesquisa();
            });
        }
    };
    
    PesquisaGleba() {
        glebaspesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({glebas: dados});
        });
        window.$('#glebas').modal("show");
    };

    SelecionarGleba(gleba, nome) {
        this.setState({logradouro: {
            ...this.state.logradouro, 
            gleba: gleba,
            nomegleba: nome
        }});
        window.$('#glebas').modal("hide");
    };

    render() {
        return (
            <div className="geral container-fluid">
                <h1 className="cabeccadastro">Logradouros</h1>
                <div className="row quadrobusca">
                    <div className="col-md-11">
                        <TextField variant="outlined" margin="dense" label="Informe o texto a ser pesquisado" className="camposbusca" id="Pesquisa" name="termo" value={this.state.termo} onChange={e => this.onChange(e)} autoFocus/><p />
                    </div>
                    <div className="col botaopesquisa">
                        <Link href="#" onClick={this.Pesquisa.bind(this)}><SearchIcon fontSize="large" /></Link>
                    </div>
                </div>
                <button className="btn btn-link botaonovo" type="button" onClick={this.MostrarDetalhes.bind(this, null)}>Novo Cadastro</button>
                <div className="tabela">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                    <StyledTableCell className="colkey">ID</StyledTableCell>
                                    <StyledTableCell>Tipo</StyledTableCell>
                                    <StyledTableCell>Nome</StyledTableCell>
                                    <StyledTableCell>Gleba</StyledTableCell>
                                    <StyledTableCell className="colbuttons"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.logradouros.map((item, indice) => {
                                        return (
                                            <StyledTableRow  key={indice}>
                                                <StyledTableCell  className="colkey"><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.id)}>{item.id}</Link></StyledTableCell > 
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.id)}>{item.tipo}</Link></StyledTableCell >
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.id)}>{item.nome}</Link></StyledTableCell >
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.id)}>{item.nomegleba}</Link></StyledTableCell >
                                                <StyledTableCell  className="colbuttons"><Link href="#" onClick={() => { if (window.confirm('Confirma a exclusão do item selecionado?')) this.onDeletarClick(item.cliente, item.id) } }><DeleteForeverIcon /></Link></StyledTableCell >
                                            </StyledTableRow >
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div class="modal fade" id="detalhes" tabindex="-1" role="dialog" aria-labelledby="detalhesLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title glebamodal" id="detalhesLabel">Detalhes</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField label="ID" disabled id="id" name="id" value={this.state.logradouro.id} style={{width: 100 + 'px'}}/><p />
                                        <FormControl>
                                            <InputLabel htmlFor="tipo">Tipo</InputLabel>
                                            <Select
                                                native
                                                value={this.state.logradouro.tipo}
                                                onChange={e => this.onChangeSetor(e)}
                                                label="Tipo"
                                                style={{width: 450 + 'px'}}
                                                inputProps={{
                                                    name: 'tipo',
                                                    id: 'tipo',
                                                }}
                                                >
                                                <option aria-label="None" value="" />
                                                <option value={'ALAMEDA'}>ALAMEDA</option>
                                                <option value={'AVENIDA'}>AVENIDA</option>
                                                <option value={'CAMINHO'}>CAMINHO</option>
                                                <option value={'ESTRADA'}>ESTRADA</option>
                                                <option value={'PRAÇA'}>PRAÇA</option>
                                                <option value={'RODOVIA'}>RODOVIA</option>
                                                <option value={'RUA'}>RUA</option>
                                                <option value={'TRAVESSA'}>TRAVESSA</option>
                                                <option value={'VIA'}>VIA</option>
                                            </Select>                                                
                                        </FormControl><p />
                                        <TextField label="Nome" id="nome" name="nome" value={this.state.logradouro.nome} style={{width: 450 + 'px'}} onChange={e => this.onChangeSetor(e)}/><p />
                                        <TextField label="Gleba" id="nomegleba" name="nomegleba" value={this.state.logradouro.nomegleba} style={{width: 400 + 'px'}}/>
                                        <Link href="#" onClick={this.PesquisaGleba.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="glebas" tabindex="-1" role="dialog" aria-labelledby="glebasLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="glebasLabel">Selecione a Gleba</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Nome</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.glebas.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link href="#" onClick={this.SelecionarGleba.bind(this, item.id, item.nome)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link href="#" onClick={this.SelecionarGleba.bind(this, item.id, item.nome)}>{item.nome}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ConsultaLogradouros;