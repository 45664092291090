import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, s10_parasitaspesquisa, s10_parasitasbuscaid, s10_parasitasnovo, s10_parasitaseditar, s10_parasitasdeletar, parasitaspesquisa } from '../../../Services/apifunctions';
import { decriptar } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#535353",
      color: theme.palette.common.white,
    },
    body: {
        backgroundColor: "#f0f0f0",
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class S10_Pecuaria extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s10_pecuaria : {
                cliente : '',
                id : '',
                atividades_bovinos : '',
                atividades_bufalos : '',
                atividades_equinos : '',
                atividades_asininos : '',
                atividades_muares : '',
                atividades_suinos : '',
                atividades_caprinos : '',
                atividades_ovinos : '',
                atividades_galinhas : '',
                atividades_codornas : '',
                atividades_outrasaves : '',
                atividades_coelhos : '',
                atividades_abelhas : '',
                atividades_aquicultura : '',
                atividades_ra : '',
                atividades_bichodaseda : '',
                atividades_pesca : '',
                atividades_naohouvecriacao : '',
                fezcontroledoencas : '',
                fezsuplementacao : ''
            },
            parasitas: [],
            s10_parasitas : [],
            s10_parasita : {
                cliente : '',
                idcenso : '',
                id : '',
                idparasita : '',
                produtoutilizado : '',
                observacao : '',
                descricaoparasita : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s10_pecuaria: {
                        ...this.state.s10_pecuaria, 
                        cliente : dados.cliente,
                        id : dados.id,
                        atividades_bovinos : dados.atividades_bovinos,
                        atividades_bufalos : dados.atividades_bufalos,
                        atividades_equinos : dados.atividades_equinos,
                        atividades_asininos : dados.atividades_asininos,
                        atividades_muares : dados.atividades_muares,
                        atividades_suinos : dados.atividades_suinos,
                        atividades_caprinos : dados.atividades_caprinos,
                        atividades_ovinos : dados.atividades_ovinos,
                        atividades_galinhas : dados.atividades_galinhas,
                        atividades_codornas : dados.atividades_codornas,
                        atividades_outrasaves : dados.atividades_outrasaves,
                        atividades_coelhos : dados.atividades_coelhos,
                        atividades_abelhas : dados.atividades_abelhas,
                        atividades_aquicultura : dados.atividades_aquicultura,
                        atividades_ra : dados.atividades_ra,
                        atividades_bichodaseda : dados.atividades_bichodaseda,
                        atividades_pesca : dados.atividades_pesca,
                        atividades_naohouvecriacao : dados.atividades_naohouvecriacao,
                        fezcontroledoencas : dados.fezcontroledoencas,
                        fezsuplementacao : dados.fezsuplementacao
                    }});
                    
                    this.setState({ novocadastro : false });
                } 
                else {
                    this.setState({s10_pecuaria: {...this.state.s10_pecuaria, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        atividades_bovinos : false,
                        atividades_bufalos : false,
                        atividades_equinos : false,
                        atividades_asininos : false,
                        atividades_muares : false,
                        atividades_suinos : false,
                        atividades_caprinos : false,
                        atividades_ovinos : false,
                        atividades_galinhas : false,
                        atividades_codornas : false,
                        atividades_outrasaves : false,
                        atividades_coelhos : false,
                        atividades_abelhas : false,
                        atividades_aquicultura : false,
                        atividades_ra : false,
                        atividades_bichodaseda : false,
                        atividades_pesca : false,
                        atividades_naohouvecriacao : false,
                        fezcontroledoencas : '1',
                        fezsuplementacao : '1'
                    }});

                    this.setState({ novocadastro : true });
                }
            });

            this.PesquisaS10_Parasita();
        }
    };

    // Relacionamento Censo->Parasitas

    PesquisaS10_Parasita() {
        s10_parasitaspesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s10_parasitas: dados})
        });
    };

    MostrarDetalhesParasita(id) {
        if (id == null)
        {
            this.setState({s10_parasita: {
                ...this.state.s10_parasita, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idparasita : '',
                produtoutilizado : '',
                observacao : '',
                descricaoparasita : ''
            }});
        }
        else
        {
            s10_parasitasbuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s10_parasita: {
                        ...this.state.s10_parasita, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idparasita : dados.idparasita,
                        produtoutilizado : dados.produtoutilizado,
                        observacao : dados.observacao,
                        descricaoparasita : dados.descricaoparasita
                    }});        
                }
            });
        }

        window.$('#detalhesparasita').modal("show");
    };

    onChangeParasita(e) {
        this.setState({s10_parasita: {...this.state.s10_parasita, [e.target.name]: e.target.value}});
    };
    
    PesquisaParasita() {
        parasitaspesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({parasitas: dados});
        });

        window.$('#parasitas').modal("show");
    };

    SelecionarParasita(parasita, descricao) {
        this.setState({s10_parasita: {
            ...this.state.s10_parasita, 
            idparasita: parasita,
            descricaoparasita: descricao
        }});
        window.$('#parasitas').modal("hide");
    };

    onConfirmarParasitaClick() {
        if (this.state.s10_parasita.id === '')
        {
            s10_parasitasnovo(this.state.s10_parasita).then(r => {
                this.PesquisaS10_Parasita();
            });
        }
        else
        {
            s10_parasitaseditar(this.state.s10_parasita).then(r => {
                this.PesquisaS10_Parasita();
            });
        }
    };

    onDeletarParasitaClick(cliente, idcenso, id) {
        s10_parasitasdeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS10_Parasita();
        });
    };
    
    // Fim Relacionamentos

    onChange(e) {
        this.setState({ s10_pecuaria: {...this.state.s10_pecuaria, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s10_pecuaria: {...this.state.s10_pecuaria, [name]: checked }});
    }

    async salvar(botao) {
        if (this.state.novocadastro) {
            censonovo(this.props.match.url, this.state.s10_pecuaria);
        }
        else {
            censoeditar(this.props.match.url, this.state.s10_pecuaria);
        }
        if (botao === 'Back') {
            this.props.history.push('/S09');
        }
        else
        if (botao === 'Next') {
            this.props.history.push('/S11');
        }
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>10 - PECUÁRIA NO ESTABELECIMENTO NO PERÍODO DE REFERÊNCIA</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Quais atividades foram desenvolvidas no estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm-3 posicaorb">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_bovinos} onClick={this.onCheckBoxClick.bind(this, "atividades_bovinos", !this.state.s10_pecuaria.atividades_bovinos)} value="atividades_bovinos"/>}
                            label="Criação de bovinos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_bufalos} onClick={this.onCheckBoxClick.bind(this, "atividades_bufalos", !this.state.s10_pecuaria.atividades_bufalos)} value="atividades_bufalos"/>}
                            label="Criação de búfalos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_equinos} onClick={this.onCheckBoxClick.bind(this, "atividades_equinos", !this.state.s10_pecuaria.atividades_equinos)} value="atividades_equinos"/>}
                            label="Criação de equinos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_asininos} onClick={this.onCheckBoxClick.bind(this, "atividades_asininos", !this.state.s10_pecuaria.atividades_asininos)} value="atividades_asininos"/>}
                            label="Criação de asininos (jumentos)"
                        />
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_muares} onClick={this.onCheckBoxClick.bind(this, "atividades_muares", !this.state.s10_pecuaria.atividades_muares)} value="atividades_muares"/>}
                            label="Criação de muares (burros e mulas)"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_suinos} onClick={this.onCheckBoxClick.bind(this, "atividades_suinos", !this.state.s10_pecuaria.atividades_suinos)} value="atividades_suinos"/>}
                            label="Criação de suínos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_caprinos} onClick={this.onCheckBoxClick.bind(this, "atividades_caprinos", !this.state.s10_pecuaria.atividades_caprinos)} value="atividades_caprinos"/>}
                            label="Criação de caprinos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_ovinos} onClick={this.onCheckBoxClick.bind(this, "atividades_ovinos", !this.state.s10_pecuaria.atividades_ovinos)} value="atividades_ovinos"/>}
                            label="Criação de ovinos"
                        />
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_galinhas} onClick={this.onCheckBoxClick.bind(this, "atividades_galinhas", !this.state.s10_pecuaria.atividades_galinhas)} value="atividades_galinhas"/>}
                            label="Criação de galinhas, galos, frangas, frangos ou pintos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_codornas} onClick={this.onCheckBoxClick.bind(this, "atividades_codornas", !this.state.s10_pecuaria.atividades_codornas)} value="atividades_codornas"/>}
                            label="Criação de codornas"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_outrasaves} onClick={this.onCheckBoxClick.bind(this, "atividades_outrasaves", !this.state.s10_pecuaria.atividades_outrasaves)} value="atividades_outrasaves"/>}
                            label="Criação de outras aves"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_coelhos} onClick={this.onCheckBoxClick.bind(this, "atividades_coelhos", !this.state.s10_pecuaria.atividades_coelhos)} value="atividades_coelhos"/>}
                            label="Criação de coelhos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_abelhas} onClick={this.onCheckBoxClick.bind(this, "atividades_abelhas", !this.state.s10_pecuaria.atividades_abelhas)} value="atividades_abelhas"/>}
                            label="Criação de abelhas"
                        />
                    </div>
                    <div className="col-sm">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_aquicultura} onClick={this.onCheckBoxClick.bind(this, "atividades_aquicultura", !this.state.s10_pecuaria.atividades_aquicultura)} value="atividades_aquicultura"/>}
                            label="Criação de peixes, camarões e moluscos (Aquicultura)"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_ra} onClick={this.onCheckBoxClick.bind(this, "atividades_ra", !this.state.s10_pecuaria.atividades_ra)} value="atividades_ra"/>}
                            label="Criação de rã"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_bichodaseda} onClick={this.onCheckBoxClick.bind(this, "atividades_bichodaseda", !this.state.s10_pecuaria.atividades_bichodaseda)} value="atividades_bichodaseda"/>}
                            label="Criação de bicho-da-seda"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_pesca} onClick={this.onCheckBoxClick.bind(this, "atividades_pesca", !this.state.s10_pecuaria.atividades_pesca)} value="atividades_pesca"/>}
                            label="Pesca"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s10_pecuaria.atividades_naohouvecriacao} onClick={this.onCheckBoxClick.bind(this, "atividades_naohouvecriacao", !this.state.s10_pecuaria.atividades_naohouvecriacao)} value="atividades_naohouvecriacao"/>}
                            label="Não houve criação"
                        />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Fez controle de doenças ou parasitas nos animais do estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="fezcontroledoencas" name="fezcontroledoencas" value={this.state.s10_pecuaria.fezcontroledoencas} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s10_pecuaria.fezcontroledoencas === '1' ? true : false} >
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesParasita.bind(this, null)}>Incluir Parasita</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Doenças/Parasitas Controlados</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-left">Produto Utilizado</StyledTableCell>
                                            <StyledTableCell className="text-left">Observação</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s10_parasitas.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesParasita.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesParasita.bind(this, item.id)}>{item.descricaoparasita}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesParasita.bind(this, item.id)}>{item.produtoutilizado}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesParasita.bind(this, item.id)}>{item.observacao}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarParasitaClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                            <span>
                                <small className="circulopreto">03</small>
                                <small>-Fez suplementação alimentar?</small>
                            </span>
                        </div>
                        <div className="col-sm posicaorb">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="fezsuplementacao" name="fezsuplementacao" value={this.state.s10_pecuaria.fezsuplementacao} onChange={e => this.onChange(e)}>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, sal mineral" />
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, ração, grãos, silagem" />
                                    <FormControlLabel value="4" control={<Radio color="primary"/>} label="Sim, subprodutos agroindustriais" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
                {/* Popups para s10_parasitas */}

                <div class="modal fade" id="detalhesparasita" tabindex="-1" role="dialog" aria-labelledby="parasitaLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="parasitaLabel">Doenças/Parasitas</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s10_parasita.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Parasita" id="parasita" name="parasita" value={this.state.s10_parasita.idparasita} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaoparasita" name="descricaoparasita" value={this.state.s10_parasita.descricaoparasita} style={{width: 300 + 'px'}}/>
                                        <Link onClick={this.PesquisaParasita.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField label="Produto Utilizado" id="produtoutilizado" name="produtoutilizado" value={this.state.s10_parasita.produtoutilizado} style={{width: 450 + 'px'}} onChange={e => this.onChangeParasita(e)} autoFocus/><p />
                                        <TextField multiline rows="3" label="Observações" id="observacao" name="observacao" value={this.state.s10_parasita.observacao} style={{width: 450 + 'px'}} onChange={e => this.onChangeParasita(e)}/><p />
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarParasitaClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="parasitas" tabindex="-1" role="dialog" aria-labelledby="parasitasLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="parasitasLabel">Selecione a Doença ou o Parasita</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.parasitas.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarParasita.bind(this, item.id, item.descricao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarParasita.bind(this, item.id, item.descricao)}>{item.descricao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>    
        );
    }
}

export default S10_Pecuaria;