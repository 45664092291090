import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
 
class S12_Bubalinos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s12_bovinos : {
                cliente : '',
                id : '',
                totalcabecas : '',
                vendidoscabecas : '',
                vendidosvalor : '',
                bufalasordenhadascabecas : '',
                leiteproduzidolitros : '',
                leitevendidolitros : '',
                precomediolitro : '',
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s12_bovinos: {
                        ...this.state.s12_bovinos, 
                        cliente : dados.cliente,
                        id : dados.id,
                        totalcabecas : dados.totalcabecas,
                        vendidoscabecas : dados.vendidoscabecas,
                        vendidosvalor : dados.vendidosvalor,
                        bufalasordenhadascabecas : dados.bufalasordenhadascabecas,
                        leiteproduzidolitros : dados.leiteproduzidolitros,
                        leitevendidolitros : dados.leitevendidolitros,
                        precomediolitro : dados.precomediolitro
                    }}, () => {
                        this.setState({
                            s12_bovinos: {...this.state.s12_bovinos, 
                                vendidosvalor: ajustamaskdecimal(this.state.s12_bovinos.vendidosvalor),
                                leiteproduzidolitros: ajustamaskdecimal(this.state.s12_bovinos.leiteproduzidolitros),
                                leitevendidolitros: ajustamaskdecimal(this.state.s12_bovinos.leitevendidolitros),
                                precomediolitro: ajustamaskdecimal(this.state.s12_bovinos.precomediolitro)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s12_bovinos: {...this.state.s12_bovinos, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        totalcabecas : '',
                        vendidoscabecas : '',
                        vendidosvalor : '',
                        bufalasordenhadascabecas : '',
                        leiteproduzidolitros : '',
                        leitevendidolitros : '',
                        precomediolitro : '',
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s12_bovinos: {...this.state.s12_bovinos, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s12_bovinos: {...this.state.s12_bovinos,
                totalcabecas: limpamask(true, this.state.s12_bovinos.totalcabecas),
                vendidoscabecas: limpamask(true, this.state.s12_bovinos.vendidoscabecas),
                vendidosvalor: limpamask(true, this.state.s12_bovinos.vendidosvalor),
                bufalasordenhadascabecas: limpamask(true, this.state.s12_bovinos.bufalasordenhadascabecas),
                leiteproduzidolitros: limpamask(true, this.state.s12_bovinos.leiteproduzidolitros),
                leitevendidolitros: limpamask(true, this.state.s12_bovinos.leitevendidolitros),
                precomediolitro: limpamask(true, this.state.s12_bovinos.precomediolitro)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s12_bovinos);
            }
            else {
                censoeditar(this.props.match.url, this.state.s12_bovinos);
            }
            if (botao === 'Back') {
                this.props.history.push('/S11');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S13');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>12 - BUBALINOS: BÚFALOS E BÚFALAS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Bubalinos no estabelecimento</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Total</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="totalcabecas" name="totalcabecas" value={this.state.s12_bovinos.totalcabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Vendidos (número/valor)</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="vendidoscabecas" name="vendidoscabecas" value={this.state.s12_bovinos.vendidoscabecas} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="vendidosvalor" name="vendidosvalor" value={this.state.s12_bovinos.vendidosvalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Produção de leite de búfala</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Búfalas ordenhadas</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="bufalasordenhadascabecas" name="bufalasordenhadascabecas" value={this.state.s12_bovinos.bufalasordenhadascabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Leite produzido</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Litros</InputAdornment> }} variant="outlined" size="small" id="leiteproduzidolitros" name="leiteproduzidolitros" value={this.state.s12_bovinos.leiteproduzidolitros} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Leite vendido</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Litros</InputAdornment> }} variant="outlined" size="small" id="leitevendidolitros" name="leitevendidolitros" value={this.state.s12_bovinos.leitevendidolitros} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do litro do leite vendido</div>
                            <div className="col-sm-3"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediolitro" name="precomediolitro" value={this.state.s12_bovinos.precomediolitro} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S12_Bubalinos;