import React, { Component } from 'react';
import Chart from "react-google-charts";
import { estatisticas } from '../../Services/apifunctions';

class PropriedadesXDistritos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dadoschart : []
        };
    }

    componentDidMount() {
        estatisticas(localStorage.getItem('cliente'), "PropriedadesXDistritos").then(dados => {
            const Names = Object.keys(dados)
            const Values = Object.values(dados)
            const chartData = [["nome", "valor"]]
            for (let i = 0; i < Names.length; i += 1) {
              chartData.push([Names[i], Values[i]])
            }

            this.setState({dadoschart: chartData});
        });
    }

    render() {
        return (
            <Chart
                width={'100%'}
                height={'100%'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.state.dadoschart}
                options={{
                    title: 'Propriedades por Cidade/Distrito',
                    chartArea: {
                        width: '80%',
                        height: '80%',
                    },
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        );
    }
}

export default PropriedadesXDistritos;