import React, { Component } from 'react';
import './Main.css';
import TotalPropriedades from "../Estatisticas/TotalPropriedades";
import PropriedadesXDistritos from "../Estatisticas/PropriedadesXDistritos";
import CondicaoLegal from "../Estatisticas/CondicaoLegal";
import Pecuaria from "../Estatisticas/Pecuaria";
import { geraPDF } from "../funcoesgerais";

class Main extends Component {
    onGeraPDFClick() {
//        alert(document.getElementById("totalpropriedades").outerHTML);
        geraPDF(document.getElementById("totalpropriedades").outerHTML, "L");
    };

    render() {
        return (
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h1>Dashboard</h1>
                    <br />
                </div>
                <div className="col-sm-6 dashboard bordaesquerda bordasuperior">
                    <TotalPropriedades />
                </div>
                <div className="col-sm-6 dashboard bordaesquerda bordadireita bordasuperior">
                    <PropriedadesXDistritos />
                </div>
                <div className="col-sm-6 dashboard bordaesquerda bordainferior bordasuperior">
                    <CondicaoLegal />
                </div>
                <div className="col-sm-6 dashboard bordacompleta">
                    <Pecuaria />
                </div>
            </div>
        );
    }
}

export default Main;