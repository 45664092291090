import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class S23_Abelhas extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s23_abelhas : {
                cliente : '',
                id : '',
                melvendidokg : '',
                precomediomelkg : '',
                ceravendidakg : '',
                precomediocerakg : '',
                propolisvendidokg : '',
                precomediopropoliskg : '',
                totalcaixas : '',
                naoexistiamcaixas : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s23_abelhas: {
                        ...this.state.s23_abelhas, 
                        cliente : dados.cliente,
                        id : dados.id,
                        melvendidokg : dados.melvendidokg,
                        precomediomelkg : dados.precomediomelkg,
                        ceravendidakg : dados.ceravendidakg,
                        precomediocerakg : dados.precomediocerakg,
                        propolisvendidokg : dados.propolisvendidokg,
                        precomediopropoliskg : dados.precomediopropoliskg,
                        totalcaixas : dados.totalcaixas,
                        naoexistiamcaixas : dados.naoexistiamcaixas
                    }}, () => {
                        this.setState({
                            s23_abelhas: {...this.state.s23_abelhas, 
                                melvendidokg: ajustamaskdecimal(this.state.s23_abelhas.melvendidokg),
                                precomediomelkg: ajustamaskdecimal(this.state.s23_abelhas.precomediomelkg),
                                ceravendidakg: ajustamaskdecimal(this.state.s23_abelhas.ceravendidakg),
                                precomediocerakg: ajustamaskdecimal(this.state.s23_abelhas.precomediocerakg),
                                propolisvendidokg: ajustamaskdecimal(this.state.s23_abelhas.propolisvendidokg),
                                precomediopropoliskg: ajustamaskdecimal(this.state.s23_abelhas.precomediopropoliskg)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s23_abelhas: {...this.state.s23_abelhas, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        melvendidokg : '',
                        precomediomelkg : '',
                        ceravendidakg : '',
                        precomediocerakg : '',
                        propolisvendidokg : '',
                        precomediopropoliskg : '',
                        totalcaixas : '',
                        naoexistiamcaixas : false
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s23_abelhas: {...this.state.s23_abelhas, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s23_abelhas: {...this.state.s23_abelhas, [name]: checked }}, () => {
            if (this.state.s23_abelhas.naoexistiamcaixas) {
                this.setState({
                    s23_abelhas: {...this.state.s23_abelhas, 
                        totalcaixas : '',
                    }
                });
            }
            else
            {
                this.setState({
                    s23_abelhas: {...this.state.s23_abelhas, 
                        totalcaixas : '',
                    }
                });
            }            
        });
    }

    async salvar(botao) {
        this.setState({
            s23_abelhas: {...this.state.s23_abelhas,
                melvendidokg: limpamask(true, this.state.s23_abelhas.melvendidokg),
                precomediomelkg: limpamask(true, this.state.s23_abelhas.precomediomelkg),
                ceravendidakg: limpamask(true, this.state.s23_abelhas.ceravendidakg),
                precomediocerakg: limpamask(true, this.state.s23_abelhas.precomediocerakg),
                propolisvendidokg: limpamask(true, this.state.s23_abelhas.propolisvendidokg),
                precomediopropoliskg: limpamask(true, this.state.s23_abelhas.precomediopropoliskg),
                totalcaixas: limpamask(true, this.state.s23_abelhas.totalcaixas)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s23_abelhas);
            }
            else {
                censoeditar(this.props.match.url, this.state.s23_abelhas);
            }
            if (botao === 'Back') {
                this.props.history.push('/S22');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S24');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>23 - CRIAÇÃO DE ABELHAS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Qual foi a quantidade e preço do mel vendido?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="melvendidokg" name="melvendidokg" value={this.state.s23_abelhas.melvendidokg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do quilo vendido</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediomelkg" name="precomediomelkg" value={this.state.s23_abelhas.precomediomelkg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual foi a quantidade e preço da cera vendida?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="ceravendidakg" name="ceravendidakg" value={this.state.s23_abelhas.ceravendidakg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do quilo vendido</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediocerakg" name="precomediocerakg" value={this.state.s23_abelhas.precomediocerakg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Qual foi a quantidade e preço da venda de geléia real, própolis e pólen?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="propolisvendidokg" name="propolisvendidokg" value={this.state.s23_abelhas.propolisvendidokg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Valor total da venda</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediopropoliskg" name="precomediopropoliskg" value={this.state.s23_abelhas.precomediopropoliskg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Qual era o total de caixas (colmeias) na data de referência?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Total de caixas (colmeias)</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s23_abelhas.naoexistiamcaixas} InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="totalcaixas" name="totalcaixas" value={this.state.s23_abelhas.totalcaixas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={this.state.s23_abelhas.naoexistiamcaixas} onClick={this.onCheckBoxClick.bind(this, "naoexistiamcaixas", !this.state.s23_abelhas.naoexistiamcaixas)} value="naoexistiamcaixas"/>}
                                    label="Não existiam caixas (colmeias)"
                                /><br />  
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S23_Abelhas;