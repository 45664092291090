import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, limpamask, integerMask } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class S09_Trabalhadores extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s09_trabalhadores : {
                cliente : '',
                id : '',
                produtorparenteshomensacima14 : '',
                produtorparenteshomensate14 : '',
                produtorparentesmulheresacima14 : '',
                produtorparentesmulheresate14 : '',
                permanenteshomensacima14 : '',
                permanenteshomensate14 : '',
                permanentesmulheresacima14 : '',
                permanentesmulheresate14 : '',
                temporarioshomensacima14 : '',
                temporarioshomensate14 : '',
                temporariosmulheresacima14 : '',
                temporariosmulheresate14 : '',
                parceiroshomensacima14 : '',
                parceiroshomensate14 : '',
                parceirosmulheresacima14 : '',
                parceirosmulheresate14 : ''
            },
            totalacima14 : '',
            totalate14 : ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s09_trabalhadores: {
                        ...this.state.s09_trabalhadores, 
                        cliente : dados.cliente,
                        id : dados.id,
                        produtorparenteshomensacima14 : dados.produtorparenteshomensacima14,
                        produtorparenteshomensate14 : dados.produtorparenteshomensate14,
                        produtorparentesmulheresacima14 : dados.produtorparentesmulheresacima14,
                        produtorparentesmulheresate14 : dados.produtorparentesmulheresate14,
                        permanenteshomensacima14 : dados.permanenteshomensacima14,
                        permanenteshomensate14 : dados.permanenteshomensate14,
                        permanentesmulheresacima14 : dados.permanentesmulheresacima14,
                        permanentesmulheresate14 : dados.permanentesmulheresate14,
                        temporarioshomensacima14 : dados.temporarioshomensacima14,
                        temporarioshomensate14 : dados.temporarioshomensate14,
                        temporariosmulheresacima14 : dados.temporariosmulheresacima14,
                        temporariosmulheresate14 : dados.temporariosmulheresate14,
                        parceiroshomensacima14 : dados.parceiroshomensacima14,
                        parceiroshomensate14 : dados.parceiroshomensate14,
                        parceirosmulheresacima14 : dados.parceirosmulheresacima14,
                        parceirosmulheresate14 : dados.parceirosmulheresate14
                    }});
                    
                    this.setState({ novocadastro : false });
                    this.soma();
                }
                else {
                    this.setState({s09_trabalhadores: {...this.state.s09_trabalhadores, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        produtorparenteshomensacima14 : '',
                        produtorparenteshomensate14 : '',
                        produtorparentesmulheresacima14 : '',
                        produtorparentesmulheresate14 : '',
                        permanenteshomensacima14 : '',
                        permanenteshomensate14 : '',
                        permanentesmulheresacima14 : '',
                        permanentesmulheresate14 : '',
                        temporarioshomensacima14 : '',
                        temporarioshomensate14 : '',
                        temporariosmulheresacima14 : '',
                        temporariosmulheresate14 : '',
                        parceiroshomensacima14 : '',
                        parceiroshomensate14 : '',
                        parceirosmulheresacima14 : '',
                        parceirosmulheresate14 : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s09_trabalhadores: {...this.state.s09_trabalhadores, [e.target.name]: e.target.value }
        }, () => {
            this.soma();
        });
    }

    soma() {
        this.setState({
            totalacima14 : parseInt(limpamask(true, this.state.s09_trabalhadores.produtorparenteshomensacima14 === "" ? 0 : this.state.s09_trabalhadores.produtorparenteshomensacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.produtorparentesmulheresacima14 === "" ? 0 : this.state.s09_trabalhadores.produtorparentesmulheresacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.permanenteshomensacima14 === "" ? 0 : this.state.s09_trabalhadores.permanenteshomensacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.permanentesmulheresacima14 === "" ? 0 : this.state.s09_trabalhadores.permanentesmulheresacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.temporarioshomensacima14 === "" ? 0 : this.state.s09_trabalhadores.temporarioshomensacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.temporariosmulheresacima14 === "" ? 0 : this.state.s09_trabalhadores.temporariosmulheresacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.parceiroshomensacima14 === "" ? 0 : this.state.s09_trabalhadores.parceiroshomensacima14)) +
                           parseInt(limpamask(true, this.state.s09_trabalhadores.parceirosmulheresacima14 === "" ? 0 : this.state.s09_trabalhadores.parceirosmulheresacima14)),
            totalate14 : parseInt(limpamask(true, this.state.s09_trabalhadores.produtorparenteshomensate14 === "" ? 0 : this.state.s09_trabalhadores.produtorparenteshomensate14)) +
                         parseInt(limpamask(true, this.state.s09_trabalhadores.produtorparentesmulheresate14 === "" ? 0 : this.state.s09_trabalhadores.produtorparentesmulheresate14)) +             
                         parseInt(limpamask(true, this.state.s09_trabalhadores.permanenteshomensate14 === "" ? 0 : this.state.s09_trabalhadores.permanenteshomensate14)) +
                         parseInt(limpamask(true, this.state.s09_trabalhadores.permanentesmulheresate14 === "" ? 0 : this.state.s09_trabalhadores.permanentesmulheresate14)) +
                         parseInt(limpamask(true, this.state.s09_trabalhadores.temporarioshomensate14 === "" ? 0 : this.state.s09_trabalhadores.temporarioshomensate14)) +
                         parseInt(limpamask(true, this.state.s09_trabalhadores.temporariosmulheresate14 === "" ? 0 : this.state.s09_trabalhadores.temporariosmulheresate14)) +
                         parseInt(limpamask(true, this.state.s09_trabalhadores.parceiroshomensate14 === "" ? 0 : this.state.s09_trabalhadores.parceiroshomensate14)) +
                         parseInt(limpamask(true, this.state.s09_trabalhadores.parceirosmulheresate14 === "" ? 0 : this.state.s09_trabalhadores.parceirosmulheresate14))
        });
    }

    async salvar(botao) {
        this.setState({
            s09_trabalhadores: {...this.state.s09_trabalhadores,
                produtorparenteshomensacima14: limpamask(true, this.state.s09_trabalhadores.produtorparenteshomensacima14),
                produtorparenteshomensate14: limpamask(true, this.state.s09_trabalhadores.produtorparenteshomensate14),
                produtorparentesmulheresacima14: limpamask(true, this.state.s09_trabalhadores.produtorparentesmulheresacima14),
                produtorparentesmulheresate14: limpamask(true, this.state.s09_trabalhadores.produtorparentesmulheresate14),
                permanenteshomensacima14: limpamask(true, this.state.s09_trabalhadores.permanenteshomensacima14),
                permanenteshomensate14: limpamask(true, this.state.s09_trabalhadores.permanenteshomensate14),
                permanentesmulheresacima14: limpamask(true, this.state.s09_trabalhadores.permanentesmulheresacima14),
                permanentesmulheresate14: limpamask(true, this.state.s09_trabalhadores.permanentesmulheresate14),
                temporarioshomensacima14: limpamask(true, this.state.s09_trabalhadores.temporarioshomensacima14),
                temporarioshomensate14: limpamask(true, this.state.s09_trabalhadores.temporarioshomensate14),
                temporariosmulheresacima14: limpamask(true, this.state.s09_trabalhadores.temporariosmulheresacima14),
                temporariosmulheresate14: limpamask(true, this.state.s09_trabalhadores.temporariosmulheresate14),
                parceiroshomensacima14: limpamask(true, this.state.s09_trabalhadores.parceiroshomensacima14),
                parceiroshomensate14: limpamask(true, this.state.s09_trabalhadores.parceiroshomensate14),
                parceirosmulheresacima14: limpamask(true, this.state.s09_trabalhadores.parceirosmulheresacima14),
                parceirosmulheresate14: limpamask(true, this.state.s09_trabalhadores.parceirosmulheresate14)
        }}, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s09_trabalhadores);
            }
            else {
                censoeditar(this.props.match.url, this.state.s09_trabalhadores);
            }
            if (botao === 'Back') {
                this.props.history.push('/S08');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S10');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>09 - PRODUTOR E PESSOAS COM LAÇOS DE PARENTESCO COM O MESMO, TRABALHADORES PERMANENTES, TEMPORÁRIOS E PARCEIROS QUE<br />TRABALHAVAM NO ESTABELECIMENTO NA DATA DE REFERÊNCIA)</strong>
                    </div>
                <Divider />
                <p />
                <div className="col-sm">
                    <span>
                        <small className="circulopreto">01</small>
                        <small>-Produtor e pessoas com laços de parentesco com o mesmo, trabalhadores permanentes, temporários e parceiros que estavam trabalhando no estabelecimento na data de referência, mesmo que estivessem ausentes por motivo de férias, licença, etc.</small>
                    </span>
                </div>
                <p />
                <div className="col-sm">
                    <div className="row">
                        <div className="col-sm-6 text-center bordacompleta">
                            <h6 id="titulotabelapessoas"><strong>CATEGORIA</strong></h6>
                        </div>
                        <div className="col-sm-1 text-center bordasuperior bordadireita bordainferior">
                            <h6 id="titulotabelapessoas"><strong>SEXO</strong></h6>
                        </div>
                        <div className="col-sm bordainferior bordasuperior bordadireita">
                            <h6 className="text-center"><strong>FAIXA ETÁRIA</strong></h6>
                            <div className="row">
                                <div className="col-sm-6 text-center bordadireita bordasuperior">
                                    <small><strong>De 14 anos e mais</strong></small>
                                </div>
                                <div className="col-sm text-center bordasuperior">
                                    <small><strong>Menos de 14 anos</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bordainferior bordadireita bordaesquerda">
                        <div className="col-sm-6 linhatabela2ajustatexto bordadireita">
                            <small>Produtor e pessoas com laços de parentesco com o mesmo</small>
                        </div>
                        <div className="col-sm-1 text-center bordadireita">
                            <div className="linhatabela2ajustahomem"><small>Homens</small></div>
                            <div className="linhatabela2ajustamulher"><small>Mulheres</small></div>
                        </div>
                        <div className="col-sm">
                            <div className="row bordainferior">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtorparenteshomensacima14" name="produtorparenteshomensacima14" value={this.state.s09_trabalhadores.produtorparenteshomensacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtorparenteshomensate14" name="produtorparenteshomensate14" value={this.state.s09_trabalhadores.produtorparenteshomensate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtorparentesmulheresacima14" name="produtorparentesmulheresacima14" value={this.state.s09_trabalhadores.produtorparentesmulheresacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtorparentesmulheresate14" name="produtorparentesmulheresate14" value={this.state.s09_trabalhadores.produtorparentesmulheresate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bordainferior bordadireita bordaesquerda">
                        <div className="col-sm-6 linhatabela2ajustatexto bordadireita">
                            <small>Trabalhadores permanentes</small>
                        </div>
                        <div className="col-sm-1 text-center bordadireita">
                            <div className="linhatabela2ajustahomem"><small>Homens</small></div>
                            <div className="linhatabela2ajustamulher"><small>Mulheres</small></div>
                        </div>
                        <div className="col-sm">
                            <div className="row bordainferior">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanenteshomensacima14" name="permanenteshomensacima14" value={this.state.s09_trabalhadores.permanenteshomensacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanenteshomensate14" name="permanenteshomensate14" value={this.state.s09_trabalhadores.permanenteshomensate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanentesmulheresacima14" name="permanentesmulheresacima14" value={this.state.s09_trabalhadores.permanentesmulheresacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanentesmulheresate14" name="permanentesmulheresate14" value={this.state.s09_trabalhadores.permanentesmulheresate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bordainferior bordadireita bordaesquerda">
                        <div className="col-sm-6 linhatabela2ajustatexto bordadireita">
                            <small>Trabalhadores temporários</small>
                        </div>
                        <div className="col-sm-1 text-center bordadireita">
                            <div className="linhatabela2ajustahomem"><small>Homens</small></div>
                            <div className="linhatabela2ajustamulher"><small>Mulheres</small></div>
                        </div>
                        <div className="col-sm">
                            <div className="row bordainferior">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporarioshomensacima14" name="temporarioshomensacima14" value={this.state.s09_trabalhadores.temporarioshomensacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporarioshomensate14" name="temporarioshomensate14" value={this.state.s09_trabalhadores.temporarioshomensate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporariosmulheresacima14" name="temporariosmulheresacima14" value={this.state.s09_trabalhadores.temporariosmulheresacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporariosmulheresate14" name="temporariosmulheresate14" value={this.state.s09_trabalhadores.temporariosmulheresate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bordainferior bordadireita bordaesquerda">
                        <div className="col-sm-6 linhatabela2ajustatexto bordadireita">
                            <small>Trabalhadores parceiros</small>
                        </div>
                        <div className="col-sm-1 text-center bordadireita">
                            <div className="linhatabela2ajustahomem"><small>Homens</small></div>
                            <div className="linhatabela2ajustamulher"><small>Mulheres</small></div>
                        </div>
                        <div className="col-sm">
                            <div className="row bordainferior">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="parceiroshomensacima14" name="parceiroshomensacima14" value={this.state.s09_trabalhadores.parceiroshomensacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="parceiroshomensate14" name="parceiroshomensate14" value={this.state.s09_trabalhadores.parceiroshomensate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="parceirosmulheresacima14" name="parceirosmulheresacima14" value={this.state.s09_trabalhadores.parceirosmulheresacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="parceirosmulheresate14" name="parceirosmulheresate14" value={this.state.s09_trabalhadores.parceirosmulheresate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row bordaesquerda bordadireita">
                        <div className="col-sm-6 linhatabela2ajustatotal">
                            <small><strong>TOTAL</strong></small>
                        </div>
                        <div className="col-sm-1 bordadireita">
                        </div>
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm-6 linhatabela2 linhatabela2ajustatextfield text-center bordadireita">
                                    <TextField disabled={true} InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="totalacima14" name="totalacima14" value={this.state.totalacima14} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela2 linhatabela2ajustatextfield text-center">
                                    <TextField disabled={true} InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="totalate14" name="totalate14" value={this.state.totalate14} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S09_Trabalhadores;