import { URL_API  } from "./base";
import { encriptar } from "../components/funcoesgerais";

// ------ LOGIN ------

export function login(email, senha) {
    return fetch(`${URL_API}/login?email=${encriptar(email)}&senha=${encriptar(senha)}`).then(resultado => resultado.json());
}

export function logoff(cliente, usuario) {
    return fetch(`${URL_API}/logoff?cliente=${encriptar(cliente)}&usuario=${encriptar(usuario)}`);
}

// ------ DISTRITOS ------

export function distritospesquisa(cliente, termo) {
    return fetch(`${URL_API}/distritos/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function distritosbuscaid(cliente, id) {
    return fetch(`${URL_API}/distritos/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function distritosnovo(distrito) {
    return fetch(`${URL_API}/distritos/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(distrito)});
}

export function distritoseditar(distrito) {
    return fetch(`${URL_API}/distritos/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(distrito)});
}

export function distritosdeletar(cliente, id) {
    return fetch(`${URL_API}/distritos/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ SUBDISTRITOS ------

export function subdistritospesquisa(cliente, termo) {
    return fetch(`${URL_API}/subdistritos/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function subdistritosbuscaid(cliente, distrito, subdistrito) {
    return fetch(`${URL_API}/subdistritos/busca/${encriptar(cliente)}/${encriptar(distrito)}/${encriptar(subdistrito)}`).then(resultado => resultado.json());
}

export function subdistritosnovo(subdistrito) {
    return fetch(`${URL_API}/subdistritos/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(subdistrito)});
}

export function subdistritoseditar(subdistrito) {
    return fetch(`${URL_API}/subdistritos/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(subdistrito)});
}

export function subdistritosdeletar(cliente, distrito, subdistrito) {
    return fetch(`${URL_API}/subdistritos/deletar/${encriptar(cliente)}/${encriptar(distrito)}/${encriptar(subdistrito)}`, {method: 'DELETE'});
}

// ------ SETORES ------

export function setorespesquisa(cliente, termo) {
    return fetch(`${URL_API}/setores/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function setoresbuscaid(cliente, id) {
    return fetch(`${URL_API}/setores/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function setoresnovo(setor) {
    return fetch(`${URL_API}/setores/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(setor)});
}

export function setoreseditar(setor) {
    return fetch(`${URL_API}/setores/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(setor)});
}

export function setoresdeletar(cliente, id) {
    return fetch(`${URL_API}/setores/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ GLEBAS ------

export function glebaspesquisa(cliente, termo) {
    return fetch(`${URL_API}/glebas/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function glebasbuscaid(cliente, id) {
    return fetch(`${URL_API}/glebas/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function glebasnovo(gleba) {
    return fetch(`${URL_API}/glebas/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(gleba)});
}

export function glebaseditar(gleba) {
    return fetch(`${URL_API}/glebas/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(gleba)});
}

export function glebasdeletar(cliente, id) {
    return fetch(`${URL_API}/glebas/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ LOGRADOUROS ------

export function logradourospesquisa(cliente, termo) {
    return fetch(`${URL_API}/logradouros/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function logradourosbuscaid(cliente, id) {
    return fetch(`${URL_API}/logradouros/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function logradourosnovo(logradouro) {
    return fetch(`${URL_API}/logradouros/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(logradouro)});
}

export function logradouroseditar(logradouro) {
    return fetch(`${URL_API}/logradouros/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(logradouro)});
}

export function logradourosdeletar(cliente, id) {
    return fetch(`${URL_API}/logradouros/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ USUARIOS ------

export function usuariospesquisa(cliente, termo) {
    return fetch(`${URL_API}/usuarios/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function usuariosbuscaemail(cliente, email) {
    return fetch(`${URL_API}/usuarios/busca/${encriptar(cliente)}/${encriptar(email)}`).then(resultado => resultado.json());
}

export function usuariosnovo(usuario) {
    return fetch(`${URL_API}/usuarios/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(usuario)});
}

export function usuarioseditar(usuario) {
    return fetch(`${URL_API}/usuarios/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(usuario)});
}

export function usuariosdeletar(cliente, email) {
    return fetch(`${URL_API}/usuarios/deletar/${encriptar(cliente)}/${encriptar(email)}`, {method: 'DELETE'});
}

// ------ RECENSEADORES ------

export function recenseadorespesquisa(cliente, termo) {
    return fetch(`${URL_API}/recenseadores/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function recenseadoresbuscaid(cliente, id) {
    return fetch(`${URL_API}/recenseadores/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function recenseadoresnovo(recenseador) {
    return fetch(`${URL_API}/recenseadores/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(recenseador)});
}

export function recenseadoreseditar(recenseador) {
    return fetch(`${URL_API}/recenseadores/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(recenseador)});
}

export function recenseadoresdeletar(cliente, id) {
    return fetch(`${URL_API}/recenseadores/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ UNIDADESSUPERFICIE ------

export function unidadessuperficiepesquisa(cliente, termo) {
    return fetch(`${URL_API}/unidadessuperficie/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function unidadessuperficiebuscaid(cliente, id) {
    return fetch(`${URL_API}/unidadessuperficie/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function unidadessuperficienovo(unidadesuperficie) {
    return fetch(`${URL_API}/unidadessuperficie/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(unidadesuperficie)});
}

export function unidadessuperficieeditar(unidadesuperficie) {
    return fetch(`${URL_API}/unidadessuperficie/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(unidadesuperficie)});
}

export function unidadessuperficiedeletar(cliente, id) {
    return fetch(`${URL_API}/unidadessuperficie/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ LAVOURAS ------

export function lavouraspesquisa(cliente, termo, tipo) {
    return fetch(`${URL_API}/lavouras/pesquisa/${encriptar(cliente)}/${encriptar(termo)}/${encriptar(tipo)}`).then(resultado => resultado.json());
}

export function lavourasbuscaid(cliente, id) {
    return fetch(`${URL_API}/lavouras/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function lavourasnovo(lavouras) {
    return fetch(`${URL_API}/lavouras/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(lavouras)});
}

export function lavouraseditar(lavouras) {
    return fetch(`${URL_API}/lavouras/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(lavouras)});
}

export function lavourasdeletar(cliente, id) {
    return fetch(`${URL_API}/lavouras/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ PRODUTOSVEGETAIS ------

export function produtosvegetaispesquisa(cliente, termo, tipo) {
    return fetch(`${URL_API}/produtosvegetais/pesquisa/${encriptar(cliente)}/${encriptar(termo)}/${encriptar(tipo)}`).then(resultado => resultado.json());
}

export function produtosvegetaisbuscaid(cliente, id) {
    return fetch(`${URL_API}/produtosvegetais/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function produtosvegetaisnovo(produtovegetal) {
    return fetch(`${URL_API}/produtosvegetais/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(produtovegetal)});
}

export function produtosvegetaiseditar(produtovegetal) {
    return fetch(`${URL_API}/produtosvegetais/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(produtovegetal)});
}

export function produtosvegetaisdeletar(cliente, id) {
    return fetch(`${URL_API}/produtosvegetais/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ ADUBOS ------

export function adubospesquisa(cliente, termo) {
    return fetch(`${URL_API}/adubos/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function adubosbuscaid(cliente, id) {
    return fetch(`${URL_API}/adubos/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function adubosnovo(adubo) {
    return fetch(`${URL_API}/adubos/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(adubo)});
}

export function aduboseditar(adubo) {
    return fetch(`${URL_API}/adubos/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(adubo)});
}

export function adubosdeletar(cliente, id) {
    return fetch(`${URL_API}/adubos/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ CALCARIOS ------

export function calcariospesquisa(cliente, termo) {
    return fetch(`${URL_API}/calcarios/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function calcariosbuscaid(cliente, id) {
    return fetch(`${URL_API}/calcarios/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function calcariosnovo(calcario) {
    return fetch(`${URL_API}/calcarios/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(calcario)});
}

export function calcarioseditar(calcario) {
    return fetch(`${URL_API}/calcarios/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(calcario)});
}

export function calcariosdeletar(cliente, id) {
    return fetch(`${URL_API}/calcarios/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ ANALISES DE SOLO ------

export function analisesdesolopesquisa(cliente, termo) {
    return fetch(`${URL_API}/analisesdesolo/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function analisesdesolobuscaid(cliente, id) {
    return fetch(`${URL_API}/analisesdesolo/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function analisesdesolonovo(analisedesolo) {
    return fetch(`${URL_API}/analisesdesolo/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(analisedesolo)});
}

export function analisesdesoloeditar(analisedesolo) {
    return fetch(`${URL_API}/analisesdesolo/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(analisedesolo)});
}

export function analisesdesolodeletar(cliente, id) {
    return fetch(`${URL_API}/analisesdesolo/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ IMPLEMENTOS ------

export function implementospesquisa(cliente, termo) {
    return fetch(`${URL_API}/implementos/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function implementosbuscaid(cliente, id) {
    return fetch(`${URL_API}/implementos/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function implementosnovo(implemento) {
    return fetch(`${URL_API}/implementos/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(implemento)});
}

export function implementoseditar(implemento) {
    return fetch(`${URL_API}/implementos/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(implemento)});
}

export function implementosdeletar(cliente, id) {
    return fetch(`${URL_API}/implementos/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ PARASITAS ------

export function parasitaspesquisa(cliente, termo) {
    return fetch(`${URL_API}/parasitas/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function parasitasbuscaid(cliente, id) {
    return fetch(`${URL_API}/parasitas/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function parasitasnovo(parasita) {
    return fetch(`${URL_API}/parasitas/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(parasita)});
}

export function parasitaseditar(parasita) {
    return fetch(`${URL_API}/parasitas/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(parasita)});
}

export function parasitasdeletar(cliente, id) {
    return fetch(`${URL_API}/parasitas/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ PRAGAS ------

export function pragaspesquisa(cliente, termo) {
    return fetch(`${URL_API}/pragas/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function pragasbuscaid(cliente, id) {
    return fetch(`${URL_API}/pragas/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function pragasnovo(praga) {
    return fetch(`${URL_API}/pragas/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(praga)});
}

export function pragaseditar(praga) {
    return fetch(`${URL_API}/pragas/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(praga)});
}

export function pragasdeletar(cliente, id) {
    return fetch(`${URL_API}/pragas/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ PROGRAMAS GOVERNAMENTAIS ------

export function programasgovernamentaispesquisa(cliente, termo) {
    return fetch(`${URL_API}/programasgovernamentais/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function programasgovernamentaisbuscaid(cliente, id) {
    return fetch(`${URL_API}/programasgovernamentais/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function programasgovernamentaisnovo(programagovernamental) {
    return fetch(`${URL_API}/programasgovernamentais/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(programagovernamental)});
}

export function programasgovernamentaiseditar(programagovernamental) {
    return fetch(`${URL_API}/programasgovernamentais/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(programagovernamental)});
}

export function programasgovernamentaisdeletar(cliente, id) {
    return fetch(`${URL_API}/programasgovernamentais/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ CENSO ------

export function censopesquisa(cliente, termo) {
    return fetch(`${URL_API}/s01/pesquisa/${encriptar(cliente)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function censodeletar(cliente, id) {
    return fetch(`${URL_API}/s01/deletar/${encriptar(cliente)}/${encriptar(id)}`, {method: 'DELETE'});
}

export function censobuscaid(URL_SERVICO, cliente, id) {
    return fetch(`${URL_API}${URL_SERVICO}/busca/${encriptar(cliente)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function censonovo(URL_SERVICO, dados) {
    return fetch(`${URL_API}${URL_SERVICO}/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(dados)});
}

export function censonovo01(URL_SERVICO, dados) {
    return fetch(`${URL_API}${URL_SERVICO}/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(dados)}).then(resultado => resultado.json());
}

export function censoeditar(URL_SERVICO, dados) {
    fetch(`${URL_API}${URL_SERVICO}/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(dados)});
}

// ------ S05 - CALCARIO UTILIZADO ------

export function s05_calcariospesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s05_calcarios/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s05_calcariosbuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_calcarios/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s05_calcariosnovo(s05_calcario) {
    return fetch(`${URL_API}/s05_calcarios/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_calcario)});
}

export function s05_calcarioseditar(s05_calcario) {
    return fetch(`${URL_API}/s05_calcarios/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_calcario)});
}

export function s05_calcariosdeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_calcarios/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ S05 - ADUBO UTILIZADO ------

export function s05_adubospesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s05_adubos/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s05_adubosbuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_adubos/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s05_adubosnovo(s05_adubo) {
    return fetch(`${URL_API}/s05_adubos/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_adubo)});
}

export function s05_aduboseditar(s05_adubo) {
    return fetch(`${URL_API}/s05_adubos/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_adubo)});
}

export function s05_adubosdeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_adubos/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ S05 - ANALISE DE SOLO ------

export function s05_analisesdesolopesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s05_analisesdesolo/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s05_analisesdesolobuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_analisesdesolo/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s05_analisesdesolonovo(s05_analisedesolo) {
    return fetch(`${URL_API}/s05_analisesdesolo/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_analisedesolo)});
}

export function s05_analisesdesoloeditar(s05_analisedesolo) {
    return fetch(`${URL_API}/s05_analisesdesolo/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_analisedesolo)});
}

export function s05_analisesdesolodeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_analisesdesolo/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ S05 - PRAGAS ------

export function s05_pragaspesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s05_pragas/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s05_pragasbuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_pragas/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s05_pragasnovo(s05_praga) {
    return fetch(`${URL_API}/s05_pragas/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_praga)});
}

export function s05_pragaseditar(s05_praga) {
    return fetch(`${URL_API}/s05_pragas/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s05_praga)});
}

export function s05_pragasdeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s05_pragas/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ S07 - IMPLEMENTOS ------

export function s07_implementospesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s07_implementos/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s07_implementosbuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s07_implementos/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s07_implementosnovo(s07_implemento) {
    return fetch(`${URL_API}/s07_implementos/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s07_implemento)});
}

export function s07_implementoseditar(s07_implemento) {
    return fetch(`${URL_API}/s07_implementos/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s07_implemento)});
}

export function s07_implementosdeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s07_implementos/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ S10 - PARASITAS ------

export function s10_parasitaspesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s10_parasitas/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s10_parasitasbuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s10_parasitas/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s10_parasitasnovo(s10_parasita) {
    return fetch(`${URL_API}/s10_parasitas/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s10_parasita)});
}

export function s10_parasitaseditar(s10_parasita) {
    return fetch(`${URL_API}/s10_parasitas/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s10_parasita)});
}

export function s10_parasitasdeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s10_parasitas/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ S38 - PROGRAMAS GOVERNAMENTAIS ------

export function s38_programasgovernamentaispesquisa(cliente, idcenso, termo) {
    return fetch(`${URL_API}/s38_programasgovernamentais/pesquisa/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(termo)}`).then(resultado => resultado.json());
}

export function s38_programasgovernamentaisbuscaid(cliente, idcenso, id) {
    return fetch(`${URL_API}/s38_programasgovernamentais/busca/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`).then(resultado => resultado.json());
}

export function s38_programasgovernamentaisnovo(s38_programagovernamental) {
    return fetch(`${URL_API}/s38_programasgovernamentais/novo`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s38_programagovernamental)});
}

export function s38_programasgovernamentaiseditar(s38_programagovernamental) {
    return fetch(`${URL_API}/s38_programasgovernamentais/editar`, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify(s38_programagovernamental)});
}

export function s38_programasgovernamentaisdeletar(cliente, idcenso, id) {
    return fetch(`${URL_API}/s38_programasgovernamentais/deletar/${encriptar(cliente)}/${encriptar(idcenso)}/${encriptar(id)}`, {method: 'DELETE'});
}

// ------ ESTATISTICAS ------

export function estatisticas(cliente, estatistica) {
    if (estatistica === "TotalPropriedades") {
        return fetch(`${URL_API}/estatisticas/totalpropriedades/${encriptar(cliente)}`).then(resultado => resultado.json());
    }
    if (estatistica === "PropriedadesXDistritos") {
        return fetch(`${URL_API}/estatisticas/propriedadesxdistritos/${encriptar(cliente)}`).then(resultado => resultado.json());
    }
    if (estatistica === "CondicaoLegal") {
        return fetch(`${URL_API}/estatisticas/condicaolegal/${encriptar(cliente)}`).then(resultado => resultado.json());
    }
    if (estatistica === "Pecuaria") {
        return fetch(`${URL_API}/estatisticas/pecuaria/${encriptar(cliente)}`).then(resultado => resultado.json());
    }
}

// ------ RELATORIOS ------

export function relestabelecimentosxdistrito(cliente, distrito) {
    return fetch(`${URL_API}/relatorios/relestabelecimentosxdistrito/${encriptar(cliente)}/${encriptar(distrito)}`).then(resultado => resultado.json());
//    return fetch(`${URL_API}/relatorios/relestabelecimentosxdistrito`, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json',}, body: JSON.stringify({cliente: encriptar(cliente), distrito: encriptar(distrito)})}).then(resultado => resultado.json());
}

export function relestabelecimentosxlavoura(cliente, lavoura) {
    return fetch(`${URL_API}/relatorios/relestabelecimentosxlavoura/${encriptar(cliente)}/${encriptar(lavoura)}`).then(resultado => resultado.json());
}

export function relestabelecimentosxpecuaria(cliente, atividade) {
    return fetch(`${URL_API}/relatorios/relestabelecimentosxpecuaria/${encriptar(cliente)}/${encriptar(atividade)}`).then(resultado => resultado.json());
}
