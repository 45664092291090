import React, { Component } from 'react';
import { login } from '../../Services/apifunctions';
import { Redirect } from 'react-router';
import './Login.css';
import '../../includes/jquery'
import { Mensagem } from '../Autenticacao/Autenticacao';
import TextField from '@material-ui/core/TextField';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            email : '',
            password : '',
            login : false
        };
    }

    VerificaSenha() {
        login(this.state.email, this.state.password).then(dados => { 
            if (dados.valido === 1) {
                if (dados.habilitadocliente) {
                    localStorage.setItem('email', this.state.email);
                    localStorage.setItem('idusuario', dados.idusuario);
                    localStorage.setItem('nomeusuario', dados.nomeusuario);
                    localStorage.setItem('administrador', dados.administrador);
                    localStorage.setItem('cliente', dados.cnpjcliente);
                    localStorage.setItem('nomecliente', dados.nomecliente);
                    localStorage.setItem('municipio', dados.municipiocliente);
                    localStorage.setItem('temsubdistrito', dados.temsubdistritocliente);
                    localStorage.setItem('temsetor', dados.temsetorcliente);
                    localStorage.setItem('nomemunicipio', dados.nomemunicipio);
                    localStorage.setItem('ufmunicipio', dados.ufmunicipio);
                    localStorage.setItem('logomarca', require(`../../images/${localStorage.getItem('cliente')}.png`))
                    this.setState({ login : true });
                }
                else {
                    window.$('#clientenaohabilitado').modal("show");
                }
            }
            else
            {
                window.$('#logininvalido').modal("show");
            }
        });
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
      }
    
     render() {
        if (this.state.login) {
            return <Redirect to='/' />
        }
        return (
            <div className="app">
                <form className="form-signin borda">
                    <h1 className="h3 mb-3 font-weight-bolder titulo borda">Acesso ao Sistema</h1>
                    <TextField className="inputs" variant="outlined" size="small" label="E-mail" id="Email" name="email" value={this.state.email} onChange={e => this.onChange(e)} required autoFocus /><p />
                    <TextField className="inputs" variant="outlined" size="small" label="Senha" id="Password" name="password" type="password" value={this.state.password} onChange={e => this.onChange(e)} required /><p />
                    <button className="btn btn-small btn-primary" type="button" onClick={this.VerificaSenha.bind(this)}>Acessar</button>
                    <p className="versao">v2.0.2171</p>
                </form>

            {Mensagem('logininvalido', 'Login inválido', 'Usuário ou senha inválidos. Tente novamente.')}
            {Mensagem('clientenaohabilitado', 'Cliente Não Habilitado', 'Este cliente não está habilitado a utilizar o sistema. Entre em contato com a MR GESTÃO PÚBLICA para outras informações')}
          </div>
        );
    }
}

export default Login;