import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, lavouraspesquisa } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);


class S30_LavouraPermanente extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s30_lavourapermanente : {
                cliente : '',
                id : '',
                totalpesexistentes : '',
                totalvendido : '',
                unidademedidatotal : '',
                equivalenciaunidademedidatotal : '',
                precomediovendido : '',
                lavoura : '',
                codigo : '',
                pesexistentes : '',
                naoexistiampes : '',
                areatotalcultura : '',
                codigonomeunidademedida : '',
                equivalenciaunidademedida : '',
                quantidadeproduzida : '',
                quantidadevendida : '',
                unidademedidavendido : '',
                equivalenciaunidademedidavendido : '',
                precomedioprodutovendido : '',
                pescolhidos : '',
                areacolhida : '',
                codigonomeunidadecolhida : '',
                equivalenciaunidademedidacolhida : '',
                descricaolavoura : ''
            },
            lavouras: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s30_lavourapermanente: {
                        ...this.state.s30_lavourapermanente, 
                        cliente : dados.cliente,
                        id : dados.id,
                        totalpesexistentes : dados.totalpesexistentes,
                        totalvendido : dados.totalvendido,
                        unidademedidatotal : dados.unidademedidatotal,
                        equivalenciaunidademedidatotal : dados.equivalenciaunidademedidatotal,
                        precomediovendido : dados.precomediovendido,
                        lavoura : dados.lavoura,
                        codigo : dados.codigo,
                        pesexistentes : dados.pesexistentes,
                        naoexistiampes : dados.naoexistiampes,
                        areatotalcultura : dados.areatotalcultura,
                        codigonomeunidademedida : dados.codigonomeunidademedida,
                        equivalenciaunidademedida : dados.equivalenciaunidademedida,
                        quantidadeproduzida : dados.quantidadeproduzida,
                        quantidadevendida : dados.quantidadevendida,
                        unidademedidavendido : dados.unidademedidavendido,
                        equivalenciaunidademedidavendido : dados.equivalenciaunidademedidavendido,
                        precomedioprodutovendido : dados.precomedioprodutovendido,
                        pescolhidos : dados.pescolhidos,
                        areacolhida : dados.areacolhida,
                        codigonomeunidadecolhida : dados.codigonomeunidadecolhida,
                        equivalenciaunidademedidacolhida : dados.equivalenciaunidademedidacolhida,
                        descricaolavoura : dados.descricaolavoura

                    }}, () => {
                        this.setState({
                            s30_lavourapermanente: {...this.state.s30_lavourapermanente, 
                                totalvendido: ajustamaskdecimal(this.state.s30_lavourapermanente.totalvendido),
                                precomediovendido: ajustamaskdecimal(this.state.s30_lavourapermanente.precomediovendido),
                                areatotalcultura: ajustamaskdecimal(this.state.s30_lavourapermanente.areatotalcultura),
                                quantidadeproduzida: ajustamaskdecimal(this.state.s30_lavourapermanente.quantidadeproduzida),
                                quantidadevendida: ajustamaskdecimal(this.state.s30_lavourapermanente.quantidadevendida),
                                precomedioprodutovendido: ajustamaskdecimal(this.state.s30_lavourapermanente.precomedioprodutovendido),
                                areacolhida: ajustamaskdecimal(this.state.s30_lavourapermanente.areacolhida)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s30_lavourapermanente: {...this.state.s30_lavourapermanente, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        totalpesexistentes : '',
                        totalvendido : '',
                        unidademedidatotal : '',
                        equivalenciaunidademedidatotal : '',
                        precomediovendido : '',
                        lavoura : '',
                        codigo : '',
                        pesexistentes : '',
                        naoexistiampes : false,
                        areatotalcultura : '',
                        codigonomeunidademedida : '',
                        equivalenciaunidademedida : '',
                        quantidadeproduzida : '',
                        quantidadevendida : '',
                        unidademedidavendido : '',
                        equivalenciaunidademedidavendido : '',
                        precomedioprodutovendido : '',
                        pescolhidos : '',
                        areacolhida : '',
                        codigonomeunidadecolhida : '',
                        equivalenciaunidademedidacolhida : '',
                        descricaolavoura : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s30_lavourapermanente: {...this.state.s30_lavourapermanente, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s30_lavourapermanente: {...this.state.s30_lavourapermanente, [name]: checked }}, () => {
            if (this.state.s30_lavourapermanente.naoexistiampes) {
                this.setState({
                    s30_lavourapermanente: {...this.state.s30_lavourapermanente,
                        lavoura : '', 
                        pesexistentes : '',
                        descricaolavoura : ''
                    }
                });
            }
        });
    }

    async salvar(botao) {
        this.setState({
            s30_lavourapermanente: {...this.state.s30_lavourapermanente,
                totalpesexistentes: limpamask(true, this.state.s30_lavourapermanente.totalpesexistentes),
                totalvendido: limpamask(true, this.state.s30_lavourapermanente.totalvendido),
                precomediovendido: limpamask(true, this.state.s30_lavourapermanente.precomediovendido),
                pesexistentes: limpamask(true, this.state.s30_lavourapermanente.pesexistentes),
                areatotalcultura: limpamask(true, this.state.s30_lavourapermanente.areatotalcultura),
                quantidadeproduzida: limpamask(true, this.state.s30_lavourapermanente.quantidadeproduzida),
                quantidadevendida: limpamask(true, this.state.s30_lavourapermanente.quantidadevendida),
                precomedioprodutovendido: limpamask(true, this.state.s30_lavourapermanente.precomedioprodutovendido),
                pescolhidos: limpamask(true, this.state.s30_lavourapermanente.pescolhidos),
                areacolhida: limpamask(true, this.state.s30_lavourapermanente.areacolhida)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s30_lavourapermanente);
            }
            else {
                censoeditar(this.props.match.url, this.state.s30_lavourapermanente);
            }
            if (botao === 'Back') {
                this.props.history.push('/S29');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S31');
            }
        });
    };

    PesquisaLavouras() {
        lavouraspesquisa(localStorage.getItem('cliente'), "@", "2").then(dados => { 
            this.setState({lavouras: dados});
        });
        window.$('#lavouras').modal("show");
    };

    SelecionarLavoura(lavoura, descricao) {
        this.setState({s30_lavourapermanente: {
            ...this.state.s30_lavourapermanente, 
            lavoura: lavoura,
            descricaolavoura : descricao
        }});
        window.$('#lavouras').modal("hide");
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>30 - LAVOURA PERMANENTE</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Qual é o número de pés existentes</small>&nbsp;
                            <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="totalpesexistentes" name="totalpesexistentes" value={this.state.s30_lavourapermanente.totalpesexistentes} onChange={e => this.onChange(e)}/>
                            <div className="col-sm posicaorb">(se número de pés menor que 50 e houve venda - passe para quesito 02 e quesito 03) e (se número de pés maior ou igual 50 - salte para o quesito 03)</div>
                        </span>
                    </div>
                </div>
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual foi a quantidade vendida</small>&nbsp;
                            <TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="totalvendido" name="totalvendido" value={this.state.s30_lavourapermanente.totalvendido} onChange={e => this.onChange(e)}/>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
{/*
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Unidade de medida (nome/equivalência)</div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" id="unidademedidatotal" name="unidademedidatotal" value={this.state.s30_lavourapermanente.unidademedidatotal} onChange={e => this.onChange(e)}/>&nbsp;
                                <TextField variant="outlined" size="small" id="equivalenciaunidademedidatotal" name="equivalenciaunidademedidatotal" value={this.state.s30_lavourapermanente.equivalenciaunidademedidatotal} onChange={e => this.onChange(e)}/>
                            </div>
                        </div>
*/}                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do produto vendido</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediovendido" name="precomediovendido" value={this.state.s30_lavourapermanente.precomediovendido} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Produtos da lavoura permanente</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Nome</div>
                            <div className="col-sm-4" disabled={this.state.s30_lavourapermanente.naoexistiampes} >
                                <TextField variant="outlined" disabled style={{width: 90 + '%'}} size="small" id="lavoura" name="lavoura" value={this.state.s30_lavourapermanente.descricaolavoura} />
                                {this.state.s30_lavourapermanente.naoexistiampes ? (
                                    null
                                ) : ( 
                                    <Link to="#" onClick={this.PesquisaLavouras.bind(this)}><SearchIcon fontSize="large" /></Link>
                                )}<p />
                            </div>
{/*
                            <div className="col-sm-1 text-right">Código</div>
                            <div className="col-sm"><TextField disabled={this.state.s30_lavourapermanente.naoexistiampes} variant="outlined" size="small" id="codigo" name="codigo" value={this.state.s30_lavourapermanente.codigo} onChange={e => this.onChange(e)}/><p /></div>
*/}
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Pés existentes</div>
                            <div className="col-sm"><TextField disabled={this.state.s30_lavourapermanente.naoexistiampes} InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="pesexistentes" name="pesexistentes" value={this.state.s30_lavourapermanente.pesexistentes} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={this.state.s30_lavourapermanente.naoexistiampes} onClick={this.onCheckBoxClick.bind(this, "naoexistiampes", !this.state.s30_lavourapermanente.naoexistiampes)} value="naoexistiampes"/>}
                                    label="Não existiam pés na data de referência"
                                /><br />  
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Área total existente da cultura</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Ha</InputAdornment> }} variant="outlined" size="small" id="areatotalcultura" name="areatotalcultura" value={this.state.s30_lavourapermanente.areatotalcultura} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
{/*                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Código e Nome da Unidade de Medida de Superfície</div>
                            <div className="col-sm-2"><TextField variant="outlined" size="small" id="codigonomeunidademedida" name="codigonomeunidademedida" value={this.state.s30_lavourapermanente.codigonomeunidademedida} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-2 text-right">Equivalência da Unidade de Medida de Superfície</div>
                            <div className="col-sm"><TextField variant="outlined" size="small" id="equivalenciaunidademedida" name="equivalenciaunidademedida" value={this.state.s30_lavourapermanente.equivalenciaunidademedida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
*/}                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade produzida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="quantidadeproduzida" name="quantidadeproduzida" value={this.state.s30_lavourapermanente.quantidadeproduzida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade vendida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="quantidadevendida" name="quantidadevendida" value={this.state.s30_lavourapermanente.quantidadevendida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
{/*                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Unidade de medida (nome/equivalência)</div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" id="unidademedidavendido" name="unidademedidavendido" value={this.state.s30_lavourapermanente.unidademedidavendido} onChange={e => this.onChange(e)}/>&nbsp;
                                <TextField variant="outlined" size="small" id="equivalenciaunidademedidavendido" name="equivalenciaunidademedidavendido" value={this.state.s30_lavourapermanente.equivalenciaunidademedidavendido} onChange={e => this.onChange(e)}/>
                            </div>
                        </div>
*/}                        
                        <p />
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do produto vendido</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomedioprodutovendido" name="precomedioprodutovendido" value={this.state.s30_lavourapermanente.precomedioprodutovendido} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Pés colhidos</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="pescolhidos" name="pescolhidos" value={this.state.s30_lavourapermanente.pescolhidos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Área colhida</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Ha</InputAdornment> }} variant="outlined" size="small" id="areacolhida" name="areacolhida" value={this.state.s30_lavourapermanente.areacolhida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
{/*                        
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Código e Nome da Unidade Medida de Superfície</div>
                            <div className="col-sm-2"><TextField variant="outlined" size="small" id="codigonomeunidadecolhida" name="codigonomeunidadecolhida" value={this.state.s30_lavourapermanente.codigonomeunidadecolhida} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-2 text-right">Equivalência da Unidade de Medida de Superfície</div>
                            <div className="col-sm"><TextField variant="outlined" size="small" id="equivalenciaunidademedidacolhida" name="equivalenciaunidademedidacolhida" value={this.state.s30_lavourapermanente.equivalenciaunidademedidacolhida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
*/}                        
                    </div>
                </div>
                <Divider />
                <p />

                {/* Modal para selecionar LAVOURAS */}
                <div class="modal fade" id="lavouras" tabindex="-1" role="dialog" aria-labelledby="lavourasLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione a Lavoura Permanente</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.lavouras.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarLavoura.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarLavoura.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S30_LavouraPermanente;