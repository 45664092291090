import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

class S26_Sericicultura extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s26_sericicultura : {
                cliente : '',
                id : '',
                casulosproduzidoskg : '',
                casulosvendidoskg : '',
                precomediokg : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s26_sericicultura: {
                        ...this.state.s26_sericicultura, 
                        cliente : dados.cliente,
                        id : dados.id,
                        casulosproduzidoskg : dados.casulosproduzidoskg,
                        casulosvendidoskg : dados.casulosvendidoskg,
                        precomediokg : dados.precomediokg
                    }}, () => {
                        this.setState({
                            s26_sericicultura: {...this.state.s26_sericicultura, 
                                casulosproduzidoskg: ajustamaskdecimal(this.state.s26_sericicultura.casulosproduzidoskg),
                                casulosvendidoskg: ajustamaskdecimal(this.state.s26_sericicultura.casulosvendidoskg),
                                precomediokg: ajustamaskdecimal(this.state.s26_sericicultura.precomediokg)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s26_sericicultura: {...this.state.s26_sericicultura, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        casulosproduzidoskg : '',
                        casulosvendidoskg : '',
                        precomediokg : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s26_sericicultura: {...this.state.s26_sericicultura, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s26_sericicultura: {...this.state.s26_sericicultura,
                casulosproduzidoskg: limpamask(true, this.state.s26_sericicultura.casulosproduzidoskg),
                casulosvendidoskg: limpamask(true, this.state.s26_sericicultura.casulosvendidoskg),
                precomediokg: limpamask(true, this.state.s26_sericicultura.precomediokg)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s26_sericicultura);
            }
            else {
                censoeditar(this.props.match.url, this.state.s26_sericicultura);
            }
            if (botao === 'Back') {
                this.props.history.push('/S25');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S27');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>26 - SERICICULTURA: BICHO-DA-SEDA</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Total de casulos e quantidade vendida</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Total de casulos produzidos</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="casulosproduzidoskg" name="casulosproduzidoskg" value={this.state.s26_sericicultura.casulosproduzidoskg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Quantidade de casulos vendidos</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="casulosvendidoskg" name="casulosvendidoskg" value={this.state.s26_sericicultura.casulosvendidoskg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do quilo vendido</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediokg" name="precomediokg" value={this.state.s26_sericicultura.precomediokg} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>    
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S26_Sericicultura;