import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import EmailMask from 'text-mask-addons/dist/emailMask'
import PropTypes from 'prop-types';
import { jsPDF } from "jspdf";

// ------ FUNÇÃO PARA APRESENTAR PDFs ------
export function geraPDF(html, formato) {
  const pdf = new jsPDF({
    orientation: formato
  });
  pdf.html(html, {
    callback: function (pdf) {
      var aba = window.open();
      var embed = document.createElement('embed');
      embed.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:100%');
      aba.document.body.appendChild(embed);
      embed.type = "application/pdf";
      embed.src = pdf.output('datauristring');
    }
  });
}
// ------ FUNÇÃO PARA LIMPAR MÁSCARAS PARA SALVAR NO BANCO ------

export function limpamask(ehnumero: Boolean, campo: String) {
    if (ehnumero) {
      return campo.toString().replace(/\./g, '').replace(',', '.');
    }
    else {
      return campo.toString().replace(/\D+/g, '');
    }
}

// ------ FUNÇÃO PARA TROCAR . POR , AO LER CAMPOS NUMÉRICOS COM DECIMAIS ------

export function ajustamaskdecimal(campo: String) {
    if (campo == null) { campo = '0'};
    return campo.toString().replace('.', ',');
}

// ------ MÁSCARAS DE EDIÇÃO ------

export function telefonefixoMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/g]}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
}

telefonefixoMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export function telefonecelularMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/g]}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
}

telefonecelularMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export function cpfMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/g]}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
}

cpfMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export function anoMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/g]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

anoMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export function cnpjMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/g]}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
}

cnpjMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export function cepMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/g]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

cnpjMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const numberdecimalMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    requireDecimal: true
})
  
export function decimalMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={numberdecimalMask}
        placeholderChar={'\u2000'}
        showMask
        guide={false}
      />
    );
}

const numberdecimalMask6 = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 6,
  requireDecimal: true
})

export function decimalMask6(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberdecimalMask6}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

const numberintegerMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
})

export function integerMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberintegerMask}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

export function emailMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={EmailMask}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

export function geralistaanos() 
{
  const years = [];

  for (var i = 0; i < 5; i++) {
    years.push(new Date().getFullYear() - i);
  }

  return years;
};

// ------ CRIPTOGRAFIA ------

export function encriptar(Texto)
{
    // Chave (Chave privada para ser usada na criptografia)
    var Chave = "ComFéemDeusNósVenceremos";

    var KeyLen = Chave.length;
    console.log(KeyLen);
    var KeyPos = 0;
    var offset = Math.floor(Math.random() * 100);
    while (offset > 256) 
    {
        offset = Math.floor(Math.random() * 100);
    }
    var dest = (offset.toString(16).toUpperCase().length === 2 ? offset.toString(16).toUpperCase() : "0" + offset.toString(16).toUpperCase());
    for (var SrcPos = 0; SrcPos <= (String(Texto).length - 1); SrcPos++)
    {
        var SrcAsc = (String(Texto).charCodeAt(SrcPos) + offset) % 255;
        SrcAsc = SrcAsc ^ Chave.charCodeAt(KeyPos);
        if (KeyPos < KeyLen - 1)
        {
            KeyPos = KeyPos + 1;
        }
        else
        {
            KeyPos = 0;
        }
        dest = dest + (SrcAsc.toString(16).toUpperCase().length === 2 ? SrcAsc.toString(16).toUpperCase() : "0" + SrcAsc.toString(16).toUpperCase());
        offset = SrcAsc;
    }
    return dest;
}

export function decriptar(Texto)
{
    // Chave (Chave privada para ser usada na criptografia)
    var Chave = "ComFéemDeusNósVenceremos";

    var dest = "";
    var KeyLen = Chave.length;
    var KeyPos = 0;

    //int Range = 256;
    if ((Texto.length > 2) &&
       (Texto.substr(2, 2) !== ""))
    {
        var offset = parseInt(Texto.substr(0, 2), 16);
        var SrcPos = 2;
        while (SrcPos < Texto.length - 1)
        {
            var SrcAsc = parseInt(Texto.substr(SrcPos, 2), 16);
            var TmpSrcAsc = SrcAsc ^ Chave.charCodeAt(KeyPos);
            if (KeyPos < KeyLen - 1)
            {
                KeyPos = KeyPos + 1;
            }
            else
            {
                KeyPos = 0;
            }
            if (TmpSrcAsc <= offset)
            {
                TmpSrcAsc = 255 + TmpSrcAsc - offset;
            }
            else
            {
                TmpSrcAsc = TmpSrcAsc - offset;
            }
            dest = dest + String.fromCharCode(TmpSrcAsc);
            offset = SrcAsc;
            SrcPos = SrcPos + 2;
        }
    }
    else
    {
        dest = "";
    }
    return dest;
}

// Funções para Google Maps
// Função que constrói a string de conversão
// de coordenadas em DD para DMS.
export function ddToDms(plat, plng) {

  var lat, lng;

  var flat = parseFloat(plat);  
  var flng = parseFloat(plng);

    // Chamada à função getDms(lat) para as coordenadas da Latitude em DMS.
  // O resultado será adicionado à variável latResult.
  lat = getDms(flat);
  lat += (flat >= 0)? 'N' : 'S';

  // Chamada à função getDms(lng) para as coordenadas da Longitude em DMS.
  // O resultado será adicionado à variável lngResult.
  lng = getDms(flng);
  lng += (flng >= 0)? 'E' : 'W';

  // Devolvendo a coordenada em DMS
  return { lat, lng };
}

export function getDms(dd) {
  var valDeg, valMin, valSec, aux, result;

  dd = Math.abs(dd);

  valDeg = Math.trunc(dd);
  result = leftPad(valDeg, 2) + "º";
  aux = dd - valDeg;

  aux = aux * 60;
  valMin = Math.trunc(aux);
  result += leftPad(valMin, 2) + "'";
  aux = aux - valMin;

  aux = aux * 60;
  valSec = aux.toFixed(11);
  result += valSec + '"';

  return result;
}

export function dmsToDd(plat, plng) {

  var lat, lng;

  lat = (plat.substr(plat.length - 1, 1) === 'N')? '' : '-';

  // Chamada à função getDd(lat) para as coordenadas da Latitude em DD.
  // O resultado será adicionado à variável latResult.
  lat += getDd(plat);

  lng = (plng.substr(plng.length - 1, 1) === 'E')? '' : '-';

  // Chamada à função getDs(lng) para as coordenadas da Longitude em DD.
  // O resultado será adicionado à variável lngResult.
  lng += getDd(plng);

  // Devolvendo a coordenada em DD
  return { lat, lng };
}


export function getDd(dms) {
  var result;

  result = parseFloat(dms.substr(6, dms.length - 8)) / 60;
  result += parseFloat(dms.substr(3, 2));
  result = (result / 60) + parseFloat(dms.substr(0, 2));

  return result;
 }

export function formatDMS(value) {
  var result;

  if ((isNaN(value.substr(0, value.length - 1))) ||
     (value.length < 7) ||
     ((value.substr(value.length - 1, 1).toUpperCase() !== "S") &&
      (value.substr(value.length - 1, 1).toUpperCase() !== "N") &&
      (value.substr(value.length - 1, 1).toUpperCase() !== "W") &&
      (value.substr(value.length - 1, 1).toUpperCase() !== "E"))) 
  {
    result = '';
  }
  else
  {
    result = value.substr(0, 2) + '°' + value.substr(2, 2) + "'" + value.substr(4, 2) + '.' + value.substr(6, value.length - 7) + '"' + value.substr(value.length - 1, 1).toUpperCase(); 
  }

  return result;
 }

 export function leftPad(value, totalWidth, paddingChar) {
    var length = totalWidth - value.toString().length + 1;
    return Array(length).join(paddingChar || '0') + value;
};