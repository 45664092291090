import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, s38_programasgovernamentaispesquisa, s38_programasgovernamentaisbuscaid, s38_programasgovernamentaisnovo, s38_programasgovernamentaiseditar, s38_programasgovernamentaisdeletar, programasgovernamentaispesquisa, } from '../../../Services/apifunctions';
import { decriptar, ajustamaskdecimal, limpamask, decimalMask } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#535353",
      color: theme.palette.common.white,
    },
    body: {
        backgroundColor: "#f0f0f0",
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class S38_Financiamentos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s38_financiamentos : {
                cliente : '',
                id : '',
                obtevefinanciamento : '',
                financiamentoinvestimento : '',
                financiamentocusteio : '',
                financiamentocomercializacao : '',
                financiamentomanutencao : '',
                saorecursosgovernamentais : '',
                agentesbancos : '',
                agentescooperativas : '',
                agentesgovernos : '',
                agentescomerciantesmateriaprima : '',
                agentesfornecedores : '',
                agentesempresaintegradora : '',
                agentesoutrasinstituicoes : '',
                agentesongs : '',
                agentesparentesamigos : '',
                agentesoutros : ''
            },
            programasgovernamentais: [],
            s38_programasgovernamentais : [],
            s38_programagovernamental : {
                cliente : '',
                idcenso : '',
                id : '',
                idprogramagovernamental : '',
                valor : '',
                observacao : '',
                descricaoprogramagovernamental : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s38_financiamentos: {
                        ...this.state.s38_financiamentos, 
                        cliente : dados.cliente,
                        id : dados.id,
                        obtevefinanciamento : dados.obtevefinanciamento,
                        financiamentoinvestimento : dados.financiamentoinvestimento,
                        financiamentocusteio : dados.financiamentocusteio,
                        financiamentocomercializacao : dados.financiamentomanutencao,
                        financiamentomanutencao : dados.financiamentomanutencao,
                        saorecursosgovernamentais : dados.saorecursosgovernamentais,
                        agentesbancos : dados.agentesbancos,
                        agentescooperativas : dados.agentescooperativas,
                        agentesgovernos : dados.agentesgovernos,
                        agentescomerciantesmateriaprima : dados.agentescomerciantesmateriaprima,
                        agentesfornecedores : dados.agentesfornecedores,
                        agentesempresaintegradora : dados.agentesempresaintegradora,
                        agentesoutrasinstituicoes : dados.agentesoutrasinstituicoes,
                        agentesongs : dados.agentesongs,
                        agentesparentesamigos : dados.agentesparentesamigos,
                        agentesoutros : dados.agentesoutros
                    }});
                    this.setState({ novocadastro : false });
                } 
                else {
                    this.setState({s38_financiamentos: {...this.state.s38_financiamentos, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        obtevefinanciamento : '1',
                        financiamentoinvestimento : false,
                        financiamentocusteio : false,
                        financiamentocomercializacao : false,
                        financiamentomanutencao : false,
                        saorecursosgovernamentais : '1',
                        agentesbancos : false,
                        agentescooperativas : false,
                        agentesgovernos : false,
                        agentescomerciantesmateriaprima : false,
                        agentesfornecedores : false,
                        agentesempresaintegradora : false,
                        agentesoutrasinstituicoes : false,
                        agentesongs : false,
                        agentesparentesamigos : false,
                        agentesoutros : false
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }

        this.PesquisaS38_ProgramaGovernamental();
    };

    // Relacionamento Censo->ProgramasGovernamentais

    PesquisaS38_ProgramaGovernamental() {
        s38_programasgovernamentaispesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s38_programasgovernamentais: dados})
        });
    };

    MostrarDetalhesProgramaGovernamental(id) {
        if (id == null)
        {
            this.setState({s38_programagovernamental: {
                ...this.state.s38_programagovernamental, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idprogramagovernamental : '',
                valor : '',
                observacao : '',
                descricaoprogramagovernamental : ''
            }});
        }
        else
        {
            s38_programasgovernamentaisbuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s38_programagovernamental: {
                        ...this.state.s38_programagovernamental, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idprogramagovernamental : dados.idprogramagovernamental,
                        valor : dados.valor,
                        observacao : dados.observacao,
                        descricaoprogramagovernamental : dados.descricaoprogramagovernamental
                    }}, () => {
                        this.setState({
                            s38_programagovernamental: {...this.state.s38_programagovernamental, 
                                valor: ajustamaskdecimal(this.state.s38_programagovernamental.valor)
                            }
                        });
                    });        
                }
            });
        }

        window.$('#detalhesprogramagovernamental').modal("show");
    };

    onChangeProgramaGovernamental(e) {
        this.setState({s38_programagovernamental: {...this.state.s38_programagovernamental, [e.target.name]: e.target.value}});
    };
    
    PesquisaProgramaGovernamental() {
        programasgovernamentaispesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({programasgovernamentais: dados});
        });

        window.$('#programasgovernamentais').modal("show");
    };

    SelecionarProgramaGovernamental(programagovernamental, descricao) {
        this.setState({s38_programagovernamental: {
            ...this.state.s38_programagovernamental, 
            idprogramagovernamental: programagovernamental,
            descricaoprogramagovernamental: descricao
        }});
        window.$('#programasgovernamentais').modal("hide");
    };

    onConfirmarProgramaGovernamentalClick() {
        this.setState({
            s38_programagovernamental: {...this.state.s38_programagovernamental,
                valor: limpamask(true, this.state.s38_programagovernamental.valor)
            }
        }, async () => {
            if (this.state.s38_programagovernamental.id === '')
            {
                s38_programasgovernamentaisnovo(this.state.s38_programagovernamental).then(r => {
                    this.PesquisaS38_ProgramaGovernamental();
                });
            }
            else
            {
                s38_programasgovernamentaiseditar(this.state.s38_programagovernamental).then(r => {
                    this.PesquisaS38_ProgramaGovernamental();
                });
            }
        });
    };

    onDeletarProgramaGovernamentalClick(cliente, idcenso, id) {
        s38_programasgovernamentaisdeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS38_ProgramaGovernamental();
        });
    };
    
    // Fim Relacionamentos

    onChange(e) {
        this.setState({
            s38_financiamentos: {...this.state.s38_financiamentos, [e.target.name]: e.target.value }
        }, () => {
            if (this.state.s38_financiamentos.obtevefinanciamento === '1') {
                this.setState({
                    s38_financiamentos: {...this.state.s38_financiamentos, 
                        financiamentoinvestimento : false,
                        financiamentocusteio : false,
                        financiamentocomercializacao : false,
                        financiamentomanutencao : false,
                        saorecursosgovernamentais : '1',
                        agentesbancos : false,
                        agentescooperativas : false,
                        agentesgovernos : false,
                        agentescomerciantesmateriaprima : false,
                        agentesfornecedores : false,
                        agentesempresaintegradora : false,
                        agentesoutrasinstituicoes : false,
                        agentesongs : false,
                        agentesparentesamigos : false,
                        agentesoutros : false
                    }
                });
            }
        });
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s38_financiamentos: {...this.state.s38_financiamentos, [name]: checked }});
    }

    async salvar(botao) {
        if (this.state.novocadastro) {
            censonovo(this.props.match.url, this.state.s38_financiamentos);
        }
        else {
            censoeditar(this.props.match.url, this.state.s38_financiamentos);
        }
        if (botao === 'Back') {
            this.props.history.push('/S37');
        }
        else
        if (botao === 'Next') {
            this.props.history.push('/S39');
        }
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>38 - FINANCIAMENTOS, EMPRÉSTIMOS E GARANTIA DE PREÇOS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Obteve financiamento, empréstimos e garantias de preços?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="obtevefinanciamento" name="obtevefinanciamento" value={this.state.s38_financiamentos.obtevefinanciamento} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <p />
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual foi a finalidade do financiamento?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.financiamentoinvestimento} onClick={this.onCheckBoxClick.bind(this, "financiamentoinvestimento", !this.state.s38_financiamentos.financiamentoinvestimento)} value="financiamentoinvestimento"/>}
                            label="Investimento"
                        />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.financiamentocusteio} onClick={this.onCheckBoxClick.bind(this, "financiamentocusteio", !this.state.s38_financiamentos.financiamentocusteio)} value="financiamentocusteio"/>}
                            label="Custeio"
                        />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.financiamentocomercializacao} onClick={this.onCheckBoxClick.bind(this, "financiamentocomercializacao", !this.state.s38_financiamentos.financiamentocomercializacao)} value="financiamentocomercializacao"/>}
                            label="Comercialização"
                        />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.financiamentomanutencao} onClick={this.onCheckBoxClick.bind(this, "financiamentomanutencao", !this.state.s38_financiamentos.financiamentomanutencao)} value="financiamentomanutencao"/>}
                            label="Manutenção"
                        />
                    </div>
                </div>
                <p />
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Os recursos são provenientes (totalmente ou parcialmente) de programas governamentais de financiamento?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm-2 posicaorb">
                        <FormControl disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"} component="fieldset">
                            <RadioGroup aria-label="saorecursosgovernamentais" name="saorecursosgovernamentais" value={this.state.s38_financiamentos.saorecursosgovernamentais} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <span>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                </span>
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s38_financiamentos.saorecursosgovernamentais === '1' ? true : false}>
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesProgramaGovernamental.bind(this, null)}>Incluir Programa Governamental</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Programas Governamentais Utilizados</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-center">Valor Financiado</StyledTableCell>
                                            <StyledTableCell className="text-left">Observação</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s38_programasgovernamentais.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesProgramaGovernamental.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesProgramaGovernamental.bind(this, item.id)}>{item.descricaoprogramagovernamental}</button></StyledTableCell >
                                                        <StyledTableCell className="text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesProgramaGovernamental.bind(this, item.id)}>R$ {item.valor.toLocaleString('pt-BR')}</button></StyledTableCell >
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo text-left" onClick={this.MostrarDetalhesProgramaGovernamental.bind(this, item.id)}>{item.observacao}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarProgramaGovernamentalClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <p />
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-De quais agentes obteve financiamentos ou empréstimos?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm-3 posicaorb">
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesbancos} onClick={this.onCheckBoxClick.bind(this, "agentesbancos", !this.state.s38_financiamentos.agentesbancos)} value="agentesbancos"/>}
                            label="Bancos"
                        /><p />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentescooperativas} onClick={this.onCheckBoxClick.bind(this, "agentescooperativas", !this.state.s38_financiamentos.agentescooperativas)} value="agentescooperativas"/>}
                            label="Cooperativas de crédito"
                        /><p />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesgovernos} onClick={this.onCheckBoxClick.bind(this, "agentesgovernos", !this.state.s38_financiamentos.agentesgovernos)} value="agentesgovernos"/>}
                            label="Governos (Federal, Estadual ou Municipal)"
                        /><p />
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentescomerciantesmateriaprima} onClick={this.onCheckBoxClick.bind(this, "agentescomerciantesmateriaprima", !this.state.s38_financiamentos.agentescomerciantesmateriaprima)} value="agentescomerciantesmateriaprima"/>}
                            label="Comerciantes de matéria-prima"
                        /><p />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesfornecedores} onClick={this.onCheckBoxClick.bind(this, "agentesfornecedores", !this.state.s38_financiamentos.agentesfornecedores)} value="agentesfornecedores"/>}
                            label="Fornecedores (insumos ou equipamentos)"
                        /><p />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesempresaintegradora} onClick={this.onCheckBoxClick.bind(this, "agentesempresaintegradora", !this.state.s38_financiamentos.agentesempresaintegradora)} value="agentesempresaintegradora"/>}
                            label="Empresa integradora"
                        /><p />
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesoutrasinstituicoes} onClick={this.onCheckBoxClick.bind(this, "agentesoutrasinstituicoes", !this.state.s38_financiamentos.agentesoutrasinstituicoes)} value="agentesoutrasinstituicoes"/>}
                            label="Outras instituições financeiras (exceto bancos e cooperativas)"
                        /><p />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesongs} onClick={this.onCheckBoxClick.bind(this, "agentesongs", !this.state.s38_financiamentos.agentesongs)} value="agentesongs"/>}
                            label="Organização não governamental - ONG"
                        /><p />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesparentesamigos} onClick={this.onCheckBoxClick.bind(this, "agentesparentesamigos", !this.state.s38_financiamentos.agentesparentesamigos)} value="agentesparentesamigos"/>}
                            label="Parentes ou amigos"
                        /><p />
                    </div>
                    <div className="col-sm">
                        <br /><br /><br /><br /><br /><br />
                        <FormControlLabel disabled={this.state.s38_financiamentos.obtevefinanciamento === "1"}
                            control={<Checkbox color="primary" checked={this.state.s38_financiamentos.agentesoutros} onClick={this.onCheckBoxClick.bind(this, "agentesoutros", !this.state.s38_financiamentos.agentesoutros)} value="agentesoutros"/>}
                            label="Outros"
                        /><p />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />

                {/* Popups para s38_programasgovernamentais */}

                <div class="modal fade" id="detalhesprogramagovernamental" tabindex="-1" role="dialog" aria-labelledby="detalhesprogramagovernamentalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhesprogramagovernamentalLabel">Programa Governamental</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s38_programagovernamental.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Programa" id="programagovernamental" name="programagovernamental" value={this.state.s38_programagovernamental.idprogramagovernamental} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaoprogramagovernamental" name="descricaoprogramagovernamental" value={this.state.s38_programagovernamental.descricaoprogramagovernamental} style={{width: 300 + 'px'}}/>
                                        <Link onClick={this.PesquisaProgramaGovernamental.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} label="Valor Financiado" id="valor" name="valor" value={this.state.s38_programagovernamental.valor} style={{width: 150 + 'px'}} onChange={e => this.onChangeProgramaGovernamental(e)} autoFocus/><p />
                                        <TextField multiline rows="3" label="Observações" id="observacao" name="observacao" value={this.state.s38_programagovernamental.observacao} style={{width: 450 + 'px'}} onChange={e => this.onChangeProgramaGovernamental(e)}/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarProgramaGovernamentalClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="programasgovernamentais" tabindex="-1" role="dialog" aria-labelledby="programasgovernamentaisLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="programasgovernamentaisLabel">Selecione o Programa Governamental</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.programasgovernamentais.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarProgramaGovernamental.bind(this, item.id, item.descricao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarProgramaGovernamental.bind(this, item.id, item.descricao)}>{item.descricao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>    
        );
    }
}

export default S38_Financiamentos;