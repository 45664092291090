import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class S28_ProducaoVegetal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s28_producaovegetal : {
                cliente : '',
                id : '',
                lavouratemporaria : '',
                lavourapermanente : '',
                horticultura : '',
                extracaovegetal : '',
                floricultura : '',
                efetivossilvicultura : '',
                produtossilvicultura : '',
                naoexistiaproducaovegetal : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s28_producaovegetal: {
                        ...this.state.s28_producaovegetal, 
                        cliente : dados.cliente,
                        id : dados.id,
                        lavouratemporaria : dados.lavouratemporaria,
                        lavourapermanente : dados.lavourapermanente,
                        horticultura : dados.horticultura,
                        extracaovegetal : dados.extracaovegetal,
                        floricultura : dados.floricultura,
                        efetivossilvicultura : dados.efetivossilvicultura,
                        produtossilvicultura : dados.produtossilvicultura,
                        naoexistiaproducaovegetal : dados.naoexistiaproducaovegetal
                    }}, () => {
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s28_producaovegetal: {...this.state.s28_producaovegetal, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        lavouratemporaria : false,
                        lavourapermanente : false,
                        horticultura : false,
                        extracaovegetal : false,
                        floricultura : false,
                        efetivossilvicultura : false,
                        produtossilvicultura : false,
                        naoexistiaproducaovegetal : false
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s28_producaovegetal: {...this.state.s28_producaovegetal, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s28_producaovegetal: {...this.state.s28_producaovegetal, [name]: checked }});
    }

    async salvar(botao) {
        if (this.state.novocadastro) {
            censonovo(this.props.match.url, this.state.s28_producaovegetal);
        }
        else {
            censoeditar(this.props.match.url, this.state.s28_producaovegetal);
        }
        if (botao === 'Back') {
            this.props.history.push('/S27');
        }
        else
        if (botao === 'Next') {
            this.props.history.push('/S29');
        }
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>28 - ATIVIDADES DA PRODUÇÃO VEGETAL</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Marque quais atividades foram desenvolvidas no estabelecimentos e seus respectivos produtos no período de referência?</small>
                        </span>
                    </div>
                    <div className="col-sm-3 posicaorb">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.lavouratemporaria} onClick={this.onCheckBoxClick.bind(this, "lavouratemporaria", !this.state.s28_producaovegetal.lavouratemporaria)} value="lavouratemporaria"/>}
                            label="Lavoura Temporária"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.lavourapermanente} onClick={this.onCheckBoxClick.bind(this, "lavourapermanente", !this.state.s28_producaovegetal.lavourapermanente)} value="lavourapermanente"/>}
                            label="Lavoura Permanente"
                        /><br />  
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.horticultura} onClick={this.onCheckBoxClick.bind(this, "horticultura", !this.state.s28_producaovegetal.horticultura)} value="horticultura"/>}
                            label="Horticultura (somente vendida)"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.extracaovegetal} onClick={this.onCheckBoxClick.bind(this, "extracaovegetal", !this.state.s28_producaovegetal.extracaovegetal)} value="extracaovegetal"/>}
                            label="Extração Vegetal"
                        /><br />  
                    </div>
                    <div className="col-sm-3">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.floricultura} onClick={this.onCheckBoxClick.bind(this, "floricultura", !this.state.s28_producaovegetal.floricultura)} value="floricultura"/>}
                            label="Floricultura"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.efetivossilvicultura} onClick={this.onCheckBoxClick.bind(this, "efetivossilvicultura", !this.state.s28_producaovegetal.efetivossilvicultura)} value="efetivossilvicultura"/>}
                            label="Efetivos da Silvicultura"
                        /><br />  
                    </div>
                    <div className="col-sm">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.produtossilvicultura} onClick={this.onCheckBoxClick.bind(this, "produtossilvicultura", !this.state.s28_producaovegetal.produtossilvicultura)} value="produtossilvicultura"/>}
                            label="Produtos da Silvicultura"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s28_producaovegetal.naoexistiaproducaovegetal} onClick={this.onCheckBoxClick.bind(this, "naoexistiaproducaovegetal", !this.state.s28_producaovegetal.naoexistiaproducaovegetal)} value="naoexistiaproducaovegetal"/>}
                            label="Não existia produção vegetal"
                        /><br />  
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S28_ProducaoVegetal;