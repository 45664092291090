import React, { Component } from 'react';
import './Relatorios.css';
import { lavouraspesquisa, relestabelecimentosxlavoura } from '../../Services/apifunctions';
import { Link } from "react-router-dom";
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { geraPDF } from "../funcoesgerais";
import { ModCabecalho, ModRodape, ModEstabelecimentosXLavoura} from "./Modelos";
import ejs from "ejs";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class RelEstabelecimentosXLavoura extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lavouras : [],
            lavoura: '',
            descricaolavoura: '',
            formato: 'P'
        };
    }

    PesquisaLavouras() {
        lavouraspesquisa(localStorage.getItem('cliente'), "@", "").then(dados => { 
            this.setState({lavouras: dados});
        });
        window.$('#lavouras').modal("show");
    };

    SelecionarLavoura(lavoura, descricao) {
        this.setState({ lavoura: lavoura,
                        descricaolavoura : descricao });
        window.$('#lavouras').modal("hide");
    };

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    };

    onImprimirClick() {
        relestabelecimentosxlavoura(localStorage.getItem('cliente'), this.state.lavoura).then(dados => { 
            var html = ModCabecalho;
            html += ModEstabelecimentosXLavoura;
            html += ModRodape;
    
            geraPDF(ejs.render(html, { logomarca: localStorage.getItem("logomarca"), cliente: localStorage.getItem('nomecliente'), relatorio: "Relação de Estabelecimentos que produzem " + this.state.descricaolavoura, lavouras: dados, formato: this.state.formato }), this.state.formato);
        });

    };

    render() {
        return (
            <div className="geral container-fluid">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="border border-5 border-bottom-0 border-dark tituloselecao text-center">
                            <h3><strong>Relação de Estabelecimentos por Lavoura</strong></h3>
                        </div>
                        <div className="border border-3 border-dark selecao text-center">
                            <div>
                                <div><strong>Selecione a Lavoura:</strong></div>
                                <TextField style={{width: '20%'}} variant="outlined" disabled size="small" id="lavouratemporaria" name="lavouratemporaria" value={this.state.lavoura} />&nbsp;
                                <TextField style={{width: '60%'}} variant="outlined" disabled size="small" id="descricaolavouratemporaria" name="descricaolavouratemporaria" value={this.state.descricaolavoura} />
                                <Link to="#" onClick={this.PesquisaLavouras.bind(this)}><SearchIcon fontSize="large" /></Link>
                            </div>
                            <div>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="formato" name="formato" value={this.state.formato} onChange={e => this.onChange(e)}>
                                        <span>
                                            <FormControlLabel value="P" control={<Radio color="primary"/>} label="Retrato" />
                                            <FormControlLabel value="L" control={<Radio color="primary"/>} label="Paisagem" />
                                        </span>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onImprimirClick.bind(this)}>Imprimir</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                </div>

                {/* Modal para selecionar LAVOURAS */}
                <div class="modal fade" id="lavouras" tabindex="-1" role="dialog" aria-labelledby="lavourasLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione a Lavoura Temporária</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell>Tipo</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.lavouras.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarLavoura.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarLavoura.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarLavoura.bind(this, item.id, item.descricao)}>{item.tipo==='1' ? 'Temporária' : 'Permanente'}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RelEstabelecimentosXLavoura;