import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, telefonefixoMask, telefonecelularMask, emailMask, cepMask, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import * as yup from 'yup';

const checkoutSchema = yup.object().shape({
    telefonefixo : yup
        .string()
        .matches(/\d{10}/, { message: 'O telefone fixo deve ter 10 dígitos incluindo o DDD', excludeEmptyString: true }),
    telefonecelular : yup
        .string()
        .matches(/\d{11}/, { message: 'O telefone celular deve ter 11 dígitos incluindo o DDD', excludeEmptyString: true }),
    email : yup
        .string()
        .email('Digite um e-mail válido'),
    municipio : yup
        .string()
        .max(100, 'O nome do município deve ter no máximo 100 caracteres'),
    localidade : yup
        .string()
        .max(100, 'O nome da localidade deve ter no máximo 100 caracteres'),
    logradouro_tipo : yup
        .string()
        .max(20, 'O tipo do logradouro deve ter no máximo 20 caracteres'),
    logradouro_nome : yup
        .string()
        .max(100, 'O nome do logradouro deve ter no máximo 100 caracteres'),
    quadra : yup
        .string()
        .max(20, 'A quadra deve ter no máximo 20 caracteres'),
    lote : yup
        .string()
        .max(20, 'O lote deve ter no máximo 20 caracteres'),
    numero_valor : yup
        .string()
        .max(20, 'O valor do número deve ter no máximo 20 caracteres'),
    numero_modificador : yup
        .string()
        .max(20, 'O modificador do número deve ter no máximo 20 caracteres'),
    numero_complemento : yup
        .string()
        .max(20, 'O modificador do número deve ter no máximo 20 caracteres'),
    numero_referencia : yup
        .string()
        .max(200, 'A referência deve ter no máximo 200 caracteres'),
    numero_elemento1 : yup
        .string()
        .max(20, 'O elemento 1 deve ter no máximo 20 caracteres'),
    numero_valor1 : yup
        .string()
        .max(20, 'O valor 1 deve ter no máximo 20 caracteres'),
    numero_elemento2 : yup
        .string()
        .max(20, 'O elemento 2 deve ter no máximo 20 caracteres'),
    numero_valor2 : yup
        .string()
        .max(20, 'O valor 2 deve ter no máximo 20 caracteres'),
    numero_elemento3 : yup
        .string()
        .max(20, 'O elemento 3 deve ter no máximo 20 caracteres'),
    numero_valor3 : yup
        .string()
        .max(20, 'O valor 3 deve ter no máximo 20 caracteres'),
    numero_elemento4 : yup
        .string()
        .max(20, 'O elemento 4 deve ter no máximo 20 caracteres'),
    numero_valor4 : yup
        .string()
        .max(20, 'O valor 4 deve ter no máximo 20 caracteres'),
    numero_elemento5 : yup
        .string()
        .max(20, 'O elemento 5 deve ter no máximo 20 caracteres'),
    numero_valor5 : yup
        .string()
        .max(20, 'O valor 5 deve ter no máximo 20 caracteres'),
    numero_elemento6 : yup
        .string()
        .max(20, 'O elemento 6 deve ter no máximo 20 caracteres'),
    numero_valor6 : yup
        .string()
        .max(20, 'O valor 6 deve ter no máximo 20 caracteres'),
});

class S40_OutroEndereco extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s40_outroendereco : {
                cliente : '',
                id : '',
                coletaoutroendereco : '',
                uf : '',
                municipio : '',
                localidade : '',
                logradouro_tipo : '',
                logradouro_nome : '',
                quadra : '',
                lote : '',
                cep : '',
                numero_valor : '',
                numero_modificador : '',
                numero_complemento : '',
                numero_referencia : '',
                numero_elemento1 : '',
                numero_valor1 : '',
                numero_elemento2 : '',
                numero_valor2 : '',
                numero_elemento3 : '',
                numero_valor3 : '',
                numero_elemento4 : '',
                numero_valor4 : '',
                numero_elemento5 : '',
                numero_valor5 : '',
                numero_elemento6 : '',
                numero_valor6 : '',
                telefonefixo : '',
                telefonecelular : '',
                email : ''
            },
            telefonefixo_error : '',
            telefonecelular_error : '',
            email_error : '',
            municipio_error : '',
            localidade_error : '',
            logradouro_tipo_error : '',
            logradouro_nome_error : '',
            quadra_error : '',
            lote_error : '',
            numero_valor_error : '',
            numero_modificador_error : '',
            numero_complemento_error : '',
            numero_referencia_error : '',
            numero_elemento1_error : '',
            numero_valor1_error : '',
            numero_elemento2_error : '',
            numero_valor2_error : '',
            numero_elemento3_error : '',
            numero_valor3_error : '',
            numero_elemento4_error : '',
            numero_valor4_error : '',
            numero_elemento5_error : '',
            numero_valor5_error : '',
            numero_elemento6_error : '',
            numero_valor6_error : ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s40_outroendereco: {
                        ...this.state.s40_outroendereco, 
                        cliente : dados.cliente,
                        id : dados.id,
                        coletaoutroendereco : dados.coletaoutroendereco,
                        uf : dados.uf,
                        municipio : dados.municipio,
                        localidade : dados.localidade,
                        logradouro_tipo : dados.logradouro_tipo,
                        logradouro_nome : dados.logradouro_nome,
                        quadra : dados.quadra,
                        lote : dados.lote,
                        cep : dados.cep,
                        numero_valor : dados.numero_valor,
                        numero_modificador : dados.numero_modificador,
                        numero_complemento : dados.numero_complemento,
                        numero_referencia : dados.numero_referencia,
                        numero_elemento1 : dados.numero_elemento1,
                        numero_valor1 : dados.numero_valor1,
                        numero_elemento2 : dados.numero_elemento2,
                        numero_valor2 : dados.numero_valor2,
                        numero_elemento3 : dados.numero_elemento3,
                        numero_valor3 : dados.numero_valor3,
                        numero_elemento4 : dados.numero_elemento4,
                        numero_valor4 : dados.numero_valor4,
                        numero_elemento5 : dados.numero_elemento5,
                        numero_valor5 : dados.numero_valor5,
                        numero_elemento6 : dados.numero_elemento6,
                        numero_valor6 : dados.numero_valor6,
                        telefonefixo : dados.telefonefixo,
                        telefonecelular : dados.telefonecelular,
                        email : dados.email
                    }}, () => {
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s40_outroendereco: {...this.state.s40_outroendereco, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        coletaoutroendereco : '1',
                        uf : ' ',
                        municipio : '',
                        localidade : '',
                        logradouro_tipo : '',
                        logradouro_nome : '',
                        quadra : '',
                        lote : '',
                        cep : '',
                        numero_valor : '',
                        numero_modificador : '',
                        numero_complemento : '',
                        numero_referencia : '',
                        numero_elemento1 : '',
                        numero_valor1 : '',
                        numero_elemento2 : '',
                        numero_valor2 : '',
                        numero_elemento3 : '',
                        numero_valor3 : '',
                        numero_elemento4 : '',
                        numero_valor4 : '',
                        numero_elemento5 : '',
                        numero_valor5 : '',
                        numero_elemento6 : '',
                        numero_valor6 : '',
                        telefonefixo : '',
                        telefonecelular : '',
                        email : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s40_outroendereco: {...this.state.s40_outroendereco, [e.target.name]: e.target.value }
        }, () => {
            if (this.state.s40_outroendereco.coletaoutroendereco === '1') {
                this.setState({
                    s40_outroendereco: {...this.state.s40_outroendereco, 
                        uf : ' ',
                        municipio : '',
                        localidade : '',
                        logradouro_tipo : '',
                        logradouro_nome : '',
                        quadra : '',
                        lote : '',
                        cep : '',
                        numero_valor : '',
                        numero_modificador : '',
                        numero_complemento : '',
                        numero_referencia : '',
                        numero_elemento1 : '',
                        numero_valor1 : '',
                        numero_elemento2 : '',
                        numero_valor2 : '',
                        numero_elemento3 : '',
                        numero_valor3 : '',
                        numero_elemento4 : '',
                        numero_valor4 : '',
                        numero_elemento5 : '',
                        numero_valor5 : '',
                        numero_elemento6 : '',
                        numero_valor6 : '',
                        telefonefixo : '',
                        telefonecelular : '',
                        email : ''
                    }
                });
            };
        });
    }

    async salvar(botao) {
        this.setState({
            s40_outroendereco: {...this.state.s40_outroendereco,
                    cep: limpamask(false, this.state.s40_outroendereco.cep),
                    telefonefixo: limpamask(false, this.state.s40_outroendereco.telefonefixo),
                    telefonecelular: limpamask(false, this.state.s40_outroendereco.telefonecelular)
                }
            }, async () => {
                try {
                    this.setState({ 
                        telefonefixo_error : '',
                        telefonecelular_error : '',
                        email_error : '',
                        municipio_error : '',
                        localidade_error : '',
                        quadra_error : '',
                        lote_error : '',
                        logradouro_tipo_error : '',
                        logradouro_nome_error : '',
                        numero_valor_error : '',
                        numero_modificador_error : '',
                        numero_complemento_error : '',
                        numero_referencia_error : '',
                        numero_elemento1_error : '',
                        numero_valor1_error : '',
                        numero_elemento2_error : '',
                        numero_valor2_error : '',
                        numero_elemento3_error : '',
                        numero_valor3_error : '',
                        numero_elemento4_error : '',
                        numero_valor4_error : '',
                        numero_elemento5_error : '',
                        numero_valor5_error : '',
                        numero_elemento6_error : '',
                        numero_valor6_error : ''
                    })  
        
                    await checkoutSchema.validate(this.state.s40_outroendereco, { abortEarly: false });
                    
                    if (this.state.novocadastro) {
                        censonovo(this.props.match.url, this.state.s40_outroendereco);
                    }
                    else {
                        censoeditar(this.props.match.url, this.state.s40_outroendereco);
                    }
                    if (botao === 'Back') {
                        this.props.history.push('/S39');
                    }
                    else
                    if (botao === 'Next') {
                        this.props.history.push('/S41');
                    }
                }
                catch(err) {
                    if (err instanceof yup.ValidationError) {
                        for (var i = 0; i < err.inner.length; i++) {
                            this.setState({ [err.inner[i].path +  '_error'] : err.inner[i].message });
                        }
                    }
                }
            }
        );
    };


    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>40 - COLETA EM OUTRO ENDEREÇO</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Os dados do estabelecimento serão coletados em outro endereço?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="coletaoutroendereco" name="coletaoutroendereco" value={this.state.s40_outroendereco.coletaoutroendereco} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-2 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-UF</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <FormControl disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} className="seletor" fullWidth variant="outlined" size="small">
                                <Select 
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="uf"
                                    value={this.state.s40_outroendereco.uf}
                                    onChange={e => this.onChange(e)}
                                    label="Selecione a UF"
                                >
                                    <MenuItem value=" ">Selecione a UF</MenuItem>
                                    <MenuItem value="AC">Acre</MenuItem>
                                    <MenuItem value="AL">Alagoas</MenuItem>
                                    <MenuItem value="AP">Amapá</MenuItem>
                                    <MenuItem value="AM">Amazonas</MenuItem>
                                    <MenuItem value="BA">Bahia</MenuItem>
                                    <MenuItem value="CE">Ceará</MenuItem>
                                    <MenuItem value="DF">Distrito Federal</MenuItem>
                                    <MenuItem value="ES">Espírito Santo</MenuItem>
                                    <MenuItem value="GO">Goiás</MenuItem>
                                    <MenuItem value="MA">Maranhão</MenuItem>
                                    <MenuItem value="MT">Mato Grosso</MenuItem>
                                    <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                    <MenuItem value="MG">Minas Gerais</MenuItem>
                                    <MenuItem value="PA">Pará</MenuItem>
                                    <MenuItem value="PB">Paraíba</MenuItem>
                                    <MenuItem value="PR">Paraná</MenuItem>
                                    <MenuItem value="PE">Pernambuco</MenuItem>
                                    <MenuItem value="PI">Piauí</MenuItem>
                                    <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                    <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                                    <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                                    <MenuItem value="RO">Roraima</MenuItem>
                                    <MenuItem value="RR">Roraima</MenuItem>
                                    <MenuItem value="SC">Santa Catarina</MenuItem>
                                    <MenuItem value="SP">São Paulo</MenuItem>
                                    <MenuItem value="SE">Sergipe</MenuItem>
                                    <MenuItem value="TO">Tocantis</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-sm subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Município</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth id="municipio" name="municipio" value={this.state.s40_outroendereco.municipio} onChange={e => this.onChange(e)}/>
                            <h6 className="validationerror">{this.state.municipio_error}</h6><p />
                        </div>
                    </div>
                    <div className="col-sm subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Localidade</small>&nbsp;
                        </span>
                        <div className="posicaorb">
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth id="localidade" name="localidade" value={this.state.s40_outroendereco.localidade} onChange={e => this.onChange(e)}/>
                            <h6 className="validationerror">{this.state.localidade_error}</h6><p />
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <span>
                                    <small className="circulopreto">05</small>
                                    <small>-Endereço</small>&nbsp;
                                </span>
                            </div>
                            <div className="col-sm-3 posicaorb">
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel htmlFor="logradouro_tipo">Tipo Logradouro</InputLabel>
                                    <Select
                                        native
                                        disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"}
                                        value={this.state.s40_outroendereco.logradouro_tipo}
                                        onChange={e => this.onChange(e)}
                                        label="Tipo Logradouro"
                                        inputProps={{
                                            name: 'logradouro_tipo',
                                            id: 'logradouro_tipo',
                                        }}
                                        >
                                        <option aria-label="None" value="" />
                                        <option value={'ALAMEDA'}>ALAMEDA</option>
                                        <option value={'AVENIDA'}>AVENIDA</option>
                                        <option value={'CAMINHO'}>CAMINHO</option>
                                        <option value={'ESTRADA'}>ESTRADA</option>
                                        <option value={'PRAÇA'}>PRAÇA</option>
                                        <option value={'RODOVIA'}>RODOVIA</option>
                                        <option value={'RUA'}>RUA</option>
                                        <option value={'TRAVESSA'}>TRAVESSA</option>
                                        <option value={'VIA'}>VIA</option>
                                    </Select>                                                
                                </FormControl><p />
                              </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Nome" id="logradouro_nome" name="logradouro_nome" value={this.state.s40_outroendereco.logradouro_nome} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.logradouro_nome_error}</h6><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 posicaorb">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Número" id="numero_valor" name="numero_valor" value={this.state.s40_outroendereco.numero_valor} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Complemento" id="numero_complemento" name="numero_complemento" value={this.state.s40_outroendereco.numero_complemento} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Ponto de Referência" id="numero_referencia" name="numero_referencia" value={this.state.s40_outroendereco.numero_referencia} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">06</small>
                            <small>-Quadra</small>
                        </span>
                        <div className="posicaorb">
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth id="quadra" name="quadra" value={this.state.s40_outroendereco.quadra} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Lote</small>
                        </span>
                        <div className="posicaorb">
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth id="lote" name="lote" value={this.state.s40_outroendereco.lote} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">08</small>
                            <small>-CEP</small>
                        </span>
                        <div className="posicaorb">
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} InputProps={{ inputComponent: cepMask }} variant="outlined" size="small" fullWidth id="cep" name="cep" value={this.state.s40_outroendereco.cep} onChange={e => this.onChange(e)} /><p />
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
{/*                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Número</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Valor" id="numero_valor" name="numero_valor" value={this.state.s40_outroendereco.numero_valor} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador" id="numero_modificador" name="numero_modificador" value={this.state.s40_outroendereco.numero_modificador} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_modificador_error}</h6><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth multiline rows="4" label="Ponto de Referencia" id="numero_referencia" name="numero_referencia" value={this.state.s40_outroendereco.numero_referencia} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_referencia_error}</h6><p />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Elemento 1" id="numero_elemento1" name="numero_elemento1" value={this.state.s40_outroendereco.numero_elemento1} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_elemento1_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador 1" id="numero_valor1" name="numero_valor1" value={this.state.s40_outroendereco.numero_valor1} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor1_error}</h6><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Elemento 2" id="numero_elemento2" name="numero_elemento2" value={this.state.s40_outroendereco.numero_elemento2} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_elemento2_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador 2" id="numero_valor2" name="numero_valor2" value={this.state.s40_outroendereco.numero_valor2} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor2_error}</h6><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Elemento 3" id="numero_elemento3" name="numero_elemento3" value={this.state.s40_outroendereco.numero_elemento3} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_elemento3_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador 3" id="numero_valor3" name="numero_valor3" value={this.state.s40_outroendereco.numero_valor3} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor3_error}</h6><p />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Elemento 4" id="numero_elemento4" name="numero_elemento4" value={this.state.s40_outroendereco.numero_elemento4} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_elemento4_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador 4" id="numero_valor4" name="numero_valor4" value={this.state.s40_outroendereco.numero_valor4} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor4_error}</h6><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Elemento 5" id="numero_elemento5" name="numero_elemento5" value={this.state.s40_outroendereco.numero_elemento5} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_elemento5_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador 5" id="numero_valor5" name="numero_valor5" value={this.state.s40_outroendereco.numero_valor5} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor5_error}</h6><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Elemento 6" id="numero_elemento6" name="numero_elemento6" value={this.state.s40_outroendereco.numero_elemento6} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_elemento6_error}</h6><p />
                            </div>
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} variant="outlined" size="small" fullWidth label="Modificador 6" id="numero_valor6" name="numero_valor6" value={this.state.s40_outroendereco.numero_valor6} onChange={e => this.onChange(e)} />
                                <h6 className="validationerror">{this.state.numero_valor6_error}</h6><p />
                            </div>
                        </div>
                    </div>
                </div>
                <p />
                <Divider />
*/}                
                <div className="row">
                    <div className="col-sm-3">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">09</small>
                                <small>-Telefone de contato</small>
                            </span>
                        </div>
                        <div className="col-sm">
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} InputProps={{ inputComponent: telefonefixoMask }} variant="outlined" size="small" fullWidth label="Fixo" id="telefonefixo" name="telefonefixo" value={this.state.s40_outroendereco.telefonefixo} onChange={e => this.onChange(e)}/>
                            <h6 className="validationerror">{this.state.telefonefixo_error}</h6><p />
                            <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} InputProps={{ inputComponent: telefonecelularMask }} variant="outlined" size="small" fullWidth label="Celular" id="telefonecelular" name="telefonecelular" value={this.state.s40_outroendereco.telefonecelular} onChange={e => this.onChange(e)}/>
                            <h6 className="validationerror">{this.state.telefonecelular_error}</h6><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">10</small>
                                <small>-Correio eletrônico (email)</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField disabled={this.state.s40_outroendereco.coletaoutroendereco === "1"} InputProps={{ inputComponent: emailMask }} variant="outlined" size="small" fullWidth id="email" name="email" value={this.state.s40_outroendereco.email} onChange={e => this.onChange(e)}/>
                                <h6 className="validationerror">{this.state.email_error}</h6><p />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S40_OutroEndereco;