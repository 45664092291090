import React, { Component } from 'react';
import '../Consultas.css';
import { censopesquisa, censodeletar } from '../../Services/apifunctions';
import { encriptar } from '../funcoesgerais';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class ConsultaCenso extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            s01_identificacoes : [],
            s01_identificacao : {
                cliente : '',
                id : 0,
                municipio : null,
                distrito : 0,
                subdistrito : 0,
                setor : 0,
                numeroordemsetor : 0,
                logradouro : 0,
                quadra : '',
                face : '',
                numero_valor : '',
                numero_modificador : '',
                numero_referencia : '',
                numero_elemento1 : '',
                numero_valor1 : '',
                numero_elemento2 : '',
                numero_valor2 : '',
                numero_elemento3 : '',
                numero_valor3 : '',
                numero_elemento4 : '',
                numero_valor4 : '',
                numero_elemento5 : '',
                numero_valor5 : '',
                numero_elemento6 : '',
                numero_valor6 : '',
                coordenada_qualidade : '',
                coordenada_latitude : '',
                coordenada_longitude : '',
                coordenada_altitude : '',
                coordenada_tipo : '',
                estabelecimento_nome : '',
                produtor_nome : '',
                produtor_apelido : '',
                telefonefixo : '',
                telefonecelular : '',
                email : '',
                tipointernet : 0,
                area_total : 0,
                area_codigounidadesuperficie : '',
                area_equivalenciaunidadesuperficie : 0,
                semarea_mel : 0,
                semarea_extrativista : 0,
                semarea_animaisbeiraestradas : 0,
                semarea_vazantesitinerantesestradas : 0,
                semarea_arrendadasparceriasocupadas : 0,
                semarea_outrasituacao : 0
            }, 
            termo : ''
        };
    }

    componentDidMount() {
        localStorage.removeItem('cadastrocenso')
    };

    Pesquisa() {
        console.log(this.state.termo);
        censopesquisa(localStorage.getItem('cliente'), this.state.termo).then(dados => { 
            this.setState({s01_identificacoes: dados});
        });
    };

    AcessarCadastro(id) {
        localStorage.setItem('cadastrocenso', id==null ? '' : encriptar(id))
        this.props.history.push('/s01');
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    onDeletarClick(cliente, id) {
        censodeletar(cliente, id).then(r => {
            this.Pesquisa();
        });
    };
    
    render() {
        return (
            <div className="geral container-fluid">
                <h1 className="cabeccadastro">Censo Agropecuário</h1>
                <div className="row quadrobusca">
                    <div className="col-md-11">
                        <TextField variant="outlined" margin="dense" label="Informe o texto a ser pesquisado" className="camposbusca" id="Pesquisa" name="termo" value={this.state.termo} onChange={e => this.onChange(e)} autoFocus/><p />
                    </div>
                    <div className="col botaopesquisa">
                        <Link href="#" onClick={this.Pesquisa.bind(this)}><SearchIcon fontSize="large" /></Link>
                    </div>
                </div>
                <button className="btn btn-link botaonovo" type="button" onClick={this.AcessarCadastro.bind(this, null)}>Novo Cadastro</button>
                <div className="tabela">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                    <StyledTableCell className="colkey">ID</StyledTableCell>
                                    <StyledTableCell>Estabelecimento</StyledTableCell>
                                    <StyledTableCell>Produtor</StyledTableCell>
                                    <StyledTableCell>Município</StyledTableCell>
                                    <StyledTableCell className="colbuttons"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.s01_identificacoes.map((item, indice) => {
                                        return (
                                            <StyledTableRow  key={indice}>
                                                <StyledTableCell  className="colkey"><Link href="#" onClick={this.AcessarCadastro.bind(this, item.id)}>{item.id}</Link></StyledTableCell > 
                                                <StyledTableCell ><Link href="#" onClick={this.AcessarCadastro.bind(this, item.id)}>{item.estabelecimento_nome}</Link></StyledTableCell >
                                                <StyledTableCell ><Link href="#" onClick={this.AcessarCadastro.bind(this, item.id)}>{item.produtor_nome}</Link></StyledTableCell >
                                                <StyledTableCell ><Link href="#" onClick={this.AcessarCadastro.bind(this, item.id)}>{item.municipio}</Link></StyledTableCell >
                                                <StyledTableCell  className="colbuttons"><Link href="#" onClick={() => { if (window.confirm('Confirma a exclusão do item selecionado?')) this.onDeletarClick(item.cliente, item.id) } }><DeleteForeverIcon /></Link></StyledTableCell >
                                            </StyledTableRow >
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}

export default ConsultaCenso;