import React, { Component } from 'react';
import { estatisticas } from '../../Services/apifunctions';

class TotalPropriedades extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            totalpropriedades : ''
        };
    }

    componentDidMount() {
        estatisticas(localStorage.getItem('cliente'), "TotalPropriedades").then(dados => {
            const Values = Object.values(dados)
            this.setState({totalpropriedades: Values[0]});
        });
    }

    render() {
        return (
            <div>
                <h6>Total de Propriedades</h6>
                <h1>{this.state.totalpropriedades}</h1>
            </div>
        );
    }
}

export default TotalPropriedades;