import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

class S37_OutrasReceitas extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s37_outrasreceitas : {
                cliente : '',
                id : '',
                desinvestimento : '',
                turismorural : '',
                exploracaomineral : '',
                artesanato : '',
                outrasreceitas : '',
                especificaroutrasreceitas : '',
                aposentadorias : '',
                atividadesforaestabelecimento : '',
                garantiasafra : '',
                proagromais : '',
                minhacasaminhavida : '',
                servicosambientais : '',
                governos : '',
                utilizourecursosmanutencao : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s37_outrasreceitas: {
                        ...this.state.s37_outrasreceitas, 
                        cliente : dados.cliente,
                        id : dados.id,
                        desinvestimento : dados.desinvestimento,
                        turismorural : dados.turismorural,
                        exploracaomineral : dados.exploracaomineral,
                        artesanato : dados.artesanato,
                        outrasreceitas : dados.outrasreceitas,
                        especificaroutrasreceitas : dados.especificaroutrasreceitas,
                        aposentadorias : dados.aposentadorias,
                        atividadesforaestabelecimento : dados.atividadesforaestabelecimento,
                        garantiasafra : dados.garantiasafra,
                        proagromais : dados.proagromais,
                        minhacasaminhavida : dados.minhacasaminhavida,
                        servicosambientais : dados.servicosambientais,
                        governos : dados.governos,
                        utilizourecursosmanutencao : dados.utilizourecursosmanutencao
                    }}, () => {
                        this.setState({
                            s37_outrasreceitas: {...this.state.s37_outrasreceitas, 
                                desinvestimento: ajustamaskdecimal(this.state.s37_outrasreceitas.desinvestimento),
                                turismorural: ajustamaskdecimal(this.state.s37_outrasreceitas.turismorural),
                                exploracaomineral: ajustamaskdecimal(this.state.s37_outrasreceitas.exploracaomineral),
                                artesanato: ajustamaskdecimal(this.state.s37_outrasreceitas.artesanato),
                                outrasreceitas: ajustamaskdecimal(this.state.s37_outrasreceitas.outrasreceitas),
                                aposentadorias: ajustamaskdecimal(this.state.s37_outrasreceitas.aposentadorias),
                                atividadesforaestabelecimento: ajustamaskdecimal(this.state.s37_outrasreceitas.atividadesforaestabelecimento),
                                garantiasafra: ajustamaskdecimal(this.state.s37_outrasreceitas.garantiasafra),
                                proagromais: ajustamaskdecimal(this.state.s37_outrasreceitas.proagromais),
                                minhacasaminhavida: ajustamaskdecimal(this.state.s37_outrasreceitas.minhacasaminhavida),
                                servicosambientais: ajustamaskdecimal(this.state.s37_outrasreceitas.servicosambientais),
                                governos: ajustamaskdecimal(this.state.s37_outrasreceitas.governos)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s37_outrasreceitas: {...this.state.s37_outrasreceitas, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        desinvestimento : '',
                        turismorural : '',
                        exploracaomineral : '',
                        artesanato : '',
                        outrasreceitas : '',
                        especificaroutrasreceitas : '',
                        aposentadorias : '',
                        atividadesforaestabelecimento : '',
                        garantiasafra : '',
                        proagromais : '',
                        minhacasaminhavida : '',
                        servicosambientais : '',
                        governos : '',
                        utilizourecursosmanutencao : '1'
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s37_outrasreceitas: {...this.state.s37_outrasreceitas, [e.target.name]: e.target.value }});
    }

    async salvar(botao) {
        this.setState({
            s37_outrasreceitas: {...this.state.s37_outrasreceitas,
                desinvestimento: limpamask(true, this.state.s37_outrasreceitas.desinvestimento),
                turismorural: limpamask(true, this.state.s37_outrasreceitas.turismorural),
                exploracaomineral: limpamask(true, this.state.s37_outrasreceitas.exploracaomineral),
                artesanato: limpamask(true, this.state.s37_outrasreceitas.artesanato),
                outrasreceitas: limpamask(true, this.state.s37_outrasreceitas.outrasreceitas),
                aposentadorias: limpamask(true, this.state.s37_outrasreceitas.aposentadorias),
                atividadesforaestabelecimento: limpamask(true, this.state.s37_outrasreceitas.atividadesforaestabelecimento),
                garantiasafra: limpamask(true, this.state.s37_outrasreceitas.garantiasafra),
                proagromais: limpamask(true, this.state.s37_outrasreceitas.proagromais),
                minhacasaminhavida: limpamask(true, this.state.s37_outrasreceitas.minhacasaminhavida),
                servicosambientais: limpamask(true, this.state.s37_outrasreceitas.servicosambientais),
                governos: limpamask(true, this.state.s37_outrasreceitas.governos)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s37_outrasreceitas);
            }
            else {
                censoeditar(this.props.match.url, this.state.s37_outrasreceitas);
            }
            if (botao === 'Back') {
                this.props.history.push('/S36');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S38');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>37 - OUTRAS RECEITAS DO ESTABELECIMENTO E OUTRAS RENDAS DO PRODUTOR</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Outras receitas do estabelecimento?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Desinvestimento</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="desinvestimento" name="desinvestimento" value={this.state.s37_outrasreceitas.desinvestimento} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Serviço de turismo rural</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="turismorural" name="turismorural" value={this.state.s37_outrasreceitas.turismorural} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Exploração mineral</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="exploracaomineral" name="exploracaomineral" value={this.state.s37_outrasreceitas.exploracaomineral} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Atividade de artesanato, tecelagem, etc.</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="artesanato" name="artesanato" value={this.state.s37_outrasreceitas.artesanato} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Outras receitas</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="outrasreceitas" name="outrasreceitas" value={this.state.s37_outrasreceitas.outrasreceitas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 posicaorb">Especificar quais são as Outras receitas</div>
                            <div className="col-sm posicaorb"><TextField variant="outlined" fullWidth multiline rows="2" size="small" id="especificaroutrasreceitas" name="especificaroutrasreceitas" value={this.state.s37_outrasreceitas.especificaroutrasreceitas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <p />
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual destas outras rendas o Produtor recebe?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Recursos de aposentadorias ou pensões</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="aposentadorias" name="aposentadorias" value={this.state.s37_outrasreceitas.aposentadorias} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Rendas obtidas em atividades fora do estabelecimento</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="atividadesforaestabelecimento" name="atividadesforaestabelecimento" value={this.state.s37_outrasreceitas.atividadesforaestabelecimento} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Recebimento de prêmio de Programa Garantia Safra</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="garantiasafra" name="garantiasafra" value={this.state.s37_outrasreceitas.garantiasafra} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Recebimento de prêmio de Programa Garantia da Atividade Agropecuária da Agricultura Familiar - PROAGRO Mais</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="proagromais" name="proagromais" value={this.state.s37_outrasreceitas.proagromais} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Recebimento do Programa Nacional de Habitação Rural Minha Casa Minha Vida</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="minhacasaminhavida" name="minhacasaminhavida" value={this.state.s37_outrasreceitas.minhacasaminhavida} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Recebimento de pagamento por serviços ambientais (Bolsa Verde e Programas Estaduais)</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="servicosambientais" name="servicosambientais" value={this.state.s37_outrasreceitas.servicosambientais} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-6 posicaorb">Provenientes de programas dos Governos (Federal, Estadual ou Municipal)</div>
                            <div className="col-sm text-right"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="governos" name="governos" value={this.state.s37_outrasreceitas.governos} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <p />
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Utilizou recursos de aposentadoria, pensão ou atividades fora na manutenção das atividades do estalecimento?</small>&nbsp;
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="utilizourecursosmanutencao" name="utilizourecursosmanutencao" value={this.state.s37_outrasreceitas.utilizourecursosmanutencao} onChange={e => this.onChange(e)}>
                                <span>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, em parte" />
                                    <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, totalmente" />
                                </span>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <p />
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S37_OutrasReceitas;