import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { logoff } from '../../Services/apifunctions';
import Menu from '../Menu/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Link from '@material-ui/core/Link';

export const isAuthenticated = () => {
    if (localStorage.getItem('email') === null) {
        return false;
    }
    else
    {
        return true;
    }
};

export const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route { ...rest} render={props => (
        isAuthenticated() ? (
            <Layout>
                <Component { ...props } />
            </Layout>
        ) : (
            <Redirect to={{pathname: '/Login', state: { from: props.location }}} />
        )
    )} />
)

export const LoginLayout = props => (
    <div>
      {props.children}
    </div>
)

export const Sair = () => {
    logoff(localStorage.getItem('cliente'), localStorage.getItem('idusuario'));
    localStorage.clear();
}

export const MainLayout = props => (
    <div className="row">
        <div className="col-sm-12">
            <div className="cabec d-flex bd-highlight mb-3 align-items-center">
                    <img style={{width: 100 + 'px', height: 100 + 'px'}} src={require(`../../images/${localStorage.getItem('cliente')}.png`)} alt="Logomarca"/>
                    <div class="mr-auto p-2 bd-highlight">
                        <h1><strong>CENSOAGRO</strong></h1>
                        <strong>{localStorage.getItem("nomecliente")}</strong>
                    </div>
                    <div class="p-2 bd-highlight">
                        <div className="esquerda">
                            Logado como:<br />
                            {localStorage.getItem("administrador") === "true" ? (
                                <strong>{localStorage.getItem("nomeusuario")} *</strong>
                            ) : (
                                <strong>{localStorage.getItem("nomeusuario")}</strong>
                            )}
                        </div>
                        &nbsp;
                        <div className="direita">
                            <Link href="#" style={{textDecoration: "none"}} onClick={Sair}>
                                <PowerSettingsNewIcon fontSize="large"/>
                            </Link>
                        </div>
                </div>
            </div>
        </div>
        <div className="col-sm-2">
            <Menu />
        </div>
        <div className="col-sm">
            {props.children}
        </div>
    </div>
)

export const Mensagem = (id, titulo, mensagem) => {
    return (
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mensagem" id="exampleModalCenterTitle">{titulo}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p className="mensagem">{mensagem}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                </div>
                </div>
            </div>
        </div>
    )
}
