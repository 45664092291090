import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class S24_Aquicultura extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s24_aquicultura : {
                cliente : '',
                id : '',
                criacaotanques : '',
                criacaobarragem : '',
                criacaolagos : '',
                criacaolitoral : '',
                criacaotanquerede : '',
                criacaoarea : '',
                especiescarpa : '',
                especiescurimata : '',
                especiesjundia : '',
                especieslambari : '',
                especiesmatrinxa : '',
                especiespacu : '',
                especiespiau : '',
                especiespirarucu : '',
                especiespirapitinga : '',
                especiessurubim : '',
                especiestambacu : '',
                especiestambaqui : '',
                especiestambatinga : '',
                especiestruta : '',
                especiestilapia : '',
                especiesornamental : '',
                especiesoutrospeixes : '',
                especiescamarao : '',
                especiesostra : '',
                especiesmexilhao : '',
                vendidospeixekg : '',
                precomediopeixekg : '',
                vendidoscamaraokg : '',
                precomediocamaraokg : '',
                vendidosostraduzias : '',
                precomedioostraduzia : '',
                vendidosmexilhaokg : '',
                precomediomexilhaokg : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s24_aquicultura: {
                        ...this.state.s24_aquicultura, 
                        cliente : dados.cliente,
                        id : dados.id,
                        criacaotanques : dados.criacaotanques,
                        criacaobarragem : dados.criacaobarragem,
                        criacaolagos : dados.criacaolagos,
                        criacaolitoral : dados.criacaolagos,
                        criacaotanquerede : dados.criacaotanquerede,
                        criacaoarea : dados.criacaoarea,
                        especiescarpa : dados.especiescarpa,
                        especiescurimata : dados.especiescurimata,
                        especiesjundia : dados.especiesjundia,
                        especieslambari : dados.especieslambari,
                        especiesmatrinxa : dados.especiesmatrinxa,
                        especiespacu : dados.especiespacu,
                        especiespiau : dados.especiespiau,
                        especiespirarucu : dados.especiespirarucu,
                        especiespirapitinga : dados.especiespirapitinga,
                        especiessurubim : dados.especiessurubim,
                        especiestambacu : dados.especiestambacu,
                        especiestambaqui : dados.especiestambaqui,
                        especiestambatinga : dados.especiestambatinga,
                        especiestruta : dados.especiestruta,
                        especiestilapia : dados.especiestilapia,
                        especiesornamental : dados.especiesornamental,
                        especiesoutrospeixes : dados.especiesoutrospeixes,
                        especiescamarao : dados.especiescamarao,
                        especiesostra : dados.especiesostra,
                        especiesmexilhao : dados.especiesmexilhao,
                        vendidospeixekg : dados.vendidospeixekg,
                        precomediopeixekg : dados.precomediopeixekg,
                        vendidoscamaraokg : dados.vendidoscamaraokg,
                        precomediocamaraokg : dados.precomediocamaraokg,
                        vendidosostraduzias : dados.vendidosostraduzias,
                        precomedioostraduzia : dados.precomedioostraduzia,
                        vendidosmexilhaokg : dados.vendidosmexilhaokg,
                        precomediomexilhaokg : dados.precomediomexilhaokg
                    }}, () => {
                        this.setState({
                            s24_aquicultura: {...this.state.s24_aquicultura, 
                                criacaoarea: ajustamaskdecimal(this.state.s24_aquicultura.criacaoarea),
                                vendidospeixekg: ajustamaskdecimal(this.state.s24_aquicultura.vendidospeixekg),
                                precomediopeixekg: ajustamaskdecimal(this.state.s24_aquicultura.precomediopeixekg),
                                vendidoscamaraokg: ajustamaskdecimal(this.state.s24_aquicultura.vendidoscamaraokg),
                                precomediocamaraokg: ajustamaskdecimal(this.state.s24_aquicultura.precomediocamaraokg),
                                vendidosostraduzias: ajustamaskdecimal(this.state.s24_aquicultura.vendidosostraduzias),
                                precomedioostraduzia: ajustamaskdecimal(this.state.s24_aquicultura.precomedioostraduzia),
                                vendidosmexilhaokg: ajustamaskdecimal(this.state.s24_aquicultura.vendidosmexilhaokg),
                                precomediomexilhaokg: ajustamaskdecimal(this.state.s24_aquicultura.precomediomexilhaokg)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s24_aquicultura: {...this.state.s24_aquicultura, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        criacaotanques : false,
                        criacaobarragem : false,
                        criacaolagos : false,
                        criacaolitoral : false,
                        criacaotanquerede : false,
                        criacaoarea : '',
                        especiescarpa : false,
                        especiescurimata : false,
                        especiesjundia : false,
                        especieslambari : false,
                        especiesmatrinxa : false,
                        especiespacu : false,
                        especiespiau : false,
                        especiespirarucu : false,
                        especiespirapitinga : false,
                        especiessurubim : false,
                        especiestambacu : false,
                        especiestambaqui : false,
                        especiestambatinga : false,
                        especiestruta : false,
                        especiestilapia : false,
                        especiesornamental : false,
                        especiesoutrospeixes : false,
                        especiescamarao : false,
                        especiesostra : false,
                        especiesmexilhao : false,
                        vendidospeixekg : '',
                        precomediopeixekg : '',
                        vendidoscamaraokg : '',
                        precomediocamaraokg : '',
                        vendidosostraduzias : '',
                        precomedioostraduzia : '',
                        vendidosmexilhaokg : '',
                        precomediomexilhaokg : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s24_aquicultura: {...this.state.s24_aquicultura, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s24_aquicultura: {...this.state.s24_aquicultura, [name]: checked }});
    }

    async salvar(botao) {
        this.setState({
            s24_aquicultura: {...this.state.s24_aquicultura,
                criacaoarea: limpamask(true, this.state.s24_aquicultura.criacaoarea),
                vendidospeixekg: limpamask(true, this.state.s24_aquicultura.vendidospeixekg),
                precomediopeixekg: limpamask(true, this.state.s24_aquicultura.precomediopeixekg),
                vendidoscamaraokg: limpamask(true, this.state.s24_aquicultura.vendidoscamaraokg),
                precomediocamaraokg: limpamask(true, this.state.s24_aquicultura.precomediocamaraokg),
                vendidosostraduzias: limpamask(true, this.state.s24_aquicultura.vendidosostraduzias),
                precomedioostraduzia: limpamask(true, this.state.s24_aquicultura.precomedioostraduzia),
                vendidosmexilhaokg: limpamask(true, this.state.s24_aquicultura.vendidosmexilhaokg),
                precomediomexilhaokg: limpamask(true, this.state.s24_aquicultura.precomediomexilhaokg)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s24_aquicultura);
            }
            else {
                censoeditar(this.props.match.url, this.state.s24_aquicultura);
            }
            if (botao === 'Back') {
                this.props.history.push('/S23');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S25');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>24 - AQUICULTURA: PEIXES, CAMARÕES E MOLUSCOS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Qual foi o local de criação?</small>
                        </span>
                    </div>
                    <div className="col-sm-3 posicaorb">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.criacaotanques} onClick={this.onCheckBoxClick.bind(this, "criacaotanques", !this.state.s24_aquicultura.criacaotanques)} value="criacaotanques"/>}
                            label="Tanques escavados/construídos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.criacaobarragem} onClick={this.onCheckBoxClick.bind(this, "criacaobarragem", !this.state.s24_aquicultura.criacaobarragem)} value="criacaobarragem"/>}
                            label="Barragem"
                        /><br />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.criacaolagos} onClick={this.onCheckBoxClick.bind(this, "criacaolagos", !this.state.s24_aquicultura.criacaolagos)} value="criacaolagos"/>}
                            label="Lagos"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.criacaolitoral} onClick={this.onCheckBoxClick.bind(this, "criacaolitoral", !this.state.s24_aquicultura.criacaolitoral)} value="criacaolitoral"/>}
                            label="Litoral"
                        /><br />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.criacaotanquerede} onClick={this.onCheckBoxClick.bind(this, "criacaotanquerede", !this.state.s24_aquicultura.criacaotanquerede)} value="criacaotanquerede"/>}
                            label="Tanque-rede"
                        /><br />  
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-7">Qual a área útil da lâmina d´água utilizada na criação? (informar em metros quadrados - m²)</div>
                            <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="criacaoarea" name="criacaoarea" value={this.state.s24_aquicultura.criacaoarea} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Quais foram as espécies criadas no estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm-2 posicaorb">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiescarpa} onClick={this.onCheckBoxClick.bind(this, "especiescarpa", !this.state.s24_aquicultura.especiescarpa)} value="especiescarpa"/>}
                            label="Carpa"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiescurimata} onClick={this.onCheckBoxClick.bind(this, "especiescurimata", !this.state.s24_aquicultura.especiescurimata)} value="especiescurimata"/>}
                            label="Curimatã"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiesjundia} onClick={this.onCheckBoxClick.bind(this, "especiesjundia", !this.state.s24_aquicultura.especiesjundia)} value="especiesjundia"/>}
                            label="Jundiá"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especieslambari} onClick={this.onCheckBoxClick.bind(this, "especieslambari", !this.state.s24_aquicultura.especieslambari)} value="especieslambari"/>}
                            label="Lambari"
                        /><br />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiesmatrinxa} onClick={this.onCheckBoxClick.bind(this, "especiesmatrinxa", !this.state.s24_aquicultura.especiesmatrinxa)} value="especiesmatrinxa"/>}
                            label="Matrinxã"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiespacu} onClick={this.onCheckBoxClick.bind(this, "especiespacu", !this.state.s24_aquicultura.especiespacu)} value="especiespacu"/>}
                            label="Pacu"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiespiau} onClick={this.onCheckBoxClick.bind(this, "especiespiau", !this.state.s24_aquicultura.especiespiau)} value="especiespiau"/>}
                            label="Piau"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiespirarucu} onClick={this.onCheckBoxClick.bind(this, "especiespirarucu", !this.state.s24_aquicultura.especiespirarucu)} value="especiespirarucu"/>}
                            label="Pirarucu"
                        /><br />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiespirapitinga} onClick={this.onCheckBoxClick.bind(this, "especiespirapitinga", !this.state.s24_aquicultura.especiespirapitinga)} value="especiespirapitinga"/>}
                            label="Pirapitinga"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiessurubim} onClick={this.onCheckBoxClick.bind(this, "especiessurubim", !this.state.s24_aquicultura.especiessurubim)} value="especiessurubim"/>}
                            label="Surubim"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiestambacu} onClick={this.onCheckBoxClick.bind(this, "especiestambacu", !this.state.s24_aquicultura.especiestambacu)} value="especiestambacu"/>}
                            label="Tambacu"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiestambaqui} onClick={this.onCheckBoxClick.bind(this, "especiestambaqui", !this.state.s24_aquicultura.especiestambaqui)} value="especiestambaqui"/>}
                            label="Tambaqui"
                        /><br />  
                    </div>
                    <div className="col-sm-2">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiestambatinga} onClick={this.onCheckBoxClick.bind(this, "especiestambatinga", !this.state.s24_aquicultura.especiestambatinga)} value="especiestambatinga"/>}
                            label="Tambatinga"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiestruta} onClick={this.onCheckBoxClick.bind(this, "especiestruta", !this.state.s24_aquicultura.especiestruta)} value="especiestruta"/>}
                            label="Truta"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiestilapia} onClick={this.onCheckBoxClick.bind(this, "especiestilapia", !this.state.s24_aquicultura.especiestilapia)} value="especiestilapia"/>}
                            label="Tilápia"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiesornamental} onClick={this.onCheckBoxClick.bind(this, "especiesornamental", !this.state.s24_aquicultura.especiesornamental)} value="especiesornamental"/>}
                            label="Peixe Ornamental"
                        /><br />  
                    </div>
                    <div className="col-sm">
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiesoutrospeixes} onClick={this.onCheckBoxClick.bind(this, "especiesoutrospeixes", !this.state.s24_aquicultura.especiesoutrospeixes)} value="especiesoutrospeixes"/>}
                            label="Outras espécies de peixes"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiescamarao} onClick={this.onCheckBoxClick.bind(this, "especiescamarao", !this.state.s24_aquicultura.especiescamarao)} value="especiescamarao"/>}
                            label="Camarão"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiesostra} onClick={this.onCheckBoxClick.bind(this, "especiesostra", !this.state.s24_aquicultura.especiesostra)} value="especiesostra"/>}
                            label="Ostra/Vieira"
                        /><br />  
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={this.state.s24_aquicultura.especiesmexilhao} onClick={this.onCheckBoxClick.bind(this, "especiesmexilhao", !this.state.s24_aquicultura.especiesmexilhao)} value="especiesmexilhao"/>}
                            label="Mexilhão"
                        /><br />  
                    </div>
                </div>
                <div className="row bordasuperior">
                    <div className="col-sm-6 bordadireita">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <p />
                                <span>
                                    <small className="circulopreto">03</small>
                                    <small>-Quantidade vendida de peixes</small>
                                </span>
                            </div>
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-sm posicaorb">Quantidade vendida</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="vendidospeixekg" name="vendidospeixekg" value={this.state.s24_aquicultura.vendidospeixekg} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm posicaorb">Preço médio do quilo vendido</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediopeixekg" name="precomediopeixekg" value={this.state.s24_aquicultura.precomediopeixekg} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <p />
                                <span>
                                    <small className="circulopreto">04</small>
                                    <small>-Quantidade vendida de camarão</small>
                                </span>
                            </div>
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-sm posicaorb">Quantidade vendida</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="vendidoscamaraokg" name="vendidoscamaraokg" value={this.state.s24_aquicultura.vendidoscamaraokg} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm posicaorb">Preço médio do quilo vendido</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediocamaraokg" name="precomediocamaraokg" value={this.state.s24_aquicultura.precomediocamaraokg} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bordasuperior bordainferior">
                    <div className="col-sm-6 bordadireita">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <p />
                                <span>
                                    <small className="circulopreto">05</small>
                                    <small>-Quantidade vendida de ostras/vieiras</small>
                                </span>
                            </div>
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-sm posicaorb">Quantidade vendida</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Dúzias</InputAdornment> }} variant="outlined" size="small" id="vendidosostraduzias" name="vendidosostraduzias" value={this.state.s24_aquicultura.vendidosostraduzias} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm posicaorb">Preço médio do quilo vendido</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomedioostraduzia" name="precomedioostraduzia" value={this.state.s24_aquicultura.precomedioostraduzia} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-12 subtitulo">
                                <p />
                                <span>
                                    <small className="circulopreto">06</small>
                                    <small>-Quantidade vendida de mexilhões</small>
                                </span>
                            </div>
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-sm posicaorb">Quantidade vendida</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="vendidosmexilhaokg" name="vendidosmexilhaokg" value={this.state.s24_aquicultura.vendidosmexilhaokg} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm posicaorb">Preço médio do quilo vendido</div>
                                    <div className="col-sm"><TextField InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediomexilhaokg" name="precomediomexilhaokg" value={this.state.s24_aquicultura.precomediomexilhaokg} onChange={e => this.onChange(e)}/><p /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S24_Aquicultura;