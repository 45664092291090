import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, limpamask, decimalMask, ajustamaskdecimal } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class S04_DistribuicaoUtilizacao extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s04_distribuicaoutilizacao : {
                cliente : '',
                id : '',
                areatotal : '',
                areapermanentes : '',
                areatemporarias : '',
                areaflores : '',
                areapastagensnaturais : '',
                areapastagensplantadasboas : '',
                areapastagensplantadasmas : '',
                areapreservacao : '',
                areaextrativismo : '',
                areaflorestasplantadas : '',
                areamatas : '',
                areaagua : '',
                forma_compraparticular : '',
                forma_compracreditofundiario : '',
                forma_herancadoacao : '',
                forma_possenaotitulada : '',
                forma_usucapiao : '',
                forma_titulacaolicenca : '',
                forma_titulacaoregulamentacao : '',
                forma_concessaodireitorealuso : '',
                forma_titulacaoquilombola : '',
                forma_concessaoterrasindigenas : '',
                forma_naosabe : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s04_distribuicaoutilizacao: {
                        ...this.state.s04_distribuicaoutilizacao, 
                        cliente : dados.cliente,
                        id : dados.id,
                        areatotal : localStorage.getItem('areatotal'),
                        areapermanentes : dados.areapermanentes,
                        areatemporarias : dados.areatemporarias,
                        areaflores : dados.areaflores,
                        areapastagensnaturais : dados.areapastagensnaturais,
                        areapastagensplantadasboas : dados.areapastagensplantadasboas,
                        areapastagensplantadasmas : dados.areapastagensplantadasmas,
                        areapreservacao : dados.areapreservacao,
                        areaextrativismo : dados.areaextrativismo,
                        areaflorestasplantadas : dados.areaflorestasplantadas,
                        areamatas : dados.areamatas,
                        areaagua : dados.areaagua,
                        forma_compraparticular : dados.forma_compraparticular,
                        forma_compracreditofundiario : dados.forma_compracreditofundiario,
                        forma_herancadoacao : dados.forma_herancadoacao,
                        forma_possenaotitulada : dados.forma_possenaotitulada,
                        forma_usucapiao : dados.forma_usucapiao,
                        forma_titulacaolicenca : dados.forma_titulacaolicenca,
                        forma_titulacaoregulamentacao : dados.forma_titulacaoregulamentacao,
                        forma_concessaodireitorealuso : dados.forma_concessaodireitorealuso,
                        forma_titulacaoquilombola : dados.forma_titulacaoquilombola,
                        forma_concessaoterrasindigenas : dados.forma_concessaoterrasindigenas,
                        forma_naosabe : dados.forma_naosabe
                    }}, () => {
                        this.setState({
                            s04_distribuicaoutilizacao: {...this.state.s04_distribuicaoutilizacao, 
                                                areatotal: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areatotal),
                                                areapermanentes: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areapermanentes),
                                                areatemporarias: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areatemporarias),
                                                areaflores: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areaflores),
                                                areapastagensnaturais: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areapastagensnaturais),
                                                areapastagensplantadasboas: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areapastagensplantadasboas),
                                                areapastagensplantadasmas: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areapastagensplantadasmas),
                                                areapreservacao: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areapreservacao),
                                                areaextrativismo: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areaextrativismo),
                                                areaflorestasplantadas: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areaflorestasplantadas),
                                                areamatas: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areamatas),
                                                areaagua: ajustamaskdecimal(this.state.s04_distribuicaoutilizacao.areaagua)
                                    }
                            });
                            this.setState({ novocadastro : false });
                            this.calculadiferenca();
                        });
                }
                else {
                    this.setState({s04_distribuicaoutilizacao: {
                        ...this.state.s04_distribuicaoutilizacao, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        areatotal : localStorage.getItem('areatotal'),
                        areapermanentes : '',
                        areatemporarias : '',
                        areaflores : '',
                        areapastagensnaturais : '',
                        areapastagensplantadasboas : '',
                        areapastagensplantadasmas : '',
                        areapreservacao : '',
                        areaextrativismo : '',
                        areaflorestasplantadas : '',
                        areamatas : '',
                        areaagua : '',
                        forma_compraparticular : false,
                        forma_compracreditofundiario : false,
                        forma_herancadoacao : false,
                        forma_possenaotitulada : false,
                        forma_usucapiao : false,
                        forma_titulacaolicenca : false,
                        forma_titulacaoregulamentacao : false,
                        forma_concessaodireitorealuso : false,
                        forma_titulacaoquilombola : false,
                        forma_concessaoterrasindigenas : false,
                        forma_naosabe : false
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s04_distribuicaoutilizacao: {...this.state.s04_distribuicaoutilizacao, [e.target.name]: e.target.value }
        }, () => {
            this.calculadiferenca();
        });
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s04_distribuicaoutilizacao: {...this.state.s04_distribuicaoutilizacao, [name]: checked }});
    }

    async salvar(botao) {
        if (this.state.diferenca === 0) {
            this.setState({
                s04_distribuicaoutilizacao: {...this.state.s04_distribuicaoutilizacao,
                        areatotal: limpamask(true, this.state.s04_distribuicaoutilizacao.areatotal),
                        areapermanentes: limpamask(true, this.state.s04_distribuicaoutilizacao.areapermanentes),
                        areatemporarias: limpamask(true, this.state.s04_distribuicaoutilizacao.areatemporarias),
                        areaflores: limpamask(true, this.state.s04_distribuicaoutilizacao.areaflores),
                        areapastagensnaturais: limpamask(true, this.state.s04_distribuicaoutilizacao.areapastagensnaturais),
                        areapastagensplantadasboas: limpamask(true, this.state.s04_distribuicaoutilizacao.areapastagensplantadasboas),
                        areapastagensplantadasmas: limpamask(true, this.state.s04_distribuicaoutilizacao.areapastagensplantadasmas),
                        areapreservacao: limpamask(true, this.state.s04_distribuicaoutilizacao.areapreservacao),
                        areaextrativismo: limpamask(true, this.state.s04_distribuicaoutilizacao.areaextrativismo),
                        areaflorestasplantadas: limpamask(true, this.state.s04_distribuicaoutilizacao.areaflorestasplantadas),
                        areamatas: limpamask(true, this.state.s04_distribuicaoutilizacao.areamatas),
                        areaagua: limpamask(true, this.state.s04_distribuicaoutilizacao.areaagua)
                    }
                }, async () => {
                    if (this.state.novocadastro) {
                        censonovo(this.props.match.url, this.state.s04_distribuicaoutilizacao);
                    }
                    else {
                        censoeditar(this.props.match.url, this.state.s04_distribuicaoutilizacao);
                    }
                    if (botao === 'Back') {
                        this.props.history.push('/S03');
                    }
                    else
                    if (botao === 'Next') {
                        this.props.history.push('/S05');
                    }
                }
            );
        } else {
            alert('Valores informados não somam o total da área informada no quesito 01. Campo DIFERENÇA deve ser zerado.')
        }
    };

    calculadiferenca() {
        this.setState({
            diferenca : parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areatotal === "" ? 0 : this.state.s04_distribuicaoutilizacao.areatotal)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areapermanentes === "" ? 0 : this.state.s04_distribuicaoutilizacao.areapermanentes)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areatemporarias === "" ? 0 : this.state.s04_distribuicaoutilizacao.areatemporarias)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areaflores === "" ? 0 : this.state.s04_distribuicaoutilizacao.areaflores)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areapastagensnaturais === "" ? 0 : this.state.s04_distribuicaoutilizacao.areapastagensnaturais)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areapastagensplantadasboas === "" ? 0 : this.state.s04_distribuicaoutilizacao.areapastagensplantadasboas)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areapastagensplantadasmas === "" ? 0 : this.state.s04_distribuicaoutilizacao.areapastagensplantadasmas)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areapreservacao === "" ? 0 : this.state.s04_distribuicaoutilizacao.areapreservacao)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areaextrativismo === "" ? 0 : this.state.s04_distribuicaoutilizacao.areaextrativismo)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areaflorestasplantadas === "" ? 0 : this.state.s04_distribuicaoutilizacao.areaflorestasplantadas)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areamatas === "" ? 0 : this.state.s04_distribuicaoutilizacao.areamatas)) -
                        parseInt(limpamask(true, this.state.s04_distribuicaoutilizacao.areaagua === "" ? 0 : this.state.s04_distribuicaoutilizacao.areaagua))
        });
    }

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>04 - DISTRIBUIÇÃO DA ÁREA DO ESTABELECIMENTO, SEGUNDO A UTILIZAÇÃO DAS TERRAS NA DATA DE REFERÊNCIA</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-T O T A L ( importar a área informada no QUADRO 01 QUESITO 18 com respectiva unidade de medida de superfície)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <TextField InputProps={{ inputComponent: decimalMask }} className="teste" variant="outlined" size="small" id="areatotal" name="areatotal" value={this.state.s04_distribuicaoutilizacao.areatotal} onChange={e => this.onChange(e)}/>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Área ocupada com lavouras permanentes</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areapermanentes" name="areapermanentes" value={this.state.s04_distribuicaoutilizacao.areapermanentes} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Área ocupada com lavouras temporárias ( inclusive horticultura e área em descanso )</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areatemporarias" name="areatemporarias" value={this.state.s04_distribuicaoutilizacao.areatemporarias} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Área ocupada com cultivo de flores (inclusive Hidroponia e Plasticultura), viveiros de mudas, estufa de plantas e casas de vegetação</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaflores" name="areaflores" value={this.state.s04_distribuicaoutilizacao.areaflores} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">05</small>
                            <small>-Área ocupada com pastagens naturais (Campos Naturais, Faxinal, etc.)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areapastagensnaturais" name="areapastagensnaturais" value={this.state.s04_distribuicaoutilizacao.areapastagensnaturais} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">06</small>
                            <small>-Área ocupada com pastagens plantadas em boas condições de uso</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areapastagensplantadasboas" name="areapastagensplantadasboas" value={this.state.s04_distribuicaoutilizacao.areapastagensplantadasboas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Área ocupada com pastagens plantadas em más condições (degradadas ou sem uso)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areapastagensplantadasmas" name="areapastagensplantadasmas" value={this.state.s04_distribuicaoutilizacao.areapastagensplantadasmas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">08</small>
                            <small>-Área ocupada com matas ou florestas naturais destinadas à preservação permanente ou reserva legal</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areapreservacao" name="areapreservacao" value={this.state.s04_distribuicaoutilizacao.areapreservacao} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">09</small>
                            <small>-Área ocupada com matas ou florestas naturais (extrativismo ou manejo florestal sustentável) (não incluir a área de preservação permanente e aquelas em sistemas agroflorestais)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaextrativismo" name="areaextrativismo" value={this.state.s04_distribuicaoutilizacao.areaextrativismo} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">10</small>
                            <small>-Área de florestas plantadas</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaflorestasplantadas" name="areaflorestasplantadas" value={this.state.s04_distribuicaoutilizacao.areaflorestasplantadas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">11</small>
                            <small>-Área com matas (nativas ou plantadas) que também são utilizadas para lavouras ou pastejo de animais (Integração lavoura-floresta-pecuária)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areamatas" name="areamatas" value={this.state.s04_distribuicaoutilizacao.areamatas} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">12</small>
                            <small>-Área de lâmina d’água, açudes, lagos, tanques/viveiros, reservatório/represa ou áreas de águas públicas e área ocupada com outros usos da terra (construções, benfeitorias ou caminhos, terras degradadas, terras inaproveitáveis para agricultura ou pecuária)</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" id="areaagua" name="areaagua" value={this.state.s04_distribuicaoutilizacao.areaagua} onChange={e => this.onChange(e)}/></span>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-8 subtitulo text-right">
                        <strong className="textodiferenca">DIFERENÇA</strong>
                    </div>
                    <div className="col-sm margemdivider" >
                        <span><TextField InputProps={{ inputComponent: decimalMask }} disabled="true" variant="outlined" size="small" id="diferenca" name="diferenca" value={this.state.diferenca}/></span>
                    </div>
                </div>
                <Divider />
                <div className="row">
                    <div className="col-sm-8 subtitulo">
                        <span>
                            <small className="circulopreto">13</small>
                            <small>-De que forma o (a) Produtor(a) obteve suas terras/ambiente de produção (admite múltipla marcação)</small>
                        </span>
                    </div>
                    <div className="col-sm-6">
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_compraparticular} onClick={this.onCheckBoxClick.bind(this, "forma_compraparticular", !this.state.s04_distribuicaoutilizacao.forma_compraparticular)} value="forma_compraparticular"/>
                                }
                                label="Compra de particular"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_compracreditofundiario} onClick={this.onCheckBoxClick.bind(this, "forma_compracreditofundiario", !this.state.s04_distribuicaoutilizacao.forma_compracreditofundiario)} value="forma_compracreditofundiario"/>
                                }
                                label="Compra via crédito fundiário (cédula da terra, banco da terra, etc.)"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_herancadoacao} onClick={this.onCheckBoxClick.bind(this, "forma_herancadoacao", !this.state.s04_distribuicaoutilizacao.forma_herancadoacao)} value="forma_herancadoacao"/>
                                }
                                label="Herança ou doação"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_possenaotitulada} onClick={this.onCheckBoxClick.bind(this, "forma_possenaotitulada", !this.state.s04_distribuicaoutilizacao.forma_possenaotitulada)} value="forma_possenaotitulada"/>
                                }
                                label="Posse não titulada"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_usucapiao} onClick={this.onCheckBoxClick.bind(this, "forma_usucapiao", !this.state.s04_distribuicaoutilizacao.forma_usucapiao)} value="forma_usucapiao"/>
                                }
                                label="Usucapião (posse da terra obtida após seu uso pacífico depois de algum tempo)"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_titulacaolicenca} onClick={this.onCheckBoxClick.bind(this, "forma_titulacaolicenca", !this.state.s04_distribuicaoutilizacao.forma_titulacaolicenca)} value="forma_titulacaolicenca"/>
                                }
                                label="Titulação ou licença de ocupação por reforma agrária"
                            />                      
                        </div>                  
                    </div>
                    <div className="col-sm">
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_titulacaoregulamentacao} onClick={this.onCheckBoxClick.bind(this, "forma_titulacaoregulamentacao", !this.state.s04_distribuicaoutilizacao.forma_titulacaoregulamentacao)} value="forma_titulacaoregulamentacao"/>
                                }
                                label="Titulação por regulamentação na amazônia legal (Programa Terra Legal)"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_concessaodireitorealuso} onClick={this.onCheckBoxClick.bind(this, "forma_concessaodireitorealuso", !this.state.s04_distribuicaoutilizacao.forma_concessaodireitorealuso)} value="forma_concessaodireitorealuso"/>
                                }
                                label="Concessão de direito real de uso (licença de ocupação)"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_titulacaoquilombola} onClick={this.onCheckBoxClick.bind(this, "forma_titulacaoquilombola", !this.state.s04_distribuicaoutilizacao.forma_titulacaoquilombola)} value="forma_titulacaoquilombola"/>
                                }
                                label="Titulação de comunidade quilombola (inclusive aquelas em fase de regularização)"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_concessaoterrasindigenas} onClick={this.onCheckBoxClick.bind(this, "forma_concessaoterrasindigenas", !this.state.s04_distribuicaoutilizacao.forma_concessaoterrasindigenas)} value="forma_concessaoterrasindigenas"/>
                                }
                                label="Concessão de terras indígenas"
                            />                      
                        </div>                  
                        <div className="col-sm posicaorb">
                            <FormControlLabel
                                control={
                                <   Checkbox color="primary" checked={this.state.s04_distribuicaoutilizacao.forma_naosabe} onClick={this.onCheckBoxClick.bind(this, "forma_naosabe", !this.state.s04_distribuicaoutilizacao.forma_naosabe)} value="forma_naosabe"/>
                                }
                                label="Não sabe como obteve as terras"
                            />                      
                        </div>                  
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S04_DistribuicaoUtilizacao;