import React, { Component } from 'react';
import '../Consultas.css';
import {implementospesquisa, implementosbuscaid, implementosnovo, implementoseditar, implementosdeletar } from '../../Services/apifunctions';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class ConsultaImplementos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            implementos : [],
            implemento : {
                    cliente: '',
                    id : '',
                    descricao : '', 
            }, 
            termo : '',
        };
    }

    Pesquisa() {
        implementospesquisa(localStorage.getItem('cliente'), this.state.termo).then(dados => { 
            this.setState({implementos: dados});
        });
    };

    MostrarDetalhes(id) {
        if (id == null)
        {
            this.setState({implemento: {
                ...this.state.implemento, 
                cliente: localStorage.getItem('cliente'), 
                id: '', 
                descricao: '',
            }});
        }
        else
        {
            implementosbuscaid(localStorage.getItem('cliente'), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({implemento: {
                        ...this.state.implemento, 
                        cliente: dados.cliente, 
                        id: dados.id, 
                        descricao: dados.descricao,
                    }});
                }
            });
        }

        window.$('#detalhes').modal("show");
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    onChangeimplemento(e) {
        this.setState({implemento: {...this.state.implemento, [e.target.name]: e.target.value}});
    };
    
    onDeletarClick(cliente, id) {
        implementosdeletar(cliente, id).then(r => {
            this.Pesquisa();
        });
    };
    
    onConfirmarClick() {
        if (this.state.implemento.id === '')
        {
            implementosnovo(this.state.implemento).then(r => {
                this.Pesquisa();
            });
        }
        else
        {
            implementoseditar(this.state.implemento).then(r => {
                this.Pesquisa();
            });
        }
    };
    
    render() {
        return (
            <div className="geral container-fluid">
                <h1 className="cabeccadastro">Implementos Agrícolas</h1>
                <div className="row quadrobusca">
                    <div className="col-md-11">
                        <TextField variant="outlined" margin="dense" label="Informe o texto a ser pesquisado" className="camposbusca" id="Pesquisa" name="termo" value={this.state.termo} onChange={e => this.onChange(e)} autoFocus/><p />
                    </div>
                    <div className="col botaopesquisa">
                        <Link href="#" onClick={this.Pesquisa.bind(this)}><SearchIcon fontSize="large" /></Link>
                    </div>
                </div>
                <button className="btn btn-link botaonovo" type="button" onClick={this.MostrarDetalhes.bind(this, null)}>Novo Cadastro</button>
                <div className="tabela">
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                    <StyledTableCell className="colkey">ID</StyledTableCell>
                                    <StyledTableCell>Descrição</StyledTableCell>
                                    <StyledTableCell className="colbuttons"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.implementos.map((item, indice) => {
                                        return (
                                            <StyledTableRow  key={indice}>
                                                <StyledTableCell  className="colkey"><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.id)}>{item.id}</Link></StyledTableCell > 
                                                <StyledTableCell ><Link href="#" onClick={this.MostrarDetalhes.bind(this, item.id)}>{item.descricao}</Link></StyledTableCell >
                                                <StyledTableCell  className="colbuttons"><Link href="#" onClick={() => { if (window.confirm('Confirma a exclusão do item selecionado?')) this.onDeletarClick(item.cliente, item.id) } }><DeleteForeverIcon /></Link></StyledTableCell >
                                            </StyledTableRow >
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div class="modal fade" id="detalhes" tabindex="-1" role="dialog" aria-labelledby="detalhesLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="detalhesLabel">Detalhes</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField label="ID" disabled id="id" name="id" value={this.state.implemento.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Descrição" id="descricao" name="descricao" value={this.state.implemento.descricao} style={{width: 450 + 'px'}} onChange={e => this.onChangeimplemento(e)}/><p />
                                    </div>
                                </form>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}

export default ConsultaImplementos;