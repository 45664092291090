import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo01, censoeditar, recenseadorespesquisa, distritospesquisa, subdistritospesquisa, setorespesquisa, logradourospesquisa, unidadessuperficiepesquisa, unidadessuperficiebuscaid } from '../../../Services/apifunctions';
import { encriptar, decriptar, telefonefixoMask, telefonecelularMask, decimalMask, decimalMask6, cepMask, emailMask, cpfMask, limpamask, ajustamaskdecimal, ddToDms, dmsToDd, formatDMS } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';import * as yup from 'yup';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#e0e0e0",
      color: theme.palette.common.black,
    },
    body: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

const checkoutSchema = yup.object().shape({
    telefonefixo : yup
        .string()
        .matches(/\d{10}/, { message: 'O telefone fixo deve ter 10 dígitos incluindo o DDD', excludeEmptyString: true }),
    telefonecelular : yup
        .string()
        .matches(/\d{11}/, { message: 'O telefone celular deve ter 11 dígitos incluindo o DDD', excludeEmptyString: true }),
    email : yup
        .string()
        .email('Digite um e-mail válido'),
    produtor_cpf : yup
        .string()
        .matches(/\d{11}/, { message: 'O CPF deve ter 11 dígitos', excludeEmptyString: true }),
    });

class S01_Identificacao extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            s01_identificacao : {
                cliente : '',
                id : '',
                recenseador: '',
                datahoracenso: '',
                matricula : '',
                municipio : '',
                distrito : '',
                subdistrito : '',
                setor : '',
                numeroordemsetor : '',
                logradouro : '',
                quadra : '',
                lote : '',
                cep : '',
                numero_valor : '',
                numero_modificador : '',
                numero_complemento : '',
                numero_referencia : '',
                numero_elemento1 : '',
                numero_valor1 : '',
                numero_elemento2 : '',
                numero_valor2 : '',
                numero_elemento3 : '',
                numero_valor3 : '',
                numero_elemento4 : '',
                numero_valor4 : '',
                numero_elemento5 : '',
                numero_valor5 : '',
                numero_elemento6 : '',
                numero_valor6 : '',
                coordenada_qualidade : '',
                coordenada_latitude : '',
                coordenada_longitude : '',
                coordenada_altitude : '',
                coordenada_tipo : '',
                estabelecimento_nome : '',
                produtor_nome : '',
                produtor_apelido : '',
                produtor_cpf : '',
                possuitelefonecontato : '',
                telefonefixo : '',
                telefonecelular : '',
                possuiemail : '',
                email : '',
                acessainternet : '',
                tipointernet : '',
                area_total : '',
                area_codigounidadesuperficie : '',
                area_equivalenciaunidadesuperficie : '',
                area_equivalenciahectaresunidadesuperficie : '',
                produtorsemarea : '',
                semarea_mel : '',
                semarea_extrativista : '',
                semarea_animaisbeiraestradas : '',
                semarea_vazantesitinerantesestradas : '',
                semarea_arrendadasparceriasocupadas : '',
                semarea_outrasituacao : '',
                nomerecenseador: '',
                descricaodistrito: '',
                descricaosubdistrito: '',
                descricaosetor: '',
                tipologradouro: '',
                nomelogradouro: '',
                nomegleba: '',
                descricaounidadesuperficie: '',
            },
            coordenada_latitudedms : '',
            coordenada_longitudedms : '',
            telefonefixo_error : '',
            telefonecelular_error : '',
            email_error : '',
            produtor_cpf_error : '',
            area_total : '',
            area_equivalenciaunidadesuperficie : '',
            recenseadores : [],
            distritos : [],
            subdistritos : [],
            setores : [],
            logradouros : [],
            unidadessuperficie: []
        }
    }
/*
    componentWillMount () {
        const script = document.createElement("script");
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyATF0AtmexxJanOu-Yr78CkKOI5Uhr0m6w';
        script.defer = true;
  
        document.body.appendChild(script);

        const script1 = document.createElement("script");
        const scriptText = document.createTextNode("function initMap() {" +
                                                        "var uluru = {lat: -25.344, lng: 131.036};" +
                                                        "var uluru = {lat: " + this.state.s01_identificacao.coordenada_latitude + ", lng: " + this.state.s01_identificacao.coordenada_longitude + "};" +
                                                        "var map = new google.maps.Map(document.getElementById('map'), {zoom: 4, center: uluru});" +
                                                        "var marker = new google.maps.Marker({position: uluru, map: map});" +
                                                        "var elevator = new google.maps.ElevationService;" +
                                                        "elevator.getElevationForLocations({" +
                                                        "    'locations': [uluru]" +
                                                        "  }, function(results, status) {" +
                                                        "    if (status === 'OK') {" +
                                                        "      if (results[0]) {" +
                                                        "        alert('Elevation: ' +Math.round(results[0].elevation*100)/100 + ' meters.');" +
                                                        "      } else {" +
                                                        "        alert('No results found');" +
                                                        "      }" +
                                                        "    } else {" +
                                                        "      alert('Elevation service failed due to: ' + status);" +
                                                        "    }" +
                                                        "  })" +
                                                   "}");
        script1.appendChild(scriptText);
        document.body.appendChild(script1);
    }
*/
    componentDidMount() {
        window.scrollTo(0, 0);

        unidadessuperficiepesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({unidadessuperficie: dados});
        });

        var data = new Date();

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.setState({s01_identificacao: {
                ...this.state.s01_identificacao, 
                cliente : localStorage.getItem('cliente'),
                id : '',
                recenseador: '',
                datahoracenso: data.getFullYear().toString().padStart(4, '0') + '-' + (data.getMonth() + 1).toString().padStart(2, '0') + '-' + data.getDate().toString().padStart(2, '0') + 'T' + data.getHours().toString().padStart(2, '0') + ':' + data.getMinutes().toString().padStart(2, '0'),
                matricula : '',
                municipio : localStorage.getItem('municipio'),
                distrito : '',
                subdistrito : '',
                setor : '',
                numeroordemsetor : '',
                logradouro : '',
                quadra : '',
                lote : '',
                cep : '',
                numero_valor : '',
                numero_modificador : '',
                numero_complemento : '',
                numero_referencia : '',
                numero_elemento1 : '',
                numero_valor1 : '',
                numero_elemento2 : '',
                numero_valor2 : '',
                numero_elemento3 : '',
                numero_valor3 : '',
                numero_elemento4 : '',
                numero_valor4 : '',
                numero_elemento5 : '',
                numero_valor5 : '',
                numero_elemento6 : '',
                numero_valor6 : '',
                coordenada_qualidade : '',
                coordenada_latitude : '',
                coordenada_longitude : '',
                coordenada_altitude : '',
                coordenada_tipo : '',
                estabelecimento_nome : '',
                produtor_nome : '',
                produtor_apelido : '',
                produtor_cpf : '',
                possuitelefonecontato : '1',
                telefonefixo : '',
                telefonecelular : '',
                possuiemail : '1',
                email : '',
                acessainternet : '1',
                tipointernet : '',
                area_total : '',
                area_codigounidadesuperficie : '',
                area_equivalenciaunidadesuperficie : '',
                area_equivalenciahectaresunidadesuperficie : '',
                produtorsemarea : false,
                semarea_mel : '',
                semarea_extrativista : '',
                semarea_animaisbeiraestradas : '',
                semarea_vazantesitinerantesestradas : '',
                semarea_arrendadasparceriasocupadas : '',
                semarea_outrasituacao : '',
                nomerecenseador: '',
                descricaodistrito : '',
                descricaosubdistrito : '',
                descricaosetor : '',
                tipologradouro: '',
                nomelogradouro: '',
                nomegleba: '',
                descricaounidadesuperficie: '',
            }}, () => {
                this.centerMap();
            })
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id != null) {
                    this.setState({s01_identificacao: {
                        ...this.state.s01_identificacao, 
                        cliente : dados.cliente,
                        id : dados.id,
                        recenseador: dados.recenseador,
                        datahoracenso: dados.datahoracenso,
                        matricula : dados.matricula,
                        municipio : dados.municipio,
                        distrito : dados.distrito,
                        subdistrito : dados.subdistrito,
                        setor : dados.setor,
                        numeroordemsetor : dados.numeroordemsetor,
                        logradouro : dados.logradouro,
                        quadra : dados.quadra,
                        lote : dados.lote,
                        cep : dados.cep,
                        numero_valor : dados.numero_valor,
                        numero_modificador : dados.numero_modificador,
                        numero_complemento : dados.numero_complemento,
                        numero_referencia : dados.numero_referencia,
                        numero_elemento1 : dados.numero_elemento1,
                        numero_valor1 : dados.numero_valor1,
                        numero_elemento2 : dados.numero_elemento2,
                        numero_valor2 : dados.numero_valor2,
                        numero_elemento3 : dados.numero_elemento3,
                        numero_valor3 : dados.numero_valor3,
                        numero_elemento4 : dados.numero_elemento4,
                        numero_valor4 : dados.numero_valor4,
                        numero_elemento5 : dados.numero_elemento5,
                        numero_valor5 : dados.numero_valor5,
                        numero_elemento6 : dados.numero_elemento6,
                        numero_valor6 : dados.numero_valor6,
                        coordenada_qualidade : dados.coordenada_qualidade,
                        coordenada_latitude : dados.coordenada_latitude,
                        coordenada_longitude : dados.coordenada_longitude,
                        coordenada_altitude : dados.coordenada_altitude,
                        coordenada_tipo : dados.coordenada_tipo,
                        estabelecimento_nome : dados.estabelecimento_nome,
                        produtor_nome : dados.produtor_nome,
                        produtor_apelido : dados.produtor_apelido,
                        produtor_cpf : dados.produtor_cpf,
                        possuitelefonecontato : dados.possuitelefonecontato,
                        telefonefixo : dados.telefonefixo,
                        telefonecelular : dados.telefonecelular,
                        possuiemail : dados.possuiemail,
                        email : dados.email,
                        acessainternet : dados.acessainternet,
                        tipointernet : dados.tipointernet,
                        area_total : dados.area_total,
                        area_codigounidadesuperficie : dados.area_codigounidadesuperficie,
                        area_equivalenciaunidadesuperficie : dados.area_equivalenciaunidadesuperficie,
                        area_equivalenciahectaresunidadesuperficie : dados.area_equivalenciahectaresunidadesuperficie,
                        produtorsemarea : dados.produtorsemarea,
                        semarea_mel : dados.semarea_mel,
                        semarea_extrativista : dados.semarea_extrativista,
                        semarea_animaisbeiraestradas : dados.semarea_animaisbeiraestradas,
                        semarea_vazantesitinerantesestradas : dados.semarea_vazantesitinerantesestradas,
                        semarea_arrendadasparceriasocupadas : dados.semarea_arrendadasparceriasocupadas,
                        semarea_outrasituacao : dados.semarea_outrasituacao,
                        nomerecenseador: dados.nomerecenseador,
                        descricaodistrito : dados.descricaodistrito,
                        descricaosubdistrito : dados.descricaosubdistrito,
                        descricaosetor : dados.descricaosetor,
                        tipologradouro: dados.tipologradouro,
                        nomelogradouro: dados.nomelogradouro,
                        nomegleba: dados.nomegleba,
                        descricaounidadesuperficie: dados.descricaounidadesuperficie,
                        }}, () => {
                            this.setState({
                                s01_identificacao: {...this.state.s01_identificacao, 
                                                    area_total: ajustamaskdecimal(this.state.s01_identificacao.area_total),
                                                    area_equivalenciaunidadesuperficie: ajustamaskdecimal(this.state.s01_identificacao.area_equivalenciaunidadesuperficie),
                                                    area_equivalenciahectaresunidadesuperficie: ajustamaskdecimal(this.state.s01_identificacao.area_equivalenciahectaresunidadesuperficie)
                                }
                            });

                            const latLngDMS = ddToDms(this.state.s01_identificacao.coordenada_latitude, this.state.s01_identificacao.coordenada_longitude);

                            this.setState({
                                coordenada_latitudedms: latLngDMS.lat,
                                coordenada_longitudedms: latLngDMS.lng,
                            });
                    
                            this.centerMap();
                        }
                    );
                }
            });
        }
    };

    onChange(e) {
        if ((e.target.name === 'coordenada_latitudedms') || (e.target.name === 'coordenada_longitudedms')){
            this.setState({ [e.target.name]: e.target.value });
        }
        else {
            this.setState({
                s01_identificacao: {...this.state.s01_identificacao, [e.target.name]: e.target.value }
            }, () => {
                if (this.state.s01_identificacao.possuitelefonecontato === '1') {
                    this.setState({
                        s01_identificacao: {...this.state.s01_identificacao, 
                                            telefonefixo: '',  
                                            telefonecelular: ''
                                        }
                    });
                }
    
                if (this.state.s01_identificacao.possuiemail === '1') {
                    this.setState({
                        s01_identificacao: {...this.state.s01_identificacao, email: '' }
                    });
                }
    
                if (this.state.s01_identificacao.acessainternet === '1') {
                    this.setState({
                        s01_identificacao: {...this.state.s01_identificacao, tipointernet: '' }
                    });
                }
            });
        }
    };

    onChangeNumerico(e, name) {
        this.setState({
            s01_identificacao: {...this.state.s01_identificacao, [name]: e.target.value }
        });
    };

    onBlur(e) {
        if ((e.target.name === 'coordenada_latitudedms') &&
            (e.target.value.substr(e.target.value.length - 1, 1).toUpperCase() !== 'S') && 
            (e.target.value.substr(e.target.value.length - 1, 1).toUpperCase() !== 'N')) {
           alert('Latitude de ter posicionamento S (Sul) ou N (Norte) no fim da coordenada.');
           e.target.focus();
        }
        else
        {
            if ((e.target.name === 'coordenada_longitudedms') &&
                (e.target.value.substr(e.target.value.length - 1, 1).toUpperCase() !== 'W') && 
                (e.target.value.substr(e.target.value.length - 1, 1).toUpperCase() !== 'E')) {
                alert('Longitude de ter posicionamento W (Oeste) ou E (Leste) no fim da coordenada.');
                e.target.focus();
            }
            else
            {
                var dms = formatDMS(e.target.value);
                if (dms === '') {
                    alert('Valor informato não está no formato correto. Deve ter pelo menos 6 dítigos númericos e uma letra (S, N, W ou E)');
                    e.target.focus();
                }
                else {
                    this.setState({ [e.target.name]: dms });

                    var dd;

                    if (e.target.name === 'coordenada_latitudedms') {
                        dd = dmsToDd(dms, this.state.coordenada_longitudedms);
                    }
                    else {
                        dd = dmsToDd(this.state.coordenada_latitudedms, dms);
                    }

                    this.setState({
                        s01_identificacao: {...this.state.s01_identificacao, 
                            coordenada_latitude : dd.lat,
                            coordenada_longitude : dd.lng
                        }
                    });

                    this.centerMap();
                }
            }
        }        
    };

    onFocus(e) {
        var valor = e.target.value.substr(0, 2) + 
                    e.target.value.substr(3, 2) + 
                    e.target.value.substr(6, 2) +
                    e.target.value.substr(9, e.target.value.length - 11) +
                    e.target.value.substr(e.target.value.length - 1, 1);
        this.setState({ [e.target.name]: valor });
    }
 
    onCheckBoxClick(name, checked) {
        this.setState({ 
            s01_identificacao: {...this.state.s01_identificacao, [name]: checked }
        }, () => {
            if (this.state.s01_identificacao.produtorsemarea === false) {
                this.setState({ 
                    s01_identificacao: {...this.state.s01_identificacao, 
                        semarea_mel: false, 
                        semarea_extrativista: false, 
                        semarea_animaisbeiraestradas: false, 
                        semarea_vazantesitinerantesestradas: false, 
                        semarea_arrendadasparceriasocupadas: false, 
                        semarea_outrasituacao: false
                    }
                });
            }
        });
    }

    salvar(botao) {
        this.setState({
            s01_identificacao: {...this.state.s01_identificacao,
                    area_total: limpamask(true, this.state.s01_identificacao.area_total),
                    area_equivalenciaunidadesuperficie: limpamask(true, this.state.s01_identificacao.area_equivalenciaunidadesuperficie),
                    area_equivalenciahectaresunidadesuperficie: limpamask(true, this.state.s01_identificacao.area_equivalenciahectaresunidadesuperficie),
                    cep: limpamask(false, this.state.s01_identificacao.cep),
                    telefonefixo: limpamask(false, this.state.s01_identificacao.telefonefixo),
                    telefonecelular: limpamask(false, this.state.s01_identificacao.telefonecelular),
                    produtor_cpf: limpamask(false, this.state.s01_identificacao.produtor_cpf)
                }
            }, async () => {
                try {
                    this.setState({ telefonefixo_error : '' });
                    this.setState({ telefonecelular_error : '' });
                    this.setState({ email_error : '' });
                    this.setState({ produtor_cpf_error : '' });
                    this.setState({ area_total : '' });
                    this.setState({ area_equivalenciaunidadesuperficie : '' });
                    this.setState({ area_equivalenciahectaresunidadesuperficie : '' });
        
                    await checkoutSchema.validate(this.state.s01_identificacao, { abortEarly: false });
        
                    if (this.state.s01_identificacao.id === '') {
                        await censonovo01(this.props.match.url, this.state.s01_identificacao).then(idretorno => {
                            localStorage.setItem('cadastrocenso', idretorno==null ? '0' : encriptar(idretorno))    
                        })
                    }
                    else {
                        censoeditar(this.props.match.url, this.state.s01_identificacao);
                    }

                    localStorage.setItem('areatotal', this.state.s01_identificacao.area_total);    

                    if (botao === 'Back') {
                        this.props.history.push('/ConsultaCenso');
                    }
                    else
                    if (botao === 'Next') {
                        this.props.history.push('/S02');
                    }
                }
                catch(err) {
                    if (err instanceof yup.ValidationError) {
                        for (var i = 0; i < err.inner.length; i++) {
                            this.setState({ [err.inner[i].path +  '_error'] : err.inner[i].message });
                        }
                    }
                }
            }
        );
    };

    PesquisaRecenseadores() {
        recenseadorespesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({recenseadores: dados});
        });
        window.$('#recenseadores').modal("show");
    };

    SelecionarRecenseador(recenseador, nome) {
        this.setState({s01_identificacao: {
            ...this.state.s01_identificacao, 
            recenseador: recenseador,
            nomerecenseador : nome
        }});
        window.$('#recenseadores').modal("hide");
    };

    PesquisaDistritos() {
        distritospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({distritos: dados});
        });
        window.$('#distritos').modal("show");
    };

    SelecionarDistrito(distrito, descricao) {
        this.setState({s01_identificacao: {
            ...this.state.s01_identificacao, 
            distrito: distrito,
            descricaodistrito : descricao
        }});
        window.$('#distritos').modal("hide");
    };

    PesquisaSubDistritos() {
        subdistritospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({subdistritos: dados});
        });
        window.$('#subdistritos').modal("show");
    };

    SelecionarSubDistrito(subdistrito, descricao) {
        this.setState({s01_identificacao: {
            ...this.state.s01_identificacao, 
            subdistrito: subdistrito,
            descricaosubdistrito : descricao
        }});
        window.$('#subdistritos').modal("hide");
    };

    PesquisaSetores() {
        setorespesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({setores: dados});
        });
        window.$('#setores').modal("show");
    };

    SelecionarSetor(setor, descricao) {
        this.setState({s01_identificacao: {
            ...this.state.s01_identificacao, 
            setor: setor,
            descricaosetor : descricao
        }});
        window.$('#setores').modal("hide");
    };

    PesquisaLogradouros() {
        logradourospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({logradouros: dados});
        });
        window.$('#logradouros').modal("show");
    };

    SelecionarLogradouro(logradouro, tipologradouro, nomelogradouro, nomegleba) {
        this.setState({s01_identificacao: {
            ...this.state.s01_identificacao, 
            logradouro: logradouro,
            tipologradouro : tipologradouro,
            nomelogradouro : nomelogradouro,
            nomegleba : nomegleba
        }});
        window.$('#logradouros').modal("hide");
    };

    BuscaUnidadeSuperficie(e) {
        unidadessuperficiebuscaid(localStorage.getItem('cliente'), e.target.value).then(dados => { 
            if (dados.id != null) {
                this.setState({s01_identificacao: {
                    ...this.state.s01_identificacao, 
                    area_codigounidadesuperficie: dados.id,
                    descricaounidadesuperficie :  dados.sigla + ' - ' + dados.descricao,
                    area_equivalenciaunidadesuperficie : ajustamaskdecimal(parseFloat(limpamask(true, this.state.s01_identificacao.area_total)) * parseFloat(dados.equivalenciametros)),
                    area_equivalenciahectaresunidadesuperficie : ajustamaskdecimal(parseFloat(limpamask(true, this.state.s01_identificacao.area_total)) * parseFloat(dados.equivalenciahectares))
                    
                }});
            }
        });
      };

    onMapClick = (t, map, coord) => { 
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        var alt = 1;
 
        const google = this.props.google;

        var elevator = new google.maps.ElevationService();

        // Initiate the location request
        elevator.getElevationForLocations(
            {
                locations: [coord],
            }, function () { alert('teste') }
        );

        this.setState({
            s01_identificacao: {...this.state.s01_identificacao, 
                coordenada_qualidade : '',
                coordenada_latitude : lat,
                coordenada_longitude : lng,
                coordenada_altitude : alt,
                coordenada_tipo : ''
            }
        });

        const latLngDMS = ddToDms(lat, lng);

        this.setState({
            coordenada_latitudedms: latLngDMS.lat,
            coordenada_longitudedms: latLngDMS.lng,
        });
    }
    
    ret(results, status) {
        alert(status + '-')
        if (status === 'OK') {
            if (results[0]) {
                alert(results[0].elevation);
            } else {
                alert('2');
            }
        } else {
            alert("Erro acessando serviço Google Maps API Elevation");
        }
    }
    
    displayMarkers() {
        return <Marker 
                    position={{
                        lat: this.state.s01_identificacao.coordenada_latitude,
                        lng: this.state.s01_identificacao.coordenada_longitude
                    }}
                    title={this.state.s01_identificacao.estabelecimento_nome}                
                />
     }

    centerMap() {
        if (this.state.s01_identificacao.coordenada_latitude === "" && this.state.s01_identificacao.coordenada_longitude === "") {
            if (navigator && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;
                this.setState({
                    s01_identificacao: {...this.state.s01_identificacao, 
                                        coordenada_latitude: coords.latitude,  
                                        coordenada_longitude: coords.longitude
                                    }
                    });
                });
            }
        }
    }

    render() {
        return (
            <div className="geral container-fluid">
                <div className="row">
                    <div className="col-sm">
                        <span>
                            <strong>Recenseador</strong>&nbsp;
                            <TextField variant="outlined" disabled style={{width: 40 + '%'}} size="small" id="recenseador" name="recenseador" value={this.state.s01_identificacao.nomerecenseador} />
                            <Link to="#" onClick={this.PesquisaRecenseadores.bind(this)}><SearchIcon fontSize="large" /></Link>
                            <span className="direita">
                                <strong>Data/Hora Censo</strong>&nbsp;
                                <TextField variant="outlined" disabled type="datetime-local" defaultValue='01/01/2020' size="small" id="datahoracenso" name="datahoracenso" value={this.state.s01_identificacao.datahoracenso} onChange={e => this.onChange(e)} InputLabelProps={{ shrink: true, }}/><p />
                            </span>
                        </span>
                    </div>
                </div>
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>01 - IDENTIFICAÇÃO E LOCALIZAÇÃO DO ESTABELECIMENTO AGROPECUÁRIO</strong>
                    </div>
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                    <small>IDENTIFICAÇÃO DO ESTABELECIMENTO NO SETOR</small></div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Matricula do Imóvel</small>
                        </span>
                        <div className="posicaorb">
                            <TextField style={{width:'300px'}} variant="outlined" size="small" id="matricula" name="matricula" value={this.state.s01_identificacao.matricula} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-UF</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth disabled id="uf" name="uf" value={localStorage.getItem("ufmunicipio")} onChange={e => this.onChange(e)}/>
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Município</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth disabled id="municipio" name="municipio" value={localStorage.getItem("nomemunicipio")} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Distrito</small>
                        </span>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <TextField variant="outlined" fullWidth disabled size="small" id="distrito" name="distrito" value={this.state.s01_identificacao.descricaodistrito} /><p />
                            </div>
                            <div className="col-sm-1 botaoparams2">
                                <Link to="#" onClick={this.PesquisaDistritos.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                            </div>
                        </div>
                    </div>

                    {localStorage.getItem("temsubdistrito") === "true" ? (
                        <div className="col-sm">
                            <span>
                                <small className="circulopreto">04</small>
                                <small>-Subdistrito</small>
                            </span>
                            <div className="row">
                                <div className="col-sm posicaorb">
                                    <TextField variant="outlined" fullWidth disabled size="small" id="subdistrito" name="subdistrito" value={this.state.s01_identificacao.descricaosubdistrito}/><p />
                                    </div>
                                <div className="col-sm-1 botaoparams2">
                                    <Link to="#" onClick={this.PesquisaSubDistritos.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )}

                    {localStorage.getItem("temsetor") === "true" ? (
                        <div className="col-sm">
                            <span>
                                <small className="circulopreto">05</small>
                                <small>-Setor</small>
                            </span>
                            <div className="row">
                                <div className="col-sm posicaorb">
                                    <TextField variant="outlined" fullWidth disabled size="small" id="setor" name="setor" value={this.state.s01_identificacao.descricaosetor}/><p />
                                </div>
                                <div className="col-sm-1 botaoparams2">
                                    <Link to="#" onClick={this.PesquisaSetores.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )}

                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">06</small>
                            <small>-Nº de ordem no setor</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth id="numeroordemsetor" name="numeroordemsetor" value={this.state.s01_identificacao.numeroordemsetor} onChange={e => this.onChange(e)} /><p />
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">07</small>
                            <small>-Endereço</small>
                        </span>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-2 posicaorb">
                                <TextField variant="outlined" size="small" disabled fullWidth label="Tipo Logradouro" id="tipologradouro" name="tipologradouro" value={this.state.s01_identificacao.tipologradouro}/><p />
                            </div>
                            <div className="col-sm-5">
                                <TextField variant="outlined" size="small" disabled fullWidth label="Nome Logradouro" id="nomelogradouro" name="nomelogradouro" value={this.state.s01_identificacao.nomelogradouro} /><p />
                            </div>
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-sm">
                                        <TextField variant="outlined" size="small" disabled fullWidth label="Gleba" id="nomegleba" name="nomegleba" value={this.state.s01_identificacao.nomegleba}/><p />
                                    </div>
                                    <div className="col-sm-1 botaoparams2">
                                        <Link to="#" onClick={this.PesquisaLogradouros.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 posicaorb">
                                <TextField variant="outlined" size="small" fullWidth label="Número" id="numero_valor" name="numero_valor" value={this.state.s01_identificacao.numero_valor} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Complemento" id="numero_complemento" name="numero_complemento" value={this.state.s01_identificacao.numero_complemento} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <TextField variant="outlined" size="small" fullWidth label="Ponto de Referência" id="numero_referencia" name="numero_referencia" value={this.state.s01_identificacao.numero_referencia} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">08</small>
                            <small>-Quadra</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth id="quadra" name="quadra" value={this.state.s01_identificacao.quadra} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">09</small>
                            <small>-Lote</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth id="lote" name="lote" value={this.state.s01_identificacao.lote} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">10</small>
                            <small>-CEP</small>
                        </span>
                        <div className="posicaorb">
                            <TextField InputProps={{ inputComponent: cepMask }} variant="outlined" size="small" fullWidth id="cep" name="cep" value={this.state.s01_identificacao.cep} onChange={e => this.onChange(e)} /><p />
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
{/*                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">11</small>
                            <small>-Número</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Valor" id="numero_valor" name="numero_valor" value={this.state.s01_identificacao.numero_valor} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador" id="numero_modificador" name="numero_modificador" value={this.state.s01_identificacao.numero_modificador} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth multiline rows="4" label="Ponto de Referencia" id="numero_referencia" name="numero_referencia" value={this.state.s01_identificacao.numero_referencia} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Elemento 1" id="numero_elemento1" name="numero_elemento1" value={this.state.s01_identificacao.numero_elemento1} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador 1" id="numero_valor1" name="numero_valor1" value={this.state.s01_identificacao.numero_valor1} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Elemento 2" id="numero_elemento2" name="numero_elemento2" value={this.state.s01_identificacao.numero_elemento2} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador 2" id="numero_valor2" name="numero_valor2" value={this.state.s01_identificacao.numero_valor2} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Elemento 3" id="numero_elemento3" name="numero_elemento3" value={this.state.s01_identificacao.numero_elemento3} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador 3" id="numero_valor3" name="numero_valor3" value={this.state.s01_identificacao.numero_valor3} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Elemento 4" id="numero_elemento4" name="numero_elemento4" value={this.state.s01_identificacao.numero_elemento4} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador 4" id="numero_valor4" name="numero_valor4" value={this.state.s01_identificacao.numero_valor4} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Elemento 5" id="numero_elemento5" name="numero_elemento5" value={this.state.s01_identificacao.numero_elemento5} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador 5" id="numero_valor5" name="numero_valor5" value={this.state.s01_identificacao.numero_valor5} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Elemento 6" id="numero_elemento6" name="numero_elemento6" value={this.state.s01_identificacao.numero_elemento6} onChange={e => this.onChange(e)} /><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Modificador 6" id="numero_valor6" name="numero_valor6" value={this.state.s01_identificacao.numero_valor6} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
        */}
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">11</small>
                            <small>-Coordenadas (dados fornecidos pelo gps)</small>
                        </span>
                    </div>
                    <div className="col-sm-8 posicaorb">
                        <div id="map" style={{height: '60vh', width: '100%'}}>
                            <Map
                                    google={this.props.google}
                                    zoom={15}
                                    center={
                                        {
                                            lat: this.state.s01_identificacao.coordenada_latitude, 
                                            lng: this.state.s01_identificacao.coordenada_longitude
                                        }
                                    }
                                    onClick={this.onMapClick}
                                    centerAroundCurrentLocation={true}
                                >
                                {this.displayMarkers()}
                            </Map>
                        </div>
                    </div>
                    <div className="col-sm ajustadadosgps">
                        <p />
{/*
                        <TextField variant="outlined" size="small" fullWidth label="Qualidade" id="coordenada_qualidade" name="coordenada_qualidade" value={this.state.s01_identificacao.coordenada_qualidade} onChange={e => this.onChange(e)}/><p />
*/}                        
                        <FormControl size="small" fullWidth variant="outlined">
                            <InputLabel className="inputLabelConfig" htmlFor="Tipo de coordenada">&nbsp;Qualidade&nbsp;</InputLabel>
                            <Select
                                value={this.state.s01_identificacao.coordenada_qualidade}
                                label="Qualidade"
                                name="coordenada_qualidade"
                                id="coordenada_qualidade"
                                onChange={e => this.onChange(e)}
                            >
                                <MenuItem value="PPS">Precise Positioning Service (PPS)</MenuItem>
                                <MenuItem value="SPS">Standard Positioning Service (SPS)</MenuItem>
                            </Select>
                        </FormControl>
                        <p />
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Latitude GPS (DMS)" id="coordenada_latitudedms" name="coordenada_latitudedms" value={this.state.coordenada_latitudedms} onChange={e => this.onChange(e)} onFocus={e => this.onFocus(e)} onBlur={e => this.onBlur(e)}/><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" fullWidth label="Longitude GPS (DMS)" id="coordenada_longitudedms" name="coordenada_longitudedms" value={this.state.coordenada_longitudedms} onChange={e => this.onChange(e)} onFocus={e => this.onFocus(e)} onBlur={e => this.onBlur(e)}/><p />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" disabled fullWidth label="Latitude Google (DD)" id="coordenada_latitude" name="coordenada_latitude" value={this.state.s01_identificacao.coordenada_latitude} onChange={e => this.onChange(e)}/><p />
                            </div>
                            <div className="col-sm">
                                <TextField variant="outlined" size="small" disabled fullWidth label="Longitude Google (DD)" id="coordenada_longitude" name="coordenada_longitude" value={this.state.s01_identificacao.coordenada_longitude} onChange={e => this.onChange(e)} /><p />
                            </div>
                        </div>
                        <TextField variant="outlined" size="small" fullWidth label="Altitude" id="coordenada_altitude" name="coordenada_altitude" value={this.state.s01_identificacao.coordenada_altitude} onChange={e => this.onChange(e)} /><p />
{/*
                        <TextField variant="outlined" size="small" fullWidth label="Tipo de Coordenada" id="coordenada_tipo" name="coordenada_tipo" value={this.state.s01_identificacao.coordenada_tipo} onChange={e => this.onChange(e)}/><p />
*/}                        
                    </div>
                </div>
                <p />
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">12</small>
                            <small>-Qual é o nome do estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <TextField variant="outlined" size="small" fullWidth id="estabelecimento_nome" name="estabelecimento_nome" value={this.state.s01_identificacao.estabelecimento_nome} onChange={e => this.onChange(e)}/><p />
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <span>
                            <small className="circulopreto">13</small>
                            <small>-Qual é o nome do(a) produtor(a) do estabelecimento?</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth id="produtor_nome" name="produtor_nome" value={this.state.s01_identificacao.produtor_nome} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small>Apelido como é conhecido(a)</small>
                        </span>
                        <div className="posicaorb">
                            <TextField variant="outlined" size="small" fullWidth id="produtor_apelido" name="produtor_apelido" value={this.state.s01_identificacao.produtor_apelido} onChange={e => this.onChange(e)}/><p />
                        </div>
                    </div>
                    <div className="col-sm">
                        <span>
                            <small>CPF do(a) produtor(a)</small>
                        </span>
                        <div className="posicaorb">
                            <TextField InputProps={{ inputComponent: cpfMask }} variant="outlined" size="small" id="produtor_cpf" name="produtor_cpf" value={this.state.s01_identificacao.produtor_cpf} onChange={e => this.onChange(e)}/>
                            <h6>{this.state.produtor_cpf_error}</h6><p />
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">14</small>
                                <small>-O estabelecimento possui telefone de contato?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="possuitelefonecontato" name="possuitelefonecontato" value={this.state.s01_identificacao.possuitelefonecontato} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-sm">
                                <TextField InputProps={{ inputComponent: telefonefixoMask }} variant="outlined" size="small" fullWidth disabled={this.state.s01_identificacao.possuitelefonecontato === "1"} label="Fixo" id="telefonefixo" name="telefonefixo" value={this.state.s01_identificacao.telefonefixo} onChange={e => this.onChange(e)}/>
                                <h6 className="validationerror">{this.state.telefonefixo_error}</h6><p />
                                <TextField InputProps={{ inputComponent: telefonecelularMask }} variant="outlined" size="small" fullWidth disabled={this.state.s01_identificacao.possuitelefonecontato ==="1"} label="Celular" id="telefonecelular" name="telefonecelular" value={this.state.s01_identificacao.telefonecelular} onChange={e => this.onChange(e)}/>
                                <h6 className="validationerror">{this.state.telefonecelular_error}</h6><p />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">15</small>
                                <small>-O estabelecimento possui contato por correio eletrônico (email)?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm-2 posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="possuiemail" name="possuiemail" value={this.state.s01_identificacao.possuiemail} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-sm">
                                <TextField InputProps={{ inputComponent: emailMask }} variant="outlined" size="small" fullWidth disabled={this.state.s01_identificacao.possuiemail === "1"} label="Email" id="email" name="email" value={this.state.s01_identificacao.email} onChange={e => this.onChange(e)}/>
                                <h6 className="validationerror">{this.state.email_error}</h6><p />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="col-sm-12 subtitulo acertasubtitulo">
                            <span>
                                <small className="circulopreto">16</small>
                                <small>-É possível acessar a internet deste endereço?</small>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 posicaorb">
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="acessainternet" name="acessainternet" value={this.state.s01_identificacao.acessainternet} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                        <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-sm">
                                <FormControl disabled={this.state.s01_identificacao.acessainternet === "1"} component="fieldset">
                                    <RadioGroup aria-label="tipointernet" name="tipointernet" value={this.state.s01_identificacao.tipointernet} onChange={e => this.onChange(e)}>
                                        <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sinal - Banda Larga (fibra ótica, satélite, wi-fi, etc...)" />
                                        <FormControlLabel value="4" control={<Radio color="primary"/>} label="Sinal - Internet móvel" />
                                        <FormControlLabel value="5" control={<Radio color="primary"/>} label="Sinal - Discada por linha telefônica" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">17</small>
                            <small>-Qual é a área total do estabelecimento na data de referência?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <TextField InputProps={{ inputComponent: decimalMask }} variant="outlined" size="small" fullWidth label="Área" id="area_total" name="area_total" value={this.state.s01_identificacao.area_total} onChange={e => this.onChange(e)}/>
                        <h6 className="validationerror">{this.state.area_total_error}</h6><p />
                    </div>
                    <div className="col-sm">
                        <FormControl size="small" fullWidth variant="outlined">
                            <InputLabel className="inputLabelConfig" htmlFor="area_codigounidadesuperficie">&nbsp;Unidade Superfície&nbsp;</InputLabel>
                            <Select
                                value={this.state.s01_identificacao.area_codigounidadesuperficie}
                                label="Unidade de Superfície"
                                name="area_codigounidadesuperficie"
                                id="area_codigounidadesuperficie"
                                onChange={e => this.BuscaUnidadeSuperficie(e)}
                                                        >
                            {this.state.unidadessuperficie.map((item) => (
                                <MenuItem key={item.descricao} value={item.id}>
                                    {item.id + ' - ' + item.descricao}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-sm">
                        <TextField InputProps={{ inputComponent: decimalMask6 }} disabled variant="outlined" size="small" fullWidth label="Equivalência em m² da unidade de medida" id="area_equivalenciaunidadesuperficie" name="area_equivalenciaunidadesuperficie" value={this.state.s01_identificacao.area_equivalenciaunidadesuperficie} />
                        <h6 className="validationerror">{this.state.area_equivalenciaunidadesuperficie_error}</h6><p />
                    </div>
                    <div className="col-sm">
                        <TextField InputProps={{ inputComponent: decimalMask6 }} disabled variant="outlined" size="small" fullWidth label="Equivalência em ha da unidade de medida" id="area_equivalenciahectaresunidadesuperficie" name="area_equivalenciahectaresunidadesuperficie" value={this.state.s01_identificacao.area_equivalenciahectaresunidadesuperficie} />
                        <h6 className="validationerror">{this.state.area_equivalenciahectaresunidadesuperficie_error}</h6><p />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm posicaorb">
                        <FormControlLabel
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.produtorsemarea} onClick={this.onCheckBoxClick.bind(this, "produtorsemarea", !this.state.s01_identificacao.produtorsemarea)} value="produtorsemarea"/>
                            }
                            label="Produtor(a) sem área (quando marcado este item habilita os itens abaixo para informar qual o tipo de produtor sem área será qualificado)"
                        />                      
                    </div>                  
                </div>
                <div className="row">
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm">
                        <FormControlLabel disabled={!this.state.s01_identificacao.produtorsemarea}
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.semarea_mel} onClick={this.onCheckBoxClick.bind(this, "semarea_mel", !this.state.s01_identificacao.semarea_mel)} value="semarea_mel"/>
                            }
                            label="Produtor(a) de mel"
                        />  
                    </div>                                      
                </div>
                <div className="row">
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm">
                        <FormControlLabel disabled={!this.state.s01_identificacao.produtorsemarea}
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.semarea_extrativista} onClick={this.onCheckBoxClick.bind(this, "semarea_extrativista", !this.state.s01_identificacao.semarea_extrativista)} value="semarea_extrativista"/>
                            }
                            label="Extrativista (babaçu, castanha-do-brasil, seringueiro, etc.)"
                        />  
                    </div>                                      
                </div>
                <div className="row">
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm">
                        <FormControlLabel disabled={!this.state.s01_identificacao.produtorsemarea}
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.semarea_animaisbeiraestradas} onClick={this.onCheckBoxClick.bind(this, "semarea_animaisbeiraestradas", !this.state.s01_identificacao.semarea_animaisbeiraestradas)} value="semarea_animaisbeiraestradas"/>
                            }
                            label="Criador(a) de animais em beira de estradas"
                        />  
                    </div>                                      
                </div>
                <div className="row">
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm">
                        <FormControlLabel disabled={!this.state.s01_identificacao.produtorsemarea}
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.semarea_vazantesitinerantesestradas} onClick={this.onCheckBoxClick.bind(this, "semarea_vazantesitinerantesestradas", !this.state.s01_identificacao.semarea_vazantesitinerantesestradas)} value="semarea_vazantesitinerantesestradas"/>
                            }
                            label="Produtor(a) em vazantes de rios, roças itinerantes, beira de estradas e que na data de referência não mais ocupava esta área"
                        />  
                    </div>                                      
                </div>
                <div className="row">
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm">
                        <FormControlLabel disabled={!this.state.s01_identificacao.produtorsemarea}
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.semarea_arrendadasparceriasocupadas} onClick={this.onCheckBoxClick.bind(this, "semarea_arrendadasparceriasocupadas", !this.state.s01_identificacao.semarea_arrendadasparceriasocupadas)} value="semarea_arrendadasparceriasocupadas"/>
                            }
                            label="Produtor(a) que produziu no período de referência em terras arrendadas, em parcerias ou ocupadas, mas que na data de referência não estava com o uso da mesma"
                        />  
                    </div>                                      
                </div>
                <div className="row">
                    <div className="col-sm-1">
                    </div>
                    <div className="col-sm">
                        <FormControlLabel disabled={!this.state.s01_identificacao.produtorsemarea}
                            control={
                            <   Checkbox color="primary" checked={this.state.s01_identificacao.semarea_outrasituacao} onClick={this.onCheckBoxClick.bind(this, "semarea_outrasituacao", !this.state.s01_identificacao.semarea_outrasituacao)} value="semarea_outrasituacao"/>
                            }
                            label="Outra situação"
                        />  
                    </div>                                      
                </div>
                <Divider />
                <p />

                {/* Modal para selecionar RECENSEADORES */}
                <div class="modal fade" id="recenseadores" tabindex="-1" role="dialog" aria-labelledby="recenseadoresLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione o Recenseador</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Nome</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.recenseadores.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarRecenseador.bind(this, item.id, item.nome)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarRecenseador.bind(this, item.id, item.nome)}>{item.nome}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal para selecionar DISTRITO */}
                <div class="modal fade" id="distritos" tabindex="-1" role="dialog" aria-labelledby="distritosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="distritosLabel">Selecione o Distrito</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Código</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.distritos.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarDistrito.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarDistrito.bind(this, item.id, item.descricao)}>{item.codigo}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarDistrito.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal para selecionar SUBDISTRITO */}
                <div class="modal fade" id="subdistritos" tabindex="-1" role="dialog" aria-labelledby="subdistritosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="subdistritosLabel">Selecione o Subdistrito</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">Distrito</StyledTableCell>
                                                <StyledTableCell>Subdistrito</StyledTableCell>
                                                <StyledTableCell>Código</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.subdistritos.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarSubDistrito.bind(this, item.subdistrito, item.descricao)}>{item.distrito}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarSubDistrito.bind(this, item.subdistrito, item.descricao)}>{item.subdistrito}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarSubDistrito.bind(this, item.subdistrito, item.descricao)}>{item.codigo}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarSubDistrito.bind(this, item.subdistrito, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal para selecionar SETOR */}
                <div class="modal fade" id="setores" tabindex="-1" role="dialog" aria-labelledby="setoresLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="setoresLabel">Selecione o Setor</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">Id</StyledTableCell>
                                                <StyledTableCell>Código</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.setores.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarSetor.bind(this, item.id, item.descricao)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarSetor.bind(this, item.id, item.descricao)}>{item.codigo}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarSetor.bind(this, item.id, item.descricao)}>{item.descricao}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal para selecionar LOGRADOURO */}
                <div class="modal fade" id="logradouros" tabindex="-1" role="dialog" aria-labelledby="logradourosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="logradourosLabel">Selecione o Logradouro</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell className="colkey">Id</StyledTableCell>
                                                <StyledTableCell>Tipo</StyledTableCell>
                                                <StyledTableCell>Nome</StyledTableCell>
                                                <StyledTableCell>Gleba</StyledTableCell>
                                                <StyledTableCell className="colbuttons"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.logradouros.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><Link to="#" onClick={this.SelecionarLogradouro.bind(this, item.id, item.tipo, item.titulo, item.nome)}>{item.id}</Link></StyledTableCell > 
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarLogradouro.bind(this, item.id, item.tipo, item.nome, item.nomegleba)}>{item.tipo}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarLogradouro.bind(this, item.id, item.tipo, item.nome, item.nomegleba)}>{item.nome}</Link></StyledTableCell >
                                                            <StyledTableCell ><Link to="#" onClick={this.SelecionarLogradouro.bind(this, item.id, item.tipo, item.nome, item.nomegleba)}>{item.nomegleba}</Link></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
        </div>    
        );
    }
}

//export default S01_Identificacao;

export default GoogleApiWrapper({
    apiKey: 'AIzaSyATF0AtmexxJanOu-Yr78CkKOI5Uhr0m6w',
    libraries: [],
  })(S01_Identificacao);
