import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, limpamask, integerMask, decimalMask, ajustamaskdecimal } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

class S06_UnidadesArmazenadoras extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s06_unidadesarmazenadoras : {
                cliente : '',
                id : '',
                existiamunidades : '',
                tiposdep_convencionaisnumero : '',
                tiposdep_convencionaiscapacidade : '',
                tiposdep_inflaveisnumero : '',
                tiposdep_inflaveiscapacidade : '',
                tiposdep_graneleirosnumero : '',
                tiposdep_graneleiroscapacidade : '',
                tiposdep_silosnumero : '',
                tiposdep_siloscapacidade : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s06_unidadesarmazenadoras: {
                        ...this.state.s06_unidadesarmazenadoras, 
                        cliente : dados.cliente,
                        id : dados.id,
                        existiamunidades : dados.existiamunidades,
                        tiposdep_convencionaisnumero : dados.tiposdep_convencionaisnumero,
                        tiposdep_convencionaiscapacidade : dados.tiposdep_convencionaiscapacidade,
                        tiposdep_inflaveisnumero : dados.tiposdep_inflaveisnumero,
                        tiposdep_inflaveiscapacidade : dados.tiposdep_inflaveiscapacidade,
                        tiposdep_graneleirosnumero : dados.tiposdep_graneleirosnumero,
                        tiposdep_graneleiroscapacidade : dados.tiposdep_graneleiroscapacidade,
                        tiposdep_silosnumero : dados.tiposdep_silosnumero,
                        tiposdep_siloscapacidade : dados.tiposdep_siloscapacidade
                    }}, () => {
                        this.setState({
                            s06_unidadesarmazenadoras: {...this.state.s06_unidadesarmazenadoras, 
                                tiposdep_convencionaiscapacidade: ajustamaskdecimal(this.state.s06_unidadesarmazenadoras.tiposdep_convencionaiscapacidade),
                                tiposdep_inflaveiscapacidade: ajustamaskdecimal(this.state.s06_unidadesarmazenadoras.tiposdep_inflaveiscapacidade),
                                tiposdep_graneleiroscapacidade: ajustamaskdecimal(this.state.s06_unidadesarmazenadoras.tiposdep_graneleiroscapacidade),
                                tiposdep_siloscapacidade: ajustamaskdecimal(this.state.s06_unidadesarmazenadoras.tiposdep_siloscapacidade)
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                }
                else {
                    this.setState({s06_unidadesarmazenadoras: {...this.state.s06_unidadesarmazenadoras, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        existiamunidades : '1',
                        tiposdep_convencionaisnumero : '',
                        tiposdep_convencionaiscapacidade : '',
                        tiposdep_inflaveisnumero : '',
                        tiposdep_inflaveiscapacidade : '',
                        tiposdep_graneleirosnumero : '',
                        tiposdep_graneleiroscapacidade : '',
                        tiposdep_silosnumero : '',
                        tiposdep_siloscapacidade : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s06_unidadesarmazenadoras: {...this.state.s06_unidadesarmazenadoras, [e.target.name]: e.target.value }
        }, () => {
            if (this.state.s06_unidadesarmazenadoras.existiamunidades === "1") {
                this.setState({ s06_unidadesarmazenadoras: {...this.state.s06_unidadesarmazenadoras, 
                    tiposdep_convencionaisnumero : '',
                    tiposdep_convencionaiscapacidade : '',
                    tiposdep_inflaveisnumero : '',
                    tiposdep_inflaveiscapacidade : '',
                    tiposdep_graneleirosnumero : '',
                    tiposdep_graneleiroscapacidade : '',
                    tiposdep_silosnumero : '',
                    tiposdep_siloscapacidade : ''
                }
            });
        }});
    }

    async salvar(botao) {
        this.setState({
            s06_unidadesarmazenadoras: {...this.state.s06_unidadesarmazenadoras,
                tiposdep_convencionaisnumero: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_convencionaisnumero),
                tiposdep_convencionaiscapacidade: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_convencionaiscapacidade),
                tiposdep_inflaveisnumero: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_inflaveisnumero),
                tiposdep_inflaveiscapacidade: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_inflaveiscapacidade),
                tiposdep_graneleirosnumero: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_graneleirosnumero),
                tiposdep_graneleiroscapacidade: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_graneleiroscapacidade),
                tiposdep_silosnumero: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_silosnumero),
                tiposdep_siloscapacidade: limpamask(true, this.state.s06_unidadesarmazenadoras.tiposdep_siloscapacidade)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s06_unidadesarmazenadoras);
            }
            else {
                censoeditar(this.props.match.url, this.state.s06_unidadesarmazenadoras);
            }
            if (botao === 'Back') {
                this.props.history.push('/S05');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S07');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>06 - UNIDADES ARMAZENADORAS PARA GRÃOS EXISTENTES NO ESTABELECIMENTO NA DATA DE REFERÊNCIA</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Existiam unidades armazenadoras para grãos?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="existiamunidades" name="existiamunidades" value={this.state.s06_unidadesarmazenadoras.existiamunidades} onChange={e => this.onChange(e)}>
                                <span>
                                    <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                </span>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Quais são os tipos de depósitos?</small>
                        </span>
                    </div>
                    <div className="col-sm-6 posicaorb bordadireita">
                        <div className="row bordainferior">
                            <div className="subtitulo posicaorb">
                                <em><strong>1 - Armazéns convencionais e estruturais</strong></em>
                            </div>
                            <div className="row posicaorb">
                                <div className="col-sm-6 posicaotextfield40">Quantidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</InputAdornment> }} variant="outlined" size="small" id="tiposdep_convencionaisnumero" name="tiposdep_convencionaisnumero" value={this.state.s06_unidadesarmazenadoras.tiposdep_convencionaisnumero} onChange={e => this.onChange(e)}/><p /></div>
                                <div className="col-sm-6 posicaotextfield40">Capacidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="tiposdep_convencionaiscapacidade" name="tiposdep_convencionaiscapacidade" value={this.state.s06_unidadesarmazenadoras.tiposdep_convencionaiscapacidade} onChange={e => this.onChange(e)}/><p /></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="subtitulo posicaorb">
                                <em><strong>2 - Infláveis</strong></em>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 posicaotextfield40">Quantidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</InputAdornment> }} variant="outlined" size="small" id="tiposdep_inflaveisnumero" name="tiposdep_inflaveisnumero" value={this.state.s06_unidadesarmazenadoras.tiposdep_inflaveisnumero} onChange={e => this.onChange(e)}/><p /></div>
                                <div className="col-sm-6 posicaotextfield40">Capacidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="tiposdep_inflaveiscapacidade" name="tiposdep_inflaveiscapacidade" value={this.state.s06_unidadesarmazenadoras.tiposdep_inflaveiscapacidade} onChange={e => this.onChange(e)}/><p /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row bordainferior">
                            <div className="subtitulo posicaorb">
                                <em><strong>3 - Armazens Graneleiros e Granelizados</strong></em>
                            </div>
                            <div className="row posicaorb">
                                <div className="col-sm-6 posicaotextfield40">Quantidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</InputAdornment> }} variant="outlined" size="small" id="tiposdep_graneleirosnumero" name="tiposdep_graneleirosnumero" value={this.state.s06_unidadesarmazenadoras.tiposdep_graneleirosnumero} onChange={e => this.onChange(e)}/><p /></div>
                                <div className="col-sm-6 posicaotextfield40">Capacidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="tiposdep_graneleiroscapacidade" name="tiposdep_graneleiroscapacidade" value={this.state.s06_unidadesarmazenadoras.tiposdep_graneleiroscapacidade} onChange={e => this.onChange(e)}/><p /></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="subtitulo posicaorb">
                                <em><strong>4 - Silos</strong></em>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 posicaotextfield40">Quantidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</InputAdornment> }} variant="outlined" size="small" id="tiposdep_silosnumero" name="tiposdep_silosnumero" value={this.state.s06_unidadesarmazenadoras.tiposdep_silosnumero} onChange={e => this.onChange(e)}/><p /></div>
                                <div className="col-sm-6 posicaotextfield40">Capacidade</div>
                                <div className="col-sm"><TextField disabled={this.state.s06_unidadesarmazenadoras.existiamunidades === "1"} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Kg</InputAdornment> }} variant="outlined" size="small" id="tiposdep_siloscapacidade" name="tiposdep_siloscapacidade" value={this.state.s06_unidadesarmazenadoras.tiposdep_siloscapacidade} onChange={e => this.onChange(e)}/><p /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S06_UnidadesArmazenadoras;