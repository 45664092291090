import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, limpamask, integerMask } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';

class S08_PessoasEstabelecimento extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s08_pessoasestabelecimento : {
                cliente : '',
                id : '',
                produtoresate90 : '',
                produtoresate180 : '',
                produtoresacima180 : '',
                permanentesate90 : '',
                permanentesate180 : '',
                permanentesacima180 : '',
                temporariosate90 : '',
                temporariosate180 : '',
                temporariosacima180 : '',
                diariaspagas : '',
                contratouservico : '',
                contratacao_empreiteiro : '',
                contratacao_cooperativa : '',
                contratacao_empresa : '',
                contratacao_naosabe : '',
                empreiteiro_diasutilizados : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s08_pessoasestabelecimento: {
                        ...this.state.s08_pessoasestabelecimento, 
                        cliente : dados.cliente,
                        id : dados.id,
                        produtoresate90 : dados.produtoresate90,
                        produtoresate180 : dados.produtoresate180,
                        produtoresacima180 : dados.produtoresacima180,
                        permanentesate90 : dados.permanentesate90,
                        permanentesate180 : dados.permanentesate180,
                        permanentesacima180 : dados.permanentesacima180,
                        temporariosate90 : dados.temporariosate90,
                        temporariosate180 : dados.temporariosate180,
                        temporariosacima180 : dados.temporariosacima180,
                        diariaspagas : dados.diariaspagas,
                        contratouservico : dados.contratouservico,
                        contratacao_empreiteiro : dados.contratacao_empreiteiro,
                        contratacao_cooperativa : dados.contratacao_cooperativa,
                        contratacao_empresa : dados.contratacao_empresa,
                        contratacao_naosabe : dados.contratacao_naosabe,
                        empreiteiro_diasutilizados : dados.empreiteiro_diasutilizados
                    }});
                    
                    this.setState({ novocadastro : false });
                }
                else {
                    this.setState({s08_pessoasestabelecimento: {...this.state.s08_pessoasestabelecimento, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        produtoresate90 : '',
                        produtoresate180 : '',
                        produtoresacima180 : '',
                        permanentesate90 : '',
                        permanentesate180 : '',
                        permanentesacima180 : '',
                        temporariosate90 : '',
                        temporariosate180 : '',
                        temporariosacima180 : '',
                        diariaspagas : '',
                        contratouservico : '1',
                        contratacao_empreiteiro : false,
                        contratacao_cooperativa : false,
                        contratacao_empresa : false,
                        contratacao_naosabe : false,
                        empreiteiro_diasutilizados : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({
            s08_pessoasestabelecimento: {...this.state.s08_pessoasestabelecimento, [e.target.name]: e.target.value }
        }, () => {
            if (this.state.s08_pessoasestabelecimento.contratouservico === '1') {
                this.setState({
                    s08_pessoasestabelecimento: {...this.state.s08_pessoasestabelecimento, 
                        contratacao_empreiteiro : false,
                        contratacao_cooperativa : false,
                        contratacao_empresa : false,
                        contratacao_naosabe : false,
                        empreiteiro_diasutilizados : ''
                    }
                });
            }
        });
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s08_pessoasestabelecimento: {...this.state.s08_pessoasestabelecimento, [name]: checked }});
    }

    async salvar(botao) {
        this.setState({
            s08_pessoasestabelecimento: {...this.state.s08_pessoasestabelecimento,
                produtoresate90: limpamask(true, this.state.s08_pessoasestabelecimento.produtoresate90),
                produtoresate180: limpamask(true, this.state.s08_pessoasestabelecimento.produtoresate180),
                produtoresacima180: limpamask(true, this.state.s08_pessoasestabelecimento.produtoresacima180),
                permanentesate90: limpamask(true, this.state.s08_pessoasestabelecimento.permanentesate90),
                permanentesate180: limpamask(true, this.state.s08_pessoasestabelecimento.permanentesate180),
                permanentesacima180: limpamask(true, this.state.s08_pessoasestabelecimento.permanentesacima180),
                temporariosate90: limpamask(true, this.state.s08_pessoasestabelecimento.temporariosate90),
                temporariosate180: limpamask(true, this.state.s08_pessoasestabelecimento.temporariosate180),
                temporariosacima180: limpamask(true, this.state.s08_pessoasestabelecimento.temporariosacima180),
                diariaspagas: limpamask(true, this.state.s08_pessoasestabelecimento.diariaspagas),
                empreiteiro_diasutilizados: limpamask(true, this.state.s08_pessoasestabelecimento.empreiteiro_diasutilizados)
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s08_pessoasestabelecimento);
            }
            else {
                censoeditar(this.props.match.url, this.state.s08_pessoasestabelecimento);
            }
            if (botao === 'Back') {
                this.props.history.push('/S07');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S09');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>08 - NÚMERO DE PESSOAS OCUPADAS NO ESTABELECIMENTO NO PERÍODO DE REFERÊNCIA</strong>
                    </div>
                <Divider />
                <p />
                <div className="col-sm">
                    <div className="row">
                        <div className="col-sm-6 text-center bordacompleta">
                            <h6 id="titulotabelapessoas"><strong>CATEGORIA</strong></h6>
                        </div>
                        <div className="col-sm bordainferior bordasuperior bordadireita">
                            <h5 className="text-center"><small><strong>NÚMERO DE PESSOAS OCUPADAS POR<br />DIAS TRABALHADOS NO ESTABELECIMENTO</strong></small></h5>
                            <div className="row">
                                <div className="col-sm-4 text-center bordadireita bordasuperior">
                                    <small><strong>Menos de 90 dias</strong></small>
                                </div>
                                <div className="col-sm-4 text-center bordadireita bordasuperior">
                                    <small><strong>De 90 a menos de 180 dias</strong></small>
                                </div>
                                <div className="col-sm text-center bordasuperior">
                                    <small><strong>De 180 dias e mais</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bordainferior linhatabela bordadireita bordaesquerda">
                        <div className="col-sm-6 bordadireita">
                            <p />
                            <span>
                                <small className="circulopreto">01</small>
                                <small>-Produtor e pessoas com laços de parentesco com o mesmo que auxiliaram em suas atividades (homens, mulheres e crianças)</small>
                            </span>
                        </div>
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm-4 linhatabela text-center align-middle bordadireita">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtoresate90" name="produtoresate90" value={this.state.s08_pessoasestabelecimento.produtoresate90} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm-4 linhatabela text-center bordadireita">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtoresate180" name="produtoresate180" value={this.state.s08_pessoasestabelecimento.produtoresate180} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela text-center">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="produtoresacima180" name="produtoresacima180" value={this.state.s08_pessoasestabelecimento.produtoresacima180} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bordainferior linhatabela bordadireita bordaesquerda">
                        <div className="col-sm-6 bordadireita">
                            <p />
                            <span>
                                <small className="circulopreto">02</small>
                                <small>-Trabalhadores permanentes e pessoas não-remuneradas com laços de parentesco com estes empregados, que as auxiliaram em suas atividades (homens, mulheres e crianças)</small>
                            </span>
                        </div>
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm-4 linhatabela text-center align-middle bordadireita">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanentesate90" name="permanentesate90" value={this.state.s08_pessoasestabelecimento.permanentesate90} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm-4 linhatabela text-center bordadireita">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanentesate180" name="permanentesate180" value={this.state.s08_pessoasestabelecimento.permanentesate180} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela text-center">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="permanentesacima180" name="permanentesacima180" value={this.state.s08_pessoasestabelecimento.permanentesacima180} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bordainferior linhatabela bordadireita bordaesquerda">
                        <div className="col-sm-6 bordadireita">
                            <p />
                            <span>
                                <small className="circulopreto">03</small>
                                <small>-Trabalhadores temporários, em regime de parceria e pessoas não-remuneradas com laços de parentesco com estes empregados, que as auxiliaram em suas atividades (homens, mulheres e crianças)</small>
                            </span>
                        </div>
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm-4 linhatabela text-center align-middle bordadireita">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporariosate90" name="temporariosate90" value={this.state.s08_pessoasestabelecimento.temporariosate90} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm-4 linhatabela text-center bordadireita">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporariosate180" name="temporariosate180" value={this.state.s08_pessoasestabelecimento.temporariosate180} onChange={e => this.onChange(e)}/>
                                </div>
                                <div className="col-sm linhatabela text-center">
                                    <p />
                                    <TextField InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="temporariosacima180" name="temporariosacima180" value={this.state.s08_pessoasestabelecimento.temporariosacima180} onChange={e => this.onChange(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p />
                <div className="row">
                    <div className="col-sm-6 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Qual foi o número de diárias pagas aos empregados temporários no período de referência?</small>
                        </span>
                    </div>
                    <div className="col-sm margemdivider" >
                        <TextField InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Diárias Pagas</InputAdornment> }} variant="outlined" size="small" id="diariaspagas" name="diariaspagas" value={this.state.s08_pessoasestabelecimento.diariaspagas} onChange={e => this.onChange(e)}/>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="col-sm-6">
                    <h4>CONTRATAÇÃO DE SERVIÇOS</h4>
                </div>
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">05</small>
                            <small>-Contratou algum serviço para o estabelecimento agropecuário no período de referência?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="contratouservico" name="contratouservico" value={this.state.s08_pessoasestabelecimento.contratouservico} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <span>
                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim" />
                                    <svg className="bi bi-arrow-right acertaposicaolabel" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z" clip-rule="evenodd"/>
                                                        <path fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z" clip-rule="evenodd"/>
                                    </svg>
                                    &nbsp;&nbsp;
                                    <text className="espacamentorb">A contratação foi feita através de:</text>
                                    <FormControl disabled={this.state.s08_pessoasestabelecimento.contratouservico === "2" ? false : true} component="fieldset">
                                        <span>
                                            <FormControlLabel
                                                control={
                                                <Checkbox color="primary" checked={this.state.s08_pessoasestabelecimento.contratacao_empreiteiro} onClick={this.onCheckBoxClick.bind(this, "contratacao_empreiteiro", !this.state.s08_pessoasestabelecimento.contratacao_empreiteiro)} value="contratacao_empreiteiro"/>
                                                }
                                                label="Empreiteiro (pessoa física)"
                                            />  
                                            <svg className="bi bi-arrow-right acertaposicaolabel" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M10.146 4.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 8l-2.647-2.646a.5.5 0 010-.708z" clip-rule="evenodd"/>
                                                                <path fill-rule="evenodd" d="M2 8a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 8z" clip-rule="evenodd"/>
                                            </svg>
                                            &nbsp;&nbsp;
                                            <text className="espacamentorb">Quantos dias utilizou na empreitada?</text>
                                            <TextField disabled={this.state.s08_pessoasestabelecimento.contratouservico === "2" ? false : true} InputProps={{ inputComponent: integerMask }} variant="outlined" size="small" id="empreiteiro_diasutilizados" name="empreiteiro_diasutilizados" value={this.state.s08_pessoasestabelecimento.empreiteiro_diasutilizados} onChange={e => this.onChange(e)}/>
                                        </span>
                                        <span>
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s08_pessoasestabelecimento.contratacao_cooperativa} onClick={this.onCheckBoxClick.bind(this, "contratacao_cooperativa", !this.state.s08_pessoasestabelecimento.contratacao_cooperativa)} value="contratacao_cooperativa"/>
                                                }
                                                label="Cooperativa"
                                            />  
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s08_pessoasestabelecimento.contratacao_empresa} onClick={this.onCheckBoxClick.bind(this, "contratacao_empresa", !this.state.s08_pessoasestabelecimento.contratacao_empresa)} value="contratacao_empresa"/>
                                                }
                                                label="Empresa (pessoa jurídica)"
                                            />  
                                            <FormControlLabel
                                                control={
                                                <   Checkbox color="primary" checked={this.state.s08_pessoasestabelecimento.contratacao_naosabe} onClick={this.onCheckBoxClick.bind(this, "contratacao_naosabe", !this.state.s08_pessoasestabelecimento.contratacao_naosabe)} value="contratacao_naosabe"/>
                                                }
                                                label="Não sabe"
                                            />  
                                        </span>
                                    </FormControl>
                                </span>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S08_PessoasEstabelecimento;