import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar, s07_implementospesquisa, s07_implementosbuscaid, s07_implementosnovo, s07_implementoseditar, s07_implementosdeletar, implementospesquisa } from '../../../Services/apifunctions';
import { decriptar, ajustamaskdecimal, limpamask, integerMask } from '../../funcoesgerais';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#535353",
      color: theme.palette.common.white,
    },
    body: {
        backgroundColor: "#f0f0f0",
        color: theme.palette.common.black,
        fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow);

class S07_MaquinasVeiculos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s07_maquinasveiculos : {
                cliente : '',
                id : '',
                existiammaquinas : ''
            },
            implementos: [],
            s07_implementos : [],
            s07_implemento : {
                cliente : '',
                idcenso : '',
                id : '',
                idimplemento : '',
                quantidade : '',
                descricaoimplemento : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s07_maquinasveiculos: {
                        ...this.state.s07_maquinasveiculos, 
                        cliente : dados.cliente,
                        id : dados.id,
                        existiammaquinas : dados.existiammaquinas
                    }});
                    
                    this.setState({ novocadastro : false });
                }
                else {
                    this.setState({s07_maquinasveiculos: {...this.state.s07_maquinasveiculos, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        existiammaquinas : '1'
                }});

                    this.setState({ novocadastro : true });
                }
            });

            this.PesquisaS07_Implemento();
        }
    };

    // Relacionamento Censo->Calcário

    PesquisaS07_Implemento() {
        s07_implementospesquisa(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), '@').then(dados => { 
            this.setState({s07_implementos: dados})
        });
    };

    MostrarDetalhesImplemento(id) {
        if (id == null)
        {
            this.setState({s07_implemento: {
                ...this.state.s07_implemento, 
                cliente: localStorage.getItem('cliente'), 
                idcenso : decriptar(localStorage.getItem('cadastrocenso')),
                id : '',
                idimplemento : '',
                quantidade : '',
                descricaoimplemento : ''
            }});
        }
        else
        {
            s07_implementosbuscaid(localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso')), id).then(dados => { 
                if (dados.id !== '') {
                    this.setState({s07_implemento: {
                        ...this.state.s07_implemento, 
                        cliente: dados.cliente, 
                        idcenso : dados.idcenso,
                        id : dados.id,
                        idimplemento : dados.idimplemento,
                        quantidade : dados.quantidade,
                        descricaoimplemento : dados.descricaoimplemento
                    }}, () => {
                        this.setState({
                            s07_implemento: {...this.state.s07_implemento, 
                                quantidade: ajustamaskdecimal(this.state.s07_implemento.quantidade)
                            }
                        });
                    });        
                }
            });
        }

        window.$('#detalhesimplemento').modal("show");
    };

    onChangeImplemento(e) {
        this.setState({s07_implemento: {...this.state.s07_implemento, [e.target.name]: e.target.value}});
    };
    
    PesquisaImplemento() {
        implementospesquisa(localStorage.getItem('cliente'), "@").then(dados => { 
            this.setState({implementos: dados});
        });

        window.$('#implementos').modal("show");
    };

    SelecionarImplemento(implemento, descricao) {
        this.setState({s07_implemento: {
            ...this.state.s07_implemento, 
            idimplemento: implemento,
            descricaoimplemento: descricao
        }});
        window.$('#implementos').modal("hide");
    };

    onConfirmarImplementoClick() {
        this.setState({
            s07_implemento: {...this.state.s07_implemento,
                quantidade: limpamask(true, this.state.s07_implemento.quantidade)
            }
        }, async () => {
            if (this.state.s07_implemento.id === '')
            {
                s07_implementosnovo(this.state.s07_implemento).then(r => {
                    this.PesquisaS07_Implemento();
                });
            }
            else
            {
                s07_implementoseditar(this.state.s07_implemento).then(r => {
                    this.PesquisaS07_Implemento();
                });
            }
        });
    };

    onDeletarImplementoClick(cliente, idcenso, id) {
        s07_implementosdeletar(cliente, idcenso, id).then(r => {
            this.PesquisaS07_Implemento();
        });
    };
    
    // Fim Relacionamentos

    onChange(e) {
        this.setState({
            s07_maquinasveiculos: {...this.state.s07_maquinasveiculos, [e.target.name]: e.target.value }
        });
    }

    salvar(botao) {
        if (this.state.novocadastro) {
            censonovo(this.props.match.url, this.state.s07_maquinasveiculos);
        }
        else {
            censoeditar(this.props.match.url, this.state.s07_maquinasveiculos);
        }

        if (botao === 'Back') {
            this.props.history.push('/S06');
        }
        else
        if (botao === 'Next') {
            this.props.history.push('/S08');
        }
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>07 - TRATORES, IMPLEMENTOS, MÁQUINAS E VEÍCULOS EXISTENTES NO ESTABELECIMENTO NA DATA DE REFERÊNCIA</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Quantos tratores, implementos, máquinas e veículos existiam no estabelecimento?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="existiammaquinas" name="existiammaquinas" value={this.state.s07_maquinasveiculos.existiammaquinas} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não existiam tratores, implementos, máquinas e veículos" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim - Quais?" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div className="col-sm-12 posicaorb" hidden={this.state.s07_maquinasveiculos.existiammaquinas === '1' ? true : false} >
                        <button className="btn btn-link btn-sm botaonovo" type="button" onClick={this.MostrarDetalhesImplemento.bind(this, null)}>Incluir Implemento</button>
                        <div className="tabela">
                            <TableContainer component={Paper}>
                                <div className=" text-center cabectab">Implementos Agrícolas</div>
                                <Table size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className="colkey text-center">Código</StyledTableCell>
                                            <StyledTableCell className="text-left">Descrição</StyledTableCell>
                                            <StyledTableCell className="text-center">Quantidade</StyledTableCell>
                                            <StyledTableCell className="colbuttons"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.s07_implementos.map((item, indice) => {
                                                return (
                                                    <StyledTableRow  key={indice}>
                                                        <StyledTableCell className="colkey text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesImplemento.bind(this, item.id)}>{item.id}</button></StyledTableCell > 
                                                        <StyledTableCell className="text-left"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesImplemento.bind(this, item.id)}>{item.descricaoimplemento}</button></StyledTableCell >
                                                        <StyledTableCell className="text-center"><button className="btn btn-link btn-sm botaonovo" onClick={this.MostrarDetalhesImplemento.bind(this, item.id)}>{item.quantidade.toLocaleString('pt-BR')}</button></StyledTableCell >
                                                        <StyledTableCell className="colbuttons text-right"><button className="btn btn-link btn-sm botaonovo" onClick={this.onDeletarImplementoClick.bind(this, item.cliente, item.idcenso, item.id)}><DeleteForeverIcon /></button></StyledTableCell >
                                                    </StyledTableRow >
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <p />
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />

                {/* Popups para s07_implementos */}

                <div class="modal fade" id="detalhesimplemento" tabindex="-1" role="dialog" aria-labelledby="implementoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="implementoLabel">Implementos</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form noValidate autoComplete="off">
                                    <div class="form-group">
                                        <TextField disabled label="ID" id="id" name="id" value={this.state.s07_implemento.id} style={{width: 100 + 'px'}}/><p />
                                        <TextField label="Implemento" id="implemento" name="implemento" value={this.state.s07_implemento.idimplemento} style={{width: 100 + 'px'}}/>&nbsp;
                                        <TextField label="Descrição" id="descricaoimplemento" name="descricaoimplemento" value={this.state.s07_implemento.descricaoimplemento} style={{width: 300 + 'px'}}/>
                                        <Link onClick={this.PesquisaImplemento.bind(this)}><SearchIcon fontSize="large" /></Link><p />
                                        <TextField InputProps={{ inputComponent: integerMask }} label="Quantidade" id="quantidade" name="quantidade" value={this.state.s07_implemento.quantidade} style={{width: 100 + 'px'}} onChange={e => this.onChangeImplemento(e)} autoFocus/><p />
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary botoes" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-primary botoes" data-dismiss="modal" onClick={this.onConfirmarImplementoClick.bind(this)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="implementos" tabindex="-1" role="dialog" aria-labelledby="implementosLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <strong class="modal-title titulomodal" id="implementosLabel">Selecione o Implemento</strong>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                                <StyledTableCell className="colkey">ID</StyledTableCell>
                                                <StyledTableCell>Descrição</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.implementos.map((item, indice) => {
                                                    return (
                                                        <StyledTableRow  key={indice}>
                                                            <StyledTableCell  className="colkey"><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarImplemento.bind(this, item.id, item.descricao)}>{item.id}</button></StyledTableCell > 
                                                            <StyledTableCell ><button className="btn btn-link btn-sm botaonovo" onClick={this.SelecionarImplemento.bind(this, item.id, item.descricao)}>{item.descricao}</button></StyledTableCell >
                                                        </StyledTableRow >
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>    
        );
    }
}

export default S07_MaquinasVeiculos;