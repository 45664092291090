import React, { Component } from 'react';
import './CadastrosCenso.css';
import Navegacao from '../CadastrosCenso/Navegacao'
import { censobuscaid, censonovo, censoeditar } from '../../../Services/apifunctions';
import { decriptar, integerMask, decimalMask, ajustamaskdecimal, limpamask } from '../../funcoesgerais';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
 
class S11_Bovinos extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            novocadastro : false,
            s11_bovinos : {
                cliente : '',
                id : '',
                totalcabecas : '',
                vacasreprodutorascabecas : '',
                naoexistembovinos : '',
                finalidadeprincipal : '',
                fezconfinamento : '',
                totalvendidocabecas : '',
                totalvendidovalor : '',
                matrizesreprodutorescabecas : '',
                matrizesreprodutoresvalor : '',
                criarecriaengordacabecas : '',
                criarecriaengordavalor : '',
                animaisabatecabecas : '',
                animaisabatevalor : '',
                vacasordenhadascabecas : '',
                leiteproduzidolitros : '',
                leiteproduzidodialitros : '',
                leiteproduzidomeslitros : '',
                leitecruvendidolitros : '',
                precomediolitro : ''
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (localStorage.getItem('cadastrocenso') === '')
        {
            this.props.history.push('/S01');
        }
        else
        {
            censobuscaid(this.props.match.url, localStorage.getItem('cliente'), decriptar(localStorage.getItem('cadastrocenso'))).then(dados => { 
                if (dados.id !== 0) {
                    this.setState({s11_bovinos: {
                        ...this.state.s11_bovinos, 
                        cliente : dados.cliente,
                        id : dados.id,
                        totalcabecas : dados.totalcabecas,
                        vacasreprodutorascabecas : dados.vacasordenhadascabecas,
                        naoexistembovinos : dados.naoexistembovinos,
                        finalidadeprincipal : dados.finalidadeprincipal,
                        fezconfinamento : dados.fezconfinamento,
                        totalvendidocabecas : dados.totalvendidocabecas,
                        totalvendidovalor : dados.totalvendidovalor,
                        matrizesreprodutorescabecas : dados.matrizesreprodutorescabecas,
                        matrizesreprodutoresvalor : dados.matrizesreprodutoresvalor,
                        criarecriaengordacabecas : dados.criarecriaengordacabecas,
                        criarecriaengordavalor : dados.criarecriaengordavalor,
                        animaisabatecabecas : dados.animaisabatecabecas,
                        animaisabatevalor : dados.animaisabatevalor,
                        vacasordenhadascabecas : dados.vacasordenhadascabecas,
                        leiteproduzidolitros : dados.leiteproduzidolitros,
                        leiteproduzidodialitros : dados.leiteproduzidodialitros,
                        leiteproduzidomeslitros : dados.leiteproduzidomeslitros,
                        leitecruvendidolitros : dados.leitecruvendidolitros,
                        precomediolitro : dados.precomediolitro
                    }}, () => {
                        this.setState({
                            s11_bovinos: {...this.state.s11_bovinos, 
                                totalvendidovalor: ajustamaskdecimal(this.state.s11_bovinos.totalvendidovalor),
                                matrizesreprodutoresvalor: ajustamaskdecimal(this.state.s11_bovinos.matrizesreprodutoresvalor),
                                criarecriaengordavalor: ajustamaskdecimal(this.state.s11_bovinos.criarecriaengordavalor),
                                animaisabatevalor: ajustamaskdecimal(this.state.s11_bovinos.animaisabatevalor),
                                leiteproduzidolitros: ajustamaskdecimal(this.state.s11_bovinos.leiteproduzidolitros),
                                leiteproduzidodialitros: ajustamaskdecimal(this.state.s11_bovinos.leiteproduzidodialitros),
                                leiteproduzidomeslitros: ajustamaskdecimal(this.state.s11_bovinos.leiteproduzidomeslitros),
                                leitecruvendidolitros: ajustamaskdecimal(this.state.s11_bovinos.leitecruvendidolitros),
                                precomediolitro: ajustamaskdecimal(this.state.s11_bovinos.precomediolitro),
                            }
                        });
                        this.setState({ novocadastro : false });
                    });
                } 
                else {
                    this.setState({s11_bovinos: {...this.state.s11_bovinos, 
                        cliente : localStorage.getItem('cliente'),
                        id : decriptar(localStorage.getItem('cadastrocenso')),
                        totalcabecas : '',
                        vacasreprodutorascabecas : '',
                        naoexistembovinos : true,
                        finalidadeprincipal : '',
                        fezconfinamento : '',
                        totalvendidocabecas : '',
                        totalvendidovalor : '',
                        matrizesreprodutorescabecas : '',
                        matrizesreprodutoresvalor : '',
                        criarecriaengordacabecas : '',
                        criarecriaengordavalor : '',
                        animaisabatecabecas : '',
                        animaisabatevalor : '',
                        vacasordenhadascabecas : '',
                        leiteproduzidolitros : '',
                        leiteproduzidodialitros : '',
                        leiteproduzidomeslitros : '',
                        leitecruvendidolitros : '',
                        precomediolitro : ''
                    }});

                    this.setState({ novocadastro : true });
                }
            });
        }
    };

    onChange(e) {
        this.setState({ s11_bovinos: {...this.state.s11_bovinos, [e.target.name]: e.target.value }});
    }

    onCheckBoxClick(name, checked) {
        this.setState({ s11_bovinos: {...this.state.s11_bovinos, [name]: checked }}, () => {
            if (this.state.s11_bovinos.naoexistembovinos) {
                this.setState({
                    s11_bovinos: {...this.state.s11_bovinos, 
                        totalcabecas : '',
                        vacasreprodutorascabecas : '',
                        finalidadeprincipal : '',
                        fezconfinamento : '',
                        totalvendidocabecas : '',
                        totalvendidovalor : '',
                        matrizesreprodutorescabecas : '',
                        matrizesreprodutoresvalor : '',
                        criarecriaengordacabecas : '',
                        criarecriaengordavalor : '',
                        animaisabatecabecas : '',
                        animaisabatevalor : '',
                        vacasordenhadascabecas : '',
                        leiteproduzidolitros : '',
                        leiteproduzidodialitros : '',
                        leiteproduzidomeslitros : '',
                        leitecruvendidolitros : '',
                        precomediolitro : ''
                    }
                });
            }
            else
            {
                this.setState({
                    s11_bovinos: {...this.state.s11_bovinos, 
                        totalcabecas : '',
                        vacasreprodutorascabecas : '',
                        finalidadeprincipal : '1',
                        fezconfinamento : '1',
                        totalvendidocabecas : '',
                        totalvendidovalor : '',
                        matrizesreprodutorescabecas : '',
                        matrizesreprodutoresvalor : '',
                        criarecriaengordacabecas : '',
                        criarecriaengordavalor : '',
                        animaisabatecabecas : '',
                        animaisabatevalor : '',
                        vacasordenhadascabecas : '',
                        leiteproduzidolitros : '',
                        leiteproduzidodialitros : '',
                        leiteproduzidomeslitros : '',
                        leitecruvendidolitros : '',
                        precomediolitro : ''
                    }
                });
            }            
        });
    }

    async salvar(botao) {
        this.setState({
            s11_bovinos: {...this.state.s11_bovinos,
                totalcabecas: limpamask(true, this.state.s11_bovinos.totalcabecas),
                vacasreprodutorascabecas: limpamask(true, this.state.s11_bovinos.vacasreprodutorascabecas),
                totalvendidocabecas: limpamask(true, this.state.s11_bovinos.totalvendidocabecas),
                totalvendidovalor: limpamask(true, this.state.s11_bovinos.totalvendidovalor),
                matrizesreprodutorescabecas: limpamask(true, this.state.s11_bovinos.matrizesreprodutorescabecas),
                matrizesreprodutoresvalor: limpamask(true, this.state.s11_bovinos.matrizesreprodutoresvalor),
                criarecriaengordacabecas: limpamask(true, this.state.s11_bovinos.criarecriaengordacabecas),
                criarecriaengordavalor: limpamask(true, this.state.s11_bovinos.criarecriaengordavalor),
                animaisabatecabecas: limpamask(true, this.state.s11_bovinos.animaisabatecabecas),
                animaisabatevalor: limpamask(true, this.state.s11_bovinos.animaisabatevalor),
                vacasordenhadascabecas: limpamask(true, this.state.s11_bovinos.vacasordenhadascabecas),
                leiteproduzidolitros: limpamask(true, this.state.s11_bovinos.leiteproduzidolitros),
                leiteproduzidodialitros: limpamask(true, this.state.s11_bovinos.leiteproduzidodialitros),
                leiteproduzidomeslitros: limpamask(true, this.state.s11_bovinos.leiteproduzidomeslitros),
                leitecruvendidolitros: limpamask(true, this.state.s11_bovinos.leitecruvendidolitros),
                precomediolitro: limpamask(true, this.state.s11_bovinos.precomediolitro),
            }
        }, async () => {
            if (this.state.novocadastro) {
                censonovo(this.props.match.url, this.state.s11_bovinos);
            }
            else {
                censoeditar(this.props.match.url, this.state.s11_bovinos);
            }
            if (botao === 'Back') {
                this.props.history.push('/S10');
            }
            else
            if (botao === 'Next') {
                this.props.history.push('/S12');
            }
        });
    };

    render() {
        return (
            <div className="geral container-fluid">
                <Divider />
                    <div className="mr-auto p-2 bd-highlight text-center">
                        <strong>11 - BOVINOS</strong>
                    </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">01</small>
                            <small>-Bovinos no estabelecimento na data de referência?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <div className="row">
                            <div className="col-sm-3">Total</div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="totalcabecas" name="totalcabecas" value={this.state.s11_bovinos.totalcabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">Vacas reprodutoras</div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="vacasreprodutorascabecas" name="vacasreprodutorascabecas" value={this.state.s11_bovinos.vacasreprodutorascabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={this.state.s11_bovinos.naoexistembovinos} onClick={this.onCheckBoxClick.bind(this, "naoexistembovinos", !this.state.s11_bovinos.naoexistembovinos)} value="naoexistembovinos"/>}
                                    label="Não existiam bovinos"
                                /><br />  
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">02</small>
                            <small>-Qual foi a finalidade principal da criação bovina?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl disabled={this.state.s11_bovinos.naoexistembovinos} component="fieldset">
                            <RadioGroup aria-label="finalidadeprincipal" name="finalidadeprincipal" value={this.state.s11_bovinos.finalidadeprincipal} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Corte" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Leite" />
                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="Trabalho" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">03</small>
                            <small>-Fez confinamento?</small>
                        </span>
                    </div>
                    <div className="col-sm posicaorb">
                        <FormControl disabled={this.state.s11_bovinos.naoexistembovinos} component="fieldset">
                            <RadioGroup aria-label="fezconfinamento" name="fezconfinamento" value={this.state.s11_bovinos.fezconfinamento} onChange={e => this.onChange(e)}>
                                <FormControlLabel value="1" control={<Radio color="primary"/>} label="Não" />
                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="Sim, no próprio estabelecimento" />
                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="Sim, em outro estabelecimento" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">04</small>
                            <small>-Qual foi o número e o valor de bovinos vendidos?</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Total de bovinos vendidos</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="totalvendidocabecas" name="totalvendidocabecas" value={this.state.s11_bovinos.totalvendidocabecas} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="totalvendidovalor" name="totalvendidovalor" value={this.state.s11_bovinos.totalvendidovalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Matrizes e Reprodutores</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="matrizesreprodutorescabecas" name="matrizesreprodutorescabecas" value={this.state.s11_bovinos.matrizesreprodutorescabecas} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="matrizesreprodutoresvalor" name="matrizesreprodutoresvalor" value={this.state.s11_bovinos.matrizesreprodutoresvalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Cria, recria, engorda ou trabalho</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="criarecriaengordacabecas" name="criarecriaengordacabecas" value={this.state.s11_bovinos.criarecriaengordacabecas} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="criarecriaengordavalor" name="criarecriaengordavalor" value={this.state.s11_bovinos.criarecriaengordavalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Animais para abate</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="animaisabatecabecas" name="animaisabatecabecas" value={this.state.s11_bovinos.animaisabatecabecas} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="animaisabatevalor" name="animaisabatevalor" value={this.state.s11_bovinos.animaisabatevalor} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />
                <div className="row">
                    <div className="col-sm-12 subtitulo">
                        <span>
                            <small className="circulopreto">05</small>
                            <small>-Produção de leite no período</small>
                        </span>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Vacas ordenhadas</div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: integerMask, endAdornment: <InputAdornment position="end">Cabeças</InputAdornment> }} variant="outlined" size="small" id="vacasordenhadascabecas" name="vacasordenhadascabecas" value={this.state.s11_bovinos.vacasordenhadascabecas} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Leite produzido</div>
                            <div className="col-sm-3"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Litros Total</InputAdornment> }} variant="outlined" size="small" id="leiteproduzidolitros" name="leiteproduzidolitros" value={this.state.s11_bovinos.leiteproduzidolitros} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm-3"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Litros/Dia</InputAdornment> }} variant="outlined" size="small" id="leiteproduzidodialitros" name="leiteproduzidodialitros" value={this.state.s11_bovinos.leiteproduzidodialitros} onChange={e => this.onChange(e)}/><p /></div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Litros/Mês</InputAdornment> }} variant="outlined" size="small" id="leiteproduzidomeslitros" name="leiteproduzidomeslitros" value={this.state.s11_bovinos.leiteproduzidomeslitros} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Leite cru vendido (mesmo resfriado)</div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, endAdornment: <InputAdornment position="end">Litros</InputAdornment> }} variant="outlined" size="small" id="leitecruvendidolitros" name="leitecruvendidolitros" value={this.state.s11_bovinos.leitecruvendidolitros} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 posicaorb">Preço médio do litro do leite vendido</div>
                            <div className="col-sm"><TextField disabled={this.state.s11_bovinos.naoexistembovinos} InputProps={{ inputComponent: decimalMask, startAdornment: <InputAdornment position="start">R$</InputAdornment> }} variant="outlined" size="small" id="precomediolitro" name="precomediolitro" value={this.state.s11_bovinos.precomediolitro} onChange={e => this.onChange(e)}/><p /></div>
                        </div>
                    </div>
                </div>
                <Divider />
                <p />

                <div className="row">
                    <div className="col-sm text-right">
                        <Button onClick={this.salvar.bind(this, 'Back')}>
                            <ArrowBackIosIcon fontSize="large" />
                        </Button>
                    </div>
                    <div className="col-sm text-left">
                        <Button onClick={this.salvar.bind(this, 'Next')}>
                            <ArrowForwardIosIcon fontSize="large" />
                        </Button>
                    </div>
                </div>
                <Navegacao />
                <br />
            </div>    
        );
    }
}

export default S11_Bovinos;